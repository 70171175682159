<div layout="row" style="padding:30px 0px!important">
    <div fxLayout="column" fxLayoutAlign="center center">
        <h2 mat-dialog-title class="text-center"><b>DATA PROCESSING WORKSHEET</b></h2>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap>
        <div fxLayoutGap="10px">
             <span>Weight: </span>
            <mat-form-field class="example-full-width">
            <input type="number" label="lb" aria-label="number" matInput>
            </mat-form-field>
        </div>

        <div fxLayoutGap="10px">
            <span>Height :</span>
            <mat-form-field class="example-full-width">
            <input type="number" aria-label="Number" matInput>
            </mat-form-field>
        </div>
        <div fxLayoutGap="10px">
                <span>Width :</span>
            <mat-form-field class="example-full-width">
            <input type="number" aria-label="Number" matInput>
            </mat-form-field>
        </div>
        <div fxLayoutGap="10px">
                <span>Thickness :</span>
            <mat-form-field class="example-full-width">
            <input type="text" aria-label="text" matInput>
            </mat-form-field>
        </div>
    </div>

  <mat-divider fxLayoutGap="20px"></mat-divider>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div fxLayoutGap="20px">
                <span >Post Office :</span>
                <mat-form-field class="example-full-width">
                <input type="text" label="lb" aria-label="text" matInput>
                </mat-form-field>
            </div>

            <div fxLayoutGap="20px">
                <span>Permit # :</span>
                <mat-form-field class="example-full-width">
                <input type="text" aria-label="Number" matInput>
                </mat-form-field>
            </div>
        </div>

        <br>
        
        <div fxLayout="row"  fxLayoutGap="20px" >
            <div fxFlex="30">
                <mat-checkbox color="primary">Letter</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Flat</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Postcard</mat-checkbox>
            </div>
        </div>

        <br>
      
        <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="30">
                <mat-checkbox color="primary">Automation</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Non-Auto</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Non-Machinable</mat-checkbox>
            </div>
        </div>

        <br>
        
        <div fxLayout="row"  fxLayoutGap="20px">
            <div fxFlex="30">
                <mat-checkbox color="primary">Non-Profit</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Ancillary Svcs</mat-checkbox>
            </div>
            <div fxFlex="40" fxLayoutGap="20px">
                <span>Foreign?</span>
                <mat-radio-group fxLayoutGap="10px">
                    <mat-radio-button color="primary" value="true">Yes</mat-radio-button>
                    <mat-radio-button color="primary" value="false">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <br>

        <div fxLayout="row"  fxLayoutGap="20px" >
            <div fxFlex="30">
                <mat-checkbox color="primary">Full Rate First Class</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary"> First Class Part</mat-checkbox>
            </div>
            <div fxFlex="30">
                <mat-checkbox color="primary">Periodical</mat-checkbox>
            </div>
        </div>

        <br>
        
        <div fxLayout="row"  fxLayoutGap="20px" >
            <div>
                <mat-checkbox color="primary">Std Prsrt</mat-checkbox>
            </div>
        </div>
        <br>
    <mat-dialog-actions fxLayout="row"  fxLayoutGap="40px" fxLayoutAlign="center center">
      <button mat-raised-button [mat-dialog-close]="true"><strong>SAVE</strong></button>
      <button mat-button  mat-dialog-close><strong>CANCEL</strong></button>
    </mat-dialog-actions>
</div>