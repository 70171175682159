<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <strong class="header-text primary-text-color">{{ title }}</strong>

  <form [formGroup]="form" fxLayout="column" fxLayoutGap="6px">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        autofocus="true"
        placeholder="Name"
        formControlName="name"
        required
      />
      <mat-error *ngIf="hasError('name', 'required')"
        >You must enter name.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        type="text"
        placeholder="Description"
        formControlName="description"
      ></textarea>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="10px">
      <strong class="primary-text-color">Inactive / Active </strong>
      <mat-slide-toggle
        color="primary"
        formControlName="status"
      ></mat-slide-toggle>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="7px">
      <button
        mat-raised-button
        color="primary"
        fxFlex="100px"
        type="submit"
        (click)="onSubmitClick()"
      >
        SUBMIT
      </button>
      <button mat-raised-button type="button" (click)="onCloseClick()">
        CLOSE
      </button>
    </div>
  </form>
</div>
