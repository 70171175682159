import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { Store } from '@ngxs/store';
import { LoadActiveUser } from 'src/app/features/account/state/account.actions';

@Component({
  selector: 'app-select-datasource',
  templateUrl: './select-datasource.component.html',
  styleUrls: ['./select-datasource.component.scss']
})
export class SelectDatasourceComponent implements OnInit {
  dataSources: any;
  dataSourceName: string;
  selectedDataSource: any;
  dataSourceId: number;

  @ViewChild('searchDataSourceInput') movieSearchInput: ElementRef;

  constructor(private apiService: ApiService,
    private dialogRef: MatDialogRef<SelectDatasourceComponent>,
    private auth: AuthUserService,
    private store: Store) { }

  ngOnInit() {
    this.loadDatasource();
    const user = this.auth.getUser();
    this.dataSourceName = user.dataSourceName;
    this.dataSourceId = user.dataSourceId;
  }

  loadDatasource() {
    const params = {
      PAGE_NO: 0,
      PAGE_SIZE: 0
    }
    this.apiService.request("GET_DATASOURCES", { params: params }).subscribe(res => {
      if (res['dataSources'].length > 0) {
        this.dataSources = res['dataSources'];//.filter(d=>d.status === true);
        this.selectedDataSource = this.dataSources.find(d => d['dataSourceId'] === this.dataSourceId);
      }
    });
  }

  // private searchDataSource(text: string): void {
  //   const params = {
  //     params: {
  //       'q': text
  //     }
  //   };
  //   this.apiService.request('SEARCH_DATA_SOURCES', params)
  //     .subscribe(response => this.dataSources = response);
  // }

  submitClick() {
    this.apiService.request('SET_USER_DATASOURCE', {
      data: {
        dataSourceId: this.selectedDataSource.dataSourceId
      }
    })
      .subscribe(res => {
        if (res.isDataSourceUpdated) {
          //this.selectedDataSource = res.dataSourceName;
          localStorage.setItem('token', res.token);
          const user = this.auth.getUser();
          user.dataSourceId = res.dataSourceId;
          user.dataSourceName = res.dataSourceName;
          this.auth.saveUser(user);
          this.dialogRef.close();
          this.store.dispatch(new LoadActiveUser());
        }
      });
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
