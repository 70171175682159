<div
  class="padding-15"
  fxLayout="column"
  fxLayoutAlign="start center"
  style="width: 100%; height: 100%"
>
  <div fxFill fxFlex="9" fxLayout="row" fxLayoutGap="10px">
    <mat-icon *ngIf="currentMode == 'SHOW_JOB_ITEM_FILES_SELECTION_MODE'" class="pointer" (click)="arrowBackRedirect()">arrow_back</mat-icon>
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Move Files To Switch
    </span>
    <div class="search" *ngIf="currentMode === 'SHOW_JOB_ITEM_SELECTION_MODE'">
      <input #searchInput type="text" placeholder="Search" [(ngModel)]="searchKey" />
      <mat-icon class="pointer" matSuffix *ngIf="searchKey" (click)="clearSearch()">close</mat-icon>
    </div>
  </div>
  <div fxFill fxFlex="82">
    <div style="overflow:auto; height: 100%;">
      <ng-container
        *ngIf="currentMode === 'SHOW_JOB_ITEM_FILES_SELECTION_MODE'"
      >
        <ng-template
          *ngTemplateOutlet="
            JOBITEMFILES;
            context: { jobItemWithDocs: jobItemWithDocs, isLoading: isLoading }
          "
        ></ng-template>
      </ng-container>
      <ng-container *ngIf="currentMode === 'SHOW_JOB_ITEM_SELECTION_MODE'">
        <ng-template
          *ngTemplateOutlet="
            JOBITEMS;
            context: { filteredJobs: filteredJobs, isLoading: isLoading }
          "
        ></ng-template>
      </ng-container>
    </div>
  </div>
  <div fxFill fxFlex="9" fxLayout="column">
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutGap="10px">
      <span fxFlex></span>
      <!-- <div fxLayoutGap="10px" *ngIf="(jobs.length != 0) && !isJobItemSelected && !isLoading">
        <small class="warn">
          Select atleast one jobitem of Switch step.</small
        >
      </div> -->
      @if(currentMode === 'SHOW_JOB_ITEM_SELECTION_MODE'){
        @if((jobs.length != 0) && !isJobItemSelected && !isLoading){
          <div fxLayoutGap="10px">
            <small class="warn">
              Select atleast one jobitem of Switch step.</small
            >
          </div>
        }
      }@else if(!isFileSelected && this.jobItemWithDocs.length > 0 && !isLoading){
          <div fxLayoutGap="10px">
            <small class="warn">
              Select atleast one file.</small
            >
          </div>
      }
      <button mat-raised-button color="primary" [disabled]="currentMode === 'SHOW_JOB_ITEM_FILES_SELECTION_MODE' ?
       !isFileSelected : !isJobItemSelected" (click)="onSubmit()">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <span>
            <strong>{{
              currentMode === "SHOW_JOB_ITEM_FILES_SELECTION_MODE"
                ? "Move"
                : "Submit"
            }}</strong></span
          >
          <span *ngIf="isFilesUploading" class="loader"></span>
        </div>
      </button>
      <button mat-raised-button type="button" (click)="close()">CLOSE</button>
    </div>
  </div>
</div>

<ng-template
  #JOBITEMFILES
  let-jobItemWithDocs="jobItemWithDocs"
  let-isLoading="isLoading"
>
  <div *ngIf="jobItemWithDocs.length == 0 || isLoading" fxLayoutAlign="center">
    <h4 class="primary-text-color">
      {{ isLoading ? "Loading..." : "No Job Items Available!" }}
    </h4>
  </div>
  <div class="grid" fxLayout="column" *ngIf="jobItemWithDocs.length > 0">
    <ng-container *ngFor="let jobItemWithDocs of jobItemWithDocs">
      <div class="grid-row" fxFlex fxLayoutGap="10px">
        <span class="primary-text-color">{{
          jobItemWithDocs.jobItemDescription
        }}</span>
      </div>
      <div
        class="grid-item-row"
        *ngIf="
          !jobItemWithDocs['files'] || jobItemWithDocs['files'].length == 0
        "
      >
        No files available!
      </div>
      <div
        class="grid-item-row"
        fxFlex
        *ngFor="let file of jobItemWithDocs['files']"
        fxLayout="row"
      >
        <div
          fxLayoutAlign="start center"
          fxLayout="row"
          fxLayoutGap="10px"
          fxFlex="60"
        >
          <mat-checkbox (change)="onChangeFileCheckbox()"
            [(ngModel)]="file['isSelected']"
            color="primary"
          ></mat-checkbox>
          <img fxFlex="30px" [src]="file.url" />
          <span class="primary-text-color">{{ file.fileName }}</span>
        </div>
        <div
          fxLayoutAlign="start center"
          fxLayout="row"
          fxLayoutGap="10px"
          fxFlex="40"
        >
          <span class="primary-text-color">{{ file.newFileName }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #JOBITEMS let-filteredJobs="filteredJobs" let-isLoading="isLoading">
  <div *ngIf="filteredJobs.length === 0 || isLoading" fxLayoutAlign="center">
    <h4 class="primary-text-color">
      {{ isLoading ? "Loading..." : "No Jobs Available!" }}
    </h4>
  </div>
  <div class="grid" fxLayout="column" *ngIf="filteredJobs.length > 0">
    <ng-container *ngFor="let job of filteredJobs">
      <div class="grid-row" fxFlex fxLayoutGap="10px">
        <span class="primary-text-color"
          >{{ job.jobNumber }} - {{ job.customerName }}</span
        >
      </div>
      <div
        class="grid-item-row"
        fxFlex
        *ngFor="let item of job['items']"
        fxLayout="row"
      >
        <div
          fxLayoutAlign="start center"
          fxLayout="row"
          fxLayoutGap="10px"
          fxFlex="50"
        >
          <mat-checkbox (change)="onChangeItemCheckbox($event, item)"
            [disabled]="item['isDisabled']"
            [(ngModel)]="item['isSelect']"
            color="primary" style="height: 36px !important;"
          ></mat-checkbox>
          <span class="primary-text-color">{{ item.description }}</span>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" class="detail-item" fxFlex="50">
          <mat-chip-set>
            <mat-chip class="mat-chip-height">
              <strong class="font-size">Quantity:</strong>
              <span class="primary-text-color font-size">{{ item.chargeQty }}</span>
            </mat-chip>
            <mat-chip class="mat-chip-height">
              <strong class="font-size">Stock:</strong>
              <span class="primary-text-color font-size"> {{ item.stock }}</span>
            </mat-chip>
            <mat-chip class="mat-chip-height">
              <span class="primary-text-color font-size">
                {{ item.currentStepName | titlecase }} /
            {{ item.currentActionName | titlecase }}</span>
            </mat-chip>
          </mat-chip-set>
        </div>

      </div>
    </ng-container>
  </div>
</ng-template>
