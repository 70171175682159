import { Component, OnInit } from "@angular/core";
import { DataSourceCreateComponent } from "./data-source-create/data-source-create.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ApiService } from "src/app/shared/services/api.service";
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: "app-data-source",
  templateUrl: "./data-source.component.html",
  styleUrls: ["./data-source.component.scss"]
})
export class DataSourceComponent implements OnInit {
  dataSources: [];
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;
  subTitles = [
    { text: "Configuration", route: "" },
    { text: "Data Source", route: "" }
  ];
  displayedColumns: string[] = ["position", "name", "path", "status", "action"];

  constructor(public dialog: MatDialog, private apiService: ApiService, private toast: ToastService) { }

  ngOnInit() {
    this.getDataSources();
  }

  /**
   * Get all data sources.
   */
  getDataSources() {
    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize
    }
    this.apiService.request("GET_DATASOURCES", { params: params }).subscribe(res => {
      this.totalRecord = res['totalRecord'];
      this.dataSources = res['dataSources'];
    });
  }

  /**
   * Open create datasource dialog.
   */
  onCreateStepClick() {
    const dialogRef = this.dialog.open(DataSourceCreateComponent, {
      width: "500px"
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) this.getDataSources();
    });
  }

  /**
   * open edit user dialog.
   */
  onEditClick(dataSourcs) {
    const dialogRef = this.dialog.open(DataSourceCreateComponent, {
      width: "500px",
      data: dataSourcs
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) this.getDataSources();
    });
  }

  /**
   * on delete click.
   */
  onDeleteClick(dataSourceId: string) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this data source ?"
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.apiService
          .request("DELETE_DATASOURCE", {
            params: { DATASOURCE_ID: dataSourceId }
          })
          .subscribe(resource => {
            if (resource['success']) {
              this.getDataSources();
              return;
            }
            this.toast.show(resource['message']);
          });
    });
  }

  /**
   * on page change
   */
  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.getDataSources();
  }
}
