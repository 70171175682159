import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formateTime'
})
export class FormateTimePipe implements PipeTransform {

  transform(seconds: number): string {
    var days = Math.floor(seconds/86400);
    var hours = Math.floor((seconds % 86400) / 3600);
    var mins = Math.floor(((seconds % 86400) % 3600) / 60);
    var secs = ((seconds % 86400) % 3600) % 60;
    return (days > 0 ? days+'d ' : '') + ('00'+hours).slice(-2) +':' + ('00'+mins).slice(-2)+':' + ('00'+secs).slice(-2);

    // const minutes: number = Math.floor(value / 60);
    // const hours: number = Math.floor(minutes/60);
    // return ('00' + Math.floor(hours) ).slice(-2) + ':'+ ('00' + Math.floor(minutes - hours * 60) ).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }

}
