import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { RYOBI_DEPARTMENT } from "src/app/shared/constant/department";
import { Job } from "src/app/shared/interfaces/job";
import { ApiService } from "src/app/shared/services/api.service";
import { RyobiPdfPrintService } from "src/app/shared/services/ryobi-pdf-print.service";

@Component({
  selector: "app-job-list-ryobi-print",
  templateUrl: "./job-list-ryobi-print.component.html",
  styleUrls: ["./job-list-ryobi-print.component.scss"],
})
export class JobListRyobiPrintComponent {
  jobs: Array<Job> = [];
  filterJobs: Array<Job> = [];
  isShowConfirmation = false;
  isLoading = false;
  jobLoading = true;
  searchKey = "";
  items = [];
  @ViewChild("searchInput") searchInput: ElementRef;
  selectedJobItems:Array<any> = [];
  isJobItemSelected : boolean = false;

  constructor(
    public dialogRef: MatDialogRef<JobListRyobiPrintComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ryobiPrint: RyobiPdfPrintService,
  ) {}

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.search(text);
      });
  }

  ngOnInit() {
    this.loadJobs();
  }

  loadJobs() {
    this.data.request["isExpand"] = true;
    this.api.request("GET_JOBS", { data: this.data.request }).subscribe(
      (response) => {
        this.jobs = response.jobs;
        this.jobs = this.jobs.filter((j) => j.status == "1");
        this.jobs.forEach((j: any) => {
          j.items = j.items.filter((i) => i.status == 1);
          j.items.forEach((item) => {
            item["isSelect"] = false;
            item["isDisabled"] = false;

            //Disable or enable checkbox
            if (item.departmentName.toLowerCase() == RYOBI_DEPARTMENT) {
              item["isDisabled"] = false;
            } else {
              item["isDisabled"] = true;
            }
          });
        });
        this.filterJobs = this.jobs;
        this.jobLoading = false;
      },
      (error) => {
        this.jobLoading = false;
      }
    );
  }

  printRyobiJobs() {
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find((i) => i["isSelect"])) {
        this.isShowConfirmation = true;
        this.isJobItemSelected = true;
      }
      if (this.isShowConfirmation) {
        break;
      }
    }
  }

  confirmPrintRyobiJobs() {
    this.isShowConfirmation = false;
    this.isLoading = true;

    let printJobs = this.jobs.reduce((res, job) => {
      let items = job.items.filter((i) => i["isSelect"]);
      if(items.length > 0){
        let itemIds = [];
        job.items.forEach((item) => {
          if (item["isSelect"]) {
            itemIds.push(item.jobItemId);
          }
        });
        res.push({ jobId: job.jobId, jobItemIds: itemIds });
      }
      return res;
    }, []);

    const payload = {
      jobs: printJobs,
    };
    
    this.ryobiPrint.printRyobiJobs(payload);
    this.isLoading = false;  
  }

  close() {
    if (this.isLoading) {
      return;
    }
    this.dialogRef.close();
  }

  search(search) {
    this.filterJobs =
      !search || search.trim() === ""
        ? this.jobs
        : this.jobs.filter(
            (j) =>
              j.jobNumber.toString().includes(search.toLowerCase().trim()) ||
              j.customerName.toLowerCase().includes(search.toLowerCase().trim())
          );
  }

  clearSearch() {
    this.searchKey = "";
    this.search(this.searchKey);
  }

  onChangeCheckbox(event, item){
    this.selectedJobItems = [];
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find(i => i['isSelect'])) {
        this.isJobItemSelected = true;
        this.selectedJobItems.push(job.items.filter(_item => _item['isSelect']));
      }
      if (this.isShowConfirmation) { break; }
    }
    this.selectedJobItems.length > 0 ? ((this.isJobItemSelected = true ) && (this.isShowConfirmation = false)) : ((this.isJobItemSelected = false) && (this.isShowConfirmation = false));
 
  }

}
