<div class="padding-15">
  <div fxLayoutAlign="start center">
    <mat-icon style="cursor: pointer" (click)="this.dialogRef.close()"
      >arrow_back</mat-icon
    >
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Select Address
    </span>
  </div>
  <div
    class="grid"
    fxLayout="column"
    style="height: calc(100vh - 130px); margin-top: 10px"
  >
    <!-- Filters -->
    <div class="filter" fxLayout="row" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">Customer</mat-label>
        <input
          matInput
          placeholder="Customer"
          (keyup)="search($event.target.value, filterType.Customer)"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">Company</mat-label>
        <input
          matInput
          placeholder="Company"
          (keyup)="search($event.target.value, filterType.Company)"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">Attention</mat-label>
        <input
          matInput
          placeholder="Attention"
          (keyup)="search($event.target.value, filterType.Attention)"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">State</mat-label>
        <input
          matInput
          placeholder="State"
          (keyup)="search($event.target.value, filterType.StateProvinceCode)"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">City</mat-label>
        <input
          matInput
          placeholder="City"
          (keyup)="search($event.target.value, filterType.City)"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="16">
        <mat-label class="primary-text-color">PostalCode</mat-label>
        <input
          matInput
          placeholder="PostalCode"
          maxlength="6"
          onkeypress="return event.charCode >= 48 && event.charCode  <= 57 "
          (keyup)="search($event.target.value, filterType.PostalCode)"
        />
      </mat-form-field>
    </div>

    <!-- <span *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" style="height: calc(100vh - 280px)">
        Loading...</span> -->
    <div
      class="grid-header"
      fxLayoutGap="2px"
      fxLayoutAlign="center center"
      style="height: 50px !important"
    >
      <small fxFlex="10"
        ><strong class="primary-text-color">Customer</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Company</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Attention</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Address</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">State</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">City</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">PostalCode</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">CountryCode</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Phone</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Email</strong></small
      >
    </div>

    <div fxLayout="row" fxLayoutAlign="center" fxFlex>
      <div *ngIf="addresses.length == 0">
        <h4 class="primary-text-color">No Address Available!</h4>
      </div>
    </div>

    <div style="overflow: auto;">
      <div
        class="grid-row"
        fxLayoutGap="2px"
        fxLayoutAlign="center center"
        *ngFor="let address of addresses"
        (click)="select(address)"
      >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="address['customer']"
          >{{ address["customer"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="address['company']"
          >{{ address["company"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="address['attention']"
          >{{ address["attention"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="address['address']"
          >{{ address["address"] }}</small
        >
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["stateProvinceCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["city"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["postalCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["countryCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["phone"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          address["email"]
        }}</small>
      </div>
    </div>
   
    <mat-paginator fxFlex="75px" fxLayoutAlign="end end"
      (page)="onPageChange($event)"
      [length]="total"
      [pageSize]="req.pageSize"
      [pageSizeOptions]="[50, 100, 200]"
    >
    </mat-paginator>
  </div>
</div>
