import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceChar'
})
export class ReplaceCharPipe implements PipeTransform {

  transform(phoneNo: any, args?: any): any {
   
    if(phoneNo != null)
    {
      var returnvalue = "";
   
      var No = phoneNo
      .split(',').join('')
      .split(')').join('')
      .split('(').join('')
      .split('.').join('')
      .split(' ').join('')
      .split('/').join('')
      .split('*').join('')
      .split('-').join('')
      .split('_').join('')
      
      var TempNo =  No.trim();
  
     if(TempNo.includes("+") || TempNo == "")
     {
      return phoneNo;
     }
     else {
        var firstgroup = TempNo.slice(0, 3);
        var secondgroup = TempNo.slice(3, 6);
        var thirdgroup = TempNo.slice(6, 10);
        
        returnvalue = "(" + firstgroup + ")" + " " + secondgroup + "-" + thirdgroup ;
  
        return returnvalue;
    }
    }
}
}
