import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Job } from 'src/app/shared/interfaces/job';
import { Customer } from 'src/app/shared/interfaces/customer';
import { Store } from '@ngxs/store';
import { LoadJobs } from '../state/job.actions';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';
import { forkJoin } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ToastService } from 'src/app/shared/services/toast.service';

export interface Jobs {
  id: number;
  department: string;
  actions: string;
}

@Component({
  selector: 'app-job-import',
  templateUrl: './job-import.component.html',
  styleUrls: ['./job-import.component.scss']
})

export class JobImportComponent implements OnInit {
  jobForm = false;
  job: Job;
  customer: Customer;
  date = new Date();
  dueDate = new Date();
  displayedColumns: string[] = ['department', 'actions', 'jobActions'];
  isValidate = true;
  isLoading = false;
  canImportJob = false;
  isWorkflowView = false;
  stepItems;

  constructor(
    public dialogRef: MatDialogRef<JobImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private api: ApiService,
    private router: Router,
    private userService: AuthUserService,
    private notificationHub: NotificationHubService,
    private permissionService : PermissionService,
    private toast : ToastService
  ) {
    data.items = [];
    this.job = data;
  }
  now = new Date();
  today: string;
  selectedStep = null
  
  ngOnInit() {
    this.loadJobDetails();
    this.today = this.now.toISOString();
    this.canImportJob = this.permissionService.canImportJob();
  }


  loadJobDetails(): void {
    const params = {
      params: {
        JOBNUMBER: this.job['jobNumber']
      }
    };

    this.api.request('IMPORT_JOB', params).subscribe(response => {
      this.job['isPaid'] = response['isPaid'];
      this.job['CanBypassPayment'] = response['CanBypassPayment'];
      this.job['isPrinterPlan'] = response['isPrinterPlan'];
      this.job.items = response.items.filter(item => item.serNo === 0);
      this.job.items.forEach(item => {
        const services = response.items.filter(
          jitem => jitem.itemNo === item['itemNo'] && jitem.serNo > 0
        );
        item['services'] = services.sort(
          (a, b) => Number(a.sortNo) > Number(b.sortNo)
        );
      });
    });
  }

  onCloseWorkflow(items) {
    this.stepItems = items;
    this.isWorkflowView = false;
  }

  onImportJob() {

    if(!this.canImportJob){
      this.toast.show("Don't have permission to import job");
      return;
    }

    // steps select for job items
    if (!this.stepItems) {
      this.isValidate = false;
      return;
    }

    this.isLoading = true;

    const jobItems = this.stepItems.map(item => {

      let steps = '';
      const notifications = [];
      const notes = [];
      //const currentStep = item.steps[0].stepId + '-' + item.steps[0].actionId;
      let mailingSorts = [];
      let mailingInfos = [];

      //job item note
      if (item.note) {
        notes.push({
          jobItemNo: item.itemNo,
          text: item.note,
          step: null,
          stepIndex: null,
          priority: item.notePriority
        });
      }

      item.steps.forEach((stepAction, index) => {
        if (steps.length > 0) {
          steps += '|';
        }
        const step = stepAction.step.stepId + '-' + stepAction.action.actionId;
        steps += step;

        const stepNotification = {// add all step notification
          jobItemNo: item.itemNo,
          step: step,
          stepIndex: index,
          isNotify: stepAction.notification
        };

        notifications.push(stepNotification);

        if (stepAction.note) { // add all steps note
          const note = {
            jobItemNo: item.itemNo,
            step: step,
            stepIndex: index,
            text: stepAction.note,
            priority: stepAction.notePriority
          };

          // step note
          notes.push(note);
        }


        let mailingSort = stepAction['mailingSort'];
        let mailingInfo = stepAction['mailingInfo'];
        if (mailingSort != undefined) {
          mailingSort['step'] = step;
          mailingSort['stepIndex'] = index;
          mailingSorts.push(mailingSort);
        }

        if (mailingInfo != undefined) {
          mailingInfo['step'] = step;
          mailingInfo['stepIndex'] = index;
          mailingInfos.push(mailingInfo);
        }
      });

      return {
        itemNo: item.itemNo,
        itemId: item.itemId,
        isUnion: item.isUnion,
        description: item.description,
        steps: steps,
        notifications: notifications,
        notes: notes,
        chargeQty: item.chargeQty,
        cSRName: item.cSRName,
        mailingSorts: mailingSorts,
        mailingInfos: mailingInfos,
        departmentsToNotify : item['departmentsToNotify'],
        isNotifyDepartmentUsers : item['isNotifyDepartmentUsers']
      };
    });


    const params = {
      data: {
        JOBNUMBER: this.job['jobNumber'],
        dateDue: this.job['dateDue'],
        dateIn: this.job['dateIn'],
        csrNo: this.job['csrNo'],
        rushDate: this.job['rushDate'],
        customerName: this.job['customerName'],
        csrName: this.job.items[0]['csrName'],
        jobItems: jobItems,
        jobTitle: this.job.title,
        isPrinterPlan: true,
      }
    };

    this.api.request('CREATE_JOB', params).subscribe(response => {
      if (response) {
        this.saveFiles(response.jobItemIds);
        this.sendNotification(jobItems);
      }
    }, error => { this.isLoading = false });
  }

  sendNotification(items) {
    items.forEach(item => {
      const notification = {
        JobId : this.job.jobId,
        JobNumber: this.job['jobNumber'],
        JobItemNo: item.itemNo,
        jobItemName: item.description,
        steps: item.steps,
        currentStepIndex: item.currentStepIndex,
        changeStepIndex: item.currentStepIndex
      };
      this.notificationHub.sendNotification(notification);
    });
  }

  saveFiles(jobItemIds) {
    if (this.stepItems['files'] && this.stepItems['files'].size > 0) {
      const formdata = new FormData();
      this.stepItems['files'].forEach(file => {
        formdata.append('files', file.file);
      });
      jobItemIds.forEach(jobItemId => {
        formdata.append('JobItemIds', jobItemId);
      });
      this.api.request('CREATE_JOB_ITEM_DOCUMENT', { data: formdata }).subscribe(res => {
        this.isLoading = false;
        this.closeDialog();
      }, error => {
        this.isLoading = false;
        this.closeDialog();
      });
    } else {
      this.isLoading = false;
      this.closeDialog();
    }
  }

  closeDialog(){
    this.dialogRef.close();
    if(this.router.url === '/jobs'){
      this.store.dispatch(new LoadJobs(this.userService.getUser().filter[0]));
    }else{
      this.router.navigate(['/jobs']); // naviate to job list page
    }
  }
}

