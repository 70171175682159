import { Component, OnInit, Inject, viewChild, ElementRef, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { debounceTime, distinctUntilChanged, fromEvent, map } from "rxjs";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { Job } from "src/app/shared/interfaces/job";
import { ApiService } from "src/app/shared/services/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-upload-to-switch",
  templateUrl: "./upload-to-switch.component.html",
  styleUrls: ["./upload-to-switch.component.scss"],
})
export class UploadToSwitchComponent implements OnInit {
  currentMode: EnumSwitchUploadFilesMode = null;
  currentToast = null;
  isLoading = false;
  isFilesUploading = false;
  jobItemIds = [];
  jobItemWithDocs: JobItemWithDoc[] = [];
  jobs: Array<Job> = [];
  isJobItemSelected: boolean ;
  isFileSelected:boolean ;
  selectedJobItems = [];
  selectedFiles = [];
  searchKey = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  filteredJobs: Array<Job> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      jobItemIds: number[];
      mode: EnumSwitchUploadFilesMode;
      getJobsRequestObject: object;
    },
    public dialogRef: MatDialogRef<UploadToSwitchComponent>,
    public dialog: MatDialog,
    private api: ApiService,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void{
    fromEvent(this.searchInput.nativeElement, "keyup")
    .pipe(
      map((event:any) => {
        return event.target.value;
      }),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe((text:string) => {
      this.search(text);
    })
  }

  ngOnInit(): void {
    this.currentMode = this.data.mode;
    if (
      this.currentMode ==
        EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_FILES_SELECTION_MODE &&
      this.data?.jobItemIds &&
      Array.isArray(this.data?.jobItemIds) &&
      this.data?.jobItemIds?.length > 0
    ) {
      this.isLoading = true;
      this.jobItemIds = this.data.jobItemIds;
      this.loadFilesByJobItemIds(this.jobItemIds);
    }

    if (
      this.currentMode == EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_SELECTION_MODE
    ) {
      this.isLoading = true;
      this.loadJobItems(this.data.getJobsRequestObject);
    }
  
  }

  loadFilesByJobItemIds(jobItemIds: number[]) {
    this.jobItemWithDocs = [];
    let selectedItemIds = [];
    if(this.jobItemWithDocs.length > 0){
    let selectedIds = this.jobItemWithDocs.map(_item => _item.jobItemId);
     selectedItemIds = jobItemIds.filter(_id => !selectedIds.includes(_id));
    }

    var reqObj = {
      data: {
        jobItemIds: this.jobItemWithDocs.length == 0 ? jobItemIds : selectedItemIds,
      },
    };

    this.api
      .request("GET_DOCUMENTS_BY_JOB_ITME_IDS_FOR_SWITCH", reqObj)
      .subscribe((res) => {
        this.isLoading = false;
        if (res["success"]) {
          for (let i = 0; i < res.result.length; i++) {
            let { jobItemId, files, jobItemDescription } = res.result[i];

            this.jobItemWithDocs.push({
              jobItemId: jobItemId,
              jobItemDescription: jobItemDescription,
              files: files.map((_) => ({
                fileId: _.fileId,
                fileName: _.fileName,
                newFileName: _.newFileName,
                url: this.getFileSrc(_),
                isSelected: false,
              })),
            });
          }
        } else {
          this.openToast(res["message"]);
          this.isLoading = false;
        }
      });

      this.onChangeFileCheckbox();
  }

  loadJobItems(request) {
    
    let payload = {
      ...request,
      isMoveToSwitch : true
    }

    this.data.getJobsRequestObject["isExpand"] = true;
    this.api.request("GET_JOBS", { data: payload }).subscribe((response) => {
      this.jobs = response.jobs;
      this.jobs = this.jobs.filter((j) => j.status == "1");
      this.jobs.forEach((j: any) => {
        j.items = j.items.filter((i) => i.status == 1);
        j.items.forEach((item) => {
          item["isSelect"] = false;
          item["isDisabled"] = false;
          if (!item.canMoveToSwitch) {
            item["isDisabled"] = true;
          }
        });
      });
      this.filteredJobs = this.jobs;
      this.isLoading = false;
    });
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500,
    });
  }

  close() {
    if (this.isFilesUploading) {
      return;
    }
      this.dialogRef.close();
    
  }
  
  arrowBackRedirect(){
    if (this.isFilesUploading) {
      return;
    }
    if(this.currentMode === 'SHOW_JOB_ITEM_FILES_SELECTION_MODE'){
      if(this.data.jobItemIds.length > 0 && this.data.mode == 'SHOW_JOB_ITEM_FILES_SELECTION_MODE'){
        this.dialogRef.close();
      }
      this.currentMode = EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_SELECTION_MODE;
    }else{
      this.dialogRef.close();
    }
  }

  onSubmit() {
    if (
      this.currentMode ==
      EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_FILES_SELECTION_MODE
    ) {
      let selectedFileIds = [];
      for (let index = 0; index < this.jobItemWithDocs.length; index++) {
        const element = this.jobItemWithDocs[index];
        const elementSelectedFilIds = element.files
          .filter((_) => _.isSelected)
          .map((_) => _.fileId);
        selectedFileIds = [...selectedFileIds, ...elementSelectedFilIds];
      }
      selectedFileIds.length > 0 ? this.isFileSelected = true : this.isFileSelected = false;
      if(selectedFileIds.length > 0){
        this.isFileSelected = true;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        height: "170px",
        width: "450px",
        data: {
          type: "Confirm",
          message: "Are you sure you want to move files to switch?",
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // let selectedFileIds = [];
          // for (let index = 0; index < this.jobItemWithDocs.length; index++) {
          //   const element = this.jobItemWithDocs[index];
          //   const elementSelectedFilIds = element.files
          //     .filter((_) => _.isSelected)
          //     .map((_) => _.fileId);
          //   selectedFileIds = [...selectedFileIds, ...elementSelectedFilIds];
          // }

          const reqObject = {
            data: {
              fileIds: selectedFileIds,
            },
          };
          this.api
            .request("MOVE_FILES_TO_SWITCH", reqObject)
            .subscribe((response) => {
              if (response.success) {
                this.openToast(response["message"]);
                this.close();
              }else{
                this.openToast(response["message"]);
              }
            });
        }
      });
    }
    }

    if (
      this.currentMode ===
      EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_SELECTION_MODE
    ) {
      this.jobItemIds = [];
      for (let index = 0; index < this.jobs.length; index++) {
        const job = this.jobs[index];
        if (job.items.find((i) => i["isSelect"])) {
          this.jobItemIds = [
            ...this.jobItemIds,
            ...job.items.filter((i) => i["isSelect"]).map((_) => _.jobItemId),
          ];
        }
      }

      if(this.jobItemIds.length > 0){
        this.isJobItemSelected = true;
        this.currentMode =
        EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_FILES_SELECTION_MODE;
      this.isLoading = true;
      this.loadFilesByJobItemIds(this.jobItemIds);
      }else{
        this.isJobItemSelected = false;
      }
     
    }
  }

  /**Image related functions */
  getDocumentUrl(docType: string) {
    switch (docType.toLowerCase()) {
      case "pdf":
        return "assets/images/pdf_thumbnail.svg";
      case "document":
        return "assets/images/doc_thumbnail.svg";
      case "excel":
        return "assets/images/xls_thumbnail.png";
      case "tiff":
        return "assets/images/tiff_thumbnail.svg";
      case "tif":
        return "assets/images/tiff_thumbnail.svg";
      case "svg":
        return "assets/images/svg_thumbnail.svg";
      case "zip":
        return "assets/images/zip_thumbnail.svg";
      default:
        return "assets/images/doc_thumbnail.svg";
    }
  }

  getDocumentType(fileExtension: string) {
    switch (fileExtension.toLowerCase()) {
      case "jpg":
        return "Image";
      case "jpeg":
        return "Image";
      case "png":
        return "Image";
      case "svg":
        return "Svg";
      case "gif":
        return "Image";
      case "tiff":
        return "Tiff";
      case "tif":
        return "Tiff";
      case "pdf":
        return "Pdf";
      case "xlsx":
        return "Excel";
      // case 'csv':
      //   return 'Csv';
      case "xls":
        return "Excel";
      // case 'zip':
      //   return 'zip';
      default:
        return "Document";
    }
  }

  isImageTypeDocument(fileName: string) {
    let docType = "Document";
    const fileExtension = fileName.split(".")[fileName.split(".").length - 1];
    return this.getDocumentType(fileExtension);
  }

  getFileSrc(file) {
    let docType = "Document";
    let documentURL = "";
    docType = this.isImageTypeDocument(file.fileName);
    if (docType === "Image") {
      if (file["isUploadedByCustomer"]) {
        documentURL = `${environment.customerPortalUrl}/api/documents/${file.fileId}/thumbnail`;
      } else {
        documentURL = `${environment.baseUrl}/api/documents/${file.fileId}/thumbnail`;
      }
    } else {
      documentURL = this.getDocumentUrl(docType);
    }
    return documentURL;
  }

  onChangeItemCheckbox(event, jobItem){
    this.selectedJobItems = [];
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find((i) => i["isSelect"])) {
        this.selectedJobItems = [
          ...this.jobItemIds,
          ...job.items.filter((i) => i["isSelect"]).map((_) => _.jobItemId),
        ];
      }
    }
    this.selectedJobItems.length > 0 ? this.isJobItemSelected = true : this.isJobItemSelected = false;
  }

  onChangeFileCheckbox(){
    this.selectedFiles = [];
    for (let index = 0; index < this.jobItemWithDocs.length; index++) {
      const element = this.jobItemWithDocs[index];
      const elementSelectedFilIds = element.files
        .filter((_) => _.isSelected)
        .map((_) => _.fileId);
        this.selectedFiles = [...this.selectedFiles, ...elementSelectedFilIds];
    }
    this.selectedFiles.length > 0 ? this.isFileSelected = true : this.isFileSelected = false;
  }

  search(search){
    this.filteredJobs = 
      !search || search.trim() === "" 
      ? this.jobs 
      : this.jobs.filter((j)=> 
        j.jobNumber.toString().includes(search.toLowerCase().trim()) ||
        j.customerName.toLowerCase().includes(search.toLowerCase().trim())
      );

  }

  clearSearch(){
    this.searchKey = "";
    this.search(this.searchKey);
  }

}

export interface JobItemWithDoc {
  jobItemId: number;
  jobItemDescription: string;
  files: JobItemFile[];
}

export interface JobItemFile {
  fileId: number;
  fileName: string;
  newFileName: string;
  url: string;
  isSelected: boolean;
}

export enum EnumSwitchUploadFilesMode {
  SHOW_JOB_ITEM_FILES_SELECTION_MODE = "SHOW_JOB_ITEM_FILES_SELECTION_MODE",
  SHOW_JOB_ITEM_SELECTION_MODE = "SHOW_JOB_ITEM_SELECTION_MODE",
}
