<div fxLayout="row" fxLayoutGap="20px" style="height: calc(100vh - 160px)">
  <div fxFlex="300px" style="overflow:auto">
    <mat-action-list >
      <button
        *ngFor="let configuration of configurations"
        (click)="onConfigurationSelected(configuration)"
        [class.selected]="selectedConfiguration === configuration"
        mat-list-item
      >
        <div fxLayout="row" style="font-size: 14px">
          <mat-icon fxFlex="20">{{ configuration.icon }}</mat-icon>
          <div fxFlex="80" class="primary-text-color">
            {{ configuration.name }}
          </div>
        </div>
      </button>
    </mat-action-list>
  </div>
  <div fxFlex>
    <div #configurationComponent></div>
  </div>
</div>
