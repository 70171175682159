import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { AuthUserService } from './auth-user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  user: User
  constructor(private userService: AuthUserService) {
  }

  public isAdminOrCSR() {
    this.user = this.userService.getUser();
    if (this.user && this.user.currentDepartment && (this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr')) {
      return true;
    }
    return false;
  }

  public isCSR() {
    this.user = this.userService.getUser();
    if (this.user && this.user.currentDepartment && (this.user.currentDepartment.departmentName.toLowerCase() === 'csr')) {
      return true;
    }
    return false;
  }

  public isAdmin() {
    this.user = this.userService.getUser();
    if (this.user && this.user.currentDepartment && (this.user.currentDepartment.departmentName.toLowerCase() === 'administration')) {
      return true;
    }
    return false;
  }
  
  public isCurrentDepartment(name){
    this.user = this.userService.getUser();
    return this.user && this.user.currentDepartment.departmentName.toLowerCase() === name.toLowerCase() ? true : false;
  }

  public canAccessNavigationMenu(menuItem) {
    this.user = this.userService.getUser();
    if (!this.user || !this.user.currentDepartment) { return; }
    switch (menuItem.title) {
      case 'CONFIGURATION':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration') { return true; }
        return false;
      case 'CUSTOMERS':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr') { return true; }
        return false;
      case 'ORDERS':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr') { return true; }
        return false;
      case 'DASHBOARD':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration') { return true; }
        return false;
      case 'SHIPMENTS':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr' || this.user.currentDepartment.departmentName.toLowerCase() === 'shipping') { return true; }
        return false;
      case 'COLLECTIONS':
        if (this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr') { return true; }
        else{return false;}
      default:
        return true;
    }
  }

  public canAccessJobItemDepartment(departmentName) {
    this.user = this.userService.getUser();
    var allUserDepts = this.user.departments.map(d => d.departmentName.toLowerCase());
    if (!departmentName) {
      return true;
    } else if (this.user && this.user.currentDepartment.departmentName.toLowerCase() === 'administration' || this.user.currentDepartment.departmentName.toLowerCase() === 'csr') {
      return true;
    } else if (this.user && allUserDepts.filter(d => d === departmentName.toLowerCase()).length > 0) {
      return true;
    }
    return false;
  }

  public canImportJob(){
    this.user = this.userService.getUser();
    return this.user.isAllowImportPrinterPlanJob ? true : false;
  }
}
