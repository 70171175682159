<div class="brand">
  <h2>
    Pacific Printing
  </h2>
</div>

<br>

<div fxLayout="row" fxLayoutAlign="start center">
  <div fxFlex="">

  </div>
  <button mat-raised-button color="default">
    <mat-icon>add</mat-icon> New Job
  </button>
</div>

<mat-nav-list>
  <mat-list-item *ngFor="let menuItem of menuItems" (click)="onSelectMenu(menuItem)" [class.active]="menuItem.title === selectedMenu?.title">

    <button mat-icon-button >
      <mat-icon>{{menuItem.icon}}</mat-icon>
      <span style="margin-left:10px;font-weight: bold;">{{menuItem.title}}</span>
      <!-- <a matLine href="...">{{ link }}</a> -->
    </button>

  </mat-list-item>
</mat-nav-list>
