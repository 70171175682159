import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-shipment-upload-addresses',
  templateUrl: './shipment-upload-addresses.component.html',
  styleUrls: ['./shipment-upload-addresses.component.scss']
})
export class ShipmentUploadAddressesComponent implements OnInit {

  excelData = [];
  isLoading = false;
  uploadLoading = false;
  constructor(public api: ApiService,
    public toast: ToastService,
    public dialog: MatDialog) { }

  ngOnInit() {
  }

  selectCSV(event) {
    this.isLoading = true;
    this.excelData = [];
    var self = this;
    const file = event[0];
    const reader = new FileReader();

    reader.onload = (event) => {

      const data = reader.result;
      var workBook = XLSX.read(data, { type: 'binary' });
      var fileData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      self.excelData = fileData['Sheet1'].map(address => {
        return {
          customer: address.hasOwnProperty('CustomerId') ? address['CustomerId'] : '',
          company: address.hasOwnProperty('CompanyorName') ? address['CompanyorName'] : '',
          attention: address.hasOwnProperty('Attention') ? address['Attention'] : '',
          address: address.hasOwnProperty('Address1') ? address['Address1'] : '',
          stateProvinceCode: address.hasOwnProperty('StateProvinceCounty') ? address['StateProvinceCounty'] : '',
          city: address.hasOwnProperty('CityorTown') ? address['CityorTown'] : '',
          postalCode: address.hasOwnProperty('PostalCode') ? address['PostalCode'] : '',
          countryCode: address.hasOwnProperty('CountryTerritory') ? address['CountryTerritory'] : '',
          phone: address.hasOwnProperty('Telephone') ? address['Telephone'] : '',
          email: address.hasOwnProperty('EmailAddress') ? address['EmailAddress'] : ''
        }
      });

      self.isLoading = false;
    }
    reader.readAsBinaryString(file);
  }

  upload() {
    this.uploadLoading = true;
    this.api.request(
      'UPLOAD_ADDRESS',
      { data: { 'Addresses': this.excelData } }
    ).subscribe(res => {
      if (res.success) {
        this.toast.show(res.message);
      }
      this.uploadLoading = false;
      this.dialog.closeAll();
    }, error => {
      this.uploadLoading = false;
    });
  }
}
