<mat-toolbar [class.sticky-header]="isFullScreenView">
  <mat-toolbar-row fxLayout="row" fxLayoutGap="50px" *ngIf="!isFullScreenView">
    <div
      class="brand-name"
      fxLayout="row"
      fxLayoutAlign="center"
      [routerLink]="['/dashboard']"
      routerLinkActive="false"
    >
      <img class="logo" [src]="src" />
    </div>

    <app-search-text (selected)="onJobSelected($event)"></app-search-text>

    <div fxLayout="row" fxFlex fxLayoutAlign="space-between">
      <div fxLayoutAlign="center center">
        <button mat-stroked-button (click)="selectDatasource()" class="border">
          <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="center center">
            <mat-icon class="mat-icon">device_hub</mat-icon>
            <span class="secondary-text-color">{{ selectedDataSource }}</span>
          </div>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <div id="Image" style="padding-top: 8px">
          <mat-icon *ngIf="IsDark" matTooltip="Light Mode" (click)="LightModeOn()" class="pointer">wb_sunny</mat-icon>
          <mat-icon *ngIf="IsLight" matTooltip="Dark Mode" (click)="DarkModeOn()" class="pointer">
            dark_mode</mat-icon>
        </div>
        <mat-menu #notificationMenu="matMenu" xPosition="before">
          
          @if (notifications && notifications.length > 0;) {
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="padding-10"
            >
              <span
                fxLayoutAlign="start center"
                style="padding-left: 10px; padding-bottom: 10px"
                fxLayoutAlign="start center"
                class="primary-text-color"
              >
                <mat-icon class="mat-icon"> notifications </mat-icon>
                Notifications
              </span>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="column" class="primary-text-color grid-header-bg">
              <div style="overflow: auto;"
                fxLayout="column"
                fxFlex="350px"
              >
              @for (notification of notifications; track $index) {
                <div class="primary-text-color grid-header-bg">
                  <button
                    mat-menu-item
                    class="notification"
                    (click)="readNotification(notification)"
                  >
                    <small class="message">{{ notification.message }}</small>
                    <div fxLayout="row" style="margin-top: 8px">
                      <span fxLayoutAlign="start center">
                        <small class="notigication-assign" *ngIf="notification['type'] === 1">
                          <mat-icon class="notigication-assign">assignment</mat-icon>
                          Assign
                        </small>
                        <small class="notigication-completed" *ngIf="notification['type'] === 2">
                          <mat-icon class="notigication-completed">assignment_turned_in</mat-icon>
                          Completed
                        </small>
                      </span>
                      <span fxFlex></span>
                      <span><small class="primary-text-color">{{
                          notification.createdAt | date
                          }}</small></span>
                    </div>
                  </button>
                  <mat-divider></mat-divider>
                </div>
              }
              </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxFlex fxLayoutAlign="center center">
              <button mat-stroked-button style="margin-top: 4px" (click)="readAllNotification()" color="primary">
                Clear All Notifications
              </button>
            </div>
          }@else{
            <button mat-menu-item class="notification">
              <mat-icon class="mat-icon">notifications_active</mat-icon>
              <span>Get notified when notification are available.</span>
            </button>
          }
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="notificationMenu">
          <mat-icon class="mat-icon" [matBadgeHidden]="notificationCount === 0" [matBadge]="notificationCount"
            matBadgeColor="warn">
            notifications</mat-icon>
        </button>

        <div class="menu-divider"></div>
        <app-user-avatar></app-user-avatar>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row id="topnav">
    <div  [ngStyle]="{'max-width': isAdmin ? '83%' : '100%', 'min-width':'50%'}">
      <mat-tab-group [disableRipple]="true"  class="mat-tab-nav"
        [selectedIndex]="getTheSelectedTabIndex()">
          @for (menuItem of menuItems; track menuItem) {
            <mat-tab *ngIf="canAccess(menuItem)">
              <ng-template mat-tab-label>
                <button [routerLink]="menuItem.route" [queryParams]="{ id: menuItem.id }"
                  [class.activeLink]="isLinkActive(menuItem)" [class.highlight]="
                      menuItem.isOrderTab && unseenOrderCount && unseenOrderCount > 0
                    " mat-button>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                    <mat-icon *ngIf="!menuItem.isCustomIcon" class="mat-icon">{{
                      menuItem.icon
                      }}</mat-icon>
                    <mat-icon *ngIf="menuItem.isCustomIcon" class="mat-icon svgimg mt-10"  [svgIcon]="menuItem.icon"></mat-icon>
                    <span class="secondary-text-color">{{
                      menuItem.title | uppercase
                      }}</span>
                  </div>
                </button>
              </ng-template>
            </mat-tab>
          }
      </mat-tab-group>
    </div>
    <span fxFlex></span>
    <div>
      <mat-tab-group [disableRipple]="true" class="mat-tab-nav"
        [selectedIndex]="getTheSelectedAppMenuTabIndex()">
        <ng-container *ngFor="let menuItem of appMenuItems">
          <mat-tab *ngIf="canAccess(menuItem)">
            <ng-template mat-tab-label>
              <button [routerLink]="menuItem.route" [queryParams]="{ id: menuItem.id }"
                [class.activeLink]="isLinkActive(menuItem)" [class.highlight]="
                      menuItem.isOrderTab && unseenOrderCount && unseenOrderCount > 0
                    " mat-button>
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                  <mat-icon *ngIf="!menuItem.isCustomIcon" class="mat-icon">{{
                    menuItem.icon
                    }}</mat-icon>
                  <mat-icon *ngIf="menuItem.isCustomIcon" class="mat-icon svgimg" [svgIcon]="menuItem.icon"></mat-icon>
                  <span class="secondary-text-color">{{
                    menuItem.title | uppercase
                    }}</span>
                </div>
              </button>
            </ng-template>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

@if(isFullScreenView){
  <div style="height: 64px"></div>
}
