import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';

const STORAGE_KEY = 'pacific_printing_auth_key';
const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor() { }

  saveUser(user: User): void {
    const userJson = JSON.stringify(user);
    localStorage.setItem(STORAGE_KEY, userJson);
  }

  getUser() {
    const userJson = localStorage.getItem(STORAGE_KEY);
    return <User>JSON.parse(userJson);
  }

  setUserFilters(filter ,departmentId ){
    const userJson = <User>JSON.parse(localStorage.getItem(STORAGE_KEY));
    userJson.filter[departmentId] = filter;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userJson));
  }

  clear() {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }

  showJobFullScreen(value){
    localStorage.setItem('SHOW_FULL_SCREEN',value);
  }

  isShowFullScreen(){
    return localStorage.getItem('SHOW_FULL_SCREEN') === 'true' ? true :false;
  }
  
}
