import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-department-create',
  templateUrl: './department-create.component.html',
  styleUrls: ['./department-create.component.scss']
})
export class DepartmentCreateComponent implements OnInit {

  form:  UntypedFormGroup;
  title = 'Add Department';
  loading = false;
 
  constructor(private formBuilder: UntypedFormBuilder ,
              private dialogRef: MatDialogRef<DepartmentCreateComponent>,
              private apiService: ApiService,
              @Inject(MAT_DIALOG_DATA) public department,
              private snackbar:MatSnackBar,
              private toast:ToastService) { }

  ngOnInit() {
   this.form = this.initForm();
   this.form.controls.status.patchValue(true);
   if(this.department){
    this.title = 'Edit Department';
    this.form.patchValue(this.department);
   }
  }

  initForm = (): UntypedFormGroup =>
  this.formBuilder.group({
    name: [''],
    description: [''],
    status: ['']
  })

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  /**
   * create new document
   */
  onSubmitClick(){
    if(this.loading){return;}
    //validate add department form.
    if(this.form.invalid){
      return;
    }

    let department = this.form.value;

    if(this.department){
    this.updateDepartment(department);
    }else{
      this.saveDepartment(department);
    }
  }

  /**
   * save department.
   */
  saveDepartment(department){
    this.loading = true;
    this.apiService.request('CREATE_DEPARTMENT',{data:department}).subscribe(res => {
      if(res.success){
        department.departmentId = res['result'];
        this.dialogRef.close(department);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    },error => {this.loading = false;});
  }

  /**
   * update department.
   */
   updateDepartment(department){
     department.departmentId = this.department.departmentId;

     let apiRequest = {
      params: {DEPARTMENT_ID: this.department.departmentId},
      data:department
     }
    this.loading= true;
    this.apiService.request('EDIT_DEPARTMENT', apiRequest).subscribe(res => {
      if(res['success']){
        this.dialogRef.close(true);
        return;
      }
      this.toast.show(res['message']);
      this.loading= false;
    },error=>{this.loading= false;});
  }

  /**
   * close add department dialog.
   */
  onCloseClick(){
    this.dialogRef.close();
  }
}
