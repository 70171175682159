import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { NotificationHubService } from "src/app/shared/services/notification-hub.service";
import { Store } from "@ngxs/store";
import { JobDetailsComponent } from "../../job-details/job-details.component";
import { ReloadeJobs } from "../../state/job.actions";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { User } from "src/app/shared/interfaces/user";
import { ToastService } from "src/app/shared/services/toast.service";
import { ShipmentCreateComponent } from "src/app/features/shipment/shipment-create/shipment-create.component";
import { ShipmentTrackingComponent } from "src/app/features/shipment/shipment-tracking/shipment-tracking.component";
import { JobDeleteConfirmDialogComponent } from "../../job-delete-confirm-dialog/job-delete-confirm-dialog.component";
import { PermissionService } from "src/app/shared/services/permission.service";
import { HTTPStatus } from "src/app/shared/services/loading.interceptor";
import { JobsPermissionService } from "src/app/shared/services/job.permissions";
import { ThemeService } from "src/app/theme.service";
import { TopnavComponent } from "src/app/core/layout/topnav/topnav.component";
import {
  EnumSwitchUploadFilesMode,
  UploadToSwitchComponent,
} from "../../job-details/upload-to-switch/upload-to-switch.component";
import { JobDetailsV2Component } from "../../job-details-v2/job-details-v2.component";
import { RyobiPdfPrintService } from "src/app/shared/services/ryobi-pdf-print.service";
import { RYOBI_DEPARTMENT } from "src/app/shared/constant/department";

@Component({
  selector: "app-job-grid-row",
  templateUrl: "./job-grid-row.component.html",
  styleUrls: ["./job-grid-row.component.scss"],
})
export class JobGridRowComponent implements OnInit {
  @Input() job;
  @Input() jobItem;
  @Input() isExpand;
  @Input() currentDepartmentId;
  @Input() currentDepartmentName;

  loading = false;
  deleteLoading = false;
  now = new Date();
  today: string;
  showDetailsView = false;
  items;
  user: User;
  allowShipment = false;
  isDeletePermission = false;
  isLoading: boolean;
  isAdminOrCsr: boolean = false;
  src;
  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private store: Store,
    private notificationHub: NotificationHubService,
    private userService: AuthUserService,
    private toast: ToastService,
    private permission: PermissionService,
    private httpStatus: HTTPStatus,
    private jobPermissions: JobsPermissionService,
    public themeService: ThemeService,
    private ryobiPrintService: RyobiPdfPrintService
  ) {}

  ngOnInit() {
    this.themeService.InvoiceSrc.subscribe((data) => {
      this.src = data;
    });

    this.items = this.job.items;
    this.today = this.now.toISOString();
    this.showDetailsView = this.isExpand;
    this.user = this.userService.getUser();
    this.isAdminOrCsr = this.permission.isAdminOrCSR();
    var isDepartmentAllowedToCreateShipment =
      this.permission.isCurrentDepartment("shipping") ||
      this.permission.isAdminOrCSR()
        ? true
        : false;
    var isAllowedCreateShipmentOnJob = this.job.isShipmentCreateAllowed;
    this.setIsAllowShipment(
      isDepartmentAllowedToCreateShipment,
      isAllowedCreateShipmentOnJob
    );

    this.isDeletePermission = this.permission.isAdminOrCSR();

    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.isLoading = status;
    });
  }

  onLoadDetailsView() {
    this.showDetailsView = !this.showDetailsView;

    if (this.showDetailsView) this.loadJobDetails();
  }

  private loadJobDetails(): void {
    var paramcurrentDepartmentID = this.currentDepartmentId;
    if (this.currentDepartmentId == 0) {
      paramcurrentDepartmentID = this.user.currentDepartment.departmentId;
    }

    if (this.items && this.items.length > 0) {
      return;
    }

    const params = {
      params: {
        JOB_ID: this.job["jobId"],
        DEPARTMENT_ID: paramcurrentDepartmentID,
      },
    };

    this.api.request("GET_JOB_ITEMS", params).subscribe((response) => {
      this.items = response.items;
      var isDepartmentAllowedToCreateShipment =
        this.permission.isCurrentDepartment("shipping") ||
        this.permission.isAdminOrCSR()
          ? true
          : false;
      var isAllowedCreateShipmentOnJob = response.isShipmentCreateAllowed;
      this.setIsAllowShipment(
        isDepartmentAllowedToCreateShipment,
        isAllowedCreateShipmentOnJob
      );
    });
  }

  setIsAllowShipment(
    isDepartmentAllowedToCreateShipment,
    isAllowedCreateShipmentOnJob
  ) {
    this.allowShipment =
      isDepartmentAllowedToCreateShipment && isAllowedCreateShipmentOnJob
        ? true
        : false;
    if (this.allowShipment && this.currentDepartmentId != 0) {
      if (this.isAdminOrCsr) {
        if (
          this.currentDepartmentId != 0 &&
          this.currentDepartmentName.toLowerCase() == "shipping"
        ) {
          this.allowShipment = true;
        }
      } else {
        //Its a shipping dept user
        if (
          this.user.currentDepartment.departmentId !== this.currentDepartmentId
        ) {
          this.allowShipment = false;
        }
      }
    }
  }

  jobItemSelection(itemId) {
    const input = {
      job: this.job,
      selectedJobItemId: itemId,
    };

    const dialogRef = this.dialog.open(JobDetailsComponent, {
      width: "98vw",
      maxWidth: "98vw",
      height: "95vh",
      maxHeight: "95vh",
      data: input,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.toast.hide();
    });
  }

  markAsComplete($event, jobItem, isJob) {
    $event.stopPropagation();
    if (this.isLoading == true) {
      this.toast.show("Loading..");
      return;
    }

    //Check Permissions if its not a job
    if (!isJob) {
      var canAccessOrModify = this.permission.canAccessJobItemDepartment(
        jobItem.departmentName
      );
      if (!canAccessOrModify) {
        this.dialog.open(ConfirmDialogComponent, {
          width: "480px",
          data: {
            type: "Info",
            message: "Please change the department to access",
          },
        });
        return;
      }
    }
    //Check permissions if its a job
    if (isJob) {
      var canAccess = true;
      this.items.forEach((_item) => {
        if (!this.permission.canAccessJobItemDepartment(_item.departmentName)) {
          canAccess = false;
        }
      });
      if (!canAccess) {
        this.dialog.open(ConfirmDialogComponent, {
          width: "480px",
          data: {
            type: "Info",
            message: "Please change the department to access",
          },
        });
        return;
      }
    }

    if (isJob && !this.items.find((x) => x.status != 2)) {
      return;
    } // check all job items is in invoice.

    if (!isJob && jobItem.status == 2) {
      return;
    } // check job item is in invoice.

    var validArtwork = false;
    var msg = "";

    if (
      !isJob &&
      jobItem.isRequiredApproveArtwork &&
      !jobItem.isImportedToProductionWithoutValidations
    ) {
      if (jobItem.firstStepDepartmentName == "design") {
        //When first step is Design
        if (
          jobItem.departmentName.toLowerCase() == "design" &&
          jobItem.currentStepIndex === 0
        ) {
          validArtwork = true;
          msg = "This Job item requires to add the Proof file first.";
          if (
            !jobItem.files.find((f) => f.isProofFile && !f.isSharedToSalesDept)
          ) {
            validArtwork = false;
          }
          if (jobItem.isApprovedProofFile == true) {
            validArtwork = true;
          }
        }
        if (
          jobItem.departmentName.toLowerCase() == "sales" &&
          jobItem.currentStepIndex === 1
        ) {
          msg = "This Job Item added proof files is not approved.";
          if (jobItem.isApprovedProofFile == true) {
            validArtwork = true;
          } else {
            validArtwork = false;
          }
        }
        if (jobItem.currentStepIndex !== 0 && jobItem.currentStepIndex !== 1) {
          validArtwork = true;
        }
      }

      //When first step is Sales
      if (jobItem.firstStepDepartmentName == "sales") {
        if (
          jobItem.departmentName.toLowerCase() == "design" &&
          jobItem.currentStepIndex == 1
        ) {
          msg = "This Job item requires to add the Proof file first.";
          validArtwork = false;
          var filesNotSharedWithSalesDept = jobItem.files.filter(
            (f) => f.isProofFile == true && f.isSharedToSalesDept == false
          );
          if (filesNotSharedWithSalesDept.length > 0) {
            validArtwork = false;
          } else {
            if (jobItem.isApprovedProofFile == true) {
              validArtwork = true;
            }
          }
        }
        if (
          jobItem.departmentName.toLowerCase() == "sales" &&
          jobItem.currentStepIndex == 0
        ) {
          validArtwork = true;

          if (jobItem.isApprovedProofFile) {
            validArtwork = true;
          }

          if (jobItem.files.find((f) => f.isProofFile)) {
            if (jobItem.isApprovedProofFile != true) {
              msg = "This Job Item added proof files is not approved";
              validArtwork = false;
            }
          }
        }
        if (jobItem.currentStepIndex !== 0 && jobItem.currentStepIndex !== 1) {
          validArtwork = true;
        }
      }

      if (validArtwork) {
        var canMoveToProduction = this.jobPermissions.canMoveToProduction(
          jobItem,
          jobItem.firstStepDepartmentName,
          this.job["isPaid"],
          jobItem["isBypassed"]
        );
        if (!canMoveToProduction) {
          validArtwork = false;
          msg = "Payment for this job is pending.";
        }
      }
    } else {
      validArtwork = true;
    }

    if (isJob == true) {
      msg =
        "Some job items have pending payment or artwork approval or Proof file Uploadation";
      //validArtwork = this.items.find(i => i.isRequiredApproveArtwork && (!i.isProofFileAdded || !i.isApprovedProofFile ))?false :true;

      var validArtworkArray: Boolean[] = [];
      this.items.forEach((_jobItem) => {
        if (
          _jobItem.isRequiredApproveArtwork &&
          !_jobItem.isImportedToProductionWithoutValidations
        ) {
          if (_jobItem.firstStepDepartmentName == "design") {
            //When first step is Design
            if (
              _jobItem.departmentName.toLowerCase() == "design" &&
              _jobItem.currentStepIndex == 0
            ) {
              validArtwork = true;
              if (
                !_jobItem.files.find(
                  (f) => f.isProofFile && !f.isSharedToSalesDept
                )
              ) {
                validArtwork = false;
              }
              if (_jobItem.isApprovedProofFile == true) {
                validArtwork = true;
              }
            }
            if (
              _jobItem.departmentName.toLowerCase() == "sales" &&
              _jobItem.currentStepIndex == 1
            ) {
              if (_jobItem.isApprovedProofFile == true) {
                validArtwork = true;
              } else {
                validArtwork = false;
              }
            }
            if (
              _jobItem.currentStepIndex !== 0 &&
              _jobItem.currentStepIndex !== 1
            ) {
              validArtwork = true;
            }
          }

          //When first step is Sales
          if (_jobItem.firstStepDepartmentName == "sales") {
            if (
              _jobItem.departmentName.toLowerCase() == "design" &&
              _jobItem.currentStepIndex == 1
            ) {
              validArtwork = false;
              var filesNotSharedWithSalesDept = _jobItem.files.filter(
                (f) => f.isProofFile == true && f.isSharedToSalesDept == false
              );
              if (filesNotSharedWithSalesDept.length > 0) {
                validArtwork = false;
              } else {
                if (_jobItem.isApprovedProofFile == true) {
                  validArtwork = true;
                }
              }
            }
            if (
              _jobItem.departmentName.toLowerCase() == "sales" &&
              _jobItem.currentStepIndex == 0
            ) {
              validArtwork = true;

              if (_jobItem.isApprovedProofFile) {
                validArtwork = true;
              }

              if (_jobItem.files.find((f) => f.isProofFile)) {
                if (_jobItem.isApprovedProofFile != true) {
                  validArtwork = false;
                }
              }
            }
            if (
              _jobItem.currentStepIndex !== 0 &&
              _jobItem.currentStepIndex !== 1
            ) {
              validArtwork = true;
            }
          }

          //Check can move to production
          var canMoveToProduction = this.jobPermissions.canMoveToProduction(
            _jobItem,
            _jobItem.firstStepDepartmentName,
            this.job["isPaid"],
            this.job["canBypassPayment"]
          );
          if (!canMoveToProduction) {
            validArtwork = false;
          }
        } else {
          validArtwork = true;
        }

        validArtworkArray.push(validArtwork);
      });

      var falsityArray = validArtworkArray.filter((b) => b == false);
      if (falsityArray != null && falsityArray.length > 0) {
        validArtwork = false;
      } else {
        validArtwork = true;
      }
    }

    if (!validArtwork) {
      this.dialog.open(ConfirmDialogComponent, {
        width: "480px",
        data: { type: "Info", message: msg },
      });
      return;
    }

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "450px",
      data: {
        type: "Confirm",
        message: isJob
          ? "Are you sure you want to move job items into next department?"
          : "Are you sure you want to move job item into next department?",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let jobItemIds = [];
        if (isJob) {
          jobItemIds = this.items
            .filter((i) => i.status !== 2)
            .map((i) => {
              return i.jobItemId;
            });
        } else {
          jobItemIds[0] = this.jobItem.jobItemId;
        }
        this.moveIntoNextDepartment(jobItemIds, isJob);
      }
    });
  }

  moveIntoNextDepartment(jobItemIds, isJob) {
    const data = {
      jobItemIds: jobItemIds,
    };
    this.loading = true;
    this.api.request("COMPLETE_CURRENT_STEP", { data: data }).subscribe(
      (res) => {
        this.store.dispatch(new ReloadeJobs());
        this.sendNotification(jobItemIds, isJob);
      },
      (error) => (this.loading = false)
    );
  }

  sendNotification(jobItemIds, isJob) {
    if (isJob) {
      jobItemIds.forEach((jobItemId) => {
        const item = this.items.find((ji) => ji.jobItemId === jobItemId);
        if (item) {
          const notification = {
            JobId: this.job.jobId,
            JobNumber: this.job.jobNumber,
            JobItemNo: item.itemNo,
            jobItemName: item.description,
            steps: item.steps,
            currentStepIndex: item.currentStepIndex,
            changeStepIndex: item.currentStepIndex + 1,
          };
          this.notificationHub.sendNotification(notification);
        }
      });
    } else {
      const notification = {
        JobId: this.job.jobId,
        JobNumber: this.job.jobNumber,
        JobItemNo: this.jobItem.itemNo,
        jobItemName: this.jobItem.description,
        steps: this.jobItem.steps,
        currentStepIndex: this.jobItem.currentStepIndex,
        changeStepIndex: this.jobItem.currentStepIndex + 1,
      };
      this.notificationHub.sendNotification(notification);
    }
  }

  archiveJob($event) {
    $event.stopPropagation();

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to archive job?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.api
          .request("ARCHIVE_JOB", { params: { JOB_ID: this.job.jobId } })
          .subscribe(
            (res) => {
              this.store.dispatch(new ReloadeJobs());
            },
            (error) => (this.loading = false)
          );
      }
    });
  }

  hasDeletePermission() {
    //    return this.permission.isAdminOrCSR();
  }

  deleteJob($event) {
    $event.stopPropagation();
    let dialogRef = this.dialog.open(JobDeleteConfirmDialogComponent, {
      height: "240px",
      width: "410px",
      data: this.job.jobNumber,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteLoading = true;
        this.api
          .request("DELETE_JOB", { params: { JOB_ID: this.job.jobId } })
          .subscribe(
            (res) => {
              this.store.dispatch(new ReloadeJobs());
            },
            (error) => (this.deleteLoading = false)
          );
      }
    });
  }

  shipment($event) {
    $event.stopPropagation();
    this.dialog.open(ShipmentCreateComponent, {
      width: "95vw",
      maxWidth: "95vw",
      height: "90vh",
      maxHeight: "90vh",
      data: {
        jobId: this.job["jobId"],
        shipmentForJob: true,
      },
    });
  }

  openSwitchFilesUploadModal(jobItem) {
    const dialogRef = this.dialog.open(UploadToSwitchComponent, {
      width: "75vw",
      height: "665px",
      maxHeight: "665px",
      data: {
        jobItemIds: [jobItem.jobItemId],
        mode: EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_FILES_SELECTION_MODE,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  // tracking($event,shipmentIdentificationNo){
  //   $event.stopPropagation();
  //   this.dialog.open(ShipmentTrackingComponent, {
  //     width: '85vw',
  //     maxWidth: '85vw',
  //     maxHeight: 'auto',
  //     data:shipmentIdentificationNo
  //   });
  // }

  onPrintRyobiJobPdf(event, job, jobItem) {
    event.stopPropagation();
    if (!job) {
      this.toast.show("Ryobi Department Jobs Not Found");
      return;
    }
    let jobIds = [];
    if (!jobItem) {
      if (job.items.length !== 0) {
        job.items.forEach((jobItem) => {
          if (jobItem.currentStepName.toLowerCase() == RYOBI_DEPARTMENT) {
            jobIds.push(jobItem.jobItemId);
          }
        });
      } else {
        let itemIds = this.items
          .filter((i) => i.status == 1 && i.currentStepName.toLowerCase() == RYOBI_DEPARTMENT)
          .map((i) => {
            jobIds.push(i.jobItemId);
          });
      }
    } else {
      jobIds.push(jobItem.jobItemId);
    }

    const payload = {
      Jobs: [
        {
          jobId: job["jobId"],
          jobItemIds: jobIds,
        },
      ],
    };
    this.ryobiPrintService.printRyobiJobs(payload);
  }

  isShowRyobiJobPdfIcon(): boolean {
    //If on Jobs tab and Department selected is Ryobi
    if (
      this.currentDepartmentId == 0 &&
      this.permission.isCurrentDepartment(RYOBI_DEPARTMENT)
    ) {
      return true;
    }

    //If on Dept Tabs and Department selected is Ryobi
    if (
      this.currentDepartmentName &&
      this.currentDepartmentName.toLowerCase() == RYOBI_DEPARTMENT &&
      (this.permission.isCurrentDepartment(RYOBI_DEPARTMENT) ||
        this.permission.isAdminOrCSR())
    ) {
      return true;
    }
    return false;
  }
}
