import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/shared/interfaces/company';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent implements OnInit {

  disabled = false;
  addressTypeValidate = false;
  showFormReadonly = false;

  form = this.formBuilder.group({
    name: ['', [Validators.required]],
    website: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    altPhone: [''],
    isBypassPayment: [false],
    isTaxExempt: [false],
    sourceType: [''],
    billingAddress: this.formBuilder.group({
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postalZip: ['', [Validators.required]],
      country: ['', [Validators.required]],
    }),
    shippingAddress: this.formBuilder.group({
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postalZip: ['', [Validators.required]],
      country: ['', [Validators.required]],
    })
  });

  constructor(public formBuilder: UntypedFormBuilder,
    private api: ApiService,
    public dialogRef: MatDialogRef<CompanyCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public company: Company,
    private toast: ToastService) { }

  ngOnInit() {
    if (this.company) {
      if (this.company.printerPlanCusNo) {

        this.form.controls['name'].clearValidators();
        this.form.controls['name'].updateValueAndValidity();

        this.form.controls['website'].clearValidators();
        this.form.controls['website'].updateValueAndValidity();

        this.form.controls['email'].clearValidators();
        this.form.controls['email'].updateValueAndValidity();

        this.form.controls['phone'].clearValidators();
        this.form.controls['phone'].updateValueAndValidity();

        this.form.controls['billingAddress']['controls']['addressLine1'].clearValidators();
        this.form.controls['billingAddress']['controls']['addressLine1'].updateValueAndValidity();
        this.form.controls['billingAddress']['controls']['state'].clearValidators();
        this.form.controls['billingAddress']['controls']['state'].updateValueAndValidity();
        this.form.controls['billingAddress']['controls']['city'].clearValidators();
        this.form.controls['billingAddress']['controls']['city'].updateValueAndValidity();
        this.form.controls['billingAddress']['controls']['postalZip'].clearValidators();
        this.form.controls['billingAddress']['controls']['postalZip'].updateValueAndValidity();
        this.form.controls['billingAddress']['controls']['country'].clearValidators();
        this.form.controls['billingAddress']['controls']['country'].updateValueAndValidity();

        this.form.controls['shippingAddress']['controls']['addressLine1'].clearValidators();
        this.form.controls['shippingAddress']['controls']['addressLine1'].updateValueAndValidity();
        this.form.controls['shippingAddress']['controls']['state'].clearValidators();
        this.form.controls['shippingAddress']['controls']['state'].updateValueAndValidity();
        this.form.controls['shippingAddress']['controls']['city'].clearValidators();
        this.form.controls['shippingAddress']['controls']['city'].updateValueAndValidity();
        this.form.controls['shippingAddress']['controls']['postalZip'].clearValidators();
        this.form.controls['shippingAddress']['controls']['postalZip'].updateValueAndValidity();
        this.form.controls['shippingAddress']['controls']['country'].clearValidators();
        this.form.controls['shippingAddress']['controls']['country'].updateValueAndValidity();

        this.showFormReadonly = true;
      }
      this.form.patchValue(this.company);
    }
  }

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false


  hasErrorInGroup(group: string, field: string, errorName: string): Boolean {
    if (this.form.get(group).get(field).errors) {
      if (this.form.get(group).get(field).errors[errorName]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onSubmit() {

    if (this.company) {
      if (!this.company.printerPlanCusNo) {
        this.markFormGroupTouched(this.form);

        if (this.form.invalid) {
          return;
        }
      }
    } else {
      this.markFormGroupTouched(this.form);

      if (this.form.invalid) {
        return;
      }
    }

    if (this.company) {
      this.update();
    } else {
      this.create();
    }
  }


  private create() {
    var reqData = JSON.parse(JSON.stringify(this.form.value));
    reqData['sourceType'] = "CUSTOMER_PORTAL";

    this.api.request('CREATE_COMPANIES', { data: reqData }).subscribe((res: any) => {
      res['success'] ? this.dialogRef.close(true) : this.toast.show(res['message']);
    });
  }

  private update() {
    const id = this.company.id
    this.company = this.form.value;
    this.company.id = id;
    const apiRequest = {
      params: { ID: this.company.id },
      data: this.company
    };

    this.api.request('UPDATE_COMPANY', apiRequest).subscribe((res: any) => {
      res['success'] ? this.dialogRef.close(true) : this.toast.show(res['message']);
    });
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
