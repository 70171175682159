<div style="height: 500px;overflow: auto;">
  <div id="timeline-content">
    <div class="timeline" *ngFor="let l of logs">
      <div
        class="event"
        *ngIf="!l.isAssign && steps && steps.length > 0"
        fxLayout="column"
        fxLayoutGap="8px"
      >
        <span class="complete font-size-10"><b>Completed</b></span>
        <small class="primary-text-color"
          ><b
            >{{ steps[l.toStep]?.step | titlecase }} /
            {{ steps[l.toStep]?.action | titlecase }} is completed job item.
          </b>
        </small>
        <small class="primary-text-color">
          By {{ l?.actionBy }} on
          {{ l?.createdAt | date : "medium" }}.</small
        >
        <mat-divider></mat-divider>
      </div>
      <div
        class="event"
        *ngIf="l.isAssign && steps && steps.length > 0"
        fxLayout="column"
        fxLayoutGap="8px"
      >
        <span class="assign font-size-10"><b>Assigned</b></span>
        <small class="primary-text-color"
          ><b>
            Job item is assigned to {{ steps[l.toStep]?.step | titlecase }} /
            {{ steps[l.toStep]?.action | titlecase }}.
          </b></small
        >
        <small class="primary-text-color">
          By {{ l?.actionBy }} on
          {{ l?.createdAt | date : "medium" }}.</small
        >
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>
