import { State, Action, StateContext, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Job } from 'src/app/shared/interfaces/job';
import { AddJob, DeleteJob, UpdateJob, LoadJobs, SearchJob, GetJob, SetJobStatus, ResetState, RemoveJobItem } from './job.actions';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { updateItem, patch } from '@ngxs/store/operators';
import * as lodash from 'lodash';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

export interface JobStateModel {
    jobs: Job[];
    currentStatus: string;
    total: number;
    isLoadingJobs: boolean;
}

export enum JobStatus {
    All,
    InProgress,
    Completed
}


@State<JobStateModel>({
    name: 'job',
    defaults: {
        jobs: [],
        currentStatus: 'inprogress',
        total: 0,
        isLoadingJobs : false
    }
})

@Injectable()
export class JobState {

    getJobsSubcsription : Subscription;

    constructor(private api: ApiService,
        private auth: AuthUserService,
        private store: Store) { }

    @Selector()
    static AllJobs(state: JobStateModel) {
        return state.jobs;
    }

    @Selector()
    static TotalJobsCount(state: JobStateModel) {
        return state.total;
    }

    @Selector()
    static IsJobsLoading(state: JobStateModel) {
        return state.isLoadingJobs;
    }

    @Selector()
    static JobsFilteredByCurrentStatus(state: JobStateModel) {
        if (state.currentStatus === 'all') {
            return state.jobs;
        }

        return state.jobs.filter(job => job.status === state.currentStatus);
    }

    /**
    * Set Jobs Status
    * @param ctx
    * @param action
    */
    @Action(SetJobStatus)
    SetJobStatus(ctx: StateContext<JobStateModel>, action: SetJobStatus) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            currentStatus: action.status
        });
    }

    /**
     * Get Jobs
     * @param ctx
     * @param action
     */
    @Action(LoadJobs)
    LoadJobs(ctx: StateContext<JobStateModel>, action: LoadJobs) {
        //check all record is loaded or not 

        let requestObject = {
            status: '',
            dateDue: '',
            dateIn: '',
            isExpand: false,
            csrNames: '',
            customerNames: '',
            jobNumbers: '',
            pageNo: 0,
            pageSize: 0,
            currentDepartmentId: 0,
            sortByJobNumber: null,
            sortByCustomer: null,
            sortByCsr: null,
            sortByDueDate: null,
        };


        if (action.filter != null) {
            requestObject = action.filter;
            requestObject.sortByJobNumber = action.filter.sortByJobNumber;
            requestObject.sortByCustomer = action.filter.sortByCustomer;
            requestObject.sortByCsr = action.filter.sortByCsr;
            requestObject.sortByDueDate = action.filter.sortByDueDate;
        }

        // get login user selected department;
        requestObject['userDepartmentId'] = this.auth.getUser().currentDepartment.departmentId;

        if(this.getJobsSubcsription){
            this.getJobsSubcsription.unsubscribe();
        }

        const state = ctx.getState();
        ctx.patchState({
            ...state,
            isLoadingJobs : true
        });

        this.getJobsSubcsription = this.api.request('GET_JOBS', { data: requestObject }).subscribe((response) => {
            const state = ctx.getState();

            if (response.jobs) {
                response.jobs.forEach(job => {
                    job['allowShipment'] = false;
                    if (job.items && job.items.find(item => item.departmentName.toLowerCase() === 'shipping')) {
                        job['allowShipment'] = true;
                    }
                });
            }

            ctx.patchState({
                ...state,
                jobs: response.jobs,
                total: response.total,
                isLoadingJobs : false
            });
        });

        return;
    }

    /**
     * Search Jobs
     * @param ctx
     * @param action
     */
    @Action(SearchJob)
    SearchJob(ctx: StateContext<JobStateModel>, action: SearchJob) {
        var apiParam = {
            params: {
                q: action.jobNumber
            }
        };
        return this.api
            .request('SEARCH_JOB', apiParam)
            .subscribe(response => { });
    }

    /**
     * Get Jobs
     * @param ctx
     * @param action
     */
    @Action(SearchJob)
    GetJob(ctx: StateContext<JobStateModel>, action: GetJob) {
        var apiParam = {
            params: {
                jobNumber: action.jobNumber
            }
        };
        return this.api
            .request('GET_JOB', apiParam)
            .subscribe(response => { });
    }

    /**
     * Adds new Job
     * @param ctx
     * @param action
     */
    @Action(AddJob)
    addJob(ctx: StateContext<JobStateModel>, action: AddJob) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            jobs: [
                ...state.jobs,
                action.job,
            ]
        });
    }

    /**
     * Updates a Job
     * @param ctx
     * @param action
     */
    @Action(UpdateJob)
    updateJob(ctx: StateContext<JobStateModel>, action: UpdateJob) {
        const job = action.job;

        if (job.items.length == 0) {
            this.store.dispatch(new DeleteJob(job));
            return;
        }

        ctx.setState(
            patch({
                jobs: updateItem<Job>(j => j.jobNumber == action.job.jobNumber, job)
            }));
    }

    /**
     * Deletes a Job
     * @param ctx
     * @param action
     */
    @Action(DeleteJob)
    deleteJob(ctx: StateContext<JobStateModel>, action: DeleteJob) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            jobs: state.jobs.filter(j => j.jobNumber != action.job.jobNumber)
        })
    }

    /**
     * Empty Jobs state
     * 
     */
    @Action(ResetState)
    emptyJobsState(ctx: StateContext<JobStateModel>, action: ResetState) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            jobs: [],
            total: 0
        })
    }

    /**
    * Remove item from A Job
    * 
    */
    @Action(RemoveJobItem)
    RemoveJobItem(ctx: StateContext<JobStateModel>, action: RemoveJobItem) {
        var payload = action.payload;
        const state = ctx.getState();
        var total = state.total;
        var jobs = lodash.cloneDeep(state.jobs);
        var jobIndex = jobs.findIndex(j => j.jobId == payload.JobId);
        var jobItems = jobs[jobIndex].items;
        jobItems = jobItems.filter(ji => ji.jobItemId !== payload.JobItemId);
        if (jobItems.length > 0) { jobs[jobIndex].items = jobItems; }
        else { jobs = jobs.filter(j => j.jobId !== payload.JobId); total = total - 1; }

        ctx.patchState({
            ...state,
            jobs: jobs,
            total: total
        });
    }

}
