import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-data-source-create',
  templateUrl: './data-source-create.component.html',
  styleUrls: ['./data-source-create.component.scss']
})
export class DataSourceCreateComponent implements OnInit {
  form: UntypedFormGroup;
  title = 'Add Data Source';
  fileErrorMsg: string;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DataSourceCreateComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public dataSource,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.form = this.initForm();
    this.form.controls.status.patchValue(true);
    if (this.dataSource) {
      this.title = 'Edit Data Source';
      this.form.patchValue(this.dataSource);
    }
  }

  /**
   * Initialize form
   */
  initForm = (): UntypedFormGroup =>
    this.formBuilder.group({
      name: ['', [Validators.required]],
      path: ['', [Validators.required]],
      status: ['']
    })

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  /**
   * close add department dialog.
   */
  onCloseClick() {
    this.dialogRef.close();
  }

  /**
   * create new document
   */
  onSubmitClick() {
    this.fileErrorMsg = undefined;

    // validate add department form.
    if (this.form.invalid || this.loading) {
      return;
    }

    if (this.isInValidFile(this.form.value['path'])) {
      this.fileErrorMsg = 'You must select valid data source.';
      return;
    }

    const dataSource = this.form.value;

    if (this.dataSource) {
      this.updateDataSource(dataSource);
    } else {
      this.saveDepartment(dataSource);
    }
  }

  /**
   * save department.
   */
  saveDepartment(dataSource) {
    this.loading = false;
    this.apiService.request('CREATE_DATASOURCE', { data: dataSource }).subscribe(res => {
      if (res['success']) {
        // dataSource.departmentId = id;
        this.dialogRef.close(true);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false; });
  }

  /**
   * update department.
   */
  updateDataSource(dataSource) {
    this.loading = true;
    dataSource.dataSourceId = this.dataSource.dataSourceId;

    const apiRequest = {
      params: { DATASOURCE_ID: this.dataSource.dataSourceId },
      data: dataSource
    };
    this.apiService.request('EDIT_DATASOURCE', apiRequest)
      .subscribe(res => {
        if (res['success']) {
          this.dialogRef.close(true);
          return;
        }
        this.toast.show(res['message'])
        this.loading = false;
      }, error => { this.loading = false; });
  }

  isInValidFile(filePath: string) {
    const ext = this.getExtension(filePath);
    return 'mdb' !== ext.trim().toLowerCase() ? true : false;
  }

  private getExtension = (filename) => {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }
}
