import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { PrinterPlanDocumentService } from "src/app/shared/services/printer-plan-document.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { OrderItem } from "src/app/shared/interfaces/orderItem";
import { User } from "src/app/shared/interfaces/user";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-printer-plan-job-item-chat",
  templateUrl: "./printer-plan-job-item-chat.component.html",
  styleUrls: ["./printer-plan-job-item-chat.component.scss"],
})
export class PrinterPlanJobItemChatComponent implements OnInit {
  @Input() JobItem: any;
  @Output() ShowPreview = new EventEmitter<any>();

  note: string = "";
  addNoteLoading: boolean = false;
  NoteTypes = [
    { id: 0, type: "Select Ink" },
    { id: 1, type: "Front Ink" },
    { id: 2, type: "Back Ink" },
  ];
  NoteTypeSelected = { id: 0, type: "Select Ink" };
  itemDocuments = [];
  addDocumentLoading = false;
  isShowPreview = false;
  previewDocument;
  previewUrl;
  user: User;
  isAdmin = false;
  isCsr = false;
  currentToast = null;
  currentSelectedNote: any = null;
  addNoteValidate: boolean = true;
  addFileValidate: boolean = true;
  isLeaveNote: boolean = true;
  leaveNote: string ='Leave Note';

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: OrderItem,
    public documentService: PrinterPlanDocumentService,
    private auth: AuthUserService,
    private api: ApiService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.JobItem.jobItemNotesAndDocs.forEach((noteOrDoc) => {
      if (noteOrDoc["isDoc"]) {
        noteOrDoc["url"] =
          noteOrDoc["url"] === null || noteOrDoc["url"] === undefined
            ? this.documentService.getDocumentUrl(noteOrDoc)
            : noteOrDoc["url"];
      }
    });
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500,
    });
  }

  selectNoteType(selectedNoteType: any) {
    this.NoteTypeSelected = selectedNoteType;
  }

  // saveNote() {
  //   if(this.addNoteLoading){
  //     return;
  //   }

  //   if(this.note == null || this.note == ""){return;}
  //   this.addNoteLoading=true;
  //   let reqObj = {
  //      jobItemId: this.JobItem.jobItemId,
  //      text:this.note,
  //      isFrontInkNote : this.NoteTypeSelected.id == 1 ? true : false,
  //      isBackInkNote : this.NoteTypeSelected.id == 2 ? true : false
  //    }

  //   this.api.request('ADD_PRINTER_PLAN_NOTE', { data: reqObj }).subscribe(res => {
  //     if (res['success']) {

  //       var addedNote = {
  //         isNote: true,
  //         isDoc: false,
  //         jobItemId: res['result']['jobItemId'],
  //         canDelete: true,
  //         canEdit : true,
  //         name: "",
  //         isCsrDoc: false,
  //         text: res['result']['text'],
  //         isFrontInkNote: res['result']['isFrontInkNote'],
  //         isBackInkNote: res['result']['isBackInkNote'],
  //         isCsrNote: true,
  //         createdAt : res['result']['createdAt'],
  //         createdBy : res['result']['createdBy'],
  //         id : res['result']['id']
  //       }
  //       this.JobItem.jobItemNotesAndDocs.push(addedNote);
  //       this.note = '';
  //       this.NoteTypeSelected = this.NoteTypes.find(o => o.id == 0);
  //       this.openToast(res['message']);
  //     }
  //     this.addNoteLoading = false;
  //   });
  // }

  saveNote() {
    this.addNoteValidate = true;
    if (this.note == null || this.note == "") {
      return this.addNoteValidate = false;
    }

    if (this.currentSelectedNote) {
      this.addNoteLoading = true;
      let reqObj = {
        params: {
          ID: this.currentSelectedNote.id,
        },
        data: {
          id: this.currentSelectedNote.id,
          text: this.note,
          isFrontInkNote: this.NoteTypeSelected.id == 1 ? true : false,
          isBackInkNote: this.NoteTypeSelected.id == 2 ? true : false,
        },
      };

      this.api.request("UPDATE_PRINTER_PLAN_NOTE", reqObj).subscribe((res) => {
        if (res["success"]) {
          var updatedNoteIndex = this.JobItem.jobItemNotesAndDocs.findIndex(
            (n) => n.id == this.currentSelectedNote.id && n.isNote
          );
          if (updatedNoteIndex > -1) {
            this.JobItem.jobItemNotesAndDocs[updatedNoteIndex].text =
              res.result.text;
            this.JobItem.jobItemNotesAndDocs[updatedNoteIndex].isFrontInkNote =
              res.result.isFrontInkNote;
            this.JobItem.jobItemNotesAndDocs[updatedNoteIndex].isBackInkNote =
              res.result.isBackInkNote;
            this.addNoteLoading = false;
            this.currentSelectedNote = null;
            this.note = "";
            this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 0);
          }
        }
      });
    } else {
      this.addNoteLoading = true;
      let reqObj = {
        // csrName:this.user.firstName + ' ' + this.user.lastName,
        jobItemId: this.JobItem.jobItemId,
        text: this.note,
        isFrontInkNote: this.NoteTypeSelected.id == 1 ? true : false,
        isBackInkNote: this.NoteTypeSelected.id == 2 ? true : false,
      };

      this.api
        .request("ADD_PRINTER_PLAN_NOTE", { data: reqObj })
        .subscribe((res) => {
          if (res["success"]) {
            var addedNote = {
              isNote: true,
              isDoc: false,
              jobItemId: res["result"]["jobItemId"],
              canDelete: true,
              canEdit: true,
              name: "",
              isCsrDoc: false,
              text: res["result"]["text"],
              isFrontInkNote: res["result"]["isFrontInkNote"],
              isBackInkNote: res["result"]["isBackInkNote"],
              isCsrNote: true,
              createdAt: res["result"]["createdAt"],
              createdBy: res["result"]["createdBy"],
              id: res["result"]["id"],
            };
            this.JobItem.jobItemNotesAndDocs.push(addedNote);
            this.note = "";
            this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 0);
            this.openToast(res["message"]);
          }
          this.addNoteLoading = false;
        });
    }
  }

  selectDocuments(event) {
    for (let index = 0; index < event.length; index++) {
      const reader = new FileReader();
      let selectedDoc = {
        file: event[index],
      };

      selectedDoc["name"] = event[index].name;
      const fileExtension = selectedDoc["name"]
        .split(".")
        [selectedDoc["name"].split(".").length - 1].replace('"', "");
      let docType = this.documentService.getDocumentType(fileExtension);
      if (docType == "Document") {
        this.openToast(
          "Please Select PDF FILES , EXCEL FILES and IMAGE FILES Only."
        );
        continue;
      }
      if (docType != "Image") {
        selectedDoc["url"] = this.documentService.getDocumentUrl(selectedDoc);
      } else {
        reader.readAsDataURL(event[index]);
        reader.onload = (event: any) => {
          selectedDoc["url"] = event.target["result"];
        };
      }

      selectedDoc["id"] = null;

      this.itemDocuments.push(selectedDoc);
    }
  }

  saveDocuments() {
    this.addFileValidate = true;
    if(this.itemDocuments.length === 0){
      this.addFileValidate = false;
    }
    if (this.itemDocuments && this.itemDocuments.length > 0) {
      this.addDocumentLoading = true;
      let formdata = new FormData();
      this.itemDocuments.forEach((document) => {
        formdata.append("files", document.file);
      });
      formdata.append("jobItemId", this.JobItem.jobItemId.toString());

      this.api
        .request("ADD_PRINTER_PLAN_DOCUMENTS", { data: formdata })
        .subscribe(
          (res) => {
            if (res["success"]) {
              this.itemDocuments = [];

              res.result.forEach((doc) => {
                var addedDoc = {
                  isNote: false,
                  isDoc: true,
                  jobItemId: doc["jobItemId"],
                  canDelete: true,
                  canEdit: true,
                  name: doc["name"],
                  isCsrDoc: true,
                  text: "",
                  isFrontInkNote: false,
                  isBackInkNote: false,
                  isCsrNote: false,
                  createdAt: doc["createdAt"],
                  createdBy: doc["createdBy"],
                  id: doc["id"],
                };
                addedDoc["url"] =
                  !addedDoc["url"] || addedDoc["url"] === null
                    ? this.documentService.getDocumentUrl(addedDoc)
                    : addedDoc["url"];
                this.JobItem.jobItemNotesAndDocs.push(addedDoc);
              });

              this.openToast(res["message"]);
            }
            this.addDocumentLoading = false;
          },
          (error) => {
            this.addDocumentLoading = false;
          }
        );
    }
  }

  deleteNote(id: number, index: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "200px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this note?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.api
          .request("DELETE_PRINTER_PLAN_NOTE", { params: { ID: id } })
          .subscribe((res) => {
            if (res["success"]) {
              this.JobItem.jobItemNotesAndDocs.splice(index, 1);
            }
          });
      }
    });
  }

  download(id: number) {
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    const linkElement = document.createElement("a");
    linkElement.setAttribute(
      "href",
      this.documentService.getDownloadDocumentUrl(id)
    );
    linkElement.dispatchEvent(clickEvent);
  }

  preview(document) {
    if (
      document["type"].toLowerCase() === "image" ||
      document["type"].toLowerCase() === "pdf" ||
      document["type"].toLowerCase() === "tiff" ||
      document["type"].toLowerCase() === "svg" ||
      document["type"].toLowerCase() === "tif" ||
      document["type"].toLowerCase() === "excel"
    ) {
      this.ShowPreview.emit({
        previewDocument: document,
        previewUrl: this.documentService.getDownloadDocumentUrl(document.id),
      });
    } else {
      this.openToast("Preview is unavailable");
    }
  }

  removeSelectedDocument(index) {
    this.itemDocuments.splice(index, 1);
  }

  deleteDocument(id: number, index: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "200px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this document?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.api
          .request("DELETE_PRINTER_PLAN_DOCUMENT", { params: { ID: id } })
          .subscribe((res) => {
            if (res["success"]) {
              this.JobItem.jobItemNotesAndDocs.splice(index, 1);
            }
          });
      }
    });
  }

  editNote(id, index) {
    this.currentSelectedNote = this.JobItem.jobItemNotesAndDocs[index];
    this.note = this.currentSelectedNote.text;
    if (this.currentSelectedNote.isFrontInkNote) {
      this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 1);
    }
    if (this.currentSelectedNote.isBackInkNote) {
      this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 2);
    }
  }

  selectChatSection(value){
   
    if(value == 'Leave Note'){
      this.isLeaveNote = true;
     }
     if(value == 'Document'){
      this.isLeaveNote = false;
     }
  }
}
