import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MatIconService {
    public icons() {
     return ["360",
     "3d_rotation",
     "4k",
     "5g",
     "6_ft_apart",
     "ac_unit",
     "access_alarm",
     "access_alarms",
     "access_time",
     "accessibility",
     "accessibility_new",
     "accessible",
     "accessible_forward",
     "account_balance",
     "account_balance_wallet",
     "account_box",
     "account_circle",
     "account_tree",
     "ad_units",
     "adb",
     "add",
     "add_a_photo",
     "add_alarm",
     "add_alert",
     "add_box",
     "add_business",
     "add_circle",
     "add_circle_outline",
     "add_comment",
     "add_ic_call",
     "add_location",
     "add_location_alt",
     "add_photo_alternate",
     "add_road",
     "add_shopping_cart",
     "add_to_home_screen",
     "add_to_photos",
     "add_to_queue",
     "addchart",
     "adjust",
     "admin_panel_settings",
     "agriculture",
     "airline_seat_flat",
     "airline_seat_flat_angled",
     "airline_seat_individual_suite",
     "airline_seat_legroom_extra",
     "airline_seat_legroom_normal",
     "airline_seat_legroom_reduced",
     "airline_seat_recline_extra",
     "airline_seat_recline_normal",
     "airplanemode_active",
     "airplanemode_inactive",
     "airplay",
     "airport_shuttle",
     "alarm",
     "alarm_add",
     "alarm_off",
     "alarm_on",
     "album",
     "all_inbox",
     "all_inclusive",
     "all_out",
     "alt_route",
     "alternate_email",
     "amp_stories",
     "analytics",
     "anchor",
     "android",
     "announcement",
     "apartment",
     "api",
     "app_blocking",
     "app_settings_alt",
     "apps",
     "architecture",
     "archive",
     "arrow_back",
     "arrow_back_ios",
     "arrow_circle_down",
     "arrow_circle_up",
     "arrow_downward",
     "arrow_drop_down",
     "arrow_drop_down_circle",
     "arrow_drop_up",
     "arrow_forward",
     "arrow_forward_ios",
     "arrow_left",
     "arrow_right",
     "arrow_right_alt",
     "arrow_upward",
     "art_track",
     "article",
     "aspect_ratio",
     "assessment",
     "assignment",
     "assignment_ind",
     "assignment_late",
     "assignment_return",
     "assignment_returned",
     "assignment_turned_in",
     "assistant",
     "assistant_photo",
     "atm",
     "attach_email",
     "attach_file",
     "attach_money",
     "attachment",
     "audiotrack",
     "auto_delete",
     "autorenew",
     "av_timer",
     "baby_changing_station",
     "backpack",
     "backspace",
     "backup",
     "backup_table",
     "ballot",
     "bar_chart",
     "batch_prediction",
     "bathtub",
     "battery_alert",
     "battery_charging_full",
     "battery_full",
     "battery_std",
     "battery_unknown",
     "beach_access",
     "bedtime",
     "beenhere",
     "bento",
     "bike_scooter",
     "biotech",
     "block",
     "bluetooth",
     "bluetooth_audio",
     "bluetooth_connected",
     "bluetooth_disabled",
     "bluetooth_searching",
     "blur_circular",
     "blur_linear",
     "blur_off",
     "blur_on",
     "book",
     "book_online",
     "bookmark",
     "bookmark_border",
     "bookmarks",
     "border_all",
     "border_bottom",
     "border_clear",
     "border_horizontal",
     "border_inner",
     "border_left",
     "border_outer",
     "border_right",
     "border_style",
     "border_top",
     "border_vertical",
     "branding_watermark",
     "brightness_1",
     "brightness_2",
     "brightness_3",
     "brightness_4",
     "brightness_5",
     "brightness_6",
     "brightness_7",
     "brightness_auto",
     "brightness_high",
     "brightness_low",
     "brightness_medium",
     "broken_image",
     "browser_not_supported",
     "brush",
     "bubble_chart",
     "bug_report",
     "build",
     "build_circle",
     "burst_mode",
     "business",
     "business_center",
     "cached",
     "cake",
     "calculate",
     "calendar_today",
     "calendar_view_day",
     "call",
     "call_end",
     "call_made",
     "call_merge",
     "call_missed",
     "call_missed_outgoing",
     "call_received",
     "call_split",
     "call_to_action",
     "camera",
     "camera_alt",
     "camera_enhance",
     "camera_front",
     "camera_rear",
     "camera_roll",
     "campaign",
     "cancel",
     "cancel_presentation",
     "cancel_schedule_send",
     "card_giftcard",
     "card_membership",
     "card_travel",
     "carpenter",
     "casino",
     "cast",
     "cast_connected",
     "cast_for_education",
     "category",
     "center_focus_strong",
     "center_focus_weak",
     "change_history",
     "charging_station",
     "chat",
     "chat_bubble",
     "chat_bubble_outline",
     "check",
     "check_box",
     "check_box_outline_blank",
     "check_circle",
     "check_circle_outline",
     "checkroom",
     "chevron_left",
     "chevron_right",
     "child_care",
     "child_friendly",
     "chrome_reader_mode",
     "class",
     "clean_hands",
     "cleaning_services",
     "clear",
     "clear_all",
     "close",
     "close_fullscreen",
     "closed_caption",
     "closed_caption_disabled",
     "cloud",
     "cloud_circle",
     "cloud_done",
     "cloud_download",
     "cloud_off",
     "cloud_queue",
     "cloud_upload",
     "code",
     "collections",
     "collections_bookmark",
     "color_lens",
     "colorize",
     "comment",
     "comment_bank",
     "commute",
     "compare",
     "compare_arrows",
     "compass_calibration",
     "computer",
     "confirmation_number",
     "connect_without_contact",
     "construction",
     "contact_mail",
     "contact_phone",
     "contact_support",
     "contactless",
     "contacts",
     "content_copy",
     "content_cut",
     "content_paste",
     "control_camera",
     "control_point",
     "control_point_duplicate",
     "copyright",
     "coronavirus",
     "corporate_fare",
     "countertops",
     "create",
     "create_new_folder",
     "credit_card",
     "crop",
     "crop_16_9",
     "crop_3_2",
     "crop_5_4",
     "crop_7_5",
     "crop_din",
     "crop_free",
     "crop_landscape",
     "crop_original",
     "crop_portrait",
     "crop_rotate",
     "crop_square",
     "dashboard",
     "data_usage",
     "date_range",
     "deck",
     "dehaze",
     "delete",
     "delete_forever",
     "delete_outline",
     "delete_sweep",
     "departure_board",
     "description",
     "design_services",
     "desktop_access_disabled",
     "desktop_mac",
     "desktop_windows",
     "developer_board",
     "developer_mode",
     "device_hub",
     "device_unknown",
     "devices",
     "devices_other",
     "dialer_sip",
     "dialpad",
     "directions",
     "directions_bike",
     "directions_boat",
     "directions_bus",
     "directions_car",
     "directions_off",
     "directions_railway",
     "directions_run",
     "directions_subway",
     "directions_transit",
     "directions_walk",
     "disc_full",
     "dns",
     "do_not_step",
     "do_not_touch",
     "dock",
     "domain",
     "domain_disabled",
     "domain_verification",
     "done",
     "done_all",
     "done_outline",
     "donut_large",
     "donut_small",
     "double_arrow",
     "drafts",
     "drag_handle",
     "drag_indicator",
     "drive_eta",
     "dry",
     "duo",
     "dvr",
     "dynamic_feed",
     "dynamic_form",
     "east",
     "eco",
     "edit",
     "edit_attributes",
     "edit_location",
     "edit_road",
     "eject",
     "elderly",
     "electric_bike",
     "electric_car",
     "electric_moped",
     "electric_scooter",
     "electrical_services",
     "elevator",
     "email",
     "emoji_emotions",
     "emoji_events",
     "emoji_flags",
     "emoji_food_beverage",
     "emoji_nature",
     "emoji_objects",
     "emoji_people",
     "emoji_symbols",
     "emoji_transportation",
     "engineering",
     "enhanced_encryption",
     "equalizer",
     "error",
     "error_outline",
     "escalator",
     "escalator_warning",
     "euro",
     "euro_symbol",
     "ev_station",
     "event",
     "event_available",
     "event_busy",
     "event_note",
     "event_seat",
     "exit_to_app",
     "expand_less",
     "expand_more",
     "explicit",
     "explore",
     "explore_off",
     "exposure",
     "exposure_neg_1",
     "exposure_neg_2",
     "exposure_plus_1",
     "exposure_plus_2",
     "exposure_zero",
     "extension",
     "face",
     "fact_check",
     "family_restroom",
     "fast_forward",
     "fast_rewind",
     "fastfood",
     "favorite",
     "favorite_border",
     "featured_play_list",
     "featured_video",
     "feedback",
     "fence",
     "fiber_dvr",
     "fiber_manual_record",
     "fiber_new",
     "fiber_pin",
     "fiber_smart_record",
     "file_copy",
     "filter",
     "filter_1",
     "filter_2",
     "filter_3",
     "filter_4",
     "filter_5",
     "filter_6",
     "filter_7",
     "filter_8",
     "filter_9",
     "filter_9_plus",
     "filter_alt",
     "filter_b_and_w",
     "filter_center_focus",
     "filter_drama",
     "filter_frames",
     "filter_hdr",
     "filter_list",
     "filter_none",
     "filter_tilt_shift",
     "filter_vintage",
     "find_in_page",
     "find_replace",
     "fingerprint",
     "fire_extinguisher",
     "fireplace",
     "first_page",
     "fitness_center",
     "flag",
     "flaky",
     "flare",
     "flash_auto",
     "flash_off",
     "flash_on",
     "flight",
     "flight_land",
     "flight_takeoff",
     "flip",
     "flip_camera_android",
     "flip_camera_ios",
     "flip_to_back",
     "flip_to_front",
     "folder",
     "folder_open",
     "folder_shared",
     "folder_special",
     "follow_the_signs",
     "font_download",
     "food_bank",
     "format_align_center",
     "format_align_justify",
     "format_align_left",
     "format_align_right",
     "format_bold",
     "format_clear",
     "format_color_reset",
     "format_indent_decrease",
     "format_indent_increase",
     "format_italic",
     "format_line_spacing",
     "format_list_bulleted",
     "format_list_numbered",
     "format_list_numbered_rtl",
     "format_paint",
     "format_quote",
     "format_shapes",
     "format_size",
     "format_strikethrough",
     "format_textdirection_l_to_r",
     "format_textdirection_r_to_l",
     "format_underlined",
     "forum",
     "forward",
     "forward_10",
     "forward_30",
     "forward_5",
     "forward_to_inbox",
     "foundation",
     "free_breakfast",
     "fullscreen",
     "fullscreen_exit",
     "functions",
     "g_translate",
     "gamepad",
     "games",
     "gavel",
     "gesture",
     "get_app",
     "gif",
     "golf_course",
     "gps_fixed",
     "gps_not_fixed",
     "gps_off",
     "grade",
     "gradient",
     "grading",
     "grain",
     "graphic_eq",
     "grass",
     "grid_off",
     "grid_on",
     "group",
     "group_add",
     "group_work",
     "handyman",
     "hd",
     "hdr_off",
     "hdr_on",
     "hdr_strong",
     "hdr_weak",
     "headset",
     "headset_mic",
     "healing",
     "hearing",
     "hearing_disabled",
     "height",
     "help",
     "help_center",
     "help_outline",
     "high_quality",
     "highlight",
     "highlight_alt",
     "highlight_off",
     "history",
     "history_edu",
     "history_toggle_off",
     "home",
     "home_repair_service",
     "home_work",
     "horizontal_rule",
     "horizontal_split",
     "hot_tub",
     "hotel",
     "hourglass_bottom",
     "hourglass_disabled",
     "hourglass_empty",
     "hourglass_full",
     "hourglass_top",
     "house",
     "house_siding",
     "how_to_reg",
     "how_to_vote",
     "http",
     "https",
     "hvac",
     "image",
     "image_aspect_ratio",
     "image_not_supported",
     "image_search",
     "import_contacts",
     "import_export",
     "important_devices",
     "inbox",
     "indeterminate_check_box",
     "info",
     "input",
     "insert_chart",
     "insert_chart_outlined",
     "insert_comment",
     "insert_drive_file",
     "insert_emoticon",
     "insert_invitation",
     "insert_link",
     "insert_photo",
     "insights",
     "integration_instructions",
     "invert_colors",
     "invert_colors_off",
     "iso",
     "keyboard",
     "keyboard_arrow_down",
     "keyboard_arrow_left",
     "keyboard_arrow_right",
     "keyboard_arrow_up",
     "keyboard_backspace",
     "keyboard_capslock",
     "keyboard_hide",
     "keyboard_return",
     "keyboard_tab",
     "keyboard_voice",
     "king_bed",
     "kitchen",
     "label",
     "label_important",
     "label_off",
     "landscape",
     "language",
     "laptop",
     "laptop_chromebook",
     "laptop_mac",
     "laptop_windows",
     "last_page",
     "launch",
     "layers",
     "layers_clear",
     "leaderboard",
     "leak_add",
     "leak_remove",
     "leave_bags_at_home",
     "legend_toggle",
     "lens",
     "library_add",
     "library_add_check",
     "library_books",
     "library_music",
     "line_style",
     "line_weight",
     "linear_scale",
     "link",
     "link_off",
     "linked_camera",
     "list",
     "list_alt",
     "live_help",
     "live_tv",
     "local_activity",
     "local_airport",
     "local_atm",
     "local_bar",
     "local_cafe",
     "local_car_wash",
     "local_convenience_store",
     "local_dining",
     "local_drink",
     "local_fire_department",
     "local_florist",
     "local_gas_station",
     "local_grocery_store",
     "local_hospital",
     "local_hotel",
     "local_laundry_service",
     "local_library",
     "local_mall",
     "local_movies",
     "local_offer",
     "local_parking",
     "local_pharmacy",
     "local_phone",
     "local_pizza",
     "local_play",
     "local_police",
     "local_post_office",
     "local_printshop",
     "local_see",
     "local_shipping",
     "local_taxi",
     "location_city",
     "location_disabled",
     "location_off",
     "location_on",
     "location_searching",
     "lock",
     "lock_open",
     "login",
     "looks",
     "looks_3",
     "looks_4",
     "looks_5",
     "looks_6",
     "looks_one",
     "looks_two",
     "loop",
     "loupe",
     "low_priority",
     "loyalty",
     "mail",
     "mail_outline",
     "map",
     "maps_ugc",
     "mark_chat_read",
     "mark_chat_unread",
     "mark_email_read",
     "mark_email_unread",
     "markunread",
     "markunread_mailbox",
     "masks",
     "maximize",
     "mediation",
     "medical_services",
     "meeting_room",
     "memory",
     "menu",
     "menu_book",
     "menu_open",
     "merge_type",
     "message",
     "mic",
     "mic_none",
     "mic_off",
     "microwave",
     "military_tech",
     "minimize",
     "miscellaneous_services",
     "missed_video_call",
     "mms",
     "mobile_friendly",
     "mobile_off",
     "mobile_screen_share",
     "mode_comment",
     "model_training",
     "monetization_on",
     "money",
     "money_off",
     "monochrome_photos",
     "mood",
     "mood_bad",
     "moped",
     "more",
     "more_horiz",
     "more_time",
     "more_vert",
     "motion_photos_on",
     "motion_photos_paused",
     "mouse",
     "move_to_inbox",
     "movie",
     "movie_creation",
     "movie_filter",
     "multiline_chart",
     "multiple_stop",
     "museum",
     "music_note",
     "music_off",
     "music_video",
     "my_location",
     "nat",
     "nature",
     "nature_people",
     "navigate_before",
     "navigate_next",
     "navigation",
     "near_me",
     "near_me_disabled",
     "network_check",
     "network_locked",
     "new_releases",
     "next_plan",
     "next_week",
     "nfc",
     "night_shelter",
     "nights_stay",
     "no_cell",
     "no_drinks",
     "no_encryption",
     "no_flash",
     "no_food",
     "no_meals",
     "no_meeting_room",
     "no_photography",
     "no_sim",
     "no_stroller",
     "no_transfer",
     "north",
     "north_east",
     "north_west",
     "not_accessible",
     "not_interested",
     "not_listed_location",
     "not_started",
     "note",
     "note_add",
     "notes",
     "notification_important",
     "notifications",
     "notifications_active",
     "notifications_none",
     "notifications_off",
     "notifications_paused",
     "offline_bolt",
     "offline_pin",
     "ondemand_video",
     "online_prediction",
     "opacity",
     "open_in_browser",
     "open_in_full",
     "open_in_new",
     "open_with",
     "outdoor_grill",
     "outlet",
     "outlined_flag",
     "pages",
     "pageview",
     "palette",
     "pan_tool",
     "panorama",
     "panorama_fish_eye",
     "panorama_horizontal",
     "panorama_vertical",
     "panorama_wide_angle",
     "party_mode",
     "pause",
     "pause_circle_filled",
     "pause_circle_outline",
     "pause_presentation",
     "payment",
     "payments",
     "pedal_bike",
     "pending",
     "pending_actions",
     "people",
     "people_alt",
     "people_outline",
     "perm_camera_mic",
     "perm_contact_calendar",
     "perm_data_setting",
     "perm_device_information",
     "perm_identity",
     "perm_media",
     "perm_phone_msg",
     "perm_scan_wifi",
     "person",
     "person_add",
     "person_add_alt_1",
     "person_add_disabled",
     "person_outline",
     "person_pin",
     "person_pin_circle",
     "person_remove",
     "person_remove_alt_1",
     "person_search",
     "personal_video",
     "pest_control",
     "pest_control_rodent",
     "pets",
     "phone",
     "phone_android",
     "phone_bluetooth_speaker",
     "phone_callback",
     "phone_disabled",
     "phone_enabled",
     "phone_forwarded",
     "phone_in_talk",
     "phone_iphone",
     "phone_locked",
     "phone_missed",
     "phone_paused",
     "phonelink",
     "phonelink_erase",
     "phonelink_lock",
     "phonelink_off",
     "phonelink_ring",
     "phonelink_setup",
     "photo",
     "photo_album",
     "photo_camera",
     "photo_filter",
     "photo_library",
     "photo_size_select_actual",
     "photo_size_select_large",
     "photo_size_select_small",
     "picture_as_pdf",
     "picture_in_picture",
     "picture_in_picture_alt",
     "pie_chart",
     "pin_drop",
     "place",
     "plagiarism",
     "play_arrow",
     "play_circle_filled",
     "play_circle_outline",
     "play_for_work",
     "playlist_add",
     "playlist_add_check",
     "playlist_play",
     "plumbing",
     "plus_one",
     "point_of_sale",
     "policy",
     "poll",
     "polymer",
     "pool",
     "portable_wifi_off",
     "portrait",
     "post_add",
     "power",
     "power_input",
     "power_off",
     "power_settings_new",
     "pregnant_woman",
     "present_to_all",
     "preview",
     "print",
     "print_disabled",
     "priority_high",
     "privacy_tip",
     "psychology",
     "public",
     "public_off",
     "publish",
     "push_pin",
     "qr_code",
     "qr_code_scanner",
     "query_builder",
     "question_answer",
     "queue",
     "queue_music",
     "queue_play_next",
     "quickreply",
     "radio",
     "radio_button_checked",
     "radio_button_unchecked",
     "rate_review",
     "read_more",
     "receipt",
     "receipt_long",
     "recent_actors",
     "record_voice_over",
     "redeem",
     "redo",
     "reduce_capacity",
     "refresh",
     "remove",
     "remove_circle",
     "remove_circle_outline",
     "remove_from_queue",
     "remove_red_eye",
     "remove_shopping_cart",
     "reorder",
     "repeat",
     "repeat_one",
     "replay",
     "replay_10",
     "replay_30",
     "replay_5",
     "reply",
     "reply_all",
     "report",
     "report_off",
     "report_problem",
     "request_quote",
     "restaurant",
     "restaurant_menu",
     "restore",
     "restore_from_trash",
     "restore_page",
     "rice_bowl",
     "ring_volume",
     "roofing",
     "room",
     "room_preferences",
     "room_service",
     "rotate_90_degrees_ccw",
     "rotate_left",
     "rotate_right",
     "rounded_corner",
     "router",
     "rowing",
     "rss_feed",
     "rule",
     "rule_folder",
     "run_circle",
     "rv_hookup",
     "sanitizer",
     "satellite",
     "save",
     "save_alt",
     "scanner",
     "scatter_plot",
     "schedule",
     "school",
     "science",
     "score",
     "screen_lock_landscape",
     "screen_lock_portrait",
     "screen_lock_rotation",
     "screen_rotation",
     "screen_share",
     "sd_card",
     "sd_storage",
     "search",
     "search_off",
     "security",
     "select_all",
     "self_improvement",
     "send",
     "sensor_door",
     "sensor_window",
     "sentiment_dissatisfied",
     "sentiment_satisfied",
     "sentiment_satisfied_alt",
     "sentiment_very_dissatisfied",
     "sentiment_very_satisfied",
     "set_meal",
     "settings",
     "settings_applications",
     "settings_backup_restore",
     "settings_bluetooth",
     "settings_brightness",
     "settings_cell",
     "settings_ethernet",
     "settings_input_antenna",
     "settings_input_component",
     "settings_input_composite",
     "settings_input_hdmi",
     "settings_input_svideo",
     "settings_overscan",
     "settings_phone",
     "settings_power",
     "settings_remote",
     "settings_system_daydream",
     "settings_voice",
     "share",
     "shop",
     "shop_two",
     "shopping_bag",
     "shopping_basket",
     "shopping_cart",
     "short_text",
     "show_chart",
     "shuffle",
     "shutter_speed",
     "sick",
     "signal_cellular_4_bar",
     "signal_cellular_alt",
     "signal_cellular_connected_no_internet_4_bar",
     "signal_cellular_no_sim",
     "signal_cellular_null",
     "signal_cellular_off",
     "signal_wifi_4_bar",
     "signal_wifi_4_bar_lock",
     "signal_wifi_off",
     "sim_card",
     "single_bed",
     "skip_next",
     "skip_previous",
     "slideshow",
     "slow_motion_video",
     "smart_button",
     "smartphone",
     "smoke_free",
     "smoking_rooms",
     "sms",
     "sms_failed",
     "snippet_folder",
     "snooze",
     "soap",
     "sort",
     "sort_by_alpha",
     "source",
     "south",
     "south_east",
     "south_west",
     "spa",
     "space_bar",
     "speaker",
     "speaker_group",
     "speaker_notes",
     "speaker_notes_off",
     "speaker_phone",
     "speed",
     "spellcheck",
     "sports",
     "sports_bar",
     "sports_baseball",
     "sports_basketball",
     "sports_cricket",
     "sports_esports",
     "sports_football",
     "sports_golf",
     "sports_handball",
     "sports_hockey",
     "sports_kabaddi",
     "sports_mma",
     "sports_motorsports",
     "sports_rugby",
     "sports_soccer",
     "sports_tennis",
     "sports_volleyball",
     "square_foot",
     "stairs",
     "star",
     "star_border",
     "star_half",
     "star_outline",
     "star_rate",
     "stars",
     "stay_current_landscape",
     "stay_current_portrait",
     "stay_primary_landscape",
     "stay_primary_portrait",
     "sticky_note_2",
     "stop",
     "stop_circle",
     "stop_screen_share",
     "storage",
     "store",
     "store_mall_directory",
     "storefront",
     "straighten",
     "streetview",
     "strikethrough_s",
     "stroller",
     "style",
     "subdirectory_arrow_left",
     "subdirectory_arrow_right",
     "subject",
     "subscript",
     "subscriptions",
     "subtitles",
     "subtitles_off",
     "subway",
     "superscript",
     "supervised_user_circle",
     "supervisor_account",
     "support",
     "support_agent",
     "surround_sound",
     "swap_calls",
     "swap_horiz",
     "swap_horizontal_circle",
     "swap_vert",
     "swap_vertical_circle",
     "switch_camera",
     "switch_left",
     "switch_right",
     "switch_video",
     "sync",
     "sync_alt",
     "sync_disabled",
     "sync_problem",
     "system_update",
     "system_update_alt",
     "tab",
     "tab_unselected",
     "table_chart",
     "table_rows",
     "table_view",
     "tablet",
     "tablet_android",
     "tablet_mac",
     "tag_faces",
     "tap_and_play",
     "tapas",
     "terrain",
     "text_fields",
     "text_format",
     "text_rotate_up",
     "text_rotate_vertical",
     "text_rotation_angledown",
     "text_rotation_angleup",
     "text_rotation_down",
     "text_rotation_none",
     "text_snippet",
     "textsms",
     "texture",
     "theaters",
     "thumb_down",
     "thumb_down_alt",
     "thumb_up",
     "thumb_up_alt",
     "thumbs_up_down",
     "time_to_leave",
     "timelapse",
     "timeline",
     "timer",
     "timer_10",
     "timer_3",
     "timer_off",
     "title",
     "toc",
     "today",
     "toggle_off",
     "toggle_on",
     "toll",
     "tonality",
     "topic",
     "touch_app",
     "tour",
     "toys",
     "track_changes",
     "traffic",
     "train",
     "tram",
     "transfer_within_a_station",
     "transform",
     "transit_enterexit",
     "translate",
     "trending_down",
     "trending_flat",
     "trending_up",
     "trip_origin",
     "tty",
     "tune",
     "turned_in",
     "turned_in_not",
     "tv",
     "tv_off",
     "two_wheeler",
     "umbrella",
     "unarchive",
     "undo",
     "unfold_less",
     "unfold_more",
     "unsubscribe",
     "update",
     "upgrade",
     "usb",
     "verified",
     "verified_user",
     "vertical_align_bottom",
     "vertical_align_center",
     "vertical_align_top",
     "vertical_split",
     "vibration",
     "video_call",
     "video_label",
     "video_library",
     "video_settings",
     "videocam",
     "videocam_off",
     "videogame_asset",
     "view_agenda",
     "view_array",
     "view_carousel",
     "view_column",
     "view_comfy",
     "view_compact",
     "view_day",
     "view_headline",
     "view_list",
     "view_module",
     "view_quilt",
     "view_sidebar",
     "view_stream",
     "view_week",
     "vignette",
     "visibility",
     "visibility_off",
     "voice_chat",
     "voice_over_off",
     "voicemail",
     "volume_down",
     "volume_mute",
     "volume_off",
     "volume_up",
     "vpn_key",
     "vpn_lock",
     "wallpaper",
     "warning",
     "wash",
     "watch",
     "watch_later",
     "water_damage",
     "waves",
     "wb_auto",
     "wb_cloudy",
     "wb_incandescent",
     "wb_iridescent",
     "wb_sunny",
     "wc",
     "web",
     "web_asset",
     "weekend",
     "west",
     "whatshot",
     "wheelchair_pickup",
     "where_to_vote",
     "widgets",
     "wifi",
     "wifi_calling",
     "wifi_lock",
     "wifi_off",
     "wifi_protected_setup",
     "wifi_tethering",
     "wine_bar",
     "work",
     "work_off",
     "work_outline",
     "wrap_text",
     "wrong_location",
     "wysiwyg",
     "youtube_searched_for",
     "zoom_in",
     "zoom_out",
     "zoom_out_map",]
       }
}