import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPListener } from '../shared/services/loading.interceptor';
import { AuthInterceptor } from '../shared/services/auth.interceptor';

const RxJS_Services = [HTTPListener];
@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([], {
      developmentMode: !environment.production
    }),
    CommonModule,
    LayoutModule,
   
  ],
  providers:[
    ...RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
     },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ]
})
export class CoreModule { }
