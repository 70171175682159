import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TopnavComponent } from './topnav/topnav.component';
import { RouterModule } from '@angular/router';
import { JobsModule } from 'src/app/features/jobs/jobs.module';
import { JobImportComponent } from 'src/app/features/jobs/job-import/job-import.component';
import { NgxsModule } from '@ngxs/store';
import { TopnavState } from './topnav/state/topnav.state';
import { ConfigurationModule } from 'src/app/features/configuration/configuration.module';

@NgModule({
    declarations: [LayoutComponent, SidenavComponent, TopnavComponent],
    imports: [
        CommonModule,
        SharedModule,
        JobsModule,
        NgxsModule.forFeature([TopnavState]),
        RouterModule.forChild([]),
        ConfigurationModule
    ]
})
export class LayoutModule { }
