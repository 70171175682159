import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authGuard } from './shared/services/role-guard.service';
import { UserProfileComponent } from './features/configuration/configurations/users/user-profile/user-profile.component';

const routes: Routes = [
  {
    path: 'account',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
      }]
  },
  {
    path: 'test',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/material-theme-test/material-theme.module').then(m => m.MaterialThemeTestModule)
      }]
  },
  {
    path: 'payment',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/payments/payments.module').then(m => m.PaymentsModule)
      }]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboards/dashboards.module').then(m => m.DashboardsModule),
        canActivate: [authGuard],
        data: { role: ['administration'] }
      },
      {
        path: 'jobs',
        loadChildren: () => import('./features/jobs/jobs.module').then(m => m.JobsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./features/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'collections',
        loadChildren: () => import('./features/collections/collections.module').then(m => m.CollectionsModule),
        canActivate: [authGuard],
        data: { role: ['administration', 'csr'] }
      },
      {
        path: 'configuration',
        loadChildren: () => import('./features/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [authGuard],
        data: { role: ['administration'] }
      },
      {
        path: 'orders',
        loadChildren: () => import('./features/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'profile',
        component: UserProfileComponent
      },
      {
        path: 'shipments',
        loadChildren: () => import('./features/shipment/shipment.module').then(m => m.ShipmentModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
