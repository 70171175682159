import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-job-mailing-info-create',
  templateUrl: './job-mailing-info-create.component.html',
  styleUrls: ['./job-mailing-info-create.component.scss']
})
export class JobMailingInfoCreateComponent implements OnInit {

  mailingInfoForm : UntypedFormGroup;
  title= 'Add Mailing Info';
  minDate = new Date();
  
  constructor(public formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public mailingInfo: any,
     public dialogRef: MatDialogRef<JobMailingInfoCreateComponent>,
     public api:ApiService) { }

  ngOnInit() {
   this.mailingInfoForm =  this.initForm(this.formBuilder);
     if(this.mailingInfo != null){
       if(this.mailingInfo['mailingInfoId']!== null){
         this.title = 'Edit Mailing Info';
       }
    this.mailingInfoForm.patchValue(this.mailingInfo);
    }
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
  formBuilder.group({
    foldingLetter:false,
    half:false,
    ra:false,
    spiral:false,
    foldingQuantity:'',
    cheshire:false,
    piggyback:false,
    sa:false,
    xerox:false,
    inkjet:false,
    laser:false,
    labellingQuantity:'',
    oneUp:false,
    twoUp:false,
    threeUp:false,
    fourUp:false,
    fiveUp:false,
    tabbingMachine: false,
    tabbingHand: false,
    special: false,
    clear: false,
    white: false,
    insertingMachine: false,
    insertingHand: false,
    envSize: '',
    noOfInsert: '',
    first: false,
    firstPS: false,
    periodicals: false,
    standart: false,
    sizeLetter: false,
    postCards: false,
    flat: false,
    meter: false,
    stamps:false,
    auto: false,
    clientToPickUp: false,
    dump: false,
    inventory: false,
    firstDueDate: '',
    labellingMaterialDueDate: '',
    insertMaterialDueDate: '',

  });

  hasError = (field: string, errorName: string): boolean =>
  this.mailingInfoForm.get(field).errors ? this.mailingInfoForm.get(field).errors[errorName] : false


  onSubmit() {
    if(this.mailingInfo != null && this.mailingInfo['mailingInfoId'] !=null){
      this.editMailingInfo();
      return;
    }

    let mailingInfo = this.mailingInfoForm.value;
     mailingInfo['foldingQuantity'] = mailingInfo['foldingQuantity'] === '' ? 0:mailingInfo['foldingQuantity'];
     mailingInfo['labellingQuantity'] = mailingInfo['labellingQuantity'] === '' ? 0:mailingInfo['labellingQuantity'];
     mailingInfo['envSize'] = mailingInfo['envSize'] === '' ? 0:mailingInfo['envSize'];
     mailingInfo['noOfInsert'] = mailingInfo['noOfInsert'] === '' ? 0:mailingInfo['noOfInsert'];
    
     this.dialogRef.close(mailingInfo);
  }

  editMailingInfo(){
    let mailingInfo = this.mailingInfoForm.value;
     mailingInfo['foldingQuantity'] = mailingInfo['foldingQuantity'] === '' ? 0:mailingInfo['foldingQuantity'];
     mailingInfo['labellingQuantity'] = mailingInfo['labellingQuantity'] === '' ? 0:mailingInfo['labellingQuantity'];
     mailingInfo['envSize'] = mailingInfo['envSize'] === '' ? 0:mailingInfo['envSize'];
     mailingInfo['noOfInsert'] = mailingInfo['noOfInsert'] === '' ? 0:mailingInfo['noOfInsert'];
     mailingInfo['mailingInfoId']=this.mailingInfo['mailingInfoId'];

    const apiRequest = {
      params: { MAILING_INFO_ID: mailingInfo['mailingInfoId'] },
      data: mailingInfo
    };
  
    this.api.request('EDIT_MAILING_INFO', apiRequest).subscribe((res: any) => {
      if (res) {
        this.dialogRef.close(mailingInfo);
      }
    },error=>{
      //this.dialogRef.close(false);
    });
    

  }
}

