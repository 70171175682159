import { ApiConfig } from '../config/api.config';

/**
 * Global App Configuration
 */

export const AppConfig = {
    title: 'Pacific Printing',
    version: 'v1.1',
    api: ApiConfig
};
