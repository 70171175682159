
<div fxLayout="column" style="height: 600px">
  <div [ngStyle]="{'min-height':JobItem.orderItemNotesAndDocs.length > 0 ? '150px' : '10px','max-height': !addNoteValidate || !addFileValidate ? '340px' : '390px'
      ,'overflow': 'auto'}">
    <div
      *ngFor="let noteOrDoc of JobItem.orderItemNotesAndDocs; let index = index"
      fxLayout="column"
    >
      <div
        *ngIf="noteOrDoc['isDoc']"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="note-item"
        [ngClass]="{
          'csr-note': noteOrDoc['isCsrDocument'],
          'customer-note': !noteOrDoc['isCsrDocument']
        }"
      >
        <div
          class="note"
          fxLayout="row"
          fxFlex="95"
          fxLayoutAlign=" center center"
        >
          <img [src]="noteOrDoc.url" />
          <small
            fxFlex
            style="cursor: pointer"
            (click)="preview(noteOrDoc)"
            class="primary-text-color"
          >
            {{ noteOrDoc.name }}</small
          >

          <div
            class="font-smaller"
            fxLayout="column"
            fxLayoutAlign="start end"
            style="width: 200px; padding-right: 5px"
          >
            <small class="primary-text-color">{{
              noteOrDoc["createdBy"] | titlecase
            }}</small>
            <small class="primary-text-color">{{
              noteOrDoc["createdAt"] | date : "MMM d, y, h:mm"
            }}</small>
          </div>
        </div>

        <div
          fxLayout="row"
          fxFlex="25px"
          fxLayoutAlign="center center"
          *ngIf="
            JobItem['isRequiredApproveArtwork'] &&
            noteOrDoc['isRequiredApproveArtwork']
          "
        >
          <mat-icon
            class="approve-icon reject-document"
            matTooltip="Does not approved artwork by customer."
            fxFlex
            *ngIf="noteOrDoc['isShowApproveStatus']"
            >assignment_late</mat-icon
          >
          <mat-icon
            class="approve-icon approve-document"
            matTooltip="Artwork is approved."
            *ngIf="noteOrDoc['isApproveArtwork']"
            >done_all
          </mat-icon>
          <mat-icon
            class="approve-icon reject-document"
            matTooltip="Artwork is rejected."
            *ngIf="noteOrDoc['isRejectArtwork']"
            >remove_done
          </mat-icon>
        </div>

        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="grid-header-bg primary-text-color"
        >
          <mat-icon class="icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="download(noteOrDoc['id'])"
            *ngIf="noteOrDoc.canDownload"
            class="grid-header-bg primary-text-color"
          >
            <mat-icon class="icon">get_app</mat-icon>
            <span class="primary-text-color">Download</span>
          </button>
          <button
            mat-menu-item
            class="grid-header-bg primary-text-color"
            *ngIf="noteOrDoc.canPreview"
            (click)="preview(noteOrDoc)"
          >
            <mat-icon class="icon">open_in_new</mat-icon>
            <span class="primary-text-color">Preview</span>
          </button>
          <button
            mat-menu-item
            *ngIf="noteOrDoc.canDelete"
            (click)="deleteDocument(noteOrDoc['id'], index)"
            class="grid-header-bg primary-text-color"
          >
            <mat-icon class="icon">delete</mat-icon>
            <span class="primary-text-color">Delete</span>
          </button>
        </mat-menu>
      </div>

      <div
        *ngIf="noteOrDoc['isNote']"
        fxLayout="row"
        class="note-item"
        [ngClass]="{
          'csr-note': noteOrDoc['isCsrNote'],
          'customer-note': !noteOrDoc['isCsrNote']
        }"
      >
        <div
          class="note"
          fxLayout="row"
          fxFlex="100"
          fxLayoutAlign=" center center"
        >
          <div style="padding-bottom: 10px; overflow: auto;">
            <small fxFlex class="preserve-spaces primary-text-color">
              <strong>
                <small *ngIf="noteOrDoc.isFrontInkNote" class="primary-text-color"
                  >Front Ink <br
                /></small>
              </strong>
              <strong>
                <small *ngIf="noteOrDoc.isBackInkNote" class="primary-text-color"
                  >Back Ink <br
                /></small>
              </strong>
              {{ noteOrDoc["text"] }}
            </small>
          </div>
          <span fxFlex></span>
          <div
            class="font-smaller"
            fxLayout="column"
            fxLayoutAlign="start end"
            style="width: 200px"
          >
            <small class="primary-text-color">{{
              noteOrDoc["createdBy"] | titlecase
            }}</small>
            <small class="primary-text-color">{{
              noteOrDoc["createdAt"] | date : "MMM d, y, h:mm"
            }}</small>
          </div>

          <button
            mat-icon-button
            *ngIf="noteOrDoc.canEdit || noteOrDoc.canDelete"
            [matMenuTriggerFor]="noteMenu"
            class="grid-header-bg primary-text-color"
          >
            <mat-icon class="icon">more_vert</mat-icon>
          </button>
          <mat-menu #noteMenu="matMenu">
            <button
              mat-menu-item
              class="grid-header-bg primary-text-color"
              *ngIf="noteOrDoc.canEdit"
              (click)="editNote(noteOrDoc['id'], index)"
            >
              <mat-icon class="icon">edit</mat-icon>
              <span class="primary-text-color">Edit</span>
            </button>
            <button
              mat-menu-item
              *ngIf="noteOrDoc.canDelete"
              class="grid-header-bg primary-text-color"
              (click)="deleteNote(noteOrDoc['id'], index)"
            >
              <mat-icon class="icon">delete</mat-icon>
              <span class="primary-text-color">Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px" style="margin: 5px;">
    <span fxFlex></span>
    <div class="toggle-chat" fxFlex="33">
      <mat-select class="select-chat-type primary-text-color" [(ngModel)]="leaveNote">
        <mat-option #matOption1 value="Leave Note" (click)="selectChatSection(matOption1.value)">
          <span clas="primary-text-color">Add Note</span>
        </mat-option>
        <mat-option #matOption2 value="Document" (click)="selectChatSection(matOption2.value)">
          <span clas="primary-text-color">Add Document</span>
        </mat-option>
      </mat-select>
  </div>
  </div>
  <!-- isLeaveNote = !isLeaveNote -->
  <div fxLayout="row" fxLayoutGap="5px">
    <div fxFlex="100" *ngIf="isLeaveNote">
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" style="width: 100%">
          <textarea
            matInput
            placeholder="Leave a Note"
            rows="2"
            [(ngModel)]="note"
            class="preserve-spaces primary-text-color"
          ></textarea>
        </mat-form-field>
        <div *ngIf="!addNoteValidate">
          <small class="warn">You must enter note</small>
          <br /><br />
        </div>
       
      </div>

      <div
      fxLayout="row"
      fxLayoutAlign="end"
      fxLayoutGap="5px"
      style="margin-top: -15px"
    >
      <button
        mat-stroked-button
        [matMenuTriggerFor]="inkSelectMenu" fxLayoutAlign="center center"
      >
      <div fxLayoutAlign="space-between center">
        <span class="primary-text-color">{{ NoteTypeSelected.type }}</span>&nbsp;&nbsp;
        <span>
        <mat-icon
          style="margin-bottom: 3px"
          >arrow_drop_down</mat-icon
        ></span>
      </div>
      </button>
      <mat-menu #inkSelectMenu="matMenu">
        <button
          *ngFor="let noteType of NoteTypes"
          mat-menu-item
          (click)="selectNoteType(noteType)"
          class="primary-text-color grid-header-bg"
        >
          {{ noteType.type }}
        </button>
      </mat-menu>

      <button
        [disabled]="addNoteLoading"
        mat-stroked-button
        color="primary"
        (click)="saveNote()"
      >
        <div fxLayoutAlign="center center">
          <mat-icon>add</mat-icon>
          <span class="primary-text-color">
            {{
              currentSelectedNote ? "Update Note" : "Add Note"
            }}&nbsp;&nbsp;</span
          >
          <span *ngIf="addNoteLoading" class="loader"></span>
        </div>
      </button>
    </div>
    </div>

    <div fxFlex="100" *ngIf="!isLeaveNote">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div
          class="drag-drop"
          fxlayout="column"
          fxLayoutAlign="center center"
          (click)="fileInput.click()"
          pacificPrintingDragDrop
          (fileDropped)="selectDocuments($event)"
        >
          <div id="myDrop">
            <div fxLayoutAlign="center center" fxLayoutGap="2px">
              <mat-icon class="icon">note_add</mat-icon>
              <small class="primary-text-color"
                >Click/Drag files here to upload</small
              >
            </div>
            <input
              hidden
              type="file"
              #fileInput
              (change)="selectDocuments($event.target.files)"
              multiple
            />
          </div>
        </div>
        <div *ngIf="!addFileValidate" class="file-validation" fxLayoutAlign="start start">
          <small class="warn">You must select file</small>
          <br />
        </div>
        <div fxLayout="row" fxLayoutAlign="center center " style="width: 100%">
          <strong fxFlexOffset="23px" *ngIf="itemDocuments.length > 0">
            <small class="primary-text-color">Added files</small>
          </strong>

          <span fxFlex></span>

          <button
            fxFlex="220px"
            fxFlexOffset="10px"
            [disabled]="addDocumentLoading"
            mat-stroked-button
            color="primary"
            (click)="saveDocuments()" style="margin-top: 8px;"
          >
            <div fxLayoutAlign="center center" fxLayoutGap="4px">
              <mat-icon>backup</mat-icon>
              <span class="primary-text-color"> Upload Document </span>
              <span *ngIf="addDocumentLoading" class="loader"></span>
            </div>
          </button>
        </div>
      </div>

      <div fxLayout="column" *ngIf="itemDocuments.length > 0">
        <div class="selected-files" style="height: 150px; overflow: auto;">
          <div
            class="note-item"
            fxLayout="row"
            fxLayoutAlign=" center center"
            *ngFor="let doc of itemDocuments; let index = index"
          >
            <div
              class="note"
              fxLayout="row"
              fxFlex
              fxLayoutAlign=" center center"
            >
              <img [src]="doc.url" />
              <small fxFlex class="primary-text-color"> {{ doc.name }}</small>
            </div>
            <button mat-icon-button (click)="removeSelectedDocument(index)">
              <mat-icon class="pointer icon">close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

