import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ApiService } from "src/app/shared/services/api.service";

@Component({
  selector: "app-job-template-label-dialog",
  templateUrl: "./job-template-label-dialog.component.html",
  styleUrls: ["./job-template-label-dialog.component.scss"],
})
export class JobTemplateLabelDialogComponent implements OnInit, AfterViewInit {
  isSearchingByJobNo: boolean = false;

  customerNamesPageNo: number = 0;
  customerNamesPageSize: number = 10;
  customerNamefilterText: string = "";
  loadingCustomerNames: boolean = false;
  customerNames = [];
  TotalCustomerNames = 0;
  selectedCustomerName = "";
  selectedCustomerNameIsInvalid = false;
  @ViewChild("customerNamesSelect") customerNameSelectElem: MatAutocomplete;
  @ViewChild("customerNameSearchInput") customerNameSearchInput: ElementRef;

  jobNosPageNo: number = 0;
  jobNosPageSize: number = 10;
  jobNosfilterText: string = "";
  loadingJobNos: boolean = false;
  jobs = [];
  TotalJobNos = 0;
  selectedJob = null;
  selectedJobNoIsInvalid = false;
  @ViewChild("jobNoSelect") jobNoSelectElem: MatAutocomplete;
  @ViewChild("jobNoSearchInput") jobNoSearchInput: ElementRef;

  itemNamePageNo: number = 0;
  itemNamePageSize: number = 10;
  itemNamefilterText: string = "";
  loadingItemName: boolean = false;
  itemNames = [];
  filteredItemNames = [];
  TotalItemNames = 0;
  selectedItemName = "";
  selectedItemNameIsInvalid = false;
  operatorMinValue = 1;
  operatorMaxValue = 1;
  @ViewChild("itemNameSelect") itemNameSelectElem: MatAutocomplete;
  @ViewChild("itemNameSearchInput") itemNameSearchInput: ElementRef;

  templateForm = this.formBuilder.group({
    customerName: [""],
    itemName: [""],
    quantityPerBox: [""],
    quantityOfBox: [""],
    boxdividetotalBoxes: [""],
    jobNo: [""],
    customerNameFilterControl: [""],
    jobNoFilterControl: [""],
    itemNameFilterControl: [""],
    operator: [0, [Validators.min(this.operatorMinValue), Validators.max(this.operatorMaxValue), Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<JobTemplateLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: UntypedFormBuilder,
    private api: ApiService
  ) { }
  ngAfterViewInit(): void {
    fromEvent(this.customerNameSearchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.templateForm.controls["customerNameFilterControl"].setErrors({
          required: true,
        });
        this.customerNamefilterText = text.toString().trim();
        this.customerNamesPageNo = 0;
        this.loadingCustomerNames = true;
        this.loadCustomerName();

        this.jobs = [];
        this.jobNosPageNo = 0;
        this.removeJobNo();

        this.itemNames = [];
        this.removeItemName();
      });

    fromEvent(this.jobNoSearchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.isSearchingByJobNo = true;
        this.templateForm.controls["jobNoFilterControl"].setErrors({
          required: true,
        });
        this.jobNosfilterText = text.trim();
        this.jobNosPageNo = 0;
        this.loadingJobNos = true;
        this.loadJobNos();

        this.itemNames = [];
        this.removeItemName();

        this.customerNamesPageNo = 0;
        this.customerNames = [];
        this.removeCustomerName();
      });

    fromEvent(this.itemNameSearchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.templateForm.controls["itemNameFilterControl"].setErrors({
          required: true,
        });
        this.itemNamefilterText = text.trim();
        if (
          this.itemNamefilterText == "" ||
          this.itemNamefilterText == null ||
          this.itemNamefilterText == undefined
        ) {
          this.filteredItemNames = this.itemNames;
        } else {
          this.filteredItemNames = this.itemNames.filter((c) =>
            c
              .toString()
              .toLowerCase()
              .includes(this.itemNamefilterText.toString().toLowerCase())
          );
        }
      });
  }

  ngOnInit() {
    this.loadCustomerName();
  }
  //job name, Item Name,Quantity per box,Quantity of Boxes and no.of boxes/Total boxes
  closeDialog(): void {
    this.dialogRef.close();
  }

  hasError = (field: string, errorName: string): boolean =>
    this.templateForm.get(field).errors
      ? this.templateForm.get(field).errors[errorName]
      : false;

  printTemplate() {
    if (this.templateForm.invalid) {
      this.markFormGroupTouched(this.templateForm);
    }
    if (this.templateForm.invalid) {
      return;
    }

    const WindowPrt = window.open(
      "",
      "",
      "width=800,height=600,left=200,top=200"
    );
    WindowPrt.document.write(`
    <!DOCTYPE html>
        <html>
        <body>
        <style>
        @media print{

          #flexitem{
            font-size:5mm;
            font-family:"Roboto";
            border:black solid 0.5mm;
            padding:1mm;
            min-width:50%;
            word-wrap: break-word;
            width:90%;
          }

          #flexbody{
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-bottom: 3px;
            width:100%;
            height:100%;
          }

          .pagebreak {
            page-break-after: always;
          }

          @page { size: 4in 5.25in;}

          .full{
            width:100%;
            height:100%;
          }
        }

        </style>
          `);

    for (
      let count = this.templateForm.value.operator;
      count <= this.templateForm.value.quantityOfBox;
      count++
    ) {
      WindowPrt.document.write(`
      <div class="full pagebreak">
      <div id="flexbody" >
          <div id="flexitem">
          <strong>JOB NO : </strong><br> ${this.templateForm.value["jobNo"]}
          </div>
          <br>
          <div id="flexitem">
          <strong>ITEM NAME : </strong><br> ${this.templateForm.value["itemName"]}
          </div>
          <br>
          <div id="flexitem">
          <strong>QUANTITY PER BOX : </strong><br> ${this.templateForm.value["quantityPerBox"]}
          </div>
          <br>
          <div id="flexitem">
          <strong>NUMBER OF BOXES : </strong><br> ${this.templateForm.value["quantityOfBox"]}
          </div>
          <br>
          <div id="flexitem">
          <strong>#BOX / TOTAL BOXES : </strong><br> ${count}/${this.templateForm.value["quantityOfBox"]}
          </div>
          </div>
          </div>
        `);
    }

    WindowPrt.document.write(`
    </body>
    </html>`);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  //All methods of job no field dropdown
  registerPanelScrollEventForJobNoField() {
    if (this.jobNoSelectElem.panel != undefined) {
      const panel = this.jobNoSelectElem.panel.nativeElement;
      panel.addEventListener("scroll", (event) =>
        this.loadNextBatchOfJobNosOnScroll(event)
      );
    }
  }
  loadNextBatchOfJobNosOnScroll(event) {
    var i = this.TotalJobNos / this.jobNosPageSize;
    i = Math.ceil(i);
    if (this.loadingJobNos) {
      return;
    }
    if (this.jobNosPageSize < i) {
      if (
        Math.ceil(event.target.scrollTop + event.target.offsetHeight) ==
        event.target.scrollHeight
      ) {
        this.jobNosPageNo = this.jobNosPageNo + 1;
        this.loadingJobNos = true;
        this.loadJobNos();
      }
    }
  }
  loadJobNos() {
    if (this.isSearchingByJobNo) {
      var requestObj = {
        params: {},
        data: {
          pageNo: this.jobNosPageNo,
          pageSize: this.jobNosPageSize,
          searchValue: this.jobNosfilterText,
        },
      };
      this.api.request("GET_JOB_NUMBERS", requestObj).subscribe((res) => {
        if (this.jobNosPageNo == 0) {
          this.TotalJobNos = res["total"];
          this.jobs = res["result"];
          this.loadingJobNos = false;
        } else {
          this.TotalJobNos = res["total"];
          var prevJobNos = this.jobs.slice();
          var jobNosRes = res["result"];
          this.jobs = prevJobNos.concat(jobNosRes);
          this.loadingJobNos = false;
        }
      });
    } else {
      const params = {
        CUSTOMER_NAME: this.templateForm.controls["customerName"].value,
      };
      this.api
        .request("GET_JOB_NOS_BY_CUSTOMER_NAME", { params: params })
        .subscribe((res) => {
          this.TotalJobNos = res["total"];
          this.jobs = [...res["result"]];
        });
    }
  }
  selectJobNo(event: MatAutocompleteSelectedEvent) {
    let jobNumber = event.option.value;
    let job = this.jobs.find(j => j.jobNumber == jobNumber);
    this.templateForm.controls["jobNo"].setValue(job.jobNumber);
    this.selectedJob = job;
    this.loadJobItemNames();
    this.templateForm.controls["customerName"].setValue(job.customerName);
    this.templateForm.controls["customerNameFilterControl"].setValue(
      job.customerName
    );
  }
  removeJobNo() {
    this.selectedJob = null;
    this.templateForm.controls["jobNoFilterControl"].setValue("");
    this.templateForm.controls["jobNo"].setValue("");
  }

  //Jo Item Field Functions
  selectItemName(event: MatAutocompleteSelectedEvent) {
    let itemName = event.option.value;
    this.templateForm.controls["itemName"].setValue(itemName);
  }
  removeItemName() {
    this.templateForm.controls["itemNameFilterControl"].setValue("");
    this.selectedItemName = "";
    this.filteredItemNames = this.itemNames;
  }
  loadJobItemNames() {
    const params = {
      JOB_ID: this.selectedJob["jobId"],
    };

    this.itemNames = [];
    this.filteredItemNames = [];
    this.removeItemName();
    this.templateForm.controls["itemNameFilterControl"].setValue("");

    this.api
      .request("GET_JOB_ITEM_NAMES_BY_JOB_ID", { params: params })
      .subscribe((res) => {
        this.TotalCustomerNames = res["total"];
        this.itemNames = [...res["result"]];
        this.filteredItemNames = [...res["result"]];
      });
  }

  //Customer Name Field
  registerPanelScrollEventForCustomerNameField() {
    if (this.customerNameSelectElem.panel != undefined) {
      const panel = this.customerNameSelectElem.panel.nativeElement;
      panel.addEventListener("scroll", (event) =>
        this.loadNextBatchOfCustomerNamesOnScroll(event)
      );
    }
  }
  loadNextBatchOfCustomerNamesOnScroll(event) {
    var i = this.TotalCustomerNames / this.customerNamesPageSize;
    i = Math.ceil(i);
    if (this.loadingCustomerNames) {
      return;
    }
    if (this.customerNamesPageNo < i) {
      if (
        Math.ceil(event.target.scrollTop + event.target.offsetHeight) ==
        event.target.scrollHeight
      ) {
        this.customerNamesPageNo = this.customerNamesPageNo + 1;
        this.loadingCustomerNames = true;
        this.loadCustomerName();
      }
    }
  }
  loadCustomerName() {
    const params = {
      PAGE_NO: this.customerNamesPageNo,
      PAGE_SIZE: this.customerNamesPageSize,
      SEARCH_NAME: this.customerNamefilterText,
    };

    this.api
      .request("GET_CUSTOMER_NAMES_OF_JOBS", { params: params })
      .subscribe((res) => {
        if (this.customerNamesPageNo == 0) {
          this.TotalCustomerNames = res["total"];
          this.customerNames = res["result"];
          this.loadingCustomerNames = false;
        } else {
          this.TotalCustomerNames = res["total"];
          var prevCustomerNames = this.customerNames.slice();
          var customerNamesRes = res["result"];
          this.customerNames = prevCustomerNames.concat(customerNamesRes);
          this.loadingCustomerNames = false;
        }
      });
  }
  selectCustomerName(event: MatAutocompleteSelectedEvent) {
    let name = event.option.value;
    if(this.templateForm.controls['customerName'].value !== name){
      this.removeJobNo();
      this.removeItemName();
    }
    this.templateForm.controls["customerName"].setValue(name);
    this.isSearchingByJobNo = false;
    this.loadJobNos();
  }
  removeCustomerName() {
    this.selectedCustomerName = "";
  }

  updateBoxDividedByTotalBox() {
    let maximumLimitAllowedOnOperator = 1;
    if (this.templateForm.value.quantityOfBox) {
      maximumLimitAllowedOnOperator = this.templateForm.value.quantityOfBox;
    }

    this.templateForm.controls["operator"].setValidators([
      Validators.min(1),
      Validators.max(maximumLimitAllowedOnOperator),
      Validators.required,
    ]);
    this.templateForm.controls["operator"].updateValueAndValidity();

    this.operatorMaxValue = maximumLimitAllowedOnOperator;

    if (
      this.templateForm.value.operator &&
      this.templateForm.value.quantityOfBox
    ) {
      const valueToSet =
        this.templateForm.value.operator.toString() +
        "/" +
        this.templateForm.value.quantityOfBox.toString();
      this.templateForm.controls["boxdividetotalBoxes"].setValue(valueToSet);
    } else {
      this.templateForm.controls["boxdividetotalBoxes"].setValue("");
    }
  }
}
