<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <strong style="font-size: 1.4rem" class="primary-text-color"
    >Data Source</strong
  >

  <strong class="primary-text-color"
    >Selected Data Source: {{ dataSourceName ? dataSourceName : "-" }}</strong
  >
  <mat-form-field appearance="outline">
    <mat-label>DataSources</mat-label>
    <mat-select placeholder="DataSources" [(value)]="selectedDataSource">
      <mat-option *ngFor="let dataSource of dataSources" [value]="dataSource">{{
        dataSource.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <span *ngIf="dataSources?.length === 0" class="primary-text-color"
    >No Data Source Availabel!</span
  >
  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
    <button
      mat-raised-button
      color="primary"
      fxFlex="100px"
      type="submit"
      (click)="submitClick()"
    >
      SUBMIT
    </button>
    <button mat-raised-button type="button" (click)="onCloseClick()">
      CLOSE
    </button>
  </div>
</div>
