import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  selectedMenu;

  menuItems = [
    { title: 'HOME', icon: 'dashboard' },
    { title: 'NEW JOBS', icon: 'chrome_reader_mode' },
    { title: 'IN PROGRESS JOBS', icon: 'home' },
    { title: 'DELIVERED JOBS', icon: 'home' },
    { title: 'HISTORY', icon: 'history' },
    { title: 'ADMIN SECTION', icon: 'settings' },
  ];
  constructor() { }

  ngOnInit() {
  }

  onSelectMenu(menu): void {
    this.selectedMenu = menu;
  }
}
