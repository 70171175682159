import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { log } from 'src/app/shared/interfaces/jobItem';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { LoadJobLogs } from '../../state/job.actions';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss']
})
export class JobHistoryComponent implements OnInit {

  logs = [];
  @Input() jobItemId;
  @Input() tabId;
  @Input() steps;
  constructor(private api: ApiService, private action$:Actions) { }

  ngOnInit() {

    this.action$.pipe(ofActionSuccessful(LoadJobLogs)).subscribe(_l => {
      if(_l.jobItemId == this.jobItemId){
        this.getLogs();
      }
    })

    this.getLogs();
  }

  getLogs(){

    const params ={
      params:{
        'JOB_ITEM_ID':this.jobItemId
      }
    };

    this.api.request('GET_LOGS',params).subscribe(_l =>{
     this.logs = _l;
    });
  }
}
