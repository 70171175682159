<div fxLayout="column" fxLayoutAlign="space-between" class="padding-15">
  <div fxLayout="column" fxLayoutAlign="center center">
    <span class="heder-text"
      ><strong class="primary-text-color"
        >{{ data.title }} - Notes</strong
      ></span
    >
  </div>

  <div style="overflow: auto;" fxFlex="280px">
    <mat-list>
      <mat-list-item *ngFor="let note of data.notes">
        <mat-icon matListItemIcon color="warn">double_arrow</mat-icon>
        <h3 matListItemTitle>{{ note.text }}</h3>
      </mat-list-item>
    </mat-list>
  </div>

  <mat-dialog-actions fxLayoutAlign="center">
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
      CONFIRM
    </button>
  </mat-dialog-actions>
</div>
