import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import * as signalR from "@microsoft/signalr";

@Injectable({
    providedIn: 'root'
})
export class OrderHubService {

    private hubConnection: signalR.HubConnection;
    public unseenOrderCount = 0;
    public unseenOrderCountUpdated: BehaviorSubject<number> = new BehaviorSubject(0);
    public loadOrders : BehaviorSubject<Boolean> = new BehaviorSubject(true);

    public startConnection = () => {

        const url = environment.customerPortalUrl + '/orderHub';

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();



        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
        this.addOrderCountEventListener();
        this.connect();
        this.hubConnection.onclose(_ => {
            this.connect();
        })
    }

    connect() {
        this.hubConnection
            .start()
            .then(() => {console.log('Connection started with Order hub');this.hubConnection.invoke("SendUnseenOrderCount");})
            .catch(err => {
                console.error('Error while starting connection: ' + err);
            });
    }

    public addOrderCountEventListener = () => {
        this.hubConnection.on('order-count-changed', (data) => {
            this.unseenOrderCount = data;
            let prevValue = this.unseenOrderCountUpdated.getValue();
            if(prevValue < data){
                this.loadOrders.next(true);
            }
            this.unseenOrderCountUpdated.next(this.unseenOrderCount);
        });
    }
}