<div fxLayout="column" class="padding-15">
  <div class="header-text" mat-dialog-title fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-icon class="info">info</mat-icon> <strong>Info</strong>
  </div>
  <mat-divider></mat-divider>
  <div fxLayoutAlign="center center">
    <mat-dialog-content style="text-align: center" class="secondary-text-color">
      <!-- <span>Job Update With PrinterPlan</span> -->
      <span>The job has been updated in printer plan, you must update the job before proceeding to further
        updates.</span>
    </mat-dialog-content>
  </div>
  <!-- <h1 *ngFor="let log of updatedDataLogs; let index = index">
    {{log}}
  </h1> -->
  <!-- <div>
      <mat-list role="list" *ngFor="let log of updatedDataLogs">
        <mat-list-item role="listitem">{{log}}</mat-list-item>
      </mat-list>
 </div> -->
  <mat-divider></mat-divider>
  <mat-dialog-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="updateJobWithPP()">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span> <strong>Proceed</strong></span>
        <span *ngIf="isLoading" class="loader"></span>
      </div>
    </button>
  </mat-dialog-actions>
</div>