<div
  *ngIf="!isWorkflowView"
  fxLayout="column"
  fxLayoutGap="8px"
  class="padding-15 h-100"
>
  <div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="column">
      <div
        class="job-info"
        fxLayout="row"
        fxFlex="auto"
        fxLayoutAlign="space-between none"
      >
        <div fxLayout="column" fxFlex="80" fxFlexOffset="2" fxFlexAlign="start">
          <div fxLayout="row">
            <div fxFlex style="font-size: 1.4rem" class="primary-text-color">
              {{ job.jobNumber }} - {{ job.title }}
            </div>
          </div>

          <div fxLayoutAlign="start center" class="primary-text-color">
            <i class="material-icons"> person </i>
            {{ job.customerName }}
            <span fxFlex="10px"></span>

            <div fxLayoutGap="5px" fxLayoutAlign="start center">
              <mat-icon>date_range</mat-icon>
              <small class="primary-text-color">Due on </small>
              <span [class.warn]="job.dateDue < today">
                {{ job.dateDue | date : "mediumDate" }}</span
              >
            </div>
          </div>
          <br />

          <div fxLayout="row wrap" fxLayoutGap="6px">
            <span fxFlex="45" class="primary-text-color"
              >CSR: {{ job?.items[0]?.csrName }} ({{ job.csrNo }})</span
            >
            <span class="primary-text-color">
              Date In:
              <strong class="primary-text-color">
                {{ job.dateDue | date : "mediumDate" }}</strong
              >
            </span>
          </div>
        </div>

        <div fxFlex fxLayoutAlign="end start">
          <mat-icon style="cursor: pointer" (click)="dialogRef.close()"
            >close</mat-icon
          >
        </div>
      </div>

      <mat-divider></mat-divider>
    </div>

    <div fxFlex="80">
      <div fxLayout="row" fxLayoutAlign="end">
        <button
          *ngIf="job.items.length > 0"
          (click)="isWorkflowView = !isWorkflowView"
          color="primary"
          mat-stroked-button
        >
          <mat-icon>transform</mat-icon>
          Create Steps
        </button>
      </div>
      <br />
      <div style="max-height:50vh;overflow: auto;">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let item of job?.items"
          class="secondary-bg-color"
        >
          <mat-expansion-panel-header class="secondary-bg-color">
            <mat-panel-title
              fxLayout="row"
              fxFillFlex
              fxLayoutAlign="space-between center"
            >
              <span fxFlex class="primary-text-color">{{
                item.description
              }}</span>
              <span fxFlex class="primary-text-color">
                Qty: {{ item.chargeQty }}</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <mat-list fxFlex class="border">
              <mat-list-item class="secondary-bg-color">
                <div fxLayout="row" fxFlex fxLayoutGap="5px">
                  <strong fxFlex
                    ><small class="primary-text-color"
                      >Department</small
                    ></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Service</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color"
                      >Paper Color</small
                    ></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">RunSize</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color"
                      >FinishSize</small
                    ></strong
                  >
                </div>
              </mat-list-item>

              <!-- Items -->
              <ng-container *ngFor="let service of item.services">
                <mat-divider></mat-divider>
                <mat-list-item class="primary-bg-color">
                  <div fxLayout="row" fxFlex fxLayoutGap="5px">
                    <small fxFlex class="primary-text-color">{{
                      service.departmentName
                    }}</small>
                    <small fxFlex class="primary-text-color">{{
                      service.serviceName
                    }}</small>
                    <small fxFlex>
                      <span
                        *ngIf="service.paperColor == ''"
                        class="primary-text-color"
                        >-</span
                      >
                      <span
                        *ngIf="service.paperColor != ''"
                        class="primary-text-color"
                        >{{ service.paperColor }}</span
                      ></small
                    >
                    <small fxFlex>
                      <span
                        *ngIf="service.runSize == null || service.runSize == ''"
                        class="primary-text-color"
                        >-</span
                      >
                      <span
                        *ngIf="service.runSize != null || service.runSize != ''"
                        class="primary-text-color"
                        >{{ service.runSize }}</span
                      >
                    </small>
                    <small fxFlex>
                      <span
                        *ngIf="
                          service.finishSize == null || service.finishSize == ''
                        "
                        class="primary-text-color"
                        >-</span
                      >
                      <span
                        *ngIf="
                          service.finishSize != null || service.finishSize != ''
                        "
                        class="primary-text-color"
                        >{{ service.finishSize }}</span
                      ></small
                    >
                  </div>
                </mat-list-item>
              </ng-container>
            </mat-list>
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    </div>

    <div
      fxLayout="row"
      fxFlex="10"
      fxFlexOffset="1"
      fxLayoutGap="15px"
      fxLayoutAlign="end center"
    >
      <small *ngIf="!isValidate" class="warn">
        <b>You must select steps for job items.</b>
      </small>
      <button
        [disabled]="isLoading"
        fxFlex="100px"
        mat-raised-button
        color="primary"
        *ngIf="canImportJob"
        (click)="onImportJob()"
      >
        IMPORT
      </button>
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        CLOSE
      </button>
    </div>
  </div>
</div>

<app-job-import-workflow
  *ngIf="isWorkflowView"
  [job]="job"
  (close)="onCloseWorkflow($event)"
></app-job-import-workflow>
