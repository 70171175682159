<div fxLayout="column" fxLayoutAlign="center center" class="confirm-dialog">
  <strong
    class="header-text primary-text-color"
    mat-dialog-title
    fxLayoutAlign="center center"
  >
    <mat-icon class="info" *ngIf="data.type == 'Info'">info</mat-icon>
    <mat-icon *ngIf="data.type == 'Confirm'" class="confirm"
      >report_problem </mat-icon
    >{{ title }}</strong
  >
  <mat-dialog-content style="text-align: center" class="secondary-text-color message">{{
    data.message
  }}</mat-dialog-content>
  <mat-dialog-actions *ngIf="data.type === 'Confirm'">
    <button
      mat-button
      color="warn"
      [mat-dialog-close]="true"
      class="primary-text-color"
    >
      CONFIRM
    </button>
    <button
      mat-button
      mat-dialog-close
      class="primary-text-color secondary-bg-color"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="data.type === 'Info'">
    <button
      mat-button
      class="info primary-text-color"
      [mat-dialog-close]="true"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
