import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor( private snackbar: MatSnackBar) { }
  show(message: string) {
    this.snackbar.open(message, null, {
      duration: 2500,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      
    });
  }

  hide(){
    this.snackbar.dismiss();
  }
}
