<div fxLayout="row">
  <app-breadcrumb title="Users" [subTitles]="subTitles"></app-breadcrumb>
  <span fxFlex></span>
  <div fxLayoutAlign="center center">
    <button mat-raised-button color="primary" (click)="onCreateUser()">
      <mat-icon class="scaleOneDotFive">add</mat-icon>
      <span>CREATE USER</span>
    </button>
  </div>
</div>

<div *ngIf="users?.length === 0" fxLayoutAlign="center">
  <h3 class="primary-text-color">No Users Available!</h3>
</div>

<br />

<!-- class="mat-elevation-z1" -->
<div class="table-container" *ngIf="users?.length > 0" style="height: Calc(100vh - 250px)" fxLayout="column" >
  <div style="height: Calc(100vh - 308px); overflow: auto;">
    <table mat-table [dataSource]="users" class="primary-bg-color">
      <!-- First Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef class="table-header">First Name</th>
        <td mat-cell *matCellDef="let user" class="table-row">
          {{ user.firstName }}
        </td>
      </ng-container>
  
      <!-- Last Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef class="table-header">Last Name</th>
        <td mat-cell *matCellDef="let user" class="table-row">
          {{ user.lastName }}
        </td>
      </ng-container>
  
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="table-header">Email</th>
        <td mat-cell *matCellDef="let user" class="table-row">
          {{ user.email }}
        </td>
      </ng-container>
  
      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef class="table-header">Phone</th>
        <td mat-cell *matCellDef="let user" class="table-row">
          {{ user.phone }}
        </td>
      </ng-container>
  
      <!-- Department Column -->
      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          Departments
        </th>
        <td mat-cell *matCellDef="let user" class="table-row">
          <ng-container
            *ngFor="let item of user.departments; let index = index"
            class="table-row"
          >
            {{ item.name | titlecase
            }}<span *ngIf="index !== user.departments.length - 1">,</span>
          </ng-container>
        </td>
      </ng-container>
  
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="table-header"></th>
        <td mat-cell *matCellDef="let user" class="table-row">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
  
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEditClick(user)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="onDeleteClick(user.userId)">
              <mat-icon>delete</mat-icon>
              <span>Remove</span>
            </button>
            <button mat-menu-item (click)="onChangePassword(user.userId)">
              <mat-icon>https</mat-icon>
              <span>Change Password</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  
  <!-- Pagination -->
  <mat-paginator
    [length]="totalRecord"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 100]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
