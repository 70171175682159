import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Store } from '@ngxs/store';
import { ReceiveNotification } from 'src/app/core/layout/topnav/state/topnav.action';
import { AuthUserService } from './auth-user.service';
import { ReloadeJobs } from 'src/app/features/jobs/state/job.actions';
import * as signalR from '@microsoft/signalr'


@Injectable({
  providedIn: 'root'
})
export class NotificationHubService {

  private hubConnection: signalR.HubConnection;
  private notificationCount = 0;
  constructor(private store: Store, private authService: AuthUserService) {}

  public startConnection = () => {
    const user = this.authService.getUser();  
    if (user) {
      const url = environment.baseUrl + '/notification?userId=' + user.userId;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets || signalR.HttpTransportType.LongPolling })
        .build();
      this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
      this.getNotification();
      this.connect();
      this.hubConnection.onclose(_ => {
       this.connect();
      })
    }
  }

  connect(){
    this.hubConnection
    .start()
    .then(() => console.log('Connection started With Notification Hub'))
    .catch(err => {
      console.error('Error while starting connection: ' + err);
    });
  }

  getNotification() {
    this.hubConnection.on('notificationReceived', (res) => {
      const notification:any = {
        id: res.notificationId,
        jobItemNo: res.jobItemNo,
        jobNumber: res.jobNumber,
        message: res.message,
        isRead: res.isRead,
        createdAt: Date.now(),
        type:res.type 
      };
      this.store.dispatch(new ReceiveNotification(notification));
      
     // this.notificationCount = this.notificationCount + 1;
     // if(this.notificationCount == 1){
        var self = this;
        setTimeout(function () {
          //self.store.dispatch(new ReloadeJobs());
          self.notificationCount = 0;
        }, 2000);
      //}      
    });

  }

  sendNotification(notification: any) {
    this.hubConnection
      .invoke('sendNotifications', notification )
      .then()
      .catch(err => console.error(err));
  }
}
