import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, AfterViewInit } from '@angular/core';
import { DepartmentsComponent } from '../configurations/departments/departments.component';
import { UsersComponent } from '../configurations/users/users.component';
import { ActionStepsComponent } from '../configurations/action-steps/action-steps.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { Store } from '@ngxs/store';
import { LoadDepartments } from './../state/configuration.actions';
import { DataSourceComponent } from '../configurations/data-source/data-source.component';
import { NavigationMenusComponent } from '../configurations/navigation-menus/navigation-menus.component';
import { CompaniesComponent } from '../configurations/companies/companies.component';

@Component({
  selector: 'app-configuration-view',
  templateUrl: './configuration-view.component.html',
  styleUrls: ['./configuration-view.component.scss']
})
export class ConfigurationViewComponent implements OnInit, AfterViewInit {



  selectedConfiguration;
  @ViewChild('configurationComponent', { read: ViewContainerRef }) configurationComponent: any;

  configurations = [
    {
      name: 'USERS',
      icon: 'people',
      component: UsersComponent
    },
    {
      name: 'DEPARTMENTS',
      icon: 'store',
      component: DepartmentsComponent
    },
    {
      name: 'STEPS/ACTIONS',
      icon: 'format_paint',
      component: ActionStepsComponent
    },
    {
      name: 'DATA SOURCE',
      icon: 'device_hub',
      component: DataSourceComponent
    },
    {
      name: 'NAVIGATION MENU',
      icon: 'menu',
      component: NavigationMenusComponent
    }, {
      name: 'COMPANIES',
      icon: 'work',
      component: CompaniesComponent
    }
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private apiService: ApiService,
    private store: Store) { }

  ngAfterViewInit(): void {
    this.selectedConfiguration = this.configurations[0];
    this.loadDepartments();
    this.loadComponent();
  }

  ngOnInit() {
  }

  onConfigurationSelected(configuration): void {
    this.selectedConfiguration = configuration;

    this.loadComponent();
  }

  private loadComponent(): void {

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.selectedConfiguration.component);
    this.configurationComponent.clear();

    let componentRef = this.configurationComponent.createComponent(componentFactory);

  }

  /**
   * get departments.
   */
  private loadDepartments() {
    this.store.dispatch(new LoadDepartments());
  }
}
