import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UPSService } from '../services/ups.service';

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingComponent implements OnInit {

  shipment = {};
  currentDate = new Date();
  date = this.currentDate.setDate(-1);
  shipmentActivity = [];
  ups;
  isLoad = false;
  loadTrackInfo = false;
  shipmentStatus = {
    status: '',
    label1: '',
    label2: '',
    day: '',
    date: '',
    time: '',
    description: ''
  }


  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private upsService: UPSService,
    private toast: ToastService) { }

  ngOnInit() {
    this.getShipment();
    this.tracking();
  }

  getShipment() {
    this.api
      .request('GET_SHIPMENT', {
        params: {
          ID: this.data.shipmentId
        }
      })
      .subscribe(res => {
        if (res && res['result']) {
          this.shipment = res['result'];
          this.ups = this.upsService.UpsServices.find(us => us.code == this.shipment['upsService']);
          this.isLoad = true;
        }
      });
  }

  tracking() {
    this.api
      .request(
        'TRACK_SHIPMENT',
        {
          params:
            { ID: this.data.identificationNo} //'1Z5338FF0107231059' }
        }
      ).subscribe(res => {
        if (res && res.trackResponse.shipment[0].warnings) {
          this.toast.show(res.trackResponse.shipment[0].warnings[0].message);
        }
        else {
           this.processTrackingResponse(res.trackResponse.shipment[0].package[0]);
        }
      });
  }

  processTrackingResponse(pkg: any) {
    pkg.activity.forEach(actv => {
      this.shipmentStatus.description = actv.status.description;
      this.shipmentActivity.push({
        city: actv.location.address.city,
        country: actv.location.address.country,
        postalCode: actv.location.address.postalCode,
        stateProvince: actv.location.address.stateProvince,
        date: actv.date.substring(4, 6) + '/' + actv.date.substring(6, 8) + '/' + actv.date.substring(0, 4),
        time: actv.time.substring(0, 2) + ':' + actv.time.substring(2, 4),
        status: this.getStatus(actv.status.type),
        description: actv.status.description
      });
    });

    this.shipmentStatus.description = this.shipmentActivity[0].description;
    if(pkg.deliveryDate && pkg.deliveryDate.length > 0){
      const deliveryDate = pkg.deliveryDate[0];
      const endTime = pkg.deliveryTime.endTime.substring(0, 2) + ':' + pkg.deliveryTime.endTime.substring(2, 4);
   
      if (deliveryDate.type == 'DEL') {
        this.shipmentStatus.status = 'DEL';
        this.shipmentStatus.label1 = 'Delivered On';
        this.shipmentStatus.label2 = 'Delivery Time';
        this.shipmentStatus.time = "at " + this.tConvert(endTime);
        this.shipmentStatus.date = deliveryDate.date.substring(4, 6) + '/' + deliveryDate.date.substring(6, 8) + '/' + deliveryDate.date.substring(0, 4);
      } else if (deliveryDate.type == 'SDD') {
        this.shipmentStatus.status = 'SDD';
        this.shipmentStatus.label1 = 'Scheduled Delivery';
        this.shipmentStatus.label2 = 'Estimated Time';
        this.shipmentStatus.time = "by " + this.tConvert(endTime);
        this.shipmentStatus.date = deliveryDate.date.substring(4, 6) + '/' + deliveryDate.date.substring(6, 8) + '/' + deliveryDate.date.substring(0, 4);
      }
  
      this.shipmentStatus.day = this.getDay(this.shipmentStatus.date);
    }
   
  }

  getStatus(type) {
    switch (type) {
      case 'D': return 'Delivered';
      case 'I': return 'In Transit';
      case 'M': return 'Billing Information Received';
      case 'MV': return 'Billing Information Voided';
      case 'X': return 'Exception';
      case 'P': return 'Pickup';
      case 'RS': return 'Returned to Shipper';
      case 'DO': return 'Delivered Origin CFS (Freight Only)';
      case 'DD': return 'Delivered Destination CFS (Freight Only)vered';
      case 'W': return 'Warehousing (Freight Only)';
      case 'NA': return 'Not Available';
      case 'O': return 'Out for Delivery';
      default: break;
    }
  }

  getDay(date){
    const day = new Date(date).getDay();
    switch(day){
      case 0: return 'Sunday';
      case 1: return 'Monday'; 
      case 2: return 'Tuesday';
      case 3: return 'Wednesday';
      case 4: return 'Thursday';
      case 5: return 'Friday';
      case 6: return 'Saturday';
    }
  }

   tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' .A.M' : ' .P.M'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  

  close() {
    this.dialog.closeAll();
  }
}
