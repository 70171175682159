<div fxLayout="column" fxLayoutGap="5px" class="padding-15">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    style="font-size: medium"
  >
    <Strong class="primary-text-color">{{ title }}</Strong>
    <span fxFlex> </span>
    <button
      mat-stroked-button
      color="primary"
      type="submit"
      (click)="onSaveAsDraft()"
      *ngIf="!data.isPrinterPlanInvoice"
      [disabled]="isLoading"
    >
      <mat-icon
        svgIcon="save_as_draft"
        style="height: 23px !important"
      ></mat-icon>
      SAVE AS DRAFT
    </button>
    <button
      mat-stroked-button
      color="primary"
      type="submit"
      (click)="onSave()"
      [disabled]="isLoading"
    >
      <mat-icon
        svgIcon="send_invoice"
        style="height: 23px !important"
      ></mat-icon>
      SEND INVOICE
    </button>
  </div>

  <div
    style="max-height: 500px; height: auto; margin-top: 15px;overflow: auto;"
    fxFlexOffset="15px"
  >
    <div fxLayout="column" fxLayoutGap="15px" class="box-style">
      <div fxLayout="row" class="styled-bottom-dotted-border">
        <div fxFlex="50">
          <strong class="primary-text-color">Order Items</strong>
        </div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">Amount</strong>
        </div>
      </div>

      <div *ngFor="let item of invoiceItems; let i = index; let l = last">
        <div [ngClass]="{ 'styled-bottom-dotted-border': l == true }">
          <div fxFlex="50" class="ellipsis primary-text-color">
            {{ item.title }}
          </div>
          <div fxFlex></div>
          <div fxFlex="30">
            <input
              class="reference-input"
              type="number"
              min="0"
              oninput="let stringVal = this.value.toString();let splitArr = stringVal.split('.');if(splitArr.length > 1){if(splitArr[1].length > 2 ){this.value = parseFloat(this.value).toFixed(2);}}"
              [(ngModel)]="item.amount"
              (ngModelChange)="calculateAmounts()"
              [disabled]="isViewOnlyMode"
            />
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <strong class="primary-text-color">Subtotal</strong>
        </div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">{{
            subTotalAmount | number : "1.2-2"
          }}</strong>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50" class="primary-text-color">Discount(%)</div>
        <div fxFlex></div>
        <div fxFlex="30">
          <input
            class="reference-input"
            type="number"
            min="0"
            max="100"
            oninput="if(this.value > 100){this.value = 100;return;}let discountStringVal = this.value.toString();let discountSplitArr = discountStringVal.split('.');if(discountSplitArr.length > 1){if(discountSplitArr[1].length > 2 ){this.value = parseFloat(this.value).toFixed(2);}}"
            [(ngModel)]="discountPercentage"
            (ngModelChange)="calculateAmounts()"
            [disabled]="isViewOnlyMode"
          />
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <strong class="primary-text-color"
            >Sales Tax({{ salesTaxPercentage }}%)</strong
          >
        </div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">{{
            salesTaxAmount | number : "1.2-2"
          }}</strong>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50" class="primary-text-color">Postage</div>
        <div fxFlex></div>
        <div fxFlex="30">
          <input
            class="reference-input"
            type="number"
            min="0"
            oninput="let postageStringVal = this.value.toString();let postageSplitArr = postageStringVal.split('.');if(postageSplitArr.length > 1){if(postageSplitArr[1].length > 2 ){this.value = parseFloat(this.value).toFixed(2);}}"
            [(ngModel)]="postage"
            (ngModelChange)="calculateAmounts()"
            [disabled]="isViewOnlyMode"
          />
        </div>
      </div>

      <div fxLayout="row" class="styled-bottom-dotted-border">
        <div fxFlex="50" class="primary-text-color">Shipping</div>
        <div fxFlex></div>
        <div fxFlex="30">
          <input
            class="reference-input"
            type="number"
            min="0"
            oninput="let shippingStringVal = this.value.toString();let shippingSplitArr = shippingStringVal.split('.');if(shippingSplitArr.length > 1){if(shippingSplitArr[1].length > 2 ){this.value = parseFloat(this.value).toFixed(2);}}"
            [(ngModel)]="shipping"
            (ngModelChange)="calculateAmounts()"
            [disabled]="isViewOnlyMode"
          />
        </div>
      </div>

      <div fxLayout="row" class="styled-bottom-dotted-border">
        <div fxFlex="50">
          <strong class="primary-text-color">Processing Fee</strong>
        </div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">{{
            processingFee | number : "1.2-2"
          }}</strong>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50"><strong class="primary-text-color">Total</strong></div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">{{
            total | number : "1.2-2"
          }}</strong>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50"><strong class="primary-text-color">Paid</strong></div>
        <div fxFlex></div>
        <div fxFlex="30">
          <input
            class="reference-input"
            type="number"
            min="0"
            oninput="let paidStringVal = this.value.toString();let paidSplitArr = paidStringVal.split('.');if(paidSplitArr.length > 1){if(paidSplitArr[1].length > 2 ){this.value = parseFloat(this.value).toFixed(2);}}"
            [(ngModel)]="paid"
            (ngModelChange)="calculateAmounts()"
            [disabled]="isViewOnlyMode"
          />
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="50">
          <strong class="primary-text-color">Balance</strong>
        </div>
        <div fxFlex></div>
        <div fxFlex="30">
          <strong class="primary-text-color">{{
            balance | number : "1.2-2"
          }}</strong>
        </div>
      </div>

      <div fxLayout="row" *ngIf="this.isUpdating">
        <button
          mat-raised-button
          color="primary"
          (click)="onClickMarkAsPaid()"
        >
          Mark As Paid
        </button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        class="box-style"
        *ngIf="
          (!data.invoice.isInDraft &&
            !data.isPrinterPlanInvoice &&
            this.isUpdating) ||
          (data.isPrinterPlanInvoice && this.isUpdating)
        "
      >
        <div fxFlex="100" fxLayout="column" fxLayoutGap="12px">
          <div
            class="input-like"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="
              (!data.invoice.isInDraft &&
                !data.isPrinterPlanInvoice &&
                this.isUpdating) ||
              (data.isPrinterPlanInvoice &&
                this.isUpdating &&
                data.invoice.isPrinterPlanInvoiceSharedWithCustomer &&
                !data.invoice.isInDraft)
            "
          >
            <div fxFlex="90" fxLayout="row" fxLayoutAlign="start center">
              <div class="link primary-text-color">
                {{ customerPortalOrderLink }}
              </div>
            </div>
            <div fxFlex></div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlex="45px"
              class="link-share"
              (click)="onClickshareCustomerPortalLinkWithCustomer()"
            >
              <mat-icon class="mat-icon" svgIcon="link_share"></mat-icon>
            </div>
          </div>

          <div
            *ngIf="isStripeGuestPaymentLinkAvailable"
            style="margin-bottom: 0px"
          >
            <strong class="primary-text">Guest Link</strong>
          </div>

          <div
            class="input-like"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="isStripeGuestPaymentLinkAvailable"
            style="margin-top: 0px"
          >
            <div fxFlex="90" fxLayout="row" fxLayoutAlign="start center">
              <div
                class="link"
                [ngClass]="{
                  'link-expired': isStripeGuestPaymentLinkActive == false
                }"
              >
                {{ stripeGuestPaymentLink }}
              </div>
            </div>
            <div fxFlex></div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlex="45px"
              class="link-share"
              (click)="copyToClipBoard(stripeGuestPaymentLink)"
            >
              <mat-icon>content_copy</mat-icon>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutGap="10px"
            *ngIf="data.isPrinterPlanInvoice"
          >
            <button
              mat-raised-button
              (click)="onClickGenerateGuestLink()"
              color="primary"
            >
              {{ stripeLinkGenerationButtonText }}
            </button>
            <button
              mat-stroked-button
              class="deactivate-Link"
              *ngIf="
                isStripeGuestPaymentLinkAvailable &&
                isStripeGuestPaymentLinkActive
              "
              (click)="onDeactiveLink()"
            >
              Deactivate Link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxFlexOffset="15px"
    fxLayoutAlign="end end"
    fxLayoutGap="3%"
  >
    <span fxFlex></span>
    <button
      mat-raised-button
      type="submit"
      (click)="close()"
      [disabled]="isLoading"
    >
      Close
    </button>
  </div>
</div>
