<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <strong class="header-text primary-text-color">{{ title }}</strong>

  <form [formGroup]="form" fxLayout="column" fxLayoutGap="6px">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        autofocus="true"
        placeholder="Title"
        formControlName="title"
        required
      />
      <mat-error *ngIf="hasError('title', 'required')"
        >You must enter title.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100">
      <mat-label>Department</mat-label>
      <mat-select
        placeholder="Select Department"
        formControlName="departmentId"
        required
      >
        <ng-container *ngFor="let dprmnt of departments$ | async">
          <mat-option
            *ngIf="dprmnt.status && dprmnt.name != 'Administrator'"
            [value]="dprmnt.departmentId"
          >
            {{ dprmnt.name | titlecase }}</mat-option
          >
        </ng-container>
      </mat-select>
      <mat-error *ngIf="hasError('departmentId', 'required')"
        >You must select department.</mat-error
      >
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="icondiv">
      <label fxFlex="30" class="primary-text-color">Icon</label>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="iconvalue">{{ iconvalue }}</mat-icon>
        <p class="primary-text-color" *ngIf="!iconvalue">No icon selected.</p>
      </div>
      <button
        class="mt-0 transparent-background"
        color="primary"
        mat-raised-button
        fxFlexOffset="5px"
        fxFlex="55px"
        (click)="selectIcon()"
      >
        <div fxFlex="row" fxLayout="center center"  class="plus-icon">
          <mat-icon class="addIcon" >add</mat-icon>
        </div>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
      <strong class="primary-text-color">Inactive / Active </strong>
      <mat-slide-toggle
        color="primary"
        formControlName="status"
      ></mat-slide-toggle>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
      <button
        mat-raised-button
        color="primary"
        fxFlex="100px"
        type="submit"
        (click)="onSubmitClick()"
      >
        SUBMIT
      </button>
      <button mat-raised-button type="button" (click)="onCloseClick()">
        CLOSE
      </button>
    </div>
  </form>
</div>
