<div fxLayout="row">
  <app-breadcrumb
    title="Workflow Steps"
    [subTitles]="subTitles"
  ></app-breadcrumb>
  <span fxFlex></span>
  <div fxLayoutAlign="center center">
    <button mat-raised-button color="primary" (click)="onCreateStepClick()">
      <mat-icon class="scaleOneDotFive">add</mat-icon>
      <span>CREATE STEP</span>
    </button>
  </div>
</div>
<div *ngIf="steps?.length === 0" fxLayoutAlign="center">
  <h3 class="primary-text-color">No Steps Available!</h3>
</div>

<div *ngIf="steps?.length > 0">
  <br />

  <div class="table-container" style="height: Calc(100vh - 250px)" fxLayout="column">
    <div style="overflow: auto; height: calc(100vh - 310px);">
      <table mat-table [dataSource]="steps">
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef class="table-header">No.</th>
          <td
            mat-cell
            *matCellDef="let step; let index = index"
            class="table-row"
          >
            {{ pageNo * pageSize + index + 1 }}
          </td>
        </ng-container>
  
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef class="table-header">Title</th>
          <td mat-cell *matCellDef="let step" class="table-row">
            {{ step.stepTitle }}
          </td>
        </ng-container>
  
        <!-- Department Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Department
          </th>
          <td mat-cell *matCellDef="let step" class="table-row">
            {{ step.department.name }}
          </td>
        </ng-container>
  
        <!-- Action Column -->
        <ng-container matColumnDef="stepActions">
          <th mat-header-cell *matHeaderCellDef class="table-header">Actions</th>
          <td mat-cell *matCellDef="let step" class="table-row">
            <ng-container
              *ngFor="let action of step.stepActions; let index = index"
            >
              {{ action.actionName
              }}<span *ngIf="index !== step.stepActions.length - 1">,</span>
            </ng-container>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="addtionalInfo">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Additional Info
          </th>
          <td mat-cell *matCellDef="let step" class="table-row">
            <span *ngIf="step.additionalInfo !== ''">
              {{ step.additionalInfo }}
            </span>
            <span *ngIf="step.additionalInfo === ''"> - </span>
          </td></ng-container
        >
  
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="table-header"></th>
          <td mat-cell *matCellDef="let step" class="table-row">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
  
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onEditClick(step)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="onDeleteClick(step.stepId)">
                <mat-icon>delete</mat-icon>
                <span>Remove</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
   
    <!-- Pagination -->
    <mat-paginator
      [length]="totalRecord"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 100]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
