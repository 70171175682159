import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit {

  public currentToast = null;
  public paymentLink : string = "Click On Generate Link";
  public email : string = "";
  public isLoading : boolean = false;
  public updateEmail : boolean = false;
  public isPaymentLinkGenerated : boolean = false;
  public isPaymentLinkShared : boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {jobId : number,email : string,generatedLink : string},
    private api: ApiService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.email = this.data.email;
  }

  close(){
    this.dialogRef.close(
      {
        jobId : this.data.jobId,
        email : this.email,
        generatedLink : this.paymentLink,
        isEmailUpdated : this.updateEmail,
        isLinkGenerated : this.isPaymentLinkGenerated,
        isPaymentLinkShared : this.isPaymentLinkShared 
      });
  }

  copyToClipBoard(text : string){
    if(!this.isPaymentLinkGenerated){
      this.openToast("Please generate payment link");
      return;
    }

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openToast("Link Copied!");
  }

  shareLink(){

    if(!this.email){
      this.openToast("Email is required");
      return;
    }

    var emailRegEx = /\S+@\S+\.\S+/;
    var isEmailValid = emailRegEx.test(this.email);

    if(!isEmailValid){
      this.openToast("Email is Invalid");
      return;
    }

    if(!this.isPaymentLinkGenerated){
      this.openToast("Please generate payment link");
      return;
    }

    if(this.isLoading){return;}

    this.isLoading = true;

    var reqObj = {
      data : {
        link : this.paymentLink,
        jobId : this.data.jobId,
        toEmail : this.email.trim(),
        updateEmailInPP : this.updateEmail
      }
    }
    this.api.request("SHARE_PAYMENT_LINK",reqObj).subscribe(res => {
      if(res['success']){
        this.isPaymentLinkShared = true;
        this.openToast(res['message']);
        this.close();
      }else{
        this.isLoading = false;
        this.openToast(res['message']);
      }
    },err => {
      this.isLoading = false;
    });
  }


  openToast(message){
    if(this.currentToast != null){
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500
    });
  }

  generatePaymentLink(){
    

    if(!this.email){
      this.openToast("Email is required");
      return;
    }

    var emailRegEx = /\S+@\S+\.\S+/;
    var isEmailValid = emailRegEx.test(this.email);

    if(!isEmailValid){
      this.openToast("Email is Invalid");
      return;
    }

    if(this.isLoading){return;}

    this.isLoading = true;

    var reqObj = {
      data : {
        type : 1,
        jobId : this.data.jobId,
        email : this.email.trim()
      }
    }

    this.paymentLink = "Generating Link....";
    this.isPaymentLinkGenerated = false;
    this.api.request("GET_PAYMENT_LINK",reqObj).subscribe(res => {
      if(res.success){
        this.paymentLink = res.result;
        this.isPaymentLinkGenerated = true;
      }
      else{
        this.paymentLink = "Click On Generate Link"
        this.openToast(res.message);
      }
      this.isLoading = false;
    },err => {
      this.isLoading = false;
      this.paymentLink = "Click On Generate Link"
    });
  }
}
