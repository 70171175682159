<div
  class="padding-15"
  fxLayout="column"
  fxLayoutGap="15px"
  style="width: 100%; height: 100%"
>
  <div
    fxFlex="10"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
  >
    <span><strong class="header-text primary-text-color">Icons</strong></span>
    <span fxFlex></span>
    <div class="search" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon fxFlex="20px">search</mat-icon>
      <input
        fxFlex
        #searchInput
        matInput
        placeholder="Search"
        type="text"
        (input)="onFilter($event.target.value)"
        [(ngModel)]="searchKey"
        class="primary-text-color"
      />
      <div>
        <mat-icon class="pointer" *ngIf="searchKey" (click)="clearFilter()"
          >close</mat-icon
        >
      </div>
    </div>
  </div>
  <div fxFlex="80">
    <div style="overflow:auto">
      <div fxLayout="row wrap">
        <div
          style="padding: 20px"
          *ngFor="let icon of matIcons"
          (click)="select(icon)"
          class="icon"
        >
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon>{{ icon }}</mat-icon>
            <small class="text-ellipsis primary-text-color">{{ icon }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      CLOSE
    </button>
  </div>
</div>
