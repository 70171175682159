import { Component, Inject, OnInit, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { debounceTime, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ShipmentPackage } from 'src/app/shared/interfaces/shipment-package';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ReloadeJobs } from '../../jobs/state/job.actions';
import { RateUpsService } from '../services/rate-ups.service';
import { ShipmentUpsService } from '../services/shipment-ups.service';
import { UPSService } from '../services/ups.service';
import { ShipmentSelectAddressComponent } from '../shipment-select-address/shipment-select-address.component';
import { ShipmentTrackingComponent } from '../shipment-tracking/shipment-tracking.component';
import { ShipmentPackageCreateComponent } from './shipment-package-create/shipment-package-create.component';

@Component({
  selector: 'app-shipment-create',
  templateUrl: './shipment-create.component.html',
  styleUrls: ['./shipment-create.component.scss']
})
export class ShipmentCreateComponent implements OnInit {

  form: UntypedFormGroup;
  subTitles = [{ text: 'Home', route: '' }, { text: 'shipping', route: '' }];
  title = 'Shipping';
  shipmentPackages = [];
  ratedPackage = {
    baseServiceCharge: 0.00,
    billingWeight: 0.00,
    serviceOptionsCharges: 0.00,
    transportationCharges: 0.00,
    totalCharges: 0.00,
  }

  isShowCreate = true;
  isLoad = false;

  UpsServices = [];
  packageTypes = [];

  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  jobItems = [];
  shipments = [];
  errors = [];
  job;
  loading = false;
  isShipmentPackageValid = true;
  isCalculateAction = true;
  shipmentForJob = false;

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rateService: RateUpsService,
    private shipmentService: ShipmentUpsService,
    private api: ApiService,
    private formBuilder: UntypedFormBuilder,
    private toast: ToastService,
    private store: Store,
    private upsService: UPSService,
    private dialogRef: MatDialogRef<ShipmentCreateComponent>,
    ) {
  }

  ngOnInit() {
    this.UpsServices = this.upsService.UpsServices;
    this.packageTypes = this.upsService.packageTypes;
    this.shipmentForJob = this.data.shipmentForJob;
    if (this.shipmentForJob) {
      this.loadJob();
    } else {
      this.isLoad = true;
      this.init();
    }

  }

  init() {
    if (this.shipmentForJob) {
      this.getShipments();
    }
    this.shipmentPackages = [];
    this.form = this.initForm(this.formBuilder);
    this.jobItems = this.job ? this.job.items.filter(item => item.departmentName.trim().toLowerCase() === 'shipping' && item.status === 1) : this.jobItems;
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      fromName: ['Pacific Printing'],
      fromAttention: ['Andrew Goett'],
      fromAddress: ['1445 Monterey Highway'],
      fromCity: ['San Jose'],
      fromStateProvinceCode: ['CA'],
      fromPostalCode: ['95110'],
      fromCountryCode: ['US'],
      fromPhone: ['(408) 293-8083'],
      toCustomer: [this.job ? this.job.customerName : '',Validators.maxLength(35)],
      toAttention: [this.job ? this.job.shippingAttention : ''],
      toCompany: [this.job ? this.job.shippingCompany : ''],
      toAddress: [this.job ? this.returnAddress(this.job.shippingAddressLine1,this.job.shippingAddressLine2) : ''],
      toCity: [this.job ? this.job.shippingCity : ''],
      toStateProvinceCode: [this.job ? this.job.shippingState : ''],
      toPostalCode: [this.job ? this.job.shippingZipCode : ''],
      toCountryCode: ['US'],
      toEmail: [this.job ? this.job.shippingEmail : ''],
      upsService: ['03'],
      packageType: ['02'],
      jobNo: [this.job ? this.job.jobNumber : null]
    })

    returnAddress(addressLine1,addressLine2){
      if(!addressLine1 && !addressLine2){return};
      if(addressLine1 && addressLine2){
        return addressLine1 +' '+ addressLine2
      }
     return addressLine1 ? addressLine1 : addressLine2 ? addressLine2 : ''
    }

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false


  loadJob() {
    const params = {
      params: {
        JOB_ID: this.data.jobId
      }
    };

    this.api.request('GET_JOB', params).subscribe((job) => {
      this.job = job;
      
      var jobItemsWhichAreInShippingAndInProgress = this.job.items.filter(i => i.departmentName.toLowerCase() == "shipping" && i.status == 1);
      if(jobItemsWhichAreInShippingAndInProgress.length == 0){
        this.isShowCreate = false;
        this.shipmentForJob = true;
        this.init();
        return;
      }

      if(this.job.status == 2){
        this.isShowCreate = false;
      }
      this.init();
    });
  }

  processRatedShipment(ratedShipment, index) {
    this.shipmentPackages[index]['serviceOptionsCharges'] = ratedShipment.ServiceOptionsCharges.MonetaryValue;
    this.shipmentPackages[index]['billingWeight'] = ratedShipment.BillingWeight.Weight;
    this.shipmentPackages[index]['totalCharges'] = ratedShipment.TotalCharges.MonetaryValue;
    this.shipmentPackages[index]['transportationCharges'] = ratedShipment.TransportationCharges.MonetaryValue;
  }

  create() {

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.errors = [];
    let shipmentReq = this.shipmentService.request();

    //Shipper Address
    shipmentReq.ShipmentRequest.Shipment.Description = this.job ? this.job.title : this.form.controls.fromName.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Name = this.form.controls.fromName.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.AttentionName = this.form.controls.fromAttention.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Address.AddressLine = this.form.controls.fromAddress.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Address.City = this.form.controls.fromCity.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Address.PostalCode = this.form.controls.fromPostalCode.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Address.StateProvinceCode = this.form.controls.fromStateProvinceCode.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Address.CountryCode = this.form.controls.fromCountryCode.value;
    shipmentReq.ShipmentRequest.Shipment.Shipper.Phone.Number = this.form.controls.fromPhone.value;

    //From address
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Address.AddressLine = this.form.controls.fromAddress.value;
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Address.City = this.form.controls.fromCity.value;
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Address.PostalCode = this.form.controls.fromPostalCode.value;
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Address.StateProvinceCode = this.form.controls.fromStateProvinceCode.value;
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Address.CountryCode = this.form.controls.fromCountryCode.value;
    shipmentReq.ShipmentRequest.Shipment.ShipFrom.Phone.Number = this.form.controls.fromPhone.value;

    //To address
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Name = this.form.controls.toCustomer.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Phone = null;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.AttentionName = this.form.controls.toAttention.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.AddressLine = this.form.controls.toAddress.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.AddressLine = this.form.controls.toAddress.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.City = this.form.controls.toCity.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.PostalCode = this.form.controls.toPostalCode.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.StateProvinceCode = this.form.controls.toStateProvinceCode.value;
    shipmentReq.ShipmentRequest.Shipment.ShipTo.Address.CountryCode = this.form.controls.toCountryCode.value;;

    //Shipper Detail
    shipmentReq.ShipmentRequest.Shipment.Shipper.ShipperNumber = this.upsService.shipperNumber;
    shipmentReq.ShipmentRequest.Shipment.PaymentInformation.ShipmentCharge.BillShipper.AccountNumber = this.upsService.shipperNumber;

    //packages details
    this.shipmentPackages.forEach(pkg => {
      const packageType = this.packageTypes.find(pt => pt.code == this.form.controls.packageType.value);
      let shipPackage = this.shipmentService.package();
      shipPackage.PackageWeight.Weight = pkg.weight;
      shipPackage.Packaging.Code = packageType.code;
      shipPackage.Description = pkg.description;
      shipmentReq.ShipmentRequest.Shipment.Package.push(shipPackage);
    });

    const upsService = this.UpsServices.find(us => us.code == this.form.controls.upsService.value)
    shipmentReq.ShipmentRequest.Shipment.Service.Code = upsService.code;
    shipmentReq.ShipmentRequest.Shipment.Service.Description = upsService.description;

    let data = {};
    data['jobId'] = parseInt(this.form.controls.jobNo.value);
    data['shipment'] = shipmentReq;
    data['packages'] = this.shipmentPackages;
    data['toAddress'] = {
      name: '',
      customer: this.form.controls.toCustomer.value,
      attention: this.form.controls.toAttention.value,
      address: this.form.controls.toAddress.value,
      stateProvinceCode: this.form.controls.toStateProvinceCode.value,
      city: this.form.controls.toCity.value,
      postalCode: this.form.controls.toPostalCode.value,
      countryCode: this.form.controls.toCountryCode.value,
      email: this.form.controls.toEmail.value,
      phone: null
    };
    data['fromAddress'] = {
      name: this.form.controls.fromName.value,
      customer: '',
      attention: this.form.controls.fromAttention.value,
      address: this.form.controls.fromAddress.value,
      stateProvinceCode: this.form.controls.fromStateProvinceCode.value,
      city: this.form.controls.fromCity.value,
      postalCode: this.form.controls.fromPostalCode.value,
      countryCode: this.form.controls.fromCountryCode.value,
      email: '',
      phone: this.form.controls.fromPhone.value
    };

    this.api.request(
      'CREATE_SHIPMENT',
      {
        data: data
      }
    ).subscribe(res => {
      if (res.response && res.response.errors && res.response.errors.length > 0) {
        res.response.errors.forEach(e => {
          this.errors.push(e.message);
        });
      }
      else {
        if (this.shipmentForJob) {
          this.isShowCreate = false;
          this.init();
          this.form.markAsUntouched({ onlySelf: true });
          this.form.markAsDirty({ onlySelf: false });
          this.store.dispatch(new ReloadeJobs());
        } else {
          this.dialogRef.close(true);
        }

        var shipmentPackages = res.ShipmentResponse.ShipmentResults.PackageResults;
        var shipmentTrackingNumbers : string[] = [];
        var isArray = Array.isArray(shipmentPackages);
        if(isArray){
          shipmentPackages.forEach(_shipmentPackage => {
            shipmentTrackingNumbers.push(_shipmentPackage.TrackingNumber);
          });
        }else{
          shipmentTrackingNumbers.push(shipmentPackages.TrackingNumber);
        }
        this.upsService.downloadLabelFromServerAndShow(shipmentTrackingNumbers);
      
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getShipments() {
    this.shipments = [];
    this.api
      .request('GET_SHIPMENTS', {
        params: {
          PAGE_NO: 0,
          PAGE_SIZE: 0,
          JOB_ID: this.job.jobNumber,
        }
      })
      .subscribe(res => {
        this.isLoad = true;
        if (res) {
          if (res['result'] && res['result'].length > 0) {
            this.shipments = res['result'];
            this.shipments.forEach(shipment => {
              shipment['show'] = false;
              const upsService = this.upsService.UpsServices.find(us => us.code == shipment.upsService);
              const packageType = this.upsService.packageTypes.find(us => us.code == shipment.packageType);
              shipment.upsService = upsService.description;
              shipment.packageType = packageType.description;
            });
            this.isShowCreate = false;
          }
        }
      });
  }

  close() {
    this.dialog.closeAll();
  }

  tracking($event, shipment) {
    this.dialog.closeAll();
    $event.stopPropagation();
    this.dialog.open(ShipmentTrackingComponent, {
      width: '75vw',
      maxWidth: '75vw',
      maxHeight: 'auto',
      data: {
        identificationNo: shipment.identificationNo,
        shipmentId: shipment.id
      }
    });
  }

  delete($event, identificationNo) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '170px',
      width: '470px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to delete shipment?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api
          .request(
            'DELETE_SHIPMENT',
            {
              params:
                { ID: identificationNo }
            }
          ).subscribe(res => {
            if (res.response && res.response.errors && res.response.errors.length > 0) {
              this.toast.show(res.response.errors[0].message);
            } else {
              this.init();
            }
          });
      }
    })
  }

  downloadLabels($event,shipment){
    $event.stopPropagation();
    var shipmentPackages : ShipmentPackage[] = shipment.packages;
    var shipmentTrackingNumbers : string[] = [];
    shipmentPackages.forEach((_shipmentPackage : ShipmentPackage) => {
      shipmentTrackingNumbers.push(_shipmentPackage.trackingNumber);
    });
    this.upsService.downloadLabelFromServerAndShow(shipmentTrackingNumbers);
  }

  downloadLabel($event, shipmentIdentificationNo : string) {
    var shipmentTrackingNumbers : string[] = [];
    shipmentTrackingNumbers.push(shipmentIdentificationNo);
    this.upsService.downloadLabelFromServerAndShow(shipmentTrackingNumbers);
  }

  deletePackage(index) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '170px',
      width: '470px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to delete shipment package?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.shipmentPackages.splice(index, 1);
        //this.resetPackageRatedInfo();
        //this.calculate();
      }
    })
  }

  addPackage(index = null, shipmentPackage = null) {

    const dialogRef = this.dialog.open(ShipmentPackageCreateComponent, {
      height: 'auto',
      width: '900px',
      data: {
        jobItems: this.jobItems,
        package: shipmentPackage,
        packages: this.shipmentPackages,
        shipmentForJob: this.shipmentForJob
      }
    });

    dialogRef.afterClosed().subscribe(pkg => {
      if (pkg) {
        if (shipmentPackage) {
          this.shipmentPackages[index] = pkg;
        } else {
          this.shipmentPackages.push(pkg);
        }
        //this.calculate();
      }
    });
  }

  selectAddress() {
    const dialogRef = this.dialog.open(ShipmentSelectAddressComponent, {
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: 'auto',
    });
    dialogRef.afterClosed().subscribe(address => {
      if (address) {
        if (address['company'] === null || address['company'] === '') { address['company'] = address['customer']; }
        this.form.controls.toCustomer.setValue(address['customer']);
        this.form.controls.toAttention.setValue(address['attention']);
        this.form.controls.toCompany.setValue(address['company']);
        this.form.controls.toAddress.setValue(address['address']);
        this.form.controls.toCity.setValue(address['city']);
        this.form.controls.toStateProvinceCode.setValue(address['stateProvinceCode']);
        this.form.controls.toPostalCode.setValue(address['postalCode']);
        //this.form.controls.toCountryCode.setValue(address['countryCode']);
        this.form.controls.toEmail.setValue(address['email']);
        this.form.controls.toPhone.setValue(address['phone']);
      }
    })
  }

  backToShipments(){
    this.isShowCreate = !this.isShowCreate
  }

}
