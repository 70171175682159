import { environment } from "src/environments/environment";

const userEndPoint = [
  {
    name: "USER_LOGIN",
    method: "POST",
    url: "/api/account/login",
  },
  {
    name: "SSO_LOGIN",
    method: "POST",
    url: "/api/account/sso/login",
  },
  {
    name: "GET_USERS",
    method: "GET",
    url: "/api/users?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&searchedName=[SEARCHED_NAME]&excludeAdmins=[EXCLUDE_ADMINS]&excludeCsrs=[EXCLUDE_CSRS]&excludeDeptUsers=[EXCLUDE_DEPT_UDERS]",
  },
  {
    name: "CREATE_USER",
    method: "POST",
    url: "/api/users",
  },
  {
    name: "EDIT_USER",
    method: "PUT",
    url: "/api/users/[USER_ID]",
  },
  {
    name: "DELETE_USER",
    method: "DELETE",
    url: "/api/users/[USER_ID]",
  },
  {
    name: "CHANGE_PASSWORD",
    method: "PUT",
    url: "/api/account/[USER_ID]/password",
  },
  {
    name: "UPDATE_PROFILE",
    method: "PUT",
    url: "/api/users/[USER_ID]/profile",
  },
  {
    name: "SET_PROFILE_PIC",
    method: "POST",
    url: "/api/users/[USER_ID]/picture",
  },
  {
    name: "SET_USER_DATASOURCE",
    method: "PUT",
    url: "/api/users/datasource",
  },
  {
    name: "SET_USER_FILTERS",
    method: "PUT",
    url: "/api/users/filters",
  },
  {
    name: "GET_USER",
    method: "GET",
    url: "/api/users/[USER_ID]",
  },
];

const customerEndPoint = [
  {
    name: "GET_CUSTOMERS",
    method: "POST",
    url: "/api/customers",
  },
  {
    name: "CREATE_CUSTOMER",
    method: "POST",
    url: "/api/customers/create",
  },
  {
    name: "EDIT_CUSTOMER",
    method: "PUT",
    url: "/api/customers/[CUSTOMER_ID]",
  },
  {
    name: "DELETE_CUSTOMER",
    method: "DELETE",
    url: "/api/customers/[CUSTOMER_ID]",
  },
  {
    name: "UPADTE_CUSTOMER_STATUS",
    method: "PUT",
    url: "/api/customers/[CUSTOMER_ID]/status",
  },
  {
    name: "RESET_PASSWORD",
    method: "PUT",
    url: "/api/customers/[EMAIL]/reset-password",
  },
  {
    name: "GET_CUSTOMERNAMES_FROM_PP_CP",
    method: "GET",
    url: "/api/customers/customer-names?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&searchName=[SEARCH_NAME]&fromCP=[FROM_CP]&fromPP=[FROM_PP]",
  },
  {
    name: "GET_PPCONTACTS_FROM_PP",
    method: "POST",
    url: "/api/customers/get-pp-contacts",
  },
  {
    name: "SYNC_PP_CONTACT",
    method: "POST",
    url: "/api/customers/sync-with-pp-contact",
  },
];

const departmentEndPoint = [
  {
    name: "GET_DEPARTMENTS",
    method: "GET",
    url: "/api/departments?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]",
  },
  {
    name: "CREATE_DEPARTMENT",
    method: "POST",
    url: "/api/departments",
  },
  {
    name: "EDIT_DEPARTMENT",
    method: "PUT",
    url: "/api/departments/[DEPARTMENT_ID]",
  },
  {
    name: "DELETE_DEPARTMENT",
    method: "DELETE",
    url: "/api/departments/[DEPARTMENT_ID]",
  },
];

const stepsEndPoint = [
  {
    name: "GET_STEPS",
    method: "GET",
    url: "/api/steps?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]",
  },
  {
    name: "CREATE_STEP",
    method: "POST",
    url: "/api/steps",
  },
  {
    name: "EDIT_STEP",
    method: "PUT",
    url: "/api/steps/[STEP_ID]",
  },
  {
    name: "DELETE_STEP",
    method: "DELETE",
    url: "/api/steps/[STEP_ID]",
  },
];

const dataSourcesEndPoint = [
  {
    name: "GET_DATASOURCES",
    method: "GET",
    url: "/api/datasources?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]",
  },
  {
    name: "CREATE_DATASOURCE",
    method: "POST",
    url: "/api/datasources",
  },
  {
    name: "EDIT_DATASOURCE",
    method: "PUT",
    url: "/api/datasources/[DATASOURCE_ID]",
  },
  {
    name: "DELETE_DATASOURCE",
    method: "DELETE",
    url: "/api/datasources/[DATASOURCE_ID]",
  },
  {
    name: "SEARCH_DATA_SOURCES",
    method: "GET",
    url: "/api/datasources/search?q=[q]",
  },
];

const roleEndPoint = [
  {
    name: "GET_ROLES",
    method: "GET",
    url: "/api/roles",
  },
];

const notificationEndPoint = [
  {
    name: "GET_NOTIFICATIONS",
    method: "GET",
    url: "/api/notifications?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]",
  },
  {
    name: "READ_NOTIFICATION",
    method: "put",
    url: "/api/notifications/[NOTIFICATION_ID]",
  },
  {
    name: "READ_ALL_NOTIFICATIONS",
    method: "put",
    url: "/api/notifications",
  },
];

const JobsEndPoint = [
  {
    name: "GET_JOBS",
    method: "POST",
    url: "/api/jobs",
  },
  {
    name: "GET_RETRIVE_JOBS",
    method: "POST",
    url: "/api/jobs/getretrivejobs",
  },
  {
    name: "RETRIVE_JOBS",
    method: "POST",
    url: "/api/jobs/retrivejobs",
  },
  {
    name: "GET_JOB",
    method: "GET",
    url: "/api/jobs/[JOB_ID]",
  },
  {
    name: "GET_JOB_ITEMS",
    method: "GET",
    url: "/api/jobs/[JOB_ID]/items/[DEPARTMENT_ID]",
  },
  {
    name: "IMPORT_JOB",
    method: "GET",
    url: "/api/jobs/import/[JOBNUMBER]",
  },
  {
    name: "SEARCH_JOBS",
    method: "GET",
    url: "/api/jobs/search?q=[q]",
  },
  {
    name: "CREATE_JOB",
    method: "POST",
    url: "/api/jobs/create",
  },
  {
    name: "UPDATE_JOBITEM",
    method: "PUT",
    url: "/api/jobs/update-item",
  },
  {
    name: "UPDATE_JOB_From_PP",
    method: "PUT",
    url: "/api/jobs/update-from-pp",
  },
  {
    name: "GET_LOGS",
    method: "GET",
    url: "/api/jobs/log/[JOB_ITEM_ID]",
  },
  {
    name: "COMPLETE_CURRENT_STEP",
    method: "PUT",
    url: "/api/jobs/complete-step",
  },
  {
    name: "CHANGE_CURRENT_STEP",
    method: "PUT",
    url: "/api/jobs/change-step",
  },
  {
    name: "CREATE_JOB_ITEM_NOTE",
    method: "POST",
    url: "/api/notes",
  },
  {
    name: "DELETE_NOTE",
    method: "DELETE",
    url: "/api/notes/[NOTE_ID]",
  },
  {
    name: "START_LOG",
    method: "POST",
    url: "/api/jobs/start-log",
  },
  {
    name: "STOP_LOG",
    method: "POST",
    url: "/api/jobs/stop-log",
  },
  {
    name: "GET_FILTER_LOOKUP_LIST",
    method: "GET",
    url: "/api/jobs/lookup-list",
  },
  {
    name: "MARK_AS_COMPLETE",
    method: "PUT",
    url: "/api/jobs/jobitem-complete",
  },
  {
    name: "UPDATE_RUSH_DATE",
    method: "PUT",
    url: "/api/jobs/rush",
  },
  {
    name: "ARCHIVE_JOB",
    method: "PUT",
    url: "/api/jobs/[JOB_ID]/archive",
  },
  {
    name: "COMPLETE_JOB",
    method: "PUT",
    url: "/api/jobs/complete",
  },
  {
    name: "DELETE_JOB",
    method: "DELETE",
    url: "/api/jobs/[JOB_ID]",
  },
  {
    name: "GET_JOBNAMES",
    method: "GET",
    url: "/api/jobs/job-names?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&searchValue=[SEARCHVALUE]",
  },
  {
    name: "GET_CSR_NAMES",
    method: "GET",
    url: "/api/jobs/csr-names?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&searchValue=[SEARCHVALUE]",
  },
  {
    name: "SET_SHARED_AT_LAST_WITH_CUSTOMER",
    method: "PUT",
    url: "/api/jobs/shared-at-last-with-customer",
  },
  {
    name: "SHARE_PAYMENT_LINK",
    method: "POST",
    url: "/api/jobs/share-link",
  },
  {
    name: "GET_CUSTOMER_NAMES_OF_JOBS",
    method: "GET",
    url: "/api/jobs/customer-names-for-template-label?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&searchValue=[SEARCH_NAME]",
  },
  {
    name: "GET_JOB_NOS_BY_CUSTOMER_NAME",
    method: "GET",
    url: "/api/jobs/job-nos-by-customer-name?customerName=[CUSTOMER_NAME]",
  },
  {
    name: "GET_JOB_ITEM_NAMES_BY_JOB_ID",
    method: "GET",
    url: "/api/jobs/job-items-names-by-job-id?jobId=[JOB_ID]",
  },
  {
    name: "GET_JOB_NUMBERS",
    method: "POST",
    url: "/api/jobs/job-nos",
  },
  {
    name: "GET_READY_FOR_PICKUP_NOTIFICATION_STATUS",
    method: "GET",
    url: "/api/jobs/jobitem/[JOB_ITEM_ID]/rfp/status",
  },
  {
    name: "UPDATE_SEND_NOTIFICATION_FOR_READY_FOR_PICKUP",
    method: "POST",
    url: "/api/jobs/change-send-notification-for-rfp",
  },
  {
    name: "GET_JOB_DETAILS_V2",
    method: "GET",
    url: "/api/jobs/v2/details/[JOB_ID]",
  },
  {
    name: "GET_JOB_ITEM_DETAILS_V2",
    method: "GET",
    url: "/api/jobs/v2/item/details/[ITEM_ID]?departmentId=[DEPARTMENT_ID]",
  },
  {
    name: "GET_JOB_ITEM_NOTES_V2",
    method: "GET",
    url: "/api/jobs/v2/item/notes/[ITEM_ID]",
  },
  {
    name: "GET_JOB_ITEM_DOCUMENTS_V2",
    method: "GET",
    url: "/api/jobs/v2/item/documents/[ITEM_ID]",
  },
  {
    name: "GET_PRINTER_PLAN_JOB_ITEM_CHAT_V2",
    method: "GET",
    url: "/api/jobs/v2/item/printer-plan/chat/[ITEM_ID]",
  },
  {
    name: "GET_CUSTOMER_PORTAL_JOB_ITEM_CHAT_V2",
    method: "GET",
    url: "/api/jobs/v2/item/customer-portal/chat/[ITEM_ID]",
  },
  {
    name: "GET_RYOBI_JOBS_PDF_DETAIL",
    method: "POST",
    url: "/api/jobs/details/ryobi-job",
  },
  {
    name: "GET_COMPARE_JOB_DETAIL_WITH_PP",
    method: "Get",
    url: "/api/jobs/should-update-job/[JOB_ID]",
  },
];

const documentsEndPoint = [
  {
    name: "DOWNLOAD_DOCUMENT",
    method: "get",
    url: "/api/documents/[DOCUMENT_ID]/download",
  },
  {
    name: "CHECK_PREVIEW_AVAILABEL",
    method: "GET",
    url: "/api/documents/[DOCUMENT_ID]/preview",
  },
  {
    name: "CREATE_JOB_ITEM_DOCUMENT",
    method: "POST",
    url: "/api/documents",
  },
  {
    name: "DELETE_JOB_ITEM_DOCUMENT",
    method: "DELETE",
    url: "/api/documents/[DOCUMENT_ID]",
  },
  {
    name: "LABEL_DOWNLOAD_FROM_SERVER",
    method: "GET",
    url: "/api/documents/shipmentlabel/[IDENTIFICATION_NUMBER]",
  },
  {
    name: "CREATE_ORDER_ITEM_DOCUMENT",
    method: "POST",
    url: "/api/documents/create-order-item-docs",
  },
  {
    name: "GET_DOCUMENTS_BY_JOB_ITME_IDS_FOR_SWITCH",
    method: "POST",
    url: "/api/documents/by-job-ids/switch",
  },
];

const dashboardEndPoint = [
  {
    name: "DASHBOARD",
    method: "POST",
    url: "/api/dashboards",
  },
];

const switchEndPoint = [
  {
    name: "MOVE_FILES_TO_SWITCH",
    method: "POST",
    url: "/api/switch/move-to-switch/files",
  },
];

const invoicedEndPoint = [
  {
    name: "CREATE_INVOICE",
    method: "POST",
    url: "/api/invoice/create",
  },
  {
    name: "UPDATE_INVOICE",
    method: "PUT",
    url: "/api/invoice/[ID]",
  },
  {
    name: "MARK_AS_PAID",
    method: "PUT",
    url: "/api/invoice/mark-as-paid",
  },
];

const templateEndPoint = [
  {
    name: "GET_ALL_TEMPLATES",
    method: "GET",
    url: "/api/templates",
  },
  {
    name: "CREATE_TEMPLATE",
    method: "POST",
    url: "/api/templates",
  },
];

const navigationEndPoint = [
  {
    name: "GET_NAVIGATIONS",
    method: "GET",
    url: "/api/navigations?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]",
  },
  {
    name: "CREATE_NAVIGATION",
    method: "POST",
    url: "/api/navigations",
  },
  {
    name: "EDIT_NAVIGATION",
    method: "PUT",
    url: "/api/navigations/[NAVIGATION_ID]",
  },
  {
    name: "DELETE_NAVIGATION",
    method: "DELETE",
    url: "/api/navigations/[NAVIGATION_ID]",
  },
];

const mailingEndPoint = [
  {
    name: "EDIT_MAILING_SORT",
    method: "PUT",
    url: "/api/mailings/sort/[MAILING_SORT_ID]",
  },

  {
    name: "EDIT_MAILING_INFO",
    method: "PUT",
    url: "/api/mailings/info/[MAILING_INFO_ID]",
  },
];

const ordersEndPoint = [
  {
    name: "GET_ORDERS",
    method: "POST",
    url: "/api/orders",
  },
  {
    name: "GET_ORDER",
    method: "GET",
    url: "/api/orders/[ID]",
  },
  {
    name: "IMPORT_ORDER",
    method: "GET",
    url: "/api/orders/[ID]/import",
  },
  {
    name: "ADD_ORDER_ITEM_NOTE",
    method: "POST",
    url: "/api/orders/note",
  },
  {
    name: "UPDATE_ORDER_ITEM_NOTE",
    method: "PUT",
    url: "/api/orders/note/[ID]",
  },
  {
    name: "DELETE_ORDER_ITEM_NOTE",
    method: "DELETE",
    url: "/api/orders/note/[ID]",
  },
  {
    name: "ADD_ORDER_ITEM_DOCUMENTS",
    method: "POST",
    url: "/api/orders/document",
  },
  {
    name: "ADD_REORDER_ITEM_DOCUMENTS",
    method: "POST",
    url: "/api/orders/reorder-item-documents",
  },
  {
    name: "DELETE_ORDER_ITEM_DOCUMENT",
    method: "DELETE",
    url: "/api/orders/document/[ID]",
  },
  {
    name: "CHANGE_ORDER_ITEM_STATUS",
    method: "PUT",
    url: "/api/orders/status",
  },
  {
    name: "GET_LOOKUP_LIST",
    method: "GET",
    url: "/api/orders/lookup-list",
  },
  {
    name: "CANCEL_ORDER",
    method: "PUT",
    url: "/api/orders/[ID]/cancel",
  },
  {
    name: "SET_REFERENCE_ORDER",
    method: "POST",
    url: "/api/orders/[ID]/reference",
  },
  {
    name: "CREATE-REORDER",
    method: "POST",
    url: "/api/orders/create-reorder-item",
  },
  {
    name: "UPDATE_ISCSRADMIN_VIEWED",
    method: "POST",
    url: "/api/orders/update-iscsradminviewed",
  },
  {
    name: "UPDATE_ORDER_ADDRESSES",
    method: "PUT",
    url: "/api/orders/update-addresses",
  },
  {
    name: "UPDATE_ORDER",
    method: "PUT",
    url: "/api/orders/[ID]",
  },
  {
    name: "CREATE_ORDER_NOTE",
    method: "POST",
    url: "/api/orders/create-order-note",
  },
  {
    name: "ASSIGN_CSR",
    method: "POST",
    url: "/api/orders/assign-csr",
  },
  {
    name: "GET_ORDER_FILTER_LOOK_UP_LIST",
    method: "POST",
    url: "/api/orders/filter-look-up-list",
  },
  {
    name: "SHARE_ORDER_DETAILS_URL",
    method: "POST",
    url: "/api/orders/share-order-view-url",
  },
  {
    name: "ADD_ORDER_ITEM",
    method: "POST",
    url: "/api/orders/order-item/create",
  },
  {
    name: "CANCEL_ORDER_ITEM",
    method: "PUT",
    url: "/api/orders/cancel/order-item/[ID]",
  },
  {
    name: "ORDER_ITEM_UPDATE",
    method: "PUT",
    url: "/api/orders/order-item/[ID]",
  },
  {
    name: "UPDATE_BYPASS_STATUS",
    method: "POST",
    url: "/api/orders/update-bypass-status",
  },
  {
    name: "GET_ORDER_DOCUMENTS",
    method: "GET",
    url: "/api/orders/document/[ID]",
  },
];

const companiesEndPoint = [
  {
    name: "GET_COMPANIES",
    method: "GET",
    url: "/api/companies?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&companyName=[COMPANY_NAME]",
  },
  {
    name: "CREATE_COMPANIES",
    method: "POST",
    url: "/api/companies",
  },
  {
    name: "UPDATE_COMPANY",
    method: "PUT",
    url: "/api/companies/[ID]",
  },
  {
    name: "DELETE_COMPANY",
    method: "DELETE",
    url: "/api/companies/[ID]",
  },
  {
    name: "SYNC_PRINTER_PLAN_CUSTOMER",
    method: "POST",
    url: "/api/companies/sync-printer-plan-customer",
  },
];

const shipmentEndPoint = [
  {
    name: "CREATE_SHIPMENT",
    method: "POST",
    url: "/api/shipments",
  },
  {
    name: "GET_SHIPMENTS",
    method: "GET",
    url: "/api/shipments?pageNo=[PAGE_NO]&pageSize=[PAGE_SIZE]&jobId=[JOB_ID]",
  },
  {
    name: "GET_SHIPMENT",
    method: "GET",
    url: "/api/shipments/[ID]",
  },
  {
    name: "TRACK_SHIPMENT",
    method: "GET",
    url: "/api/shipments/track/[ID]",
  },
  {
    name: "LABEL_RECOVERY",
    method: "POST",
    url: "/api/shipments/label",
  },
  {
    name: "CALCULATE_SHIPMENT",
    method: "POST",
    url: "/api/shipments/calculate",
  },
  {
    name: "DELETE_SHIPMENT",
    method: "DELETE",
    url: "/api/shipments/cancel/[ID]",
  },
  {
    name: "UPLOAD_ADDRESS",
    method: "POST",
    url: "/api/shipments/upload-address",
  },
  {
    name: "GET_ADDRESSES",
    method: "POST",
    url: "/api/shipments/addresses",
  },
];

const addressEndPoint = [
  {
    name: "GET_ADDRESSES_BY_USER",
    method: "GET",
    url: "/api/address/get-by-userId/[USER_ID]",
  },
];

const paymentsEndPoint = [
  {
    name: "GET_PAYMENT_LINK",
    method: "POST",
    url: "/api/payment/payment-link",
  },
  {
    name: "DEACTIVE_PAYMENT_LINK",
    method: "POST",
    url: "/api/payment/deactive-payment-link",
  },
];

const printerPlanEndPoint = [
  {
    name: "GET_PRINTER_PLAN_JOB_DETAILS",
    method: "GET",
    url: "/api/printerplan/jobs/[JOB_NUMBER]",
  },
];

const printerPlanDocumentsEndPoints = [
  {
    name: "ADD_PRINTER_PLAN_DOCUMENTS",
    method: "POST",
    url: "/api/printerplandocument/create",
  },
  {
    name: "DELETE_PRINTER_PLAN_DOCUMENT",
    method: "DELETE",
    url: "/api/printerplandocument/[ID]",
  },
];

const printerPlanNotesEndPoint = [
  {
    name: "ADD_PRINTER_PLAN_NOTE",
    method: "POST",
    url: "/api/printerplannotes/create",
  },
  {
    name: "DELETE_PRINTER_PLAN_NOTE",
    method: "DELETE",
    url: "/api/printerplannotes/[ID]",
  },
  {
    name: "UPDATE_PRINTER_PLAN_NOTE",
    method: "PUT",
    url: "/api/printerplannotes/[ID]",
  },
];

const collectionsEndPoint = [
  {
    name: "GET_STRIPE_COLLECTIONS",
    method: "POST",
    url: "/api/collections/get-stripe-collections",
  },
  {
    name: "GET_OTHER_COLLECTIONS",
    method: "POST",
    url: "/api/collections/get-other-collections",
  },
  {
    name: "CREATE_STRIPE_REFUND",
    method: "POST",
    url: "/api/collections/create-stripe-refund",
  },
  {
    name: "CREATE_OTHER_REFUND",
    method: "POST",
    url: "/api/collections/create-other-refund",
  },
  {
    name: "GET_COLLECTION_LOOK_UP_LIST",
    method: "GET",
    url: "/api/collections/filter-look-up-list",
  },
  {
    name: "GET_STRIPE_PAYMENT_INTENTS",
    method: "GET",
    url: "/api/collections/stripe-payment-intents/[PAYMENT_ID]",
  },
  {
    name: "GET_OTHER_PAYMENT_INTENTS",
    method: "GET",
    url: "/api/collections/other-payment-intents/[EXISTING_PAYMENTHISTORY_ID]",
  },
  {
    name: "GET_COLLECTION_FILTER_LOOK_UP",
    method: "POST",
    url: "/api/collections/filter-look-up",
  },
];

const jobNotesEndPoints = [
  {
    name: "CREATE_JOB_NOTE",
    method: "POST",
    url: "/api/jobnotes/create",
  },
];

export const ApiConfig = {
  baseUrl: environment.baseUrl,
  endpoints: [
    ...userEndPoint,
    ...roleEndPoint,
    ...JobsEndPoint,
    ...departmentEndPoint,
    ...stepsEndPoint,
    ...dataSourcesEndPoint,
    ...customerEndPoint,
    ...dashboardEndPoint,
    ...templateEndPoint,
    ...notificationEndPoint,
    ...navigationEndPoint,
    ...mailingEndPoint,
    ...documentsEndPoint,
    ...ordersEndPoint,
    ...companiesEndPoint,
    ...shipmentEndPoint,
    ...invoicedEndPoint,
    ...addressEndPoint,
    ...paymentsEndPoint,
    ...collectionsEndPoint,
    ...printerPlanEndPoint,
    ...printerPlanDocumentsEndPoints,
    ...printerPlanNotesEndPoint,
    ...jobNotesEndPoints,
    ...switchEndPoint,
  ],
};
