import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { TokenService } from './token.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private router: Router ,private tostService:ToastService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.endsWith('/login'))
      return next.handle(req);
    // const token: string = this.tokenService.get();

    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigate(['/account/login']);
      return;
    }
    let request = req;
    if (token){//'Bearer ' +
      const cloned = req.clone({
        headers: req.headers.set('Authorization','Bearer '+token)
      });
      request = cloned;
    }

    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
         if (event && event['status'] === 204 ) {
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err && err['status'] === 401 && err.url.endsWith('/login') !== true) {
            localStorage.clear();
            this.router.navigate(['/account/login']);
          } else{}
            // do error handling here
           
        }
      }));
  }
}
