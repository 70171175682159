import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { pbkdf2 } from 'crypto';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UPSService } from '../../services/ups.service';

@Component({
  selector: 'app-shipment-package-create',
  templateUrl: './shipment-package-create.component.html',
  styleUrls: ['./shipment-package-create.component.scss']
})
export class ShipmentPackageCreateComponent implements OnInit {

  form: UntypedFormGroup;
  separatorKeysCodes = [ENTER, COMMA];
  itemControl = new UntypedFormControl();
  filteredItems: Observable<any[]>;
  selectedItems = [];
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  packageItemValid = true;
  @ViewChild('itemInput') itemInput: ElementRef;
  UpsServices = [];
  packageTypes = [];
  shipmentForJob = false;

  constructor(
    private dialogRef: MatDialogRef<ShipmentPackageCreateComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private upsService: UPSService) { }

  ngOnInit() {
    this.UpsServices = this.upsService.UpsServices;
    this.form = this.initForm(this.formBuilder);
    this.shipmentForJob = this.data.shipmentForJob;
    if (this.data.package) {
      this.form.patchValue(this.data.package);
      this.selectedItems = this.data.package.items ?  JSON.parse(JSON.stringify(this.data.package.items)) : this.selectedItems;
    } else {
      this.selectedItems = this.data.jobItems ?  JSON.parse(JSON.stringify(this.data.jobItems)):this.selectedItems;
    }
    this.filterItems();
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      description: [''],
      // packageType: [''],
      // length: [''],
      // height: [''],
      // width: [''],
      weight: ['']
    });

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  filterItems() {
    let items = [];
    this.data.jobItems.forEach(item => {
      if (!this.selectedItems.find(itm => itm.itemId == item.itemId)) {
        items.push(item);
      }
    });

    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this.filter(fruit) : items.slice()));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const item = this.selectedItems.find(item => item.description == value);

    // Add our item
    if (item) {
      this.selectedItems.push(item);
      this.filterItems();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.itemControl.setValue(null);
  }

  remove(fruit: any): void {
    const index = this.selectedItems.indexOf(fruit);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.filterItems();
    }
  }

  filter(item: any) {
    let value = '';
    if (typeof item === 'object') {
      value = item.description;
    } else {
      value = item;
    }

    return this.data.jobItems.filter(itm =>
      itm.description.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const item = this.data.jobItems.find(item => item.description == event.option.viewValue);
    if (item && !this.selectedItems.find(item => item.description == event.option.viewValue)) {
      this.selectedItems.push(item);
      this.filterItems();
    }
    this.itemInput.nativeElement.value = '';
    this.itemControl.setValue(null);
  }

  close() {
    this.dialogRef.close();
  }

  create() {
    //check validation
    this.packageItemValid = true;
    if (this.form.invalid || (this.shipmentForJob && this.selectedItems.length == 0)) {
      if (this.shipmentForJob && this.selectedItems.length == 0) {
        this.packageItemValid = false;
      }
      return;
    }

    let shipmentPackage = this.form.value;

    shipmentPackage['items'] = null;
    shipmentPackage['jobItems'] = '';

    if (this.shipmentForJob) {
      shipmentPackage['items'] = this.selectedItems;
      var jobItems = this.selectedItems.map(i => { return i.jobItemId });
      shipmentPackage['jobItems'] = jobItems.toString();
    }

    this.dialogRef.close(shipmentPackage);
  }

}
