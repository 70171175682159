import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

export enum FilterType {
  Customer,
  Company,
  Attention,
  StateProvinceCode,
  City,
  PostalCode
}

@Component({
  selector: 'app-shipment-select-address',
  templateUrl: './shipment-select-address.component.html',
  styleUrls: ['./shipment-select-address.component.scss']
})
export class ShipmentSelectAddressComponent implements OnInit {

  addresses = [];
  total;
  req = {
    customer: '',
    company: '',
    attention: '',
    stateProvinceCode: '',
    city: '',
    postalCode: '',
    pageSize: 50,
    pageNo: 0
  };

  private subject: Subject<any> = new Subject();


  constructor(public api: ApiService,
    public dialogRef: MatDialogRef<ShipmentSelectAddressComponent>) { }

  ngOnInit() {
    this.loadAddress();

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(search => {
      this.applyFilter(search.value, search.type);
    });

  }

  public get filterType(): typeof FilterType {
    return FilterType;
  }

  loadAddress() {
    this.api.request(
      'GET_ADDRESSES',
      { data: this.req }
    ).subscribe(res => {
      if (res.success) {
        this.addresses = res['result'];
        this.total = res['total'];
      }
    }, error => {
    });
  }

  select(address) {
    this.dialogRef.close(address);
  }

  onPageChange($event) {
    this.req.pageNo = $event.pageIndex;
    this.req.pageSize = $event.pageSize;
    this.loadAddress();
  }

  search(value, type: FilterType): void {
    this.subject.next({ type: type, value: value });
  }

  applyFilter(value, type: FilterType): void {
    switch (type) {
      case FilterType.Customer: this.req.customer = value;
        break;
      case FilterType.Company: this.req.company = value;
        break;
      case FilterType.Attention: this.req.attention = value;
        break;
      case FilterType.StateProvinceCode: this.req.stateProvinceCode = value;
        break;
      case FilterType.City: this.req.city = value;
        break;
      case FilterType.PostalCode: this.req.postalCode = value;
        break;
      default:
        break;
    }

    this.loadAddress();
  }
}


