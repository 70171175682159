import { Component, OnInit } from '@angular/core';
import { User } from './../../../../shared/interfaces/user';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserCreateComponent } from './user-create/user-create.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ChangePasswordComponent } from 'src/app/features/configuration/configurations/users/change-password/change-password.component';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  user: User;
  users: Array<User>;
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;
  subTitles = [
    { text: 'Home', 'route': '' },
    { text: 'Users', 'route': '' }
  ];

  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'phone', 'departments', 'action'];
  //dataSource = [];

  constructor(public dialog: MatDialog,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private toast: ToastService) { }

  ngOnInit() {
    this.getUsers();
  }

  /**
   * Get users.
   */
  getUsers = () => {

    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize,
      SEARCHED_NAME: "",
      EXCLUDE_ADMINS: false,
      EXCLUDE_CSRS: false,
      EXCLUDE_DEPT_UDERS: false
    }

    this.apiService.request('GET_USERS', { params: params }).subscribe((res: Array<User>) => {
      this.totalRecord = res['totalRecord'];
      this.users = res['users'];
    });
  }

  /**
   * Open create user dialog.
   */
  onCreateUser = () => {
    const dialogRef = this.dialog.open(UserCreateComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) { this.getUsers(); }
    });
  }

  /**
   * Open edit user dialog.
   */
  onEditClick = (user: User) => {
    const dialogRef = this.dialog.open(UserCreateComponent, {
      width: '800px',
      data: user
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) { this.getUsers(); }
    });
  }

  /**
   * On delete click.
   */
  onDeleteClick = (userId: string) => {

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { type: 'Confirm', message: 'Are you sure you want to delete this user ?' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.apiService.request('DELETE_USER', { params: { USER_ID: userId } }).subscribe(res => {
          if (res['success']) {
            this.getUsers();
            return;
          }
          this.toast.show(res['message']);

        });
      }
    });

  }

  /**
   * On change Pasword click
   */
  onChangePassword(userId: string) {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: userId
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) { this.getUsers(); }
    });
  }

  /**
  * on page change
  */
  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.getUsers();
  }




}
