import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { FullScreenView } from 'src/app/core/layout/topnav/state/topnav.action';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ShipmentPackage } from 'src/app/shared/interfaces/shipment-package';
import { ApiService } from 'src/app/shared/services/api.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UPSService } from '../services/ups.service';
import { ShipmentCreateComponent } from '../shipment-create/shipment-create.component';
import { ShipmentTrackingComponent } from '../shipment-tracking/shipment-tracking.component';
import { ShipmentUploadAddressesComponent } from '../shipment-upload-addresses/shipment-upload-addresses.component';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {

  subTitles = [{ text: 'Home', route: '' }, { text: 'Shipments', route: '' }];
  title = 'Shipments';
  shipments = [];
  paginator = {
    pageNo: 0,
    pageSize: 50,
    total: 0
  };
  image: any;
  url: any;
  isFullScreenView = false;
  allowUploadAddress = false;

  constructor(private api: ApiService,
    private dialog: MatDialog,
    private toast: ToastService,
    private upsService: UPSService,
    @Inject(DOCUMENT) private document: any,
    private actions$: Actions,
    private store: Store,
    private permission: PermissionService) { }

  ngOnInit() {
    this.loadShipments();
    const self = this;
    this.document.addEventListener("fullscreenchange", function () {
      if (self.document.fullscreenElement === null) {
        self.store.dispatch(new FullScreenView(false));
        self.isFullScreenView = false;
      }
    });

    this.actions$.pipe(ofActionCompleted(FullScreenView)).subscribe(res => {
      this.isFullScreenView = res.action.isFullScreenView;
    });

    this.allowUploadAddress = this.permission.isCurrentDepartment('shipping') ? true : false;
  }


  downloadLabels($event, shipment) {
    $event.stopPropagation();
    var shipmentPackages: ShipmentPackage[] = shipment.packages;
    var shipmentTrackingNumbers: string[] = [];
    shipmentPackages.forEach((_shipmentPackage: ShipmentPackage) => {
      shipmentTrackingNumbers.push(_shipmentPackage.trackingNumber);
    });
    this.upsService.downloadLabelFromServerAndShow(shipmentTrackingNumbers);
  }

  downloadLabel($event, shipmentIdentificationNo: string) {
    var shipmentTrackingNumbers: string[] = [];
    shipmentTrackingNumbers.push(shipmentIdentificationNo);
    this.upsService.downloadLabelFromServerAndShow(shipmentTrackingNumbers);
  }

  onPageChange($event) {
    this.paginator.pageNo = $event.pageIndex;
    this.paginator.pageSize = $event.pageSize;
    this.loadShipments();
  }

  loadShipments() {
    this.api
      .request('GET_SHIPMENTS', {
        'params': {
          'PAGE_NO': this.paginator.pageNo,
          'PAGE_SIZE': this.paginator.pageSize,
          'JOB_ID': 0
        }
      })
      .subscribe(res => {
        if (res) {
          if (res['result'] && res['result'].length > 0) {
            this.shipments = res['result'];
            this.shipments.forEach(shipment => {
              shipment['show'] = false;
              const upsService = this.upsService.UpsServices.find(us => us.code == shipment.upsService);
              const packageType = this.upsService.packageTypes.find(us => us.code == shipment.packageType);
              shipment.upsService = upsService.description;
              shipment.packageType = packageType.description;
            });
            this.paginator.total = res['total'];
          }
        }
      });
  }

  tracking($event, shipment) {
    $event.stopPropagation();
    this.dialog.open(ShipmentTrackingComponent, {
      width: '75vw',
      maxWidth: '75vw',
      maxHeight: 'auto',
      data: {
        identificationNo: shipment.identificationNo,
        shipmentId: shipment.id
      }
    });
  }

  delete($event, identificationNo) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '170px',
      width: '470px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to delete shipment?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api
          .request(
            'DELETE_SHIPMENT',
            {
              params:
                { ID: identificationNo }
            }
          ).subscribe(res => {
            if (res.response && res.response.errors && res.response.errors.length > 0) {
              this.toast.show(res.response.errors[0].message);
            } else {
              this.loadShipments();
            }
          });
      }
    })
  }

  create() {
    const dialogRef = this.dialog.open(ShipmentCreateComponent, {
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: 'auto',
      data: {
        shipmentForJob: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadShipments();
      }
    })
  }

  public uploadAddress() {
    const dialogRef = this.dialog.open(ShipmentUploadAddressesComponent, {
      width: '90vw',
      maxWidth: '90vw',
      maxHeight: 'auto',
      // height:'80vh'
    });


  }
}
