<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <strong class="header-text primary-text-color">{{ title }}</strong>

  <form [formGroup]="userForm" autocomplete="off">
    <div fxLayout="row" fxLayoutGap="2% grid">
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>First Name</mat-label>
        <input
          matInput
          type="text"
          autofocus="true"
          placeholder="First Name"
          formControlName="firstName"
          required
        />
        <mat-error *ngIf="hasError('firstName', 'required')"
          >You must enter first name.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Last Name"
          formControlName="lastName"
          required
        />
        <mat-error *ngIf="hasError('lastName', 'required')"
          >You must enter last name.</mat-error
        >
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="2% grid">
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          placeholder="Email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
          formControlName="email"
          required
        />
        <mat-error *ngIf="hasError('email', 'required')"
          >You must enter email.</mat-error
        >
        <mat-error *ngIf="hasError('email', 'pattern')"
          >You must enter valid email.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Phone</mat-label>
        <input
          matInput
          type="text"
          placeholder="Phone"
          minlength="10"
          maxlength="10"
          pattern="^[0-9]*$"
          formControlName="phone"
          required
        />
        <mat-error *ngIf="hasError('phone', 'required')"
          >You must enter phone number.</mat-error
        >
        <mat-error *ngIf="hasError('phone', 'pattern')"
          >You must enter valid phone number.</mat-error
        >
        <mat-error
          *ngIf="
            !hasError('phone', 'pattern') && hasError('phone', 'minlength')
          "
          >You must enter valid phone number.</mat-error
        >
      </mat-form-field>
    </div>

    <div *ngIf="!this.user" fxLayout="row" fxLayoutGap="2% grid">
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Password</mat-label>
        <input
          matInput
          minlength="6"
          [type]="!isShowPassword ? 'password' : 'text'"
          autocomplete="off"
          placeholder="Password"
          formControlName="password"
          required
        />
        <mat-icon
          matSuffix
          (click)="isShowPassword = !isShowPassword"
          class="material-icons gray eye-icon"
          style="cursor: pointer"
        >
          {{ isShowPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
        <mat-error *ngIf="hasError('password', 'required')"
          >You must enter password.</mat-error
        >
        <mat-error *ngIf="hasError('password', 'minlength')"
          >Password length should be 6 digit.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          minlength="6"
          [type]="!isShowConfPassword ? 'password' : 'text'"
          autocomplete="off"
          placeholder="Password"
          formControlName="confirmPassword"
          required
        />
        <mat-icon
          matSuffix
          (click)="isShowConfPassword = !isShowConfPassword"
          class="material-icons gray eye-icon"
          style="cursor: pointer"
        >
          {{ isShowConfPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
        <mat-error *ngIf="hasError('confirmPassword', 'required')"
          >You must enter password.</mat-error
        >
        <mat-error
          *ngIf="userForm.controls['confirmPassword'].errors?.MatchPassword"
          >Password does not match
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="2% grid">
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Department</mat-label>
        <mat-select
          placeholder="Department"
          formControlName="departmentId"
          multiple
          [disabled]="
            this.userForm.controls.admin.value ||
            this.userForm.controls.csr.value
          "
        >
          <mat-option
            *ngFor="let dprmnt of departments$ | async"
            [value]="dprmnt.departmentId"
            NgStyle=""
          >
            {{ dprmnt.name | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('departmentId', 'required')"
          >You must select department.</mat-error
        >
      </mat-form-field>

      <div fxLayout="column">
        <div fxLayout="row" fxFlexOffset="15" fxLayoutGap="20px">
          <mat-checkbox
            color="primary"
            formControlName="admin"
            (change)="onAdminCsrProperty()"
            >Admin</mat-checkbox
          >
          <mat-checkbox
            color="primary"
            formControlName="csr"
            (change)="onAdminCsrProperty()"
            >CSR</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="2% grid"
      *ngIf="this.userForm.controls.csr.value"
    >
      <mat-form-field
        appearance="outline"
        fxFlex="100"
        (click)="AttachListeners()"
      >
        <mat-label>CSR Name</mat-label>
        <mat-chip-grid #csrChipList aria-label="CSR Name Selection">
          <mat-chip-row
            *ngFor="let csr of selectedCSRs"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeAll()"
          >
            {{ csr }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="CSR Name"
            matInput
            #csrSearchInput
            [formControl]="CSRSearchInputCtrl"
            [matAutocomplete]="csrautoComplete"
            [matChipInputFor]="csrChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (focus)="showAll()"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #csrautoComplete="matAutocomplete"
          (optionSelected)="selectCSR($event)"
        >
          <mat-option *ngFor="let csr of filteredCSRs" [value]="csr">
            {{ csr }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="2% grid">
      <mat-checkbox
        color="primary"
        labelPosition="after"
        formControlName="isAllowImportPrinterPlanJob"
      >
        <small class="primary-text-color">
          Allow the user to import jobs from the Printers Plan
        </small>
      </mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
      <small
        *ngIf="
          this.userForm.controls.admin.value || this.userForm.controls.csr.value
        "
        class="warn"
        >By default assigned to all departments and able to import printer plan
        job.</small
      >
      <span fxFlex></span>
      <button
        mat-raised-button
        color="primary"
        fxFlex="100px"
        (click)="onSubmit()"
      >
        SUBMIT
      </button>
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        CLOSE
      </button>
    </div>
  </form>
</div>
