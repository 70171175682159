import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import * as Tiff from 'tiff.js';

@Component({
  selector: 'app-tiff-preview',
  templateUrl: './tiff-preview.component.html',
  styleUrls: ['./tiff-preview.component.scss']
})
export class TiffPreviewComponent implements OnInit {
  @Input('TiffImageResourceURL') public tiffImageResourceUrl : string;
  isFilePreviewLoading: boolean;
  constructor(private toast: ToastService) { }

  ngOnInit() {
    this.previewTiff();
  }

  previewTiff(){
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'arraybuffer';
          xhr.open('GET', this.tiffImageResourceUrl);
          xhr.onloadend = (e) => {
            this.isFilePreviewLoading = false;
            var tiff = new Tiff({ buffer: xhr.response });
            var canvas = tiff.toCanvas();
            var divtage = document.getElementById("tiffsection");
            divtage.append(canvas);
          };
          xhr.onerror = (e) => {
            this.isFilePreviewLoading = false;
            this.toast.show('Preview is unavailable.');
          }
          xhr.send();
          this.isFilePreviewLoading = true;
  }
}
