import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Company } from 'src/app/shared/interfaces/company';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CompanyCreateComponent } from './company-create/company-create.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  subTitles = [
    { text: "Configuration", route: "" },
    { text: "Companies", route: "" }
  ];
  displayedColumns = ["index", "name", "website", "email", "phone", "action"];
  companies: Array<Company>;
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;

  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize,
      COMPANY_NAME: ''
    }

    this.api.request('GET_COMPANIES', { params: params }).subscribe(res => {
      this.totalRecord = res['total'];
      this.companies = res['result'];
    });
  }

  create() {
    const dialogRef = this.dialog.open(CompanyCreateComponent, {
      width: '70vw',
      height: '85vh',
      maxHeight: '85vh'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) { this.load(); }
    });
  }

  edit(company) {
    const dialogRef = this.dialog.open(CompanyCreateComponent, {
      width: '70vw',
      height: '85vh',
      maxHeight: '85vh',
      data: company
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) { this.load(); }
    });
  }

  delete(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '440px',
      data: { type: 'Confirm', message: 'Are you sure you want to delete this company ?' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.api.request('DELETE_COMPANY', { params: { ID: id } }).subscribe(res => {
          if (res['success']) {
            this.toast.show(res['message']);
            this.load();
          } else {
            this.toast.show(res['message']);
          }
          return;
        });
      }
    });
  }

  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.load();
  }

  sync(company: Company) {
    var reqObj = {
      params: {},
      data: {
        printerPlanCusNo: company.printerPlanCusNo,
        dataSourceId: company.dataSourceId
      }
    }

    this.api.request("SYNC_PRINTER_PLAN_CUSTOMER", reqObj).subscribe(res => {
      if (res['success']) {
        this.toast.show(res['message']);
        this.load();
      }
    })
  }
}



