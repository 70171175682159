import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-job-template-create',
  templateUrl: './job-template-create.component.html',
  styleUrls: ['./job-template-create.component.scss']
})
export class JobTemplateCreateComponent implements OnInit {

  form :UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JobTemplateCreateComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public steps:string,
    private store:Store) { }

  ngOnInit() {
    this.form = this.initForm(this.formBuilder);
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
  formBuilder.group({
    name: ['', [Validators.required]],
  })

  hasError = (field: string, errorName: string): boolean =>
  this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  /**
   * Create new template on server.
   */
  onSubmit(){

    if(this.form.invalid){
      return;
    }

    let request ={
      name:this.form.controls.name.value,
      steps:this.steps
    };

    this.api.request('CREATE_TEMPLATE',{data:request}).subscribe(res=>{
     this.dialogRef.close(true);
    },error => {this.dialogRef.close(false);});
  }
}
