import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Job } from 'src/app/shared/interfaces/job';
import { ApiService } from 'src/app/shared/services/api.service';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
  selector: 'app-job-list-move',
  templateUrl: './job-list-move.component.html',
  styleUrls: ['./job-list-move.component.scss']
})
export class JobListMoveComponent implements OnInit, AfterViewInit {

  jobs: Array<Job> = [];
  filterJobs: Array<Job> = [];
  isShowConfirmation = false;
  isLoading = false;
  jobLoading = true;
  searchKey = '';
  items = [];
  selectedJobItems:Array<any> = [];
  isJobItemSelected : boolean = false;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private store: Store,
    public dialogRef: MatDialogRef<JobListMoveComponent>,
    private api: ApiService,
    private notificationHub: NotificationHubService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permission: PermissionService) { }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.search(text);
    });
  }

  ngOnInit() {
    this.loadJobs();
  }

  loadJobs() {
    this.data.request['isExpand'] = true;
    this.api.request(
      'GET_JOBS',
      { data: this.data.request }
    )
      .subscribe((response) => {
        this.jobs = response.jobs;
        this.jobs = this.jobs.filter(j => j.status == '1');
        this.jobs.forEach((j: any) => {
          j.items = j.items.filter(i => i.status == 1);
          j.items.forEach(item => {
            item['isSelect'] = false;
            item['isDisabled'] = false;
            var validArtwork = false;
            if (item.isRequiredApproveArtwork && !item.isImportedToProductionWithoutValidations) {
              if (item.firstStepDepartmentName == 'design') {
                //When first step is Design
                if (item.departmentName == 'design' && item.currentStepIndex == 0) {
                  validArtwork = true;
                  if (!item.files.find(f => f.isProofFile && !f.isSharedToSalesDept)) {
                    validArtwork = false;
                  }
                  if (item.isApprovedProofFile == true) {
                    validArtwork = true;
                  }

                }
                if (item.departmentName == 'sales' && item.currentStepIndex == 1) {
                  if (item.isApprovedProofFile == true) {
                    validArtwork = true;
                  }

                }
                if ((item.currentStepIndex !== 0 && item.departmentName !== 'design') && (item.currentStepIndex !== 1 && item.departmentName !== 'sales')) {
                  validArtwork = true;
                }
              }

              //When first step is Sales
              if (item.firstStepDepartmentName == 'sales') {
                if (item.departmentName == 'design' && item.currentStepIndex == 1) {
                  validArtwork = false;
                  var filesNotSharedWithSalesDept = item.files.filter(f => f.isProofFile == true && f.isSharedToSalesDept == false);
                  if (filesNotSharedWithSalesDept.length > 0) { validArtwork = false; }
                  else {
                    if (item.isApprovedProofFile == true) {
                      validArtwork = true;
                    }
                  }
                }
                if (item.departmentName == 'sales' && item.currentStepIndex == 0) {
                  validArtwork = true;

                  if (item.isApprovedProofFile) {
                    validArtwork = true;
                  }

                  if (item.files.find(f => f.isProofFile)) {
                    if (item.isApprovedProofFile != true) {
                      validArtwork = false;
                    }
                  }
                }
                if ((item.currentStepIndex !== 1 && item.departmentName !== 'design') && (item.currentStepIndex !== 0 && item.departmentName !== 'sales')) {
                  validArtwork = true;
                }
              }


              //Check its paid
              if (item.currentStepIndex != 0) {
                if (j['isPaid'] !== true && item['isBypassed'] !== true) {
                  validArtwork = false;
                }
              }
            }
            else {
              validArtwork = true;
            }

            if (!this.permission.canAccessJobItemDepartment(item.departmentName)) {
              validArtwork = false;
            }

            //Disable or enable checkbox
            if (validArtwork) { item['isDisabled'] = false; }
            else { item['isDisabled'] = true; }


          });
        });
        this.filterJobs = this.jobs;
        this.jobLoading = false;
      }, error => {
        this.jobLoading = false;
      });
  }

  moveJobs() {
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find(i => i['isSelect'])) {
        this.isShowConfirmation = true;
        this.isJobItemSelected = true;
      }
      if (this.isShowConfirmation) { break; }
    }
  }

  confirmMoveJobs() {
    this.isShowConfirmation = false;
    this.isLoading = true;
    let jobItems = [];
    this.jobs.forEach((j: any) => {
      let items = j.items.filter(i => i['isSelect']);
      items.forEach(item => {
        item.jobNumber = j.jobNumber;
      });
      jobItems = [...jobItems, ...items];
    });

    this.moveJobsIntoNextDepartment(jobItems);
  }

  moveJobsIntoNextDepartment(jobItems) {
    const data = {
      jobItemIds: jobItems.map(i => { return i.jobItemId })
    };
    this.api.request('COMPLETE_CURRENT_STEP', { data: data })
      .subscribe(res => {
        this.sendNotification(jobItems);
        this.isLoading = false;
      }, error => this.isLoading = false);
  }

  sendNotification(jobItems) {
    jobItems.forEach(item => {
      const notification = {
        JobId: item.jobId,
        JobNumber: item.jobNumber,
        JobItemNo: item.itemNo,
        jobItemName: item.description,
        steps: item.steps,
        currentStepIndex: item.currentStepIndex,
        changeStepIndex: item.currentStepIndex + 1
      };
      this.notificationHub.sendNotification(notification);
    });

    this.dialogRef.close(true);
  }


  close() {
    if (this.isLoading) { return; }
    this.dialogRef.close();
  }

  search(search) {
    this.filterJobs = !search || search.trim() === '' ?
      this.jobs :
      this.jobs.filter(j => j.jobNumber.toString().includes(search.toLowerCase().trim()) || j.customerName.toLowerCase().includes(search.toLowerCase().trim()));
  }

  clearSearch() {
    this.searchKey = '';
    this.search(this.searchKey);
  }

  onChangeCheckbox(event, item){
    this.selectedJobItems = [];
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find(i => i['isSelect'])) {
        this.isJobItemSelected = true;
        this.selectedJobItems.push(job.items.filter(_item => _item['isSelect']));
      }
      if (this.isShowConfirmation) { break; }
    }
    this.selectedJobItems.length > 0 ? ((this.isJobItemSelected = true ) && (this.isShowConfirmation = false)) : ((this.isJobItemSelected = false) && (this.isShowConfirmation = false));
 
  }

}


