<div
  fxLayout="row"
  class="grid-header full-screen-view"
  fxLayoutAlign="center center"
  fxLayoutGap="2px"
>
  <small class="text-ellipsis" fxFlex="5" class="primary-text-color">
    <div
      class="sort-header"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="onSortClick(sortByJobNumber, 'job')"
    >
      <strong>Job</strong>
      <mat-icon>{{
        sortByJobNumber === "asc"
          ? "arrow_upward"
          : sortByJobNumber === "desc"
          ? "arrow_downward"
          : ""
      }}</mat-icon>
    </div>
  </small>
  <small class="text-ellipsis" fxFlex="16" class="primary-text-color">
    <div
      class="sort-header"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="onSortClick(sortByCustomer, 'customer')"
    >
      <strong>Customer</strong>
      <mat-icon>{{
        sortByCustomer === "asc"
          ? "arrow_upward"
          : sortByCustomer === "desc"
          ? "arrow_downward"
          : ""
      }}</mat-icon>
    </div>
  </small>
  <small class="text-ellipsis" fxFlex="10" class="primary-text-color">
    <div
      class="sort-header"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="onSortClick(sortByCsr, 'csr')"
    >
      <strong>CSR</strong>
      <mat-icon>{{
        sortByCsr === "asc"
          ? "arrow_upward"
          : sortByCsr === "desc"
          ? "arrow_downward"
          : ""
      }}</mat-icon>
    </div>
  </small>
  <small class="text-ellipsis primary-text-color" fxFlex="6"
    ><strong>Qty</strong></small
  >
  <small class="text-ellipsis primary-text-color" fxFlex="11"
    ><strong>Stock/Size</strong></small
  >
  <small class="text-ellipsis primary-text-color" fxFlex="8"
    ><strong>Dept</strong></small
  >
  <small class="text-ellipsis primary-text-color" fxFlex="12"
    ><strong>Step/Action</strong></small
  >
  <small class="text-ellipsis primary-text-color" fxFlex="11"
    ><strong>Status</strong></small
  >
  <small class="text-ellipsis primary-text-color" fxFlex="8">
    <div
      class="sort-header"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="onSortClick(sortByDueDate, 'duedate')"
    >
      <strong>Date Due</strong>
      <mat-icon>{{
        sortByDueDate === "asc"
          ? "arrow_upward"
          : sortByDueDate === "desc"
          ? "arrow_downward"
          : ""
      }}</mat-icon>
    </div>
  </small>
  <small class="text-ellipsis primary-text-color" fxFlex="4"
    ><strong>Item</strong></small
  >
  <small fxFlex="2">
    <!-- is rush or not -->
  </small>
  <small class="text-ellipsis primary-text-color" fxFlex="7"
    ><strong></strong
  ></small>
</div>

<!-- <div style="height: 40px"></div> -->
<!-- Display no jobs available message -->
<div
  *ngIf="
    !(isJobsLoading | async) &&
    (jobs | async).length == 0 &&
    !renderDefaultLoadingJobs
  "
  fxLayoutAlign="center"
>
  <h4 class="primary-text-color">No Jobs Available!</h4>
</div>

<div
  *ngIf="
    renderDefaultLoadingJobs ||
    ((isJobsLoading | async) && (jobs | async).length == 0)
  "
  fxLayoutAlign="center"
>
  <h4 class="primary-text-color">Loading...</h4>
</div>

<app-job-grid-row
  *ngFor="let job of jobs | async; let index = index"
  [job]="job"
  [jobItem]="null"
  [isExpand]="isExpand"
  [currentDepartmentId]="currentDepartmentId"
  [currentDepartmentName]="currentDepartmentName"
>
</app-job-grid-row>
