<!-- <mat-form-field class="example-full-width"> -->
<div class="search-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon>search</mat-icon>

  <!-- class.mat-elevation-z2]="hasFocus"  -->
  <!-- <input matInput placeholder="State" aria-label="State" [matAutocomplete]="auto" [formControl]="stateCtrl"> -->
  <!-- (focus)="hasFocus=true" (focusout)="hasFocus=false" -->
  <input
    matInput
    #searchInput
    type="text"
    placeholder="Search/Import Customer or Job"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      [class.bold-line]="result.isJobAdded"
      (click)="onJobSelected(result)"
      *ngFor="let result of results"
    >
      <strong class="secondary-text-color">#{{ result.jobNumber }}</strong>
      <span style="margin: 0px 3px"> | </span>
      <span fxFlex class="overflow-text"> {{ result.customerName }}</span>
      <small class="secondary-text-color">{{
        result.dateIn | date : "mediumDate"
      }}</small>
    </mat-option>
    <mat-option *ngIf="results?.length === 0">
      <span class="secondary-text-color">No Records Found!</span>
    </mat-option>
    <!-- <mat-option>
              <span>Customer 111</span>
            </mat-option>
            <mat-option>
                <span>Job 112</span>
              </mat-option> -->
    <!-- <mat-option *ngFor="let state of filteredStates | async" [value]="state.name">
              <img class="example-option-img" aria-hidden [src]="state.flag" height="25">
              <span>{{state.name}}</span> |
              <small>Population: {{state.population}}</small>
            </mat-option> -->
  </mat-autocomplete>

  <!-- <br>
      <mat-divider></mat-divider>
       -->
</div>
<!-- </mat-form-field> -->
