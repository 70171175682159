<div fxLayout="column" fxLayoutGap="10px" class="padding-15">
  <div fxLayout="row">
    <mat-icon *ngIf="isShowCreate && shipmentForJob && shipments.length > 0" fxLayoutGap="5px" (click)="backToShipments()" style="cursor: pointer;">arrow_back</mat-icon>
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Shipment
    </span>
    <button
      *ngIf="!isShowCreate && jobItems.length > 0"
      type="button"
      color="primary"
      mat-stroked-button
      (click)="isShowCreate = !isShowCreate"
    >
      <span class="primary-text-color">CREATE</span>
    </button>
    <button
      *ngIf="isShowCreate && shipmentForJob && shipments.length > 0"
      type="button"
      color="primary"
      mat-stroked-button
      (click)="isShowCreate = !isShowCreate"
    >
    <span class="primary-text-color">SHIPMENTS</span>
    </button>
    <span fxFlex="10px"></span>
    <mat-icon (click)="close()" style="cursor: pointer">close</mat-icon>
  </div>
  <div *ngIf="isShowCreate"
    fxLayout="column"
  >
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" *ngIf="!isLoad">
      Loading...
    </div>
    <!-- create shipment -->
    <form
        *ngIf="isShowCreate && isLoad"
        [formGroup]="form"
        autocomplete="off"
        fxLayout="column"
        fxLayoutGap="10px"
      >
      <div style="height: 75vh; overflow: auto;">
        <fieldset>
          <legend class="primary-text-color">Shipper Information:</legend>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>JobNo</mat-label>
              <input
                matInput
                type="text"
                placeholder="JobNo"
                [readonly]="job"
                formControlName="jobNo"
                pattern="^[0-9]*$"
                required
              />
              <mat-error *ngIf="hasError('jobNo', 'required')"
                >You must enter job no.</mat-error
              >
              <mat-error *ngIf="hasError('jobNo', 'pattern')"
                >You must enter valid job no.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                placeholder="Name"
                formControlName="fromName"
                required
              />
              <mat-error *ngIf="hasError('fromName', 'required')"
                >You must enter name.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Attention</mat-label>
              <input
                matInput
                type="text"
                placeholder="Attention"
                formControlName="fromAttention"
                required
              />
              <mat-error *ngIf="hasError('fromAttention', 'required')"
                >You must enter attention.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Phone</mat-label>
              <input
                matInput
                type="text"
                placeholder="Phone"
                formControlName="fromPhone"
                required
              />
              <mat-error *ngIf="hasError('fromPhone', 'required')"
                >You must enter phone no.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Address</mat-label>
              <input
                matInput
                type="text"
                placeholder="Address"
                formControlName="fromAddress"
                required
              />
              <mat-error *ngIf="hasError('fromAddress', 'required')"
                >You must enter address.</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Country Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="country Code"
                formControlName="fromCountryCode"
                required
              />
              <mat-error *ngIf="hasError('fromCountryCode', 'required')"
                >You must enter country code.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>State Province Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="State Province Code"
                formControlName="fromStateProvinceCode"
                required
              />
              <mat-error *ngIf="hasError('fromStateProvinceCode', 'required')"
                >You must enter state province code.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>City</mat-label>
              <input
                matInput
                type="text"
                placeholder="City"
                formControlName="fromCity"
                required
              />
              <mat-error *ngIf="hasError('fromCity', 'required')"
                >You must enter city.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="Postal Code"
                minlength="5"
                maxlength="6"
                pattern="^[0-9]*$"
                formControlName="fromPostalCode"
                required
              />
              <mat-error *ngIf="hasError('fromPostalCode', 'required')"
                >You must enter postal code.</mat-error
              >
              <mat-error *ngIf="hasError('fromPostalCode', 'pattern')"
                >You must enter valid postal code.</mat-error
              >
              <mat-error
                *ngIf="
                  !hasError('fromPostalCode', 'pattern') &&
                  hasError('fromPostalCode', 'minlength')
                "
                >You must enter valid postal code.</mat-error
              >
            </mat-form-field>
            <div fxFlex="20"></div>
          </div>
        </fieldset>

        <fieldset>
          <legend class="primary-text-color">Customer Information:</legend>
          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            style="margin-bottom: 8px; margin-top: -5px !important"
          >
            <button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="selectAddress()"
            >
             <span class="primary-text-color">SELECT ADDRESS</span> 
            </button>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Customer</mat-label>
              <input
                matInput
                type="text"
                placeholder="Customer"
                formControlName="toCustomer"
                required
                maxlength="35"
              />
              <mat-error *ngIf="hasError('toCustomer', 'required')"
                >You must enter customer.</mat-error
              >
              <mat-error *ngIf="hasError('toCustomer', 'maxlength')"
                >Maximum length allowed is 35 characters.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Attention</mat-label>
              <input
                matInput
                type="text"
                placeholder="Attention"
                formControlName="toAttention"
                required
              />
              <mat-error *ngIf="hasError('toAttention', 'required')"
                >You must enter attention.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Company</mat-label>
              <input
                matInput
                type="text"
                placeholder="Company"
                formControlName="toCompany"
                required
              />
              <mat-error *ngIf="hasError('toCompany', 'required')"
                >You must enter company.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Address</mat-label>
              <input
                matInput
                type="text"
                placeholder="Address"
                formControlName="toAddress"
                required
              />
              <mat-error *ngIf="hasError('toAddress', 'required')"
                >You must enter address.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Country Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="country Code"
                formControlName="toCountryCode"
                required
              />
              <mat-error *ngIf="hasError('toCountryCode', 'required')"
                >You must enter country code.</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>State Province Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="State Province Code"
                formControlName="toStateProvinceCode"
                required
              />
              <mat-error *ngIf="hasError('toStateProvinceCode', 'required')"
                >You must enter state province code.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>City</mat-label>
              <input
                matInput
                type="text"
                placeholder="City"
                formControlName="toCity"
                required
              />
              <mat-error *ngIf="hasError('toCity', 'required')"
                >You must enter city.</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                type="text"
                placeholder="Postal Code"
                minlength="5"
                maxlength="6"
                pattern="^[0-9]*$"
                formControlName="toPostalCode"
                required
              />
              <mat-error *ngIf="hasError('toPostalCode', 'required')"
                >You must enter postal code.</mat-error
              >
              <mat-error *ngIf="hasError('toPostalCode', 'pattern')"
                >You must enter valid postal code.</mat-error
              >
              <mat-error
                *ngIf="
                  !hasError('toPostalCode', 'pattern') &&
                  hasError('toPostalCode', 'minlength')
                "
                >You must enter valid postal code.</mat-error
              >
            </mat-form-field>
            <div fxFlex="20"></div>
            <div fxFlex="20"></div>
          </div>
        </fieldset>

        <fieldset>
          <legend class="primary-text-color">Shipment Information:</legend>
          <div fxLayout="column" fxLayoutGap="10px">
            <div
              fxLayout="row"
              fxFlex
              fxLayoutAlign="start start"
              fxLayoutGap="10px"
            >
              <mat-form-field appearance="fill" fxFlex="30">
                <mat-label>UPS Service</mat-label>
                <mat-select
                  placeholder="Select UPS Service"
                  formControlName="upsService"
                  required
                >
                  <ng-container *ngFor="let service of UpsServices">
                    <mat-option [value]="service.code">
                      {{ service.description | titlecase }}</mat-option
                    >
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="hasError('upsService', 'required')"
                  >You must select ups service.</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="30">
                <mat-label class="mat-label">Package Type</mat-label>
                <mat-select
                  placeholder="Select Package Types"
                  formControlName="packageType"
                  required
                >
                  <ng-container *ngFor="let type of packageTypes">
                    <mat-option [value]="type.code">
                      {{ type.description | titlecase }}</mat-option
                    >
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="hasError('packageType', 'required')"
                  >You must select package type.</mat-error
                >
              </mat-form-field>
              <span fxFlex></span>
              <button mat-stroked-button color="primary" (click)="addPackage()">
                <mat-icon>add</mat-icon>
                <span class="primary-text-color">ADD PACKAGE</span>
              </button>
            </div>
            <div class="grid" *ngIf="shipmentPackages.length > 0">
              <div
                fxLayout="row"
                class="grid-header"
                fxLayoutAlign="center center"
              >
                <strong fxFlex="10">
                  <small class="primary-text-color">Package</small>
                </strong>
                <strong fxFlex="35" *ngIf="shipmentForJob"
                  ><small class="primary-text-color">Items</small></strong
                >
                <strong fxFlex="10"
                  ><small class="primary-text-color">Weight</small></strong
                >
                <strong fxFlex="35"
                  ><small class="primary-text-color">Description</small></strong
                >
                <span fxFlex></span>
                <strong fxFlex="10"><small></small></strong>
              </div>
              <div
                fxLayout="row"
                class="grid-row"
                fxLayoutAlign="center center"
                *ngFor="let pkg of shipmentPackages; let ind = index"
              >
                <span fxFlex="10">
                  <small>{{ ind + 1 }}</small>
                </span>
                <span fxFlex="35" class="text-ellipsis" *ngIf="shipmentForJob">
                  <small *ngFor="let item of pkg.items; let index = index">
                    <span *ngIf="index > 0">,</span>
                    {{ item.description }}
                  </small>
                </span>
                <span fxFlex="10"
                  ><small class="primary-text-color">{{
                    pkg.weight
                  }}</small></span
                >
                <span fxFlex="35" class="text-ellipsis"
                  ><small class="primary-text-color">{{
                    pkg.description
                  }}</small></span
                >
                <span fxFlex></span>
                <span fxFlex="10">
                  <mat-icon class="pointer" (click)="addPackage(ind, pkg)"
                    >edit</mat-icon
                  >
                  <mat-icon class="pointer" (click)="deletePackage(ind)"
                    >delete</mat-icon
                  >
                </span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

        <!-- <div style="height: 10px"></div> -->
        <div *ngIf="isShowCreate && isLoad" fxLayout="row" fxLayoutAlign="end center" style="margin-right: 150px;">
          <div class="error" *ngIf="errors.length > 0" fxLayoutGap="10px">
            <small>{{ errors[0] }}</small>
          </div>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            (click)="create()" class="margin-left"
          >
            <div fxLayoutAlign="center center" fxLayoutGap="5px">
              <span> SUBMIT</span>
              <span *ngIf="loading" class="loader"></span>
            </div>
          </button>
          <span fxFlex="5px"></span>
        </div>
    </form>
  </div>
    
    <!-- shipment list -->
    <div *ngIf="!isShowCreate && shipmentForJob"
      fxLayout="column"
      style="height: 75vh; overflow: auto;"
    >
      <div fxFlex fxLayout="column" fxLayoutAlign="center center" *ngIf="!isLoad">
        Loading...
      </div>
      <div *ngIf="!isShowCreate && shipmentForJob && isLoad">
        <div *ngIf="shipments.length == 0" fxLayoutAlign="center">
          <h4 class="primary-text-color">No Shipments Available!</h4>
        </div>
        <div class="grid" *ngIf="shipments.length > 0">
          <div fxLayout="row" class="grid-header" fxLayoutAlign="center center">
            <strong fxFlex="15">
              <small class="primary-text-color">Tracking No</small>
            </strong>
            <strong fxFlex="25"
              ><small class="primary-text-color">Customer</small></strong
            >
            <strong fxFlex="20"
              ><small class="primary-text-color"
                >UPS Service/ Description</small
              ></strong
            >
            <strong fxFlex="20"
              ><small class="primary-text-color">Package Type</small></strong
            >
            <strong fxFlex="10"
              ><small class="primary-text-color">Created</small></strong
            >
            <strong fxFlex="10"></strong>
          </div>
          <ng-container *ngFor="let shipment of shipments">
            <div fxLayout="row" class="grid-row" fxLayoutAlign="center center">
              <small fxFlex="15" class="text-ellipsis primary-text-color">{{
                shipment["identificationNo"]
              }}</small>
              <small
                fxFlex="25"
                class="shipment-selection text-ellipsis primary-text-color"
                (click)="shipment['show'] = !shipment['show']"
                ><strong>{{ shipment["toAddress"]["customer"] }}</strong></small
              >
              <small fxFlex="20" class="text-ellipsis primary-text-color">{{
                shipment["upsService"]
              }}</small>
              <small fxFlex="20" class="text-ellipsis primary-text-color">{{
                shipment["packageType"]
              }}</small>
              <small fxFlex="10" class="text-ellipsis primary-text-color">{{
                shipment["createdDate"] | date
              }}</small>
              <span
                fxFlex="10"
                fxLayoutGap="5px"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-icon
                  matTooltip="Download Labels"
                  (click)="downloadLabels($event, shipment)"
                  class="icon"
                  >receipt</mat-icon
                >
                <mat-icon
                  matTooltip="Rate Info"
                  [matMenuTriggerFor]="menu"
                  class="icon"
                  >info</mat-icon
                >
                <mat-icon
                  matTooltip="Track"
                  (click)="tracking($event, shipment)"
                  class="icon"
                  >gps_fixed</mat-icon
                >
                <mat-icon
                  matTooltip="Delete"
                  (click)="delete($event, shipment['identificationNo'])"
                  class="icon"
                  >delete
                </mat-icon>
                <mat-menu #menu="matMenu">
                  <div fxLayout="column" style="padding: 10px">
                    <small fxLayout="row" fxLayoutGap="5px">
                      <strong class="primary-text-color">Billing Weight:</strong>
                      <span>{{ shipment["billingWeight"] }}</span>
                    </small>
                    <small fxLayout="row" fxLayoutGap="5px">
                      <strong class="primary-text-color"
                        >Service Options Charges:</strong
                      >
                      <span>{{ shipment["serviceOptionsCharges"] }}</span>
                    </small>
                    <small fxLayout="row" fxLayoutGap="5px">
                      <strong class="primary-text-color"
                        >Transportation Charges:</strong
                      >
                      <span>{{ shipment["transportationCharges"] }}</span>
                    </small>
                    <small fxLayout="row" fxLayoutGap="5px">
                      <strong class="primary-text-color">Total Charges:</strong>
                      <span>{{ shipment["totalCharges"] }}</span>
                    </small>
                  </div>
                </mat-menu>
              </span>
            </div>
            <ng-container
              *ngFor="let package of shipment.packages; let index = index"
            >
              <div
                *ngIf="shipment['show']"
                fxLayout="row"
                class="grid-row shipment-item-row"
                fxLayoutAlign="center center"
              >
                <small fxFlex="15" class="text-ellipsis primary-text-color">{{
                  package["trackingNumber"]
                }}</small>
                <small fxFlex="25" class="text-ellipsis primary-text-color"
                  >Package {{ index + 1 }}</small
                >
                <small
                  fxFlex="20"
                  class="text-ellipsis primary-text-color"
                  [matTooltip]="package['description']"
                  >{{ package["description"] }}</small
                >
                <small fxFlex="20" class="text-ellipsis"></small>
                <small fxFlex="10" class="text-ellipsis"></small>
                <span fxFlex="10">
                  <mat-icon
                    matTooltip="Download Label"
                    (click)="downloadLabel($event, package['trackingNumber'])"
                    class="icon"
                    >receipt</mat-icon
                  >
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
</div>
