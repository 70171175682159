import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-job-mailing-sort-create',
  templateUrl: './job-mailing-sort-create.component.html',
  styleUrls: ['./job-mailing-sort-create.component.scss']
})
export class JobMailingSortCreateComponent implements OnInit {

  mailingSortForm : UntypedFormGroup;
  title= 'Add Mailing Sort';

  constructor(public formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public mailingSort: any,
     public dialogRef: MatDialogRef<JobMailingSortCreateComponent>,
     public api:ApiService ) { }

  ngOnInit() {
   this.mailingSortForm =  this.initForm(this.formBuilder);

   if(this.mailingSort != null){
    if(this.mailingSort['mailingSortId']!== null){
      this.title = 'Edit Mailing Sort';
    }
    this.mailingSortForm.patchValue(this.mailingSort);
    }
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
  formBuilder.group({
    weight: '',
    width: '',
    height: '',
    thickness: '',
    postOffice:'',
    permit:'',
    letter: false,
    flat: false,
    postcard: false,
    nonMachinable: false,
    nonAuto: false,
    automation: false,
    nonProfit: false,
    ancillarySvcs: false,
    stdPrsrt: false,
    fullRateFirstClass: false,
    firstClassPart: false,
    periodical: false,
    foreign:false
  })

hasError = (field: string, errorName: string): boolean =>
  this.mailingSortForm.get(field).errors ? this.mailingSortForm.get(field).errors[errorName] : false


  onSubmit() {
    if(this.mailingSort != null && this.mailingSort['mailingSortId'] !=null){
      this.editMailingInfo();
      return;
    }
    let mailingSort = this.getRequestObject();

  //   mailingSort['weight'] = mailingSort['weight'] === '' ? 0:mailingSort['weight'];
  //   mailingSort['width'] = mailingSort['width'] === '' ? 0:mailingSort['width'];
  //   mailingSort['height'] = mailingSort['height'] === '' ? 0:mailingSort['height'];
  // //  mailingSort['postOffice'] = mailingSort['postOffice'] === '' ? 0:mailingSort['postOffice'];
  //   mailingSort['thickness'] = mailingSort['thickness'] === '' ? 0:mailingSort['thickness'];

    this.dialogRef.close(mailingSort);
  }

  editMailingInfo(){
    let mailingSort = this.getRequestObject();
    mailingSort['mailingSortId'] =this.mailingSort['mailingSortId'];

    const apiRequest = {
      params: { MAILING_SORT_ID: mailingSort['mailingSortId'] },
      data: mailingSort
    };
  
    this.api.request('EDIT_MAILING_SORT', apiRequest).subscribe((res: any) => {
      if (res) {
        this.dialogRef.close(mailingSort);
      }
    },error=>{
    });
  }

  getRequestObject(){
    let mailingSort = this.mailingSortForm.value;

    mailingSort['weight'] = mailingSort['weight'] === '' ? 0:mailingSort['weight'];
    mailingSort['width'] = mailingSort['width'] === '' ? 0:mailingSort['width'];
    mailingSort['height'] = mailingSort['height'] === '' ? 0:mailingSort['height'];
    mailingSort['thickness'] = mailingSort['thickness'] === '' ? 0:mailingSort['thickness'];
   // mailingSort['foreign'] = mailingSort['foreign'] === 'false' ? false:true;
    return mailingSort;
  }

}
