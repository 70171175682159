import { Component, OnInit } from '@angular/core';
import { Store } from "@ngxs/store";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "./../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ApiService } from "src/app/shared/services/api.service";
import { NavigationMenuCreateComponent } from './navigation-menu-create/navigation-menu-create.component';
import { Navigation } from 'src/app/shared/interfaces/navigation';
import { LoadNavigations, AddNavigation, UpdateNavigation, DeleteNavigation } from '../../state/configuration.actions';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-navigation-menus',
  templateUrl: './navigation-menus.component.html',
  styleUrls: ['./navigation-menus.component.scss']
})
export class NavigationMenusComponent implements OnInit {
  subTitles = [
    { text: "Configuration", route: "" },
    { text: "Navigation Menu", route: "" }
  ];
  displayedColumns = ["position", "title", "department", "status", "icon", "action"];
  navigations: Array<Navigation>;
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private api: ApiService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.loadNavigations();
  }

  loadNavigations() {

    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize
    }

    this.api.request('GET_NAVIGATIONS', { params: params }).subscribe(res => {
      console.log(res);
      this.totalRecord = res['totalRecord'];

      this.navigations = res['navigations'];
    });
  }

  /**
   * Open add department popup
   */
  onCreateDepartment() {
    //show add department popup
    const dialogRef = this.dialog.open(NavigationMenuCreateComponent, {
      width: "500px"


    });

    dialogRef.afterClosed().subscribe(navigation => {
      if (navigation) {
        this.loadNavigations();
        this.store.dispatch(new AddNavigation(navigation));
      }
    });
  }

  /**
   * open edit user dialog.
   */
  onEditClick(navigation) {
    const dialogRef = this.dialog.open(NavigationMenuCreateComponent, {
      width: "500px",
      data: navigation
    });

    dialogRef.afterClosed().subscribe(nav => {

      if (nav)
        this.loadNavigations();
      this.store.dispatch(new UpdateNavigation(nav));
    });
  };

  /**
   * on delete click.
   */
  onDeleteClick(navigationId) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this navigation menu ?"
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.api
          .request("DELETE_NAVIGATION", {
            params: { NAVIGATION_ID: navigationId }
          })
          .subscribe(res => {
            if (res['success']) {
              this.loadNavigations();
              this.store.dispatch(new DeleteNavigation(navigationId));
              return;
            }
            this.toast.show(res['message']);
          });
    });
  };

  /**
  * on page change
  */
  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.loadNavigations();
  }
}

