import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconService } from 'src/app/shared/services/maticon.service';


@Component({
  selector: 'app-mat-icon',
  templateUrl: './mat-icon.component.html',
  styleUrls: ['./mat-icon.component.scss']
})
export class MatIconComponent implements OnInit {

  matIcons:any;
  starticon:any;
  allMatIcons:any;
  searchKey : any;
  matIconsHash = {};
  hashIndex = 0;

  constructor(private matIconService:MatIconService,
    public dialogRef: MatDialogRef<MatIconComponent>) { }

  ngOnInit(): void {
    this.allMatIcons = this.matIconService.icons();
    this.matIcons = this.allMatIcons; 
    
  }

  onFilter(value){
    this.matIcons = this.matIconService.icons();
    let newData = this.matIcons.filter((obj) => {
      return obj.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    })
    this.matIcons = newData; 
 }

 clearFilter() {
   this.searchKey = '';
   this.matIcons = this.matIconService.icons();
 }

 select(icon:string){
   this.dialogRef.close(icon);
 }

}


