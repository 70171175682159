<div fxLayout="row" (click)="onLoadDetailsView()">


  <small class="status status-inprogress" fxLayout="column" fxLayoutAlign="center center"
    [ngClass]="{'status-complete': job.status === 'complete','status-inprogress':job.status === 'in progress','status-new':job.status === 'new'}">
    <span *ngIf="job.status === 'complete'" >COMP</span>
    <span *ngIf="job.status === 'in progress'" >IN PROG</span>
  </small>
<div fxLayout="column" fxLayoutAlign="center null" fxFlex >
  <div fxLayout="row"   fxLayoutGap="2px"  class="job-info" >
    <div fxFlex="30" fxLayoutAlign="start center" >
      <div style="font-size: 1.0rem" fxLayoutAlign="start center">
        <mat-icon>person</mat-icon>
        <span  class="text-ellipsis" >{{job.customerName}}</span>
      </div>
    </div>

    <div fxFlex="25" fxLayoutAlign="start center" >
      <span 
      class="text-ellipsis" >JOB:{{job.jobNumber}} - {{job.title}}</span>
    </div>

    <div fxFlex="25" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" >
          <span fxFlex>CSR: <strong> {{job.csrName}} ({{job.csrNo}})</strong> </span>
    </div>

    <div  fxFlex="20" fxLayoutAlign="start center">
      <mat-icon>date_range</mat-icon> Due on .<strong [class.warn]="job.dateDue < today">
        {{job.dateDue |date:'mediumDate' }}</strong>
        <span fxFlex></span>
        <mat-chip-listbox  >
          <mat-chip-option *ngIf="job['rushDate']" color="warn" selected>
            Rush</mat-chip-option>           
        </mat-chip-listbox>
    </div>

  </div>

  <div *ngIf='showDetailsView' fxLayout="row"  fxLayoutGap="2px" class="job-info"    >
     
      <div fxFlex="30"  fxLayoutAlign="start center">
              <mat-icon>date_range</mat-icon> Date In .<strong> {{job.dateIn |date:'mediumDate' }}</strong>
            </div>
            
    <div fxFlex  *ngIf="job.status !== 'complete' && items.length > 0 && showDetailsView" >
      <div fxLayoutAlign="start center">
        <button [disabled]="loading" mat-stroked-button color="primary" (click)="markAsComplete($event)"
          style="width: 100%">
          <div fxLayoutAlign="center center">
            <mat-icon>done_all</mat-icon>
            <span> Mark As Completed &nbsp;&nbsp;</span>
            <span *ngIf="loading" class="loader"></span>
          </div>
        </button>
      </div>      
      <div fxFlex></div>
    </div>
  </div>

  <div *ngIf='showDetailsView'>
    <mat-list>
      <!-- Header -->
      <mat-list-item>
        <div fxLayout="row" fxFlex>
          <strong fxFlex="22">Item</strong>      
          <strong fxFlex="10">Department</strong>
          <strong fxFlex="20">Step Action</strong>
          <strong fxFlex="8">Quantity</strong>
          <strong fxFlex="11">Color</strong>
          <strong fxFlex="8">Size</strong>
          <strong fxFlex="13">Stock</strong>
          <strong fxFlex="7">Status</strong>
          <strong fxFlex="1"></strong>
        </div>
      </mat-list-item>

      <!-- Items -->

      <ng-container *ngFor="let item of items;let index=index">
        <mat-divider></mat-divider>
        <mat-list-item>
          <div fxLayout="row wrap" fxFlex>
            <div (click)="jobItemSelection($event ,index)" fxFlex="22" class="job-item-selection text-ellipsis" >{{item.description}}
            </div>            
            <div fxFlex="10">{{item.departmentName}}</div>
            <div fxFlex="20" class="text-ellipsis">{{item.currentStepName|titlecase}} / {{item.currentActionName|titlecase}}</div>
            <div fxFlex="8">
              {{item.chargeQty}}
            </div>
            <div fxFlex="11" class="text-ellipsis">
              <span *ngIf="item.color != ''" >{{item.color}}</span>
              <span *ngIf="item.color == ''" >-</span>
            </div>
            <div fxFlex="8">
              <span *ngIf="item.size != ''" >{{item.size}}</span>
              <span *ngIf="item.size == ''" >-</span>
            </div>
            <div fxFlex="13" class="text-ellipsis" >
              <span *ngIf="item.stock != ''" >{{item.stock}}</span>
              <span *ngIf="item.stock == ''" >-</span>
            </div>
            <div fxFlex="7">
              <span *ngIf="item.status == '0'" class="text-blue">New</span>
              <span *ngIf="item.status == '1'" class="text-red ">In progress</span>
              <span *ngIf="item.status == '2'" class="text-green ">Invoice</span>
            </div>
            <div  fxFlex="1">
              <mat-icon style="color:#282858" *ngIf="item.artwork" >file_copy</mat-icon>
            </div>

          </div>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>
</div>

</div>