import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { timeStamp } from 'console';

@Component({
  selector: 'app-job-delete-confirm-dialog',
  templateUrl: './job-delete-confirm-dialog.component.html',
  styleUrls: ['./job-delete-confirm-dialog.component.scss']
})
export class JobDeleteConfirmDialogComponent implements OnInit {

  jobNumber;
  isInValid = false;
  constructor(@Inject(MAT_DIALOG_DATA) public selectedJobNumber: any,
    public dialogRef: MatDialogRef<JobDeleteConfirmDialogComponent>,) { }

  ngOnInit() {
  }

  delete() {
    this.isInValid = false;
    if (this.jobNumber != this.selectedJobNumber) {
      this.isInValid = true;
      return;
    }

    this.dialogRef.close(true);
  }

}
