import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class PrinterPlanDocumentService {


    getDocumentType(fileExtension: string) {
        switch (fileExtension.toLowerCase()) {
          case 'jpg':
            return 'Image';
          case 'jpeg':
            return 'Image';
          case 'png':
            return 'Image';
          case 'svg':
            return 'SVG';
          case 'gif':
            return 'Image';
          case 'tiff':
            return 'Tiff';
          case 'tif':
            return 'Tiff';  
          case 'pdf':
            return 'Pdf';
          case 'xlsx':
            return 'Excel';
          case 'xls':
            return 'Excel';  
          // case 'csv':
          //   return 'Csv';
          default:
            return 'Document';
        }
      }

      getDocumentSrc(docType: string) {
        switch (docType.toLowerCase()) {
            case 'pdf':
                return 'assets/images/pdf_thumbnail.svg';
              case 'xlsx':
                return 'assets/images/xls_thumbnail.png';
              case 'xls':
                return 'assets/images/xls_thumbnail.png';
              case 'document':
                return 'assets/images/doc_thumbnail.svg';
              case 'excel':
                return 'assets/images/xls_thumbnail.png';
              case 'tiff':
                return 'assets/images/tiff_thumbnail.svg';
              case 'svg':
                return 'assets/images/svg_thumbnail.svg';
              case 'zip':
                return 'assets/images/zip_thumbnail.svg';
              default:
                return 'assets/images/doc_thumbnail.svg';
        }
      }

      getThumbnailUrl(documentId:number){
        return `${environment.baseUrl}/api/printerplandocument/${documentId}/thumbnail`
      }

      getDownloadDocumentUrl(documentId:number){
        return `${environment.baseUrl}/api/printerplandocument/${documentId}/download`
      }


      getDocumentUrl(document){   
          let docType = 'Document';
          let documentURL = '';
          if(document != undefined ){
            const extension = document['name'].split('.')[document['name'].split('.').length - 1];
            docType = this.getDocumentType(extension);
            document['type']=docType;
            documentURL = docType === 'Image' ? this.getThumbnailUrl(document['id']): this.getDocumentSrc(docType);
            return documentURL
          }
        }


        isImageTypeDocument(fileName: string) {
          let docType = 'Document';
          const fileExtension = fileName.split('.')[fileName.split('.').length - 1];
          return this.getDocumentType(fileExtension);
        }
}