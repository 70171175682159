<div>
  <app-breadcrumb
    fxFlex="250px"
    [title]="title"
    [subTitles]="subTitles"
  ></app-breadcrumb>
  <span fxFlex></span>
  <div fxLayoutAlign="center center" fxLayoutGap="5px">
    <button mat-raised-button color="primary" (click)="uploadAddress()">
      <mat-icon>add</mat-icon>
      <span>Upload Address</span>
    </button>
  </div>
</div>
<div
  class="grid"
  fxLayout="column"
  [class.full-screen-view-grid]="isFullScreenView"
>
  <div fxLayout="row" class="grid-header" fxLayoutAlign="center center">
    <strong fxFlex="20">
      <small class="primary-text-color">Tracking No</small>
    </strong>
    <strong fxFlex="20"
      ><small class="primary-text-color">Customer</small></strong
    >
    <strong fxFlex="20"
      ><small class="primary-text-color"
        >UPS Service / Description</small
      ></strong
    >
    <strong fxFlex="20"
      ><small class="primary-text-color">Package Type</small></strong
    >
    <strong fxFlex="10"
      ><small class="primary-text-color">Created</small></strong
    >
    <strong fxFlex="10"></strong>
  </div>
  <div
    *ngIf="shipments.length === 0"
    fxLayoutAlign="center"
    [class.full-screen-view-row]="isFullScreenView"
    style="height: calc(100vh - 328px)"
  >
    <h4 class="primary-text-color">No Shipments Available!</h4>
  </div>
  <div
    *ngIf="shipments.length > 0"
    [class.full-screen-view-row]="isFullScreenView"
    style="height: calc(100vh - 328px); overflow: auto;"
  >
    <ng-container *ngFor="let shipment of shipments">
      <div fxLayout="row" class="grid-row" fxLayoutAlign="center center">
        <small fxFlex="20" class="text-ellipsis primary-text-color">{{
          shipment["identificationNo"]
        }}</small>
        <small
          fxFlex="20"
          class="shipment-selection text-ellipsis primary-text-color"
          (click)="shipment['show'] = !shipment['show']"
          ><strong>{{ shipment["toAddress"]["customer"] }}</strong></small
        >
        <small fxFlex="20" class="text-ellipsis primary-text-color">{{
          shipment["upsService"]
        }}</small>
        <small fxFlex="20" class="text-ellipsis primary-text-color">{{
          shipment["packageType"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          shipment["createdDate"] | date
        }}</small>
        <span fxFlex="10" fxLayoutGap="5px" fxLayout="row">
          <mat-icon
            matTooltip="Download Labels"
            (click)="downloadLabels($event, shipment)"
            class="icon"
            >receipt</mat-icon
          >
          <mat-icon
            matTooltip="Rate Info"
            [matMenuTriggerFor]="menu"
            class="icon"
            >info</mat-icon
          >
          <mat-icon
            matTooltip="Track"
            (click)="tracking($event, shipment)"
            class="icon"
            >gps_fixed</mat-icon
          >
          <mat-icon
            matTooltip="Delete"
            (click)="delete($event, shipment['identificationNo'])"
            class="icon"
            >delete
          </mat-icon>
          <mat-menu #menu="matMenu">
            <div fxLayout="column" style="padding: 10px">
              <small fxLayout="row" fxLayoutGap="5px">
                <strong class="primary-text-color">Billing Weight:</strong>
                <span class="primary-text-color">{{
                  shipment["billingWeight"]
                }}</span>
              </small>
              <small fxLayout="row" fxLayoutGap="5px">
                <strong class="primary-text-color"
                  >Service Options Charges:</strong
                >
                <span class="primary-text-color">{{
                  shipment["serviceOptionsCharges"]
                }}</span>
              </small>
              <small fxLayout="row" fxLayoutGap="5px">
                <strong class="primary-text-color"
                  >Transportation Charges:</strong
                >
                <span class="primary-text-color">{{
                  shipment["transportationCharges"]
                }}</span>
              </small>
              <small fxLayout="row" fxLayoutGap="5px">
                <strong class="primary-text-color">Total Charges:</strong>
                <span class="primary-text-color">{{
                  shipment["totalCharges"]
                }}</span>
              </small>
            </div>
          </mat-menu>
        </span>
      </div>
      <ng-container
        *ngFor="let package of shipment.packages; let index = index"
      >
        <div
          *ngIf="shipment['show']"
          fxLayout="row"
          class="grid-header"
          fxLayoutAlign="center center"
        >
          <small fxFlex="20" class="text-ellipsis primary-text-color">{{
            package["trackingNumber"]
          }}</small>
          <small fxFlex="20" class="text-ellipsis primary-text-color"
            >Package {{ index + 1 }}</small
          >
          <small
            fxFlex="20"
            class="text-ellipsis primary-text-color"
            [matTooltip]="package['description']"
            >{{ package["description"] }}</small
          >
          <small fxFlex="20" class="text-ellipsis"></small>
          <small fxFlex="10" class="text-ellipsis"></small>
          <span fxFlex="10">
            <mat-icon
              matTooltip="Download Label"
              (click)="downloadLabel($event, package['trackingNumber'])"
              class="icon"
            >
              receipt</mat-icon
            >
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <mat-paginator
    fxFlex
    (page)="onPageChange($event)"
    [length]="paginator.total"
    [pageSize]="paginator.pageSize"
    [pageSizeOptions]="[50, 100, 200]"
  >
  </mat-paginator>
</div>
