import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { Job } from '../../interfaces/job';
import { MatDialog } from '@angular/material/dialog';
import { AuthUserService } from '../../services/auth-user.service';
import { ToastService } from '../../services/toast.service';
import { JobDetailsV2Component } from 'src/app/features/jobs/job-details-v2/job-details-v2.component';

@Component({
  selector: 'app-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss']
})
export class SearchTextComponent implements OnInit, AfterViewInit {

  hasFocus = false;
  text: string;
  disableInput: boolean = false;
  results: any;
  searchApiSubscription: Subscription = null;

  @Output() selected = new EventEmitter<Job>();

  @ViewChild('searchInput') movieSearchInput: ElementRef;

  constructor(private api: ApiService,
    private dialog: MatDialog,
    private authUser: AuthUserService,
    private toast: ToastService) { }

  ngAfterViewInit(): void {
    fromEvent(this.movieSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 1)
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchJob(text);
    });
  }

  ngOnInit() {
    this.enableOrDisableSearchInput();
  }

  private enableOrDisableSearchInput() {
    if (this.authUser.getUser().currentDepartment.departmentName.toLowerCase() === "administration" || this.authUser.getUser().currentDepartment.departmentName.toLowerCase() === "csr") {
      this.disableInput = false;
    } else {
      this.disableInput = true;
    }
  }

  private searchJob(text: string): void {
    var user = this.authUser.getUser();

    if (user.dataSourceId == null) {
      this.toast.show('You must select data soure.');
      return;
    }

    if (this.searchApiSubscription) {
      this.searchApiSubscription.unsubscribe();
    }

    const params = {
      params: {
        'q': text
      }
    };
    this.searchApiSubscription = this.api
      .request('SEARCH_JOBS', params)
      .subscribe(response => this.results = response);
  }

  onJobSelected(job: Job): void {

    if (job['isJobAdded']) {
      this.openJobDetailModel(job);
    } else {
      this.selected.emit(job);
    }
    this.results = null;
  }

  openJobDetailModel(job) {
    const input = {
      job: job,
      selectdoJobItem: 0,
      isTopNavSearch: true
    };
    const dialogRef = this.dialog.open(JobDetailsV2Component, {
      width: '98vw',
      maxWidth: '98vw',
      height: '95vh',
      maxHeight: '95vh',
      data: input,
    });

    dialogRef.afterClosed().subscribe(res => {
      this.toast.hide();
    });
  }

}
