<div class="padding-15" fxLayout="column" fxLayoutAlign="start center" style="height: 100%;">

    <div fxFill fxFlex="9" fxLayout="row" fxLayoutGap="10px">
        <!-- <mat-icon class="pointer" (click)="close()">arrow_back</mat-icon> -->
        <span fxFlex style="font-size: 18px" class="primary-text-color">
            Print Ryobi Jobs
        </span>
        <div class="search">
            <input #searchInput matInput type="text" placeholder="Search" [(ngModel)]="searchKey" />
            <mat-icon class="pointer" matSuffix *ngIf="searchKey" (click)="clearSearch()">close</mat-icon>
        </div>
    </div>

    <div fxFill fxLayout="column" fxFlex="89">
        <div style="height: 68vh; overflow: auto;">
            <div *ngIf="filterJobs.length === 0 || jobLoading" fxLayoutAlign="center">
                <h4 class="primary-text-color">
                    {{ jobLoading ? "Loading..." : "No Jobs Available!" }}
                </h4>
            </div>
            <div class="grid" fxLayout="column" *ngIf="filterJobs.length > 0">
                <ng-container *ngFor="let job of filterJobs">
                    <div class="grid-row" fxFlex fxLayoutGap="10px">
                        <!-- <mat-checkbox [(ngModel)]="job['isSelect']" color="primary"></mat-checkbox> -->
                        <span class="primary-text-color">{{ job.jobNumber }} - {{ job.customerName }}</span>
                    </div>
                    <div class="grid-item-row" fxFlex *ngFor="let item of job['items']" fxLayout="row">
                        <div fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="10px" fxFlex="70">
                            <mat-checkbox [disabled]="item['isDisabled']" [(ngModel)]="item['isSelect']"
                            (change)="onChangeCheckbox($event, item)"  color="primary"></mat-checkbox>
                            <span class="primary-text-color">{{ item.description }}</span>
                        </div>
                        <div fxLayoutAlign="end center" fxLayout="row" fxLayoutGap="10px" fxFlex="30">
                            <span class="primary-text-color">{{ item.currentStepName | titlecase }} /
                                {{ item.currentActionName | titlecase }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div fxFill fxFlex fxLayout="column" style="padding-top: 10px;">
        <div fxLayoutGap="10px">
            <div fxLayoutGap="10px" *ngIf="isShowConfirmation && !isLoading && isJobItemSelected">
                <small class="warn">
                    Do you want to print ryobi jobs?</small>
                <button mat-stroked-button color="primary" (click)="confirmPrintRyobiJobs()">
                    YES
                </button>
                <button mat-stroked-button color="primary" (click)="isShowConfirmation = false">
                    NO
                </button>
            </div>
            <span fxFlex></span>
            <div fxLayoutGap="10px" *ngIf="!isJobItemSelected && !isShowConfirmation && !isLoading">
                <small class="warn">
                  Select atleast one jobitem for print.</small
                >
            </div>
            <button mat-raised-button color="primary" [disabled]="!isJobItemSelected" (click)="printRyobiJobs()">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <span> <strong>Print </strong></span>
                    <span *ngIf="isLoading" class="loader"></span>
                </div>
            </button>
            <button mat-raised-button type="button" (click)="close()">CLOSE</button>
        </div>
    </div>
</div>