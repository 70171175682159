import { NgModule } from "@angular/core";
import { JobListComponent } from "./job-list/job-list.component";
import { SharedModule } from "src/app/shared/shared.module";
import { JobsRoutingModule } from "./jobs-routing.module";
import { JobComponent } from "./job/job.component";
import { JobImportComponent } from "./job-import/job-import.component";
import { NgxsModule } from "@ngxs/store";
import { JobState } from "./state/job.state";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { JobImportWorkflowComponent } from "./job-import/job-import-workflow/job-import-workflow.component";
import { JobTemplateCreateComponent } from "./job-import/job-template-create/job-template-create.component";
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { JobHistoryComponent } from "./job-details/job-history/job-history.component";
import { JobNoteAlertComponent } from "./job-details/job-note-alert/job-note-alert.component";
import { JobSendBackComponent } from "./job-details/job-send-back/job-send-back.component";
import { JobMailingSortCreateComponent } from "./job-mailing/job-mailing-sort-create/job-mailing-sort-create.component";
import { JobMailingInfoCreateComponent } from "./job-mailing/job-mailing-info-create/job-mailing-info-create.component";
import { JobMailingSortComponent } from "./job-mailing/job-mailing-sort/job-mailing-sort.component";
import { JobMailingInfoComponent } from "./job-mailing/job-mailing-info/job-mailing-info.component";
// import { ImageViewerModule } from "ngx-image-viewer";
import { ConfigurationState } from "../configuration/state/configuration.state";
import { JobGridComponent } from "./job-grid/job-grid.component";
import { JobGridRowComponent } from "./job-grid/job-grid-row/job-grid-row.component";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { JobListMoveComponent } from "./job-list/job-list-move/job-list-move.component";
import { ShipmentModule } from "../shipment/shipment.module";
import { JobDeleteConfirmDialogComponent } from "./job-delete-confirm-dialog/job-delete-confirm-dialog.component";
import { JobOrderImportComponent } from "./job-import/job-order-import/job-order-import.component";
import { JobTemplateLabelDialogComponent } from "./job-template-label-dialog/job-template-label-dialog.component";
import { PaymentLinkComponent } from "./job-details/payment-link/payment-link.component";
import { CreateInvoiceComponent } from "./job-details/create-invoice/create-invoice.component";
import { JobChatComponent } from "./job-details/job-chat/job-chat.component";
import { DocumentService } from "src/app/shared/services/document.service";
import { MarkAsPaidComponent } from "./job-details/mark-as-paid/mark-as-paid.component";
import { PrinterPlanJobItemChatComponent } from "./job-details/printer-plan-job-item-chat/printer-plan-job-item-chat.component";
import { PrinterPlanDocumentService } from "src/app/shared/services/printer-plan-document.service";
import { RetriveJobsComponent } from "./job-list/retrive-jobs/retrive-jobs.component";
import { UploadToSwitchComponent } from "./job-details/upload-to-switch/upload-to-switch.component";
import { JobDetailsV2Component } from "./job-details-v2/job-details-v2.component";
import { JobListRyobiPrintComponent } from './job-list/job-list-ryobi-print/job-list-ryobi-print.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { JobUpdateWithPPComponent } from "./job-details/job-update-with-pp/job-update-with-pp.component";

@NgModule({
  declarations: [
    JobListComponent,
    JobComponent,
    JobImportComponent,
    JobDetailsComponent,
    JobImportWorkflowComponent,
    JobTemplateCreateComponent,
    JobHistoryComponent,
    JobNoteAlertComponent,
    JobSendBackComponent,
    JobMailingSortComponent,
    JobMailingInfoComponent,
    JobMailingSortCreateComponent,
    JobMailingInfoCreateComponent,
    JobGridComponent,
    JobGridRowComponent,
    JobListMoveComponent,
    JobDeleteConfirmDialogComponent,
    JobOrderImportComponent,
    JobTemplateLabelDialogComponent,
    PaymentLinkComponent,
    CreateInvoiceComponent,
    JobChatComponent,
    MarkAsPaidComponent,
    PrinterPlanJobItemChatComponent,
    RetriveJobsComponent,
    UploadToSwitchComponent,
    JobDetailsV2Component,
    JobListRyobiPrintComponent,
    JobUpdateWithPPComponent
  ],
  imports: [
    SharedModule,
    JobsRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PdfJsViewerModule,
    ShipmentModule,
    // ImageViewerModule.forRoot(),
    NgxsModule.forFeature([JobState, ConfigurationState]),
  ],
  exports: [
    JobImportWorkflowComponent,
    JobTemplateLabelDialogComponent,
    PaymentLinkComponent,
  ],
  providers: [DocumentService, PrinterPlanDocumentService],
})
export class JobsModule {}
