import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { HTTPStatus } from 'src/app/shared/services/loading.interceptor';
import { FullScreenView } from './topnav/state/topnav.action';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  isLoading: boolean;
  isFullScreenView: boolean = false;
  constructor(private httpStatus: HTTPStatus,
    @Inject(DOCUMENT) private document: any,
    private actions$: Actions,
    private store: Store,) { }

  ngOnInit() {
    const self = this;
    this.document.addEventListener("fullscreenchange", function () {
      if (self.document.fullscreenElement === null) {
        self.store.dispatch(new FullScreenView(false));
        self.isFullScreenView = false;
      }
    });

    this.actions$.pipe(ofActionCompleted(FullScreenView)).subscribe(res => {
      this.isFullScreenView = res.action.isFullScreenView;
    });

    this.httpStatus
      .getHttpStatus()
      .subscribe((status: boolean) => {
        this.isLoading = status;
      });
  }
}


