<div>
  <!-- <div style="overflow: auto;"> -->
    <app-topnav></app-topnav>
    <div style="height:10px;" class="main-container-bg">
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="red"></mat-progress-bar>
    </div>
    <div  class="main-content " [class.full-screen-view]="isFullScreenView" >
      <router-outlet></router-outlet>
    </div>
  <!-- </div> -->
</div>