import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from './../../../../../shared/services/api.service';
import { Select, Store } from '@ngxs/store';
import { ConfigurationState } from 'src/app/features/configuration/state/configuration.state';
import { fromEvent, Observable } from 'rxjs';
import { Department } from 'src/app/shared/interfaces/department';
import { PasswordConfirm } from './PasswordConfirm';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  @Select(ConfigurationState.AllDepartments) departments$: Observable<Department[]>;
  userForm: UntypedFormGroup;
  title = 'Create User';
  isShowPassword = false;
  isShowConfPassword = false;
  loading = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  CSRSearchInputCtrl = new UntypedFormControl();
  filteredCSRs: string[];
  selectedCSRs = [];

  searchedCSRNAme = "";
  csrPageNumber = 0;
  csrPageSize = 10;
  isCSRChangeListenerAttached = false;
  isCSRScrollPanelAttached = false;
  totalCSRNames = 0;
  @ViewChild('csrSearchInput') csrSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('csrautoComplete') csrAutocomplete: MatAutocomplete;

  constructor(private dialig: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserCreateComponent>,
    private apiService: ApiService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public user: any,
    private toast: ToastService,
    private authUser: AuthUserService,) { }

  ngOnInit() {

    this.initPage();
  }

  initPage = () => {
    this.userForm = this.initForm(this.formBuilder);
    this.userForm.get('departmentId').setValidators([Validators.required]);
    this.userForm.get('departmentId').updateValueAndValidity();


    if (this.user) {
      let departmentIds = this.user.departments.filter(d => d.name.toLowerCase() != "administration" && d.name.toLowerCase() != "csr");
      this.user['departmentId'] = departmentIds;
      this.title = 'Edit User';
      this.userForm.patchValue(this.user);

      this.userForm.controls.password.disable();
      this.userForm.controls.confirmPassword.disable();

      // set user selected department.
      let isAdmin = this.user.departments.find(dept => dept.name == 'Administration') ? true : false
      let isCsr = this.user.departments.find(dept => dept.name == 'Csr') ? true : false



      if (isAdmin || isCsr) {
        this.userForm.controls.admin.patchValue(isAdmin);
        this.userForm.controls.csr.patchValue(isCsr);
        this.userForm.controls.isAllowImportPrinterPlanJob.disable();
        this.userForm.get('departmentId').disable();
        this.userForm.get('departmentId').setValidators(null);
        this.userForm.get('departmentId').updateValueAndValidity();
      } else {
        this.userForm.get('departmentId').setValidators([Validators.required]);
        this.userForm.get('departmentId').updateValueAndValidity();
        this.userForm.controls['departmentId'].setValue(this.user.departments.map(dept => { return dept['departmentId']; }
        ));
      }


      if (isCsr) {
        if (this.user.csrName) {
          this.selectedCSRs.push(this.user.csrName);
          this.userForm.controls.csrname.setValue(this.user.csrName);
        }
      }
    }
    this.getCSRNames();
  }



  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      departmentId: [''],
      password: ['', [Validators.required, Validators.minLength]],
      confirmPassword: ['', [Validators.required, Validators.minLength]],
      admin: [false],
      csr: [false],
      csrname: [''],
      isAllowImportPrinterPlanJob: [false]
    },
      { validator: PasswordConfirm.MatchPassword })

  hasError = (field: string, errorName: string): boolean =>
    this.userForm.get(field).errors ? this.userForm.get(field).errors[errorName] : false




  onSubmit = () => {
    // validate form
    if (this.userForm.invalid || this.loading) {
      return;
    }
    // create request object
    let requestObject = this.userForm.getRawValue();
    delete requestObject['confirmPassword'];

    if (!this.user) {
      this.createUser(requestObject);
      return;
    }
    this.editUser(requestObject);
  }

  onAdminCsrProperty = () => {
    if (this.userForm.controls.admin.value || this.userForm.controls.csr.value) {
      this.userForm.controls.isAllowImportPrinterPlanJob.patchValue(true);
      this.userForm.controls.isAllowImportPrinterPlanJob.disable();
      this.userForm.controls.departmentId.clearValidators();
    } else {
      this.userForm.controls.departmentId.setValidators(Validators.required);
      this.userForm.controls.isAllowImportPrinterPlanJob.enable();
    }

    this.userForm.controls.departmentId.updateValueAndValidity();

    if (this.userForm.controls.csr.value) {
      this.isCSRChangeListenerAttached = false;
      this.isCSRScrollPanelAttached = false;
    }
  }

  createUser = (requestObject) => {
    this.loading = true;
    this.apiService.request('CREATE_USER', { data: requestObject }).subscribe((res: any) => {
      if (res['success']) {
        this.dialogRef.close(true);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false });
  }

  /**
   * Edit user detail.
   */
  editUser = (requestObject) => {
    const apiRequest = {
      params: { USER_ID: this.user.userId },
      data: requestObject
    };

    this.loading = true;

    // add user id in user object.
    apiRequest.data['userId'] = this.user.userId;

    this.apiService.request('EDIT_USER', apiRequest).subscribe((res: any) => {
      if (res['success']) {
        this.dialogRef.close(true);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false; });
  }



  /**CSR Input DD */
  removeAll(): void {
    this.userForm.controls.csrname.setValue(null);
    this.selectedCSRs = [];
  }

  showAll() {
    this.CSRSearchInputCtrl.setValue(this.csrSearchInput.nativeElement.value);
  }

  attachCSRInputChangeListener() {
    if (this.isCSRChangeListenerAttached) { return; }
    fromEvent(this.csrSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      var tempSearchedCSRName = text.trim();
      if (tempSearchedCSRName == this.searchedCSRNAme) { return; }
      this.searchedCSRNAme = text.trim();
      this.CSRSearchInputCtrl.setValue(this.searchedCSRNAme);
      this.csrPageNumber = 0;
      this.getCSRNames();
    });

    this.isCSRChangeListenerAttached = !this.isCSRChangeListenerAttached;
  }

  selectCSR(event: MatAutocompleteSelectedEvent): void {
    let value = event.option.value;
    var i = this.selectedCSRs.findIndex(o => o == value);
    if (i < 0) {
      this.selectedCSRs = [];
      this.selectedCSRs.push(value);
      this.userForm.controls.csrname.setValue(value);
    }
    this.csrSearchInput.nativeElement.value = '';
    this.CSRSearchInputCtrl.setValue(null);
    this.csrPageNumber = 0;
    this.searchedCSRNAme = '';
    this.getCSRNames();
    this.csrSearchInput.nativeElement.blur();
  }

  registerPanelScrollEvent() {
    if (this.csrAutocomplete.panel != undefined) {
      const panel = this.csrAutocomplete.panel.nativeElement;
      panel.addEventListener('scroll', event => this.loadNextBatchOfCSRsOnScroll(event));
    }
  }

  AttachListeners() {
    this.attachCSRInputChangeListener();
    this.registerPanelScrollEvent();
  }

  loadNextBatchOfCSRsOnScroll(event) {
    var i = this.totalCSRNames / this.csrPageSize;
    i = Math.ceil(i);
    if (this.csrPageNumber < i) {
      if (Math.ceil(event.target.scrollTop + event.target.offsetHeight) == event.target.scrollHeight) {
        this.csrPageNumber = this.csrPageNumber + 1;
        this.getCSRNames();
      }
    }
  }

  getCSRNames(): void {

    var user = this.authUser.getUser();

    if (user.dataSourceId == null) {
      this.toast.show('You must select data soure.');
      return;
    }

    const params = {
      params: {
        'PAGE_NO': this.csrPageNumber,
        'PAGE_SIZE': this.csrPageSize,
        'SEARCHVALUE': this.searchedCSRNAme
      }
    };
    this.apiService
      .request('GET_CSR_NAMES', params)
      .subscribe(response => {
        this.totalCSRNames = response['total'];
        if (this.csrPageNumber == 0) {
          this.filteredCSRs = response['result'];
        }
        else {
          this.filteredCSRs = this.filteredCSRs.concat(response['result']);
        }
      });
  }
}
