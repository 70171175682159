<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <strong class="header-text primary-text-color">{{ title }}</strong>

  <form
    [formGroup]="form"
    autocomplete="off"
    fxLayout="column"
    fxLayoutGap="4px"
  >
    <mat-form-field appearance="outline" fxFlex="100">
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        autofocus="true"
        autocomplete="none"
        placeholder="Name"
        formControlName="name"
        required
      />
      <mat-error *ngIf="hasError('name', 'required')"
        >You must enter name.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="100">
      <mat-label>Department</mat-label>
      <mat-select
        placeholder="Select Department"
        formControlName="department"
        required
      >
        <ng-container *ngFor="let dprmnt of departments$ | async">
          <mat-option
            *ngIf="dprmnt.status && dprmnt.name != 'Administrator'"
            [value]="dprmnt.departmentId"
          >
            {{ dprmnt.name | titlecase }}</mat-option
          >
        </ng-container>
      </mat-select>
      <mat-error *ngIf="hasError('department', 'required')"
        >You must select department.</mat-error
      >
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field appearance="outline" fxFlex="85">
        <mat-label>Action Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Name"
          formControlName="action"
        />
      </mat-form-field>
      <div fxLayout="column">
        <button
          class="mt-0"
          color="primary"
          mat-raised-button
          fxFlexOffset="5px"
          fxFlex="55px"
          (click)="addAction()"
        >
          <div fxFlex="row" fxLayout="center center">
            <mat-icon>add</mat-icon>
          </div>
        </button>
      </div>
    </div>
    <span class="validation-error" *ngIf="!stepActionValidate"
      >You must add action.</span
    >
    <!-- Display all created actions. -->
    <div>
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let action of actions"
          ><strong>{{ action.actionName }}</strong>
          <mat-icon matChipRemove (click)="onRemoveClick(action)"
            >cancel</mat-icon
          >
        </mat-chip-option>
      </mat-chip-listbox>
      <br />
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <strong class="primary-text-color">Add More Info</strong>
      <mat-slide-toggle
        color="primary"
        [checked]="additionalInfo"
        (change)="additionalInfo = !additionalInfo"
      ></mat-slide-toggle>
    </div>

    <mat-form-field *ngIf="additionalInfo" appearance="outline" fxFlex="100">
      <mat-label>Select Department</mat-label>
      <mat-select
        placeholder="Select Department"
        formControlName="additionalInfo"
      >
        <mat-option value="mailing">Mailing</mat-option>
        <mat-option value="shiping">Shiping</mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
      <button
        mat-raised-button
        fxFlex="100px"
        color="primary"
        type="submit"
        (click)="onSubmitClick()"
      >
        SUBMIT
      </button>
      <button mat-raised-button type="button" (click)="onCloseClick()">
        CLOSE
      </button>
    </div>
  </form>
</div>
