<div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
  <!-- <button mat-icon-button>
    <mat-icon (click)="onClose()">arrow_back</mat-icon>
  </button> -->
  <strong fxFlex style="font-size:18px">
    {{stepAction.stepName|titlecase}} / {{stepAction.actionName|titlecase}} - Mailing Info

  </strong>
  <button mat-icon-button>
    <mat-icon color="primary" (click)="onSubmit()">edit</mat-icon>
  </button>
</div>
<br>

<div fxLayout="column" fxLayoutGap="15px" style="height: 400px;">
  <div style="overflow: auto;">
    <div fxLayout="column" fxLayoutGap="15px">
      <div fxflex>
        <div fxFlex fxLayoutGap="5px" class="header-primary" > <strong>Folding</strong> </div>
      </div>
      <div fxLayout="row wrap">

        <div fxFlex="25" fxLayoutGap="5px" fxLayoutAlign="start center"><span>Letter:</span>
          <mat-icon *ngIf="mailingInfo.foldingLetter" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.foldingLetter" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Half:</span>
          <mat-icon *ngIf="mailingInfo.half" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.half" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>R/A:</span>
          <mat-icon *ngIf="mailingInfo.ra" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.ra" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Spiral:</span>
          <mat-icon *ngIf="mailingInfo.spiral" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.spiral" color="warn">clear</mat-icon>
        </div>
      </div>

      <div fxLayot="row">
        <div fxFlex="25" fxLayoutGap="5px"><span>Quantity:</span> <b>{{mailingInfo.foldingQuantity}}</b></div>
      </div>

      <mat-divider></mat-divider>
      <div fxflex>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Labelling</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Cheshire:</span>
          <mat-icon *ngIf="mailingInfo.cheshire" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.cheshire" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Piggyback:</span>
          <mat-icon *ngIf="mailingInfo.piggyback" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.piggyback" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Xerox:</span>
          <mat-icon *ngIf="mailingInfo.xerox" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.xerox" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Ink Jet:</span>
          <mat-icon *ngIf="mailingInfo.inkjet" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.inkjet" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Laser:</span>
          <mat-icon *ngIf="mailingInfo.laser" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.laser" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>S/A:</span>
          <mat-icon *ngIf="mailingInfo.sa" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.sa" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutGap="5px"><span>Quantity:</span> <b>{{mailingInfo.labellingQuantity}}</b></div>

      </div>

      <mat-divider></mat-divider>

      <div>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Labels Per Sheet</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>1UP:</span>
          <mat-icon *ngIf="mailingInfo.oneUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.oneUp" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>2UP:</span>
          <mat-icon *ngIf="mailingInfo.twoUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.twoUp" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>3Up:</span>
          <mat-icon *ngIf="mailingInfo.threeUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.threeUp" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>4Up:</span>
          <mat-icon *ngIf="mailingInfo.fourUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.fourUp" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>5Up:</span>
          <mat-icon *ngIf="mailingInfo.fiveUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.fiveUp" color="warn">clear</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Tabbing</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Machine:</span>
          <mat-icon *ngIf="mailingInfo.tabbingMachine" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.tabbingMachine" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Hand:</span>
          <mat-icon *ngIf="mailingInfo.tabbingHand" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.tabbingHand" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Special:</span>
          <mat-icon *ngIf="mailingInfo.special" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.special" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Clear:</span>
          <mat-icon *ngIf="mailingInfo.clear" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.clear" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>White:</span>
          <mat-icon *ngIf="mailingInfo.white" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.wWhite" color="warn">clear</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div fxflex>
        <div fxFlex fxLayoutGap="5px" class="header-primary"><strong>Inserting</strong></div>
      </div>
      <div fxLayout="row wrap">

        <div fxFlex="25" fxLayoutGap="5px" fxLayoutAlign="start center"><span>Machine:</span>
          <mat-icon *ngIf="mailingInfo.insertingMachine" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.insertingMachine" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Hand:</span>
          <mat-icon *ngIf="mailingInfo.insertingHand" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.insertingHand" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutGap="5px"><span>Env Size:</span> <b>{{mailingInfo.envSize}}</b></div>

        <div fxFlex="25" fxLayoutGap="5px"><span>No of Insters:</span> <b>{{mailingInfo.noOfInsert}}</b></div>
      </div>

      <mat-divider></mat-divider>

      <div>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Mail Classification</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>1st:</span>
          <mat-icon *ngIf="mailingInfo.first" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.first" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>1st P.S:</span>
          <mat-icon *ngIf="mailingInfo.firstPS" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.firstPS" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Periodicals:</span>
          <mat-icon *ngIf="mailingInfo.periodicals" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.periodicals" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Standart:</span>
          <mat-icon *ngIf="mailingInfo.standart" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.standart" color="warn">clear</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>


      <div>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Size & Shape</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Letter:</span>
          <mat-icon *ngIf="mailingInfo.sizeLetter" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.sizeLetter" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Post Cards:</span>
          <mat-icon *ngIf="mailingInfo.postCards" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.postCards" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Flat:</span>
          <mat-icon *ngIf="mailingInfo.flat" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.flat" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Meter:</span>
          <mat-icon *ngIf="mailingInfo.meter" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.meter" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Stamps:</span>
          <mat-icon *ngIf="mailingInfo.stamps" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.stamps" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Auto:</span>
          <mat-icon *ngIf="mailingInfo.auto" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.auto" color="warn">clear</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div>
        <div fxFlex="25" fxLayoutGap="5px" class="header-primary"><strong>Surplus Materials</strong></div>
      </div>
      <div fxLayot="row">
        <div fxFlex="25" fxLayoutAlign="start center"><span>Client To Pick Up:</span>
          <mat-icon *ngIf="mailingInfo.clientToPickUp" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.clientToPickUp" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Dump:</span>
          <mat-icon *ngIf="mailingInfo.dump" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.dump" color="warn">clear</mat-icon>
        </div>
        <div fxFlex="25" fxLayoutAlign="start center"><span>Inventory:</span>
          <mat-icon *ngIf="mailingInfo.inventory" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingInfo.inventory" color="warn">clear</mat-icon>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxflex>
        <div fxFlex fxLayoutGap="5px" class="header-primary"><strong>Scheduling Information</strong></div>
      </div>
      <div fxLayout="row wrap">

        <div fxFlex="30" fxLayoutGap="5px"><span>1st Due:</span> <b>{{mailingInfo.firstDueDate | date}}</b></div>

        <div fxFlex="35" fxLayoutGap="5px"><span>Labelling Material Due:</span>
          <b>{{mailingInfo.labellingMaterialDueDate | date}}</b></div>

        <div fxFlex="35" fxLayoutGap="5px"><span>Insert Material Due:</span>
          <b>{{mailingInfo.insertMaterialDueDate | date}}</b></div>
      </div>

    </div>
  </div>

  <mat-dialog-actions fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close>CLOSE</button>
  </mat-dialog-actions>
</div>