<div class="padding-15" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row">
    <!-- <mat-icon (click)="onClose()" class="cursor-pointer">arrow_back</mat-icon> -->
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Create Steps
    </span>
    <!-- <mat-icon (click)="onClose()" class="cursor-pointer">close</mat-icon> -->
  </div>

  <div fxLayout="column">
    <mat-form-field class="remove-field-wrapper">
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let jobItem of selectedJobItems"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(jobItem)"
        >
          <strong>{{ jobItem.description }}</strong>
          <mat-icon matChipRemove *ngIf="removable" class="cursor-pointer"
            >cancel</mat-icon
          >
        </mat-chip-row>
        <input
          placeholder="Job Items"
          #jobItemInput
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        <mat-option
          *ngFor="let item of filteredJobItems | async"
          [value]="item"
        >
          {{ item.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div fxLayout="column">
      <div *ngIf="isRush && !rushDate" fxFillFlex fxLayoutAlign="end center">
        <small class="warn"><b>You must select rush date time</b></small>
      </div>
      <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="remove-field-wrapper field-wrapper-50h">
          <mat-form-field appearance="fill">
            <mat-label>Template</mat-label>
            <mat-select (selectionChange)="templateSelection($event)">
              <mat-option *ngFor="let template of templates" [value]="template">
                {{ template.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <form
          [formGroup]="assignedCsrForm"
          *ngIf="!isPrinterPlanJob"
          class="remove-field-wrapper field-wrapper-50h"
        >
          <mat-form-field
            appearance="fill"
            (click)="registerPanelScrollEventForAdminCsrField()"
          >
            <mat-label>Assigned CSR</mat-label>
            <input
              type="text"
              placeholder="Assigned CSR"
              matInput
              [matAutocomplete]="assignedCsrMatAutocomplete"
              formControlName="assignedCsrFilterControl"
              #assignedCsrSearchInput
            />
          </mat-form-field>
          <mat-autocomplete
            #assignedCsrMatAutocomplete="matAutocomplete"
            #assignedCsrSelect
            (optionSelected)="selectAssignedCsr($event)"
          >
            <mat-option *ngFor="let user of assignedCsrs" [value]="user">
              {{ user.firstName + " " + user.lastName }}
            </mat-option>
            <mat-option *ngIf="assignedCsrs?.length === 0" [value]="null">
              <span>No Records Found!</span>
            </mat-option>
          </mat-autocomplete>
        </form>

        <button (click)="onAddStep()" mat-stroked-button color="primary">
          <mat-icon>add</mat-icon> <span class="primary-text-color">ADD</span>
        </button>

        <button mat-stroked-button color="primary" (click)="onSaveTemplate()">
          <mat-icon>save</mat-icon> <span class="primary-text-color">Save As Templete</span>
        </button>

        <mat-checkbox
          color="primary"
          [(ngModel)]="allNotification"
          (change)="onAllNotificationClick()"
          >Notify All
        </mat-checkbox>

        <mat-checkbox color="primary" [(ngModel)]="isNotifyDeptUsers"
          >Notify Dept.
        </mat-checkbox>

        <mat-form-field
          class="remove-field-wrapper field-wrapper-50h"
          *ngIf="isNotifyDeptUsers"
        >
          <mat-select
            [formControl]="selectedDepartments"
            multiple
            placeholder="SELECT DEPT"
          >
            <mat-select-trigger>
              {{ getSelectDepartmentsText() }}
            </mat-select-trigger>
            <mat-option
              [value]="allDepartment"
              (click)="toggleDepartmentSelectAllOption()"
              >All</mat-option
            >
            <mat-option
              *ngFor="let department of departments"
              [value]="department"
              (click)="toggleDepartmentSelectPerOne()"
              >{{ department.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div fxFlex></div>
        <mat-checkbox
          color="primary"
          [(ngModel)]="isRush"
          class="primary-text-color"
          >Rush</mat-checkbox
        >

        <mat-form-field class="remove-field-wrapper field-wrapper-50h">
          <input
            matInput
            [disabled]="!isRush"
            readonly
            [min]="minDate"
            [(ngModel)]="rushDate"
            [owlDateTime]="dt2"
            placeholder="Rush date time"
          />
          <mat-icon
            matSuffix
            *ngIf="isRush"
            color="primary"
            [owlDateTimeTrigger]="dt2"
            >date_range</mat-icon
          >
          <mat-icon matSuffix *ngIf="!isRush">date_range</mat-icon>
          <owl-date-time [hour12Timer]="true" #dt2></owl-date-time>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px" fxFlexOffset="4px">
      <div fxFlex="60" fxLayout="column">
        <div
          cdkDropList
          class="example-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            fxLayout="row"
            *ngIf="isAddStep"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <mat-form-field>
              <mat-label>Step</mat-label>
              <mat-select [(ngModel)]="newStep">
                <mat-option *ngFor="let step of steps" [value]="step">
                  {{ step.stepTitle }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Action</mat-label>
              <mat-select [(ngModel)]="newStepAction">
                <mat-option
                  *ngFor="let action of newStep?.stepActions"
                  [value]="action"
                >
                  {{ action.actionName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div fxLayoutGap="10px">
              <button (click)="onSaveStep()" mat-stroked-button color="primary">
                <mat-icon>save</mat-icon>
              </button>

              <button
                (click)="isAddStep = false"
                mat-stroked-button
                color="primary"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>

          <!-- cdkDropListDropped)="drop($event)"> cdkDrag -->
          <div
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            style="padding-top: 5px; padding-bottom: 5px"
          >
            <div
              cdkDrag
              [cdkDragDisabled]="
                jobItemRequiredApproveArtwork && (index == 0 || index == 1)
              "
              class="example-box"
              [class.selected-step]="selectedStep?.id === seleStepAction?.id"
              *ngFor="let seleStepAction of selectedSteps; let index = index"
              (click)="onSelectStep(seleStepAction)"
            >
              <div
                fxFlex
                fxLayoutAlign="space-between center"
                fxLayoutGap="20px"
              >
                <mat-form-field fxFlex appearance="fill">
                  <mat-label>Step</mat-label>
                  <mat-select
                    [(ngModel)]="seleStepAction.step"
                    (click)="$event.stopPropagation()"
                    (selectionChange)="seleStepAction['action'] = null"
                  >
                    <mat-option
                      *ngFor="let step of seleStepAction.steps"
                      [value]="step"
                    >
                      {{ step.stepTitle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex appearance="fill">
                  <mat-label>Action</mat-label>
                  <mat-select
                    [(ngModel)]="seleStepAction.action"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-option
                      *ngFor="let action of seleStepAction?.step?.stepActions"
                      [value]="action"
                    >
                      {{ action.actionName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <span
                  fxFlex="50px"
                  fxLayoutAlign="end center"
                  fxLayoutGap="5px"
                >
                  <mat-icon
                    *ngIf="seleStepAction.step?.additionalInfo !== null"
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    >add</mat-icon
                  >
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="onClickMailingSort(seleStepAction)"
                    >
                      Mailing Sort
                    </button>
                    <button
                      mat-menu-item
                      (click)="onClickMailingInfo(seleStepAction)"
                    >
                      Mailing Info
                    </button>
                  </mat-menu>
                  <mat-icon
                    (click)="onRemoveStep(index)"
                    matTooltip="Delete Step/Action"
                    class="cursor-pointer"
                    >delete</mat-icon
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex fxLayout="column" fxLayoutGap="3px">
        <!-- title -->
        <small *ngIf="selectedStep" class="primary-text-color"
          >Note/File for: {{ selectedStep.step?.stepTitle }}/{{
            selectedStep.action?.actionName
          }}</small
        >
        <small *ngIf="!selectedStep" class="primary-text-color"
          >Note/File for: Job items</small
        >

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-checkbox
            *ngIf="selectedStep"
            color="primary"
            [(ngModel)]="selectedStep.notification"
            (change)="onNotificationClick()"
            >Notify
          </mat-checkbox>
          <div fxFlex></div>
          <div fxFlex="45" fxLayoutAlign="center center" fxLayoutGap="5px">
            <small class="primary-text-color">Priority: </small>
            <mat-select
              #notePriority
              class="select-step"
              (valueChange)="setNotePriority()"
            >
              <mat-option value="high">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="high"></span> <span>High (Alert)</span>
                </div>
              </mat-option>
              <mat-option value="medium">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="medium"></span> <span>Medium</span>
                </div>
              </mat-option>
              <mat-option value="low" selected>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="low"></span> <span>Low</span>
                </div>
              </mat-option>
            </mat-select>
          </div>
        </div>

        <mat-form-field appearance="outline">
          <textarea
            matInput
            rows="4"
            #note
            placeholder="Leave a Note"
            (change)="addNote()"
            class="preserve-spaces"
          ></textarea>
        </mat-form-field>

        <div
          class="drag-drop"
          fxlayout="column"
          fxLayoutAlign="center center"
          (click)="fileInput.click()"
          pacificPrintingDragDrop
          (fileDropped)="uploadFile($event)"
        >
          <div id="myDrop">
            <div>
              <small class="primary-text-color"
                >Click/Drag files here to upload</small
              >
            </div>
            <input
              hidden
              type="file"
              #fileInput
              (change)="uploadFile($event.target.files)"
              multiple
            />
          </div>
        </div>

        <!-- files of job -->
        <div *ngIf="jobItemFiles.size > 0">
          <ng-template
            *ngTemplateOutlet="filesTemplate; context: { files: jobItemFiles }"
          >
          </ng-template>
        </div>

        <!-- union bug section -->
        <div fxLayout="column" fxFlexOffset="16px" fxLayoutGap="4px">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <strong class="primary-text-color"
              >Is Union Bug Placement Required?
            </strong>
            <mat-radio-group
              fxFlexOffset="4px"
              #radioGroup="matRadioGroup"
              [(ngModel)]="unionBug"
              fxLayout="row"
              fxLayoutGap="10px"
              (change)="onUnionBugChange($event)"
            >
              <mat-radio-button color="primary" value="true"
                >Yes</mat-radio-button
              >
              <mat-radio-button color="primary" value="false"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <small *ngIf="!departmentValidate" class="warn">{{
            departmentValidateErrorMessage
          }}</small>
          <small *ngIf="isErrorForAssignedCsr" class="warn">{{
            errorMsgForAssignedCsr
          }}</small>
          <small *ngIf="unionBugValidate" class="warn">
            You must select Union Bug Placement</small
          >
          <small *ngIf="!jobItemsValidate" class="warn">
            You must select job items.
          </small>
          <small *ngIf="jobItemsValidate && !stepsValidate" class="warn">
            You must select valid steps for job items.
          </small>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxFlexOffset="10px"
    fxLayoutAlign="end center"
  >
    <button
      fxFlex="100px"
      mat-raised-button
      (click)="onAssign()"
      color="primary"
    >
      {{ isEdit ? "UPDATE" : "ASSIGN" }}
    </button>
    <button mat-raised-button type="button" (click)="onClose()">CANCEL</button>
  </div>
</div>

<ng-template #filesTemplate let-files="files">
  <div class="job-files" fxLayout="column">
    <div *ngFor="let file of files">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex>
        <img [src]="file.url" />
        <small fxFlex class="primary-text-color">{{ file.file.name }} </small>
        <div fxLayoutAlign="center center">
          <span (click)="onRemoveFile(file)" style="margin-top: 5px">
            <mat-icon matTooltip="Delete File">delete</mat-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
