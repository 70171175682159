import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionStepsCreateComponent } from './action-steps-create/action-steps-create.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Store } from '@ngxs/store';
import { Step } from 'src/app/shared/interfaces/jobItem';
import { LoadSteps } from '../../state/configuration.actions';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-action-steps',
  templateUrl: './action-steps.component.html',
  styleUrls: ['./action-steps.component.scss']
})
export class ActionStepsComponent implements OnInit {


  subTitles = [
    { text: 'Configuration', 'route': '' },
    { text: 'Steps', 'route': '' }
  ];
  //@Select(ConfigurationState.Steps) steps$: Observable< Step[]>;
  steps: Array<Step>;
  displayedColumns: string[] = ['position', 'title', 'department', 'stepActions', 'addtionalInfo', 'action'];
  dataSource = [];
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;
  loading = false;

  constructor(private dialog: MatDialog,
    private apiService: ApiService,
    private store: Store,
    private toast: ToastService) { }

  ngOnInit() {
    this.loadSteps();
  }

  /**
   * load the steps.
   */
  loadSteps() {
    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize
    };

    this.apiService.request('GET_STEPS', { params: params }).subscribe(res => {
      this.totalRecord = res['totalRecord'];
      this.steps = res['steps'];
      this.dataSource = this.steps;
    });
  }

  /**
   * Open step created model.
   */
  onCreateStepClick() {
    //show create new  step popup
    const dialogRef = this.dialog.open(ActionStepsCreateComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.loadSteps();
      this.store.dispatch(new LoadSteps());
    });
  }

  /**
   * open edit user dialog.
   */
  onEditClick = (step) => {
    const dialogRef = this.dialog.open(ActionStepsCreateComponent, {
      width: '500px',
      data: step
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.loadSteps();
      this.store.dispatch(new LoadSteps());
    });
  }

  /**
   * on delete click.
   */
  onDeleteClick = (stepId: string) => {

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { type: 'Confirm', message: 'Are you sure you want to delete this step ?' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.apiService.request('DELETE_STEP', { params: { STEP_ID: stepId } }).subscribe(res => {
          if (res['success']) {
            this.loadSteps();
          }
          this.toast.show(res['message']);
        });
      }
    });

  }

  /**
   * on page change
   */
  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.loadSteps();
  }
}
