<div
  fxLayout="column"
  style="width: 100%; height: 100%"
  class="padding-10"
  fxLayoutGap="5px"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    fxFlex="30px"
  >
    <!-- <mat-icon class="pointer" type="button" (click)="closeDialog()"
      >arrow_back</mat-icon
    > -->
    <Strong class="primary-text-color">Template Label</Strong>
  </div>

  <div style="overflow: auto;"
    fxFlex
    fxLayout="column"
  >
    <form
      fxLayout="column"
      [formGroup]="templateForm"
      autocomplete="off"
      auto
      style="width: 98%; padding-top: 10px;"
      fxLayoutGap="10px"
    >
      <!-- Customer Name Field -->
      <mat-form-field
        class="margin-top-5"
        appearance="outline"
        (click)="registerPanelScrollEventForCustomerNameField()"
      >
        <mat-label>Customer Name</mat-label>
        <input
          type="text"
          placeholder="SEARCH CUSTOMER NAME"
          matInput
          [matAutocomplete]="customerNamesMatAutocomplete"
          formControlName="customerNameFilterControl"
          #customerNameSearchInput
          required
        />
        <mat-error *ngIf="hasError('customerNameFilterControl', 'required')"
          >Please Select Customer Name .</mat-error
        >
      </mat-form-field>
      <mat-autocomplete
        #customerNamesMatAutocomplete="matAutocomplete"
        #customerNamesSelect
        (optionSelected)="selectCustomerName($event)"
      >
        <mat-option *ngFor="let name of customerNames" [value]="name">
          <div>
            {{ name }}
          </div>
        </mat-option>
        <mat-option
          *ngIf="customerNames?.length === 0"
          (click)="removeCustomerName()"
        >
          <span>No Records Found!</span>
        </mat-option>
      </mat-autocomplete>

      <!-- Job No Field-->
      <mat-form-field
        appearance="outline"
        (click)="registerPanelScrollEventForJobNoField()"
      >
        <mat-label>Job No</mat-label>
        <input
          type="number"
          placeholder="SEARCH JOB NO"
          matInput
          [matAutocomplete]="jobNoMatAutocomplete"
          formControlName="jobNoFilterControl"
          #jobNoSearchInput
          required
        />
        <mat-error *ngIf="hasError('jobNoFilterControl', 'required')"
          >Please Select Job No .</mat-error
        >
      </mat-form-field>
      <mat-autocomplete
        #jobNoMatAutocomplete="matAutocomplete"
        #jobNoSelect
        (optionSelected)="selectJobNo($event)"
      >
        <mat-option *ngFor="let job of jobs" [value]="job.jobNumber">
          <div>
            {{ job.jobNumber }}
          </div>
        </mat-option>
        <mat-option *ngIf="jobs?.length === 0" (click)="removeJobNo()">
          <span>No Records Found!</span>
        </mat-option>
      </mat-autocomplete>

      <!-- Item Names Field -->
      <mat-form-field appearance="outline">
        <mat-label>Item Name</mat-label>
        <input
          type="text"
          placeholder="SEARCH ITEM NAME"
          matInput
          [matAutocomplete]="itemNameMatAutocomplete"
          formControlName="itemNameFilterControl"
          #itemNameSearchInput
          required
        />
        <mat-error *ngIf="hasError('itemNameFilterControl', 'required')"
          >Please Select Item Name .</mat-error
        >
      </mat-form-field>
      <mat-autocomplete
        #itemNameMatAutocomplete="matAutocomplete"
        #itemNameSelect
        (optionSelected)="selectItemName($event)"
      >
        <mat-option *ngFor="let name of filteredItemNames" [value]="name">
          <div>
            {{ name }}
          </div>
        </mat-option>
        <mat-option
          *ngIf="filteredItemNames?.length === 0"
          (click)="removeItemName()"
        >
          <span>No Records Found!</span>
        </mat-option>
      </mat-autocomplete>

      <mat-form-field appearance="outline">
        <mat-label>Quantity Per Box</mat-label>
        <input
          matInput
          type="number"
          formControlName="quantityPerBox"
          required
          class="primary-text-color"
        />
        <mat-error *ngIf="hasError('quantityPerBox', 'required')"
          >Please enter Quantity Per Box.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Number Of Boxes</mat-label>
        <input
          matInput
          type="number"
          formControlName="quantityOfBox"
          required
          (change)="updateBoxDividedByTotalBox()"
        />
        <mat-error *ngIf="hasError('quantityOfBox', 'required')"
          >Please enter Quantity Of Box.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Select Operator</mat-label>
        <input
          matInput
          type="number"
          formControlName="operator"
          (change)="updateBoxDividedByTotalBox()"
        />
        <mat-error *ngIf="hasError('operator', 'min')"
          >Minimum value allowed is {{ operatorMinValue }}.</mat-error
        >
        <mat-error *ngIf="hasError('operator', 'max')"
          >Maximum value allowed is {{ operatorMaxValue }}.</mat-error
        >
        <mat-error *ngIf="hasError('operator', 'required')"
          >Please enter Operator.</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label># Box / Total Box</mat-label>
        <input
          matInput
          type="text"
          formControlName="boxdividetotalBoxes"
          required
          readonly
        />
        <mat-error *ngIf="hasError('boxdividetotalBoxes', 'required')"
          ># Box / Total Box is required.</mat-error
        >
      </mat-form-field>
    </form>
  </div>

  <div
    fxLayout="row"
    fxFlexOffset="10px"
    fxLayoutAlign="end center"
    fxLayoutGap="3%"
    fxFlex="30px"
  >
    <span fxFlex></span>
    <button
      mat-raised-button
      color="primary"
      (click)="printTemplate()"
      fxFlex="100px"
    >
      PRINT
    </button>
    <button mat-raised-button fxFlex="100px" type="button" mat-dialog-close>
      CLOSE
    </button>
  </div>
</div>
