<div fxLayout="row">
  <app-breadcrumb title="Data Source" [subTitles]="subTitles"></app-breadcrumb>
  <span fxFlex></span>
  <div fxLayoutAlign="center center">
    <button mat-raised-button color="primary" (click)="onCreateStepClick()">
      <mat-icon class="scaleOneDotFive">add</mat-icon>
      <span>CREATE DATA SOURCE</span>
    </button>
  </div>
</div>
<div fxLayoutAlign="center" *ngIf="dataSources?.length == 0">
  <h3 class="primary-text-color">No Data Source Available!</h3>
</div>

<br />

<div class="table-container" *ngIf="dataSources?.length > 0" style="height: Calc(100vh - 250px)" fxLayout="column">
  <div style="overflow: auto; height: calc(100vh - 310px);">
    <table mat-table [dataSource]="dataSources">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef class="table-header">No.</th>
        <td
          mat-cell
          *matCellDef="let datasource; let index = index"
          class="table-row"
        >
          {{ pageNo * pageSize + index + 1 }}
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-header">Name</th>
        <td mat-cell *matCellDef="let datasource" class="table-row">
          {{ datasource.name }}
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef class="table-header">Path</th>
        <td mat-cell *matCellDef="let datasource" class="table-row">
          {{ datasource.path }}
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="table-header">Status</th>
        <td mat-cell *matCellDef="let datasource" class="table-row">
          <span *ngIf="datasource.status" class="primary-text-color">
            Active
          </span>
          <span *ngIf="!datasource.status" class="primary-text-color">
            In Active
          </span>
        </td>
      </ng-container>
  
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="table-header"></th>
        <td mat-cell *matCellDef="let datasource" class="table-row">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
  
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEditClick(datasource)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              mat-menu-item
              (click)="onDeleteClick(datasource.dataSourceId)"
            >
              <mat-icon>delete</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <!-- Pagination -->
  <mat-paginator
    [length]="totalRecord"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 100]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
