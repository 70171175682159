<div fxLayout="column" fxLayoutGap="10px" class="padding-15">
  <!-- #perfectscroll -->
  <div 
    fxFlexOffset="10px"
    style="height: 75vh;overflow: auto;"
    fxLayout="column"
  >
    <form
      [formGroup]="form"
      autocomplete="off"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <div fxLayoutGap="10px">
          <strong class="primary-text-color">Company Information</strong>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Name<span *ngIf="!showFormReadonly">*</span></mat-label>
            <input
              matInput
              type="text"
              placeholder="Name"
              autofocus="true"
              formControlName="name"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error *ngIf="hasError('name', 'required')"
              >You must enter first name.</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label
              >Website<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              matInput
              type="text"
              placeholder="Website"
              autofocus="true"
              formControlName="website"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error *ngIf="hasError('website', 'required')"
              >You must enter website.</mat-error
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Email<span *ngIf="!showFormReadonly">*</span></mat-label>
            <input
              matInput
              type="email"
              placeholder="Email"
              autofocus="true"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              formControlName="email"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error *ngIf="hasError('email', 'required')"
              >You must enter email.</mat-error
            >
            <mat-error *ngIf="hasError('email', 'pattern')"
              >You must enter valid email.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Phone<span *ngIf="!showFormReadonly">*</span></mat-label>
            <input
              matInput
              type="text"
              placeholder="Phone"
              minlength="10"
              maxlength="10"
              pattern="^[0-9]*$"
              formControlName="phone"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error *ngIf="hasError('phone', 'required')"
              >You must enter phone number.</mat-error
            >
            <mat-error *ngIf="hasError('phone', 'pattern')"
              >You must enter valid phone number.</mat-error
            >
            <mat-error
              *ngIf="
                !hasError('phone', 'pattern') && hasError('phone', 'minlength')
              "
              >You must enter valid phone number.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="billingAddress">
        <strong class="primary-text-color"
          >Company Billing Address Details</strong
        >
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label
              >Address Line 1<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              formControlName="addressLine1"
              placeholder="Address Line 1"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="
                hasErrorInGroup('billingAddress', 'addressLine1', 'required')
              "
              >You must enter address line 1.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="48" >
            <mat-label>Address Line 2</mat-label>
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Address Line 2"
              formControlName="addressLine2"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="
                hasErrorInGroup('billingAddress', 'addressLine2', 'required')
              "
              >You must enter address line 2.
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label>City<span *ngIf="!showFormReadonly">*</span></mat-label>
            <input
              color="primary"
              matInput
              type="text"
              placeholder="City"
              formControlName="city"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('billingAddress', 'city', 'required')"
              >You must enter city.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >State/Territory<span *ngIf="!showFormReadonly"
                >*</span
              ></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="State"
              formControlName="state"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('billingAddress', 'state', 'required')"
              >You must enter state.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >Postal Zip<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Zip"
              minlength="5"
              maxlength="5"
              pattern="^[0-9]*$"
              formControlName="postalZip"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('billingAddress', 'postalZip', 'required')"
              >You must enter zip code.
            </mat-error>
            <mat-error
              *ngIf="hasErrorInGroup('billingAddress', 'postalZip', 'pattern')"
              >You must enter valid zip code.
            </mat-error>
            <mat-error
              *ngIf="
                !hasErrorInGroup('billingAddress', 'postalZip', 'pattern') &&
                hasErrorInGroup('billingAddress', 'postalZip', 'minlength')
              "
            >
              You must enter valid zip code.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >Country<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Country"
              formControlName="country"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('billingAddress', 'country', 'required')"
              >You must enter country.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="shippingAddress">
        <strong class="primary-text-color"
          >Company Shipping Address Details</strong
        >
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label
              >Address Line 1<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              formControlName="addressLine1"
              placeholder="Address Line 1"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="
                hasErrorInGroup('shippingAddress', 'addressLine1', 'required')
              "
              >You must enter address line 1.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Address Line 2</mat-label>
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Address Line 2"
              formControlName="addressLine2"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="
                hasErrorInGroup('shippingAddress', 'addressLine2', 'required')
              "
              >You must enter address line 2.
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label>City<span *ngIf="!showFormReadonly">*</span></mat-label>
            <input
              color="primary"
              matInput
              type="text"
              placeholder="City"
              formControlName="city"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('shippingAddress', 'city', 'required')"
              >You must enter city.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >State/Territory<span *ngIf="!showFormReadonly"
                >*</span
              ></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="State"
              formControlName="state"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('shippingAddress', 'state', 'required')"
              >You must enter state.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >Postal Zip<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Zip"
              minlength="5"
              maxlength="5"
              pattern="^[0-9]*$"
              formControlName="postalZip"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="
                hasErrorInGroup('shippingAddress', 'postalZip', 'required')
              "
              >You must enter zip code.
            </mat-error>
            <mat-error
              *ngIf="hasErrorInGroup('shippingAddress', 'postalZip', 'pattern')"
              >You must enter valid zip code.
            </mat-error>
            <mat-error
              *ngIf="
                !hasErrorInGroup('shippingAddress', 'postalZip', 'pattern') &&
                hasErrorInGroup('shippingAddress', 'postalZip', 'minlength')
              "
            >
              You must enter valid zip code.</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="23">
            <mat-label
              >Country<span *ngIf="!showFormReadonly">*</span></mat-label
            >
            <input
              color="primary"
              matInput
              type="text"
              placeholder="Country"
              formControlName="country"
              [readonly]="showFormReadonly"
              [ngClass]="{ 'cursor-not-allowed': showFormReadonly === true }"
            />
            <mat-error
              *ngIf="hasErrorInGroup('shippingAddress', 'country', 'required')"
              >You must enter country.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-checkbox
            color="primary"
            labelPosition="after"
            formControlName="isBypassPayment"
          >
            <small class="primary-text-color">
              Allow company to bypass the payment process
            </small>
          </mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="2%" class="title-space">
          <mat-checkbox
            color="primary"
            labelPosition="after"
            formControlName="isTaxExempt"
            [disabled]="showFormReadonly"
          >
            <small class="primary-text-color">
              Allow sales tax exemption
            </small>
          </mat-checkbox>
        </div>
      </div>
    </form>
  </div>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
    <button
      mat-raised-button
      color="primary"
      fxFlex="100px"
      (click)="onSubmit()"
    >
      SAVE
    </button>
    <button mat-raised-button fxFlex="100px" type="button" mat-dialog-close>
      CLOSE
    </button>
  </div>
</div>
