import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationViewComponent } from './configuration-view/configuration-view.component';
import { UsersComponent } from './configurations/users/users.component';
import { UserProfileComponent } from './configurations/users/user-profile/user-profile.component';
import { authGuardChild } from 'src/app/shared/services/role-guard.service';


const routes: Routes = [
    {
        path: '',
        component: ConfigurationViewComponent
    },
    {
      path:'profile',
      component:UserProfileComponent,
      canActivateChild: [authGuardChild],
      data: {role: ['']}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
