import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

type EXCELFILEFORMAT = any[][];
@Component({
  selector: 'app-excel-preview',
  templateUrl: './excel-preview.component.html',
  styleUrls: ['./excel-preview.component.scss']
})
export class ExcelPreviewComponent implements OnInit {

  @Input('excelFileResourceUrl') public ExcelFileResourceURL :string; 
  
  totalSheets : number= 0;
  sheetNames : string[]= [];
  isLoading = false;
  
  ExcelWorkbook :XLSX.WorkBook ;
  CurrentWorkSheet  : XLSX.WorkSheet;
  CurrentWorkSheetName : string ;
  CurrentWorkSheetData : EXCELFILEFORMAT;
  CurrentWorkSheetHeader : EXCELFILEFORMAT;
  constructor() { }

  ngOnInit() {
    this.onInit();
  }

  onInit(){
    this.loadSheetFromServer();
    
  }

  loadSheetFromServer(){
    this.isLoading = true;
    //Sending request
    var request = new XMLHttpRequest();
    request.open('GET', this.ExcelFileResourceURL);
    request.responseType = 'blob';
    request.onloadend = () => {
      var reader = new FileReader();
      reader.readAsBinaryString(request.response);
      reader.onloadend = (e: any) => {
        /* read workbook */
        const bstr: string = reader.result as string;
        this.ExcelWorkbook = XLSX.read(bstr, { type: 'binary' });

        this.totalSheets = this.ExcelWorkbook.SheetNames.length;
        this.sheetNames = this.ExcelWorkbook.SheetNames;

        this.isLoading = false;
        this.selectSheet(0);
      };
    };
    request.onerror = (e)=>{
      this.isLoading = false;
    }
    request.send();
  }
 
  selectSheet($event : any ){
        this.isLoading = true;
        const index =   $event.index;
        this.CurrentWorkSheetName = this.ExcelWorkbook.SheetNames[index];
        this.CurrentWorkSheet = this.ExcelWorkbook.Sheets[this.CurrentWorkSheetName];
        
        this.CurrentWorkSheetData = [];
        this.CurrentWorkSheetHeader = [];

        this.CurrentWorkSheetData = <EXCELFILEFORMAT>(XLSX.utils.sheet_to_json(this.CurrentWorkSheet, { header: 1 }));
        if(this.CurrentWorkSheetData.length > 0){this.CurrentWorkSheetHeader = this.CurrentWorkSheetData[0];this.CurrentWorkSheetData = this.CurrentWorkSheetData.slice(1);}
        
        this.isLoading = false;
  }

}
