<div fxLayout="column" fxLayoutGap="12px" class="padding-15">
  <div style="font-size: 21px">
    <strong class="primary-text-color">Payment Methods</strong>
  </div>

  <div class="styled-bottom-solid-border"></div>

  <div
    fxLayout="row"
    fxLayoutGap="12px"
    fxFlexOffset="12px"
    fXLayoutAlign="start center"
  >
    <div fxLayoutAlign="center center" class="primary-text-color">Please choose the mode of payment</div>
    <div fxLayoutAlign="center center">
    <mat-select class="select-payment-mode" [(value)]="selectedModeOfPayment">
      <mat-option *ngFor="let mode of modeOfPayments" [value]="mode.value">
        {{ mode.displayValue }}
      </mat-option>
    </mat-select>
  </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="center center"
    fxFlexOffset="20px"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="onClickMarkAsPaid()"
      fxFlex="100px"
    >
      Ok
    </button>
  </div>
</div>
