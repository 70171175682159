<div fxLayout="column" fxLayoutGap="10px" class="padding-15">
  <div fxLayout="row" fxLayoutGap="10px">
    <!-- <mat-icon class="pointer" (click)="close()">arrow_back</mat-icon> -->
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Package
    </span>
    <span fxFlex></span>
    <!-- <mat-icon class="pointer" (click)="close()">close</mat-icon> -->
  </div>
  <form [formGroup]="form">
    <div fxLayout="row" *ngIf="shipmentForJob">
      <mat-form-field
        class="job-item-auto-complete"
        appearance="fill"
        fxFlex="100"
      >
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let item of selectedItems"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(item)"
          >
            {{ item.description }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="Job Items"
            #itemInput
            [formControl]="itemControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            class="primary-text-color"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
        >
          <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            {{ item.description }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <mat-form-field appearance="fill" fxFlex="50">
        <mat-label>Weight(LBS)</mat-label>
        <input
          matInput
          type="text"
          placeholder="Weight(LBS)"
          minlength="1"
          maxlength="8"
          pattern="^\d*(\.\d{0,2})?$"
          formControlName="weight"
          required
        />
        <mat-error *ngIf="hasError('weight', 'required')"
          >You must enter weight.</mat-error
        >
        <mat-error *ngIf="hasError('weight', 'pattern')"
          >You must enter valid weight.</mat-error
        >
        <mat-error
          *ngIf="
            !hasError('weight', 'pattern') && hasError('weight', 'minlength')
          "
          >You must enter weight.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          type="text"
          placeholder="Description"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign="end center"
      style="margin-top: 5px"
    >
      <small class="warn" *ngIf="selectedItems.length == 0 && !packageItemValid"
        >Please select job items to shipment package.</small
      >
      <span fxFlex="10px"> </span>
      <button mat-raised-button color="primary" (click)="create()">
        <span> SUBMIT</span>
      </button>
      <button mat-raised-button (click)="close()">
        <span> CLOSE</span>
      </button>
    </div>
  </form>
</div>
