import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-workflow-mockup',
  templateUrl: './workflow-mockup.component.html',
  styleUrls: ['./workflow-mockup.component.scss']
})
export class WorkflowMockupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    
  }
  

}
