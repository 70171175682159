import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Job } from 'src/app/shared/interfaces/job';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LoadJobs } from '../../state/job.actions';
import { JobImportComponent } from '../job-import.component';

@Component({
  selector: 'app-job-order-import',
  templateUrl: './job-order-import.component.html',
  styleUrls: ['./job-order-import.component.scss']
})
export class JobOrderImportComponent implements OnInit {

  date = new Date();
  dueDate = new Date();
  isValidate = true;
  isLoading = false;
  isWorkflowView = false;
  stepItems;
  now = new Date();
  today: string;
  selectedStep = null;
  isLoad = false;
  isImportToProduction = false;

  constructor(
    public dialogRef: MatDialogRef<JobImportComponent>,
    @Inject(MAT_DIALOG_DATA) public job: Job,
    private store: Store,
    private api: ApiService,
    private router: Router,
    private userService: AuthUserService,
    private notificationHub: NotificationHubService,
    private permissionService: PermissionService,
    private toast: ToastService,
  ) {
    this.job['items'] = [];
  }

  ngOnInit() {
    this.isImportToProduction = this.job.isImportToProduction;
    this.loadOrderDetail();
    this.today = this.now.toISOString();
  }

  loadOrderDetail() {
    var authUser = this.userService.getUser();
    const params = {
      PAGE_NO: 0,
      PAGE_SIZE: 0
    }
    this.api.request("GET_DEPARTMENTS", { params: params }).subscribe(departmentsRes => {
      this.api.request('IMPORT_ORDER', { params: { ID: this.job['id'] } }).subscribe(order => {
        this.api.request("GET_USER", { params: { USER_ID: authUser.userId } }).subscribe(user => {
          if (!order['csrNo'] && this.permissionService.isCSR()) {
            order['csrNo'] = user.userId;
            order['csrName'] = user.csrName ? user.csrName : user.firstName + " " + user.lastName;
          }
          this.job = order;
          this.job.items.forEach(_item => {
            _item['isNotifyDepartmentUsers'] = false;
            _item['departmentsToNotify'] = departmentsRes['departments'].map(d => d.departmentId);
          })
          this.job.isImportToProduction = this.isImportToProduction;
          this.isLoad = true;
        })
      });
    })
  }


  onCloseWorkflow(items) {
    this.stepItems = items;
    this.isWorkflowView = false;
  }

  onImportJob() {
    var user = this.userService.getUser();

    if (user.dataSourceId == null) {
      this.toast.show('You must select data soure.');
      return;
    }

    // steps select for job items
    if (!this.stepItems) {
      this.isValidate = false;
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    const jobItems = this.stepItems.map((item) => {
      let steps = "";
      const notifications = [];
      const notes = [];
      //const currentStep = item.steps[0].stepId + '-' + item.steps[0].actionId;
      let mailingSorts = [];
      let mailingInfos = [];

      //job item note
      if (item.note) {
        notes.push({
          jobItemNo: item.itemNo,
          text: item.note,
          step: null,
          stepIndex: null,
          priority: item.notePriority,
        });
      }

      item.steps.forEach((stepAction, index) => {
        if (steps.length > 0) {
          steps += "|";
        }
        const step = stepAction.step.stepId + "-" + stepAction.action.actionId;
        steps += step;

        const stepNotification = {
          // add all step notification
          jobItemNo: item.itemNo,
          step: step,
          stepIndex: index,
          isNotify: stepAction.notification,
        };

        notifications.push(stepNotification);

        if (stepAction.note) {
          // add all steps note
          const note = {
            jobItemNo: item.itemNo,
            step: step,
            stepIndex: index,
            text: stepAction.note,
            priority: stepAction.notePriority,
          };

          // step note
          notes.push(note);
        }

        let mailingSort = stepAction["mailingSort"];
        let mailingInfo = stepAction["mailingInfo"];
        if (mailingSort != undefined) {
          mailingSort["step"] = step;
          mailingSort["stepIndex"] = index;
          mailingSorts.push(mailingSort);
        }

        if (mailingInfo != undefined) {
          mailingInfo["step"] = step;
          mailingInfo["stepIndex"] = index;
          mailingInfos.push(mailingInfo);
        }
      });

      return {
        itemNo: item.itemNo,
        itemId: item.itemId,
        isUnion: item.isUnion,
        description: item.description,
        steps: steps,
        notifications: notifications,
        notes: notes,
        chargeQty: item.chargeQty,
        cSRName: item.cSRName,
        mailingSorts: mailingSorts,
        mailingInfos: mailingInfos,
        departmentsToNotify: item["departmentsToNotify"],
        isNotifyDepartmentUsers: item["isNotifyDepartmentUsers"],
        importToProductionWithoutValidations: item.isImportToProduction,
      };
    });

    const params = {
      data: {
        JOBNUMBER: this.job['jobNumber'],
        dateDue: this.job['dateDue'],
        dateIn: this.job['dateIn'],
        csrNo: this.job['csrNo'],
        rushDate: this.job['rushDate'],
        customerName: this.job['customerName'],
        csrName: this.job['csrName'],
        jobItems: jobItems,
        jobTitle: this.job.title,
        isPrinterPlan: false,
      }
    };

    this.api.request('CREATE_JOB', params).subscribe(response => {
      if (response) {
        this.job.jobId = response.jobId;
        this.saveFiles(response.jobItemIds);
        this.sendNotification(jobItems);
      }
    }, error => { this.isLoading = false; this.dialogRef.disableClose = false; });
  }

  sendNotification(items) {
    items.forEach(item => {
      const notification = {
        JobId: this.job.jobId,
        JobNumber: this.job['jobNumber'],
        JobItemNo: item.itemNo,
        jobItemName: item.description,
        steps: item.steps,
        currentStepIndex: item.currentStepIndex,
        changeStepIndex: item.currentStepIndex,
        isPrinterPlan: false
      };
      this.notificationHub.sendNotification(notification);
    });
  }

  saveFiles(jobItemIds) {
    if (this.stepItems['files'] && this.stepItems['files'].size > 0) {
      const formdata = new FormData();
      this.stepItems['files'].forEach(file => {
        formdata.append('files', file.file);
      });
      jobItemIds.forEach(jobItemId => {
        formdata.append('JobItemIds', jobItemId);
      });
      this.api.request('CREATE_JOB_ITEM_DOCUMENT', { data: formdata }).subscribe(res => {
        this.isLoading = false;
        this.closeDialog();
      }, error => {
        this.isLoading = false;
        this.closeDialog();
      });
    } else {
      this.isLoading = false;
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
    if (this.router.url === '/jobs') {
      this.store.dispatch(new LoadJobs(this.userService.getUser().filter[0]));
    } else {
      this.router.navigate(['/jobs']); // naviate to job list page
    }
  }
}

