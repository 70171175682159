import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { InvoiceDto, InvoiceItemDto } from 'src/app/shared/interfaces/invoice';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { MarkAsPaidComponent } from '../mark-as-paid/mark-as-paid.component';
import { PaymentLinkComponent } from '../payment-link/payment-link.component';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {

  currentToast = null;
  saveAsDraft: boolean = false;
  isViewOnlyMode: boolean = false;
  salesTaxAmount: number = 0;
  salesTaxPercentage: number = 0;
  subTotalAmount: number = 0;
  balance: number = 0;
  invoiceItems: InvoiceItemDto[] = [];
  previousInvoiceItems: InvoiceItemDto[] = [];
  title: string = "";
  isUpdating = false;
  isLoading = false;
  discountPercentage: number = 0;
  postage: number = 0;
  shipping: number = 0;
  paid: number = 0;
  discountAmount: number = 0;
  total: number = 0;
  isStripeGuestPaymentLinkAvailable: boolean = false;
  isStripeGuestPaymentLinkActive: boolean = true;
  stripeGuestPaymentLink: string = "";
  stripeLinkGenerationButtonText: string = "";

  //Stripe Fees
  fixedFee = 0.30;
  fixedPercenatge = 0.029;
  processingFee: number = 0;

  //Link
  customerPortalOrderLink = "";

  constructor(public dialogRef: MatDialogRef<CreateInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: InvoiceDto, taxPercentage: number, isViewOnlyMode: boolean, isPrinterPlanInvoice: boolean, job: any },
    private _snackBar: MatSnackBar,
    private api: ApiService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,) {
    matIconRegistry.addSvgIcon('save_as_draft', sanitizer.bypassSecurityTrustResourceUrl('assets/images/save-as-draft.svg'));
    matIconRegistry.addSvgIcon('send_invoice', sanitizer.bypassSecurityTrustResourceUrl('assets/images/send-invoice.svg'));
    matIconRegistry.addSvgIcon('link_share', sanitizer.bypassSecurityTrustResourceUrl('assets/images/link-share.svg'));
  }

  ngOnInit() {
    if (this.data.invoice.id && this.data.invoice.id != 0) { this.title = "Edit Invoice"; this.isUpdating = true; }
    else { this.title = "Create Invoice"; this.isUpdating = false; }
    this.invoiceItems = this.data.invoice.invoiceItems;
    this.isViewOnlyMode = this.data.isViewOnlyMode;
    this.data.invoice.invoiceItems.forEach(_invoiceItem => {
      this.previousInvoiceItems.push(
        {
          id: _invoiceItem.id,
          amount: _invoiceItem.amount,
          type: _invoiceItem.type,
          invoiceId: _invoiceItem.invoiceId,
          referenceId: _invoiceItem.referenceId
        }
      );
    });

    if (this.data.isPrinterPlanInvoice) {
      this.subTotalAmount = this.data.invoice.subTotal;
      this.discountAmount = this.data.invoice.discount;
      this.discountPercentage = this.data.invoice.discountPercentage;
      this.shipping = this.data.invoice.shipping;
      this.postage = this.data.invoice.postage;
      this.salesTaxAmount = this.data.invoice.salesTax;
      this.salesTaxPercentage = this.data.invoice.salesTaxPercentage;
      this.paid = this.data.invoice.paid;

      if (this.subTotalAmount > 0) {
        this.processingFee = parseFloat(((this.salesTaxAmount + ((this.subTotalAmount + this.shipping + this.postage) - this.discountAmount - this.paid) + this.fixedFee) / (1 - this.fixedPercenatge) - (((this.subTotalAmount + this.shipping + this.postage) - this.discountAmount - this.paid) + this.salesTaxAmount)).toFixed(2));
      } else {
        this.processingFee = 0;
      }

      this.total = +(this.subTotalAmount + this.salesTaxAmount + this.processingFee).toFixed(2);
      if (this.postage) { this.total = this.total + this.postage; }
      if (this.shipping) { this.total = this.total + this.shipping; }
      this.total = this.total - this.discountAmount;

      this.balance = this.total;
      this.balance = this.balance - this.paid;

      let emailAddress = "";
      if (this.data.job) {
        if (this.data.job.buyerInfo) {
          emailAddress = this.data.job.buyerInfo.email;
        }
      }

      this.customerPortalOrderLink = `${environment.customerPortalUrl.replace('/api', '')}/orders/${this.data.invoice.referenceId}/printer-plan/details?${emailAddress ? `?emailAddress=${emailAddress}` : ''}`;

      if (this.data.job.guestPaymentLink) {
        this.isStripeGuestPaymentLinkAvailable = true;
        this.stripeGuestPaymentLink = this.data.job.guestPaymentLink;
        this.isStripeGuestPaymentLinkActive = this.data.job.isGuestPaymentLinkActive;
        this.stripeLinkGenerationButtonText = "Re-generate Link";
      } else {
        this.stripeLinkGenerationButtonText = "Guest Link";
      }

    } else {
      this.saveAsDraft = this.data.invoice.isInDraft;
      this.salesTaxPercentage = this.data.taxPercentage;
      this.discountPercentage = this.data.invoice.discountPercentage;
      this.discountAmount = this.data.invoice.discount;
      this.postage = this.data.invoice.postage;
      this.shipping = this.data.invoice.shipping;
      this.paid = this.data.invoice.paid;
      this.calculateAmounts();
      this.customerPortalOrderLink = `${environment.customerPortalUrl.replace('/api', '')}/orders/${this.data.invoice.referenceId}/detail?validateCanMakePayment=true&emailAddress=${this.data.job.customerEmail}`;
    }

  }

  showToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500
    });
  }

  close() {
    if (this.isLoading) { return; }
    this.resetValues();
    this.dialogRef.close(null);
  }

  calculateAmounts() {
    if (this.data.isPrinterPlanInvoice) {
      return;
    }

    let subTotal: number = 0;

    this.invoiceItems.forEach(item => {
      if (item.amount == null || item.amount == undefined || item.amount < 0) { item.amount = 0; }
      subTotal = subTotal + item.amount;
    });

    this.subTotalAmount = +subTotal.toFixed(2);

    if (this.discountPercentage && this.discountPercentage > 0) {
      this.discountAmount = parseFloat(((this.subTotalAmount * this.discountPercentage) / 100).toFixed(2));
    }
    else {
      this.discountPercentage = 0;
      this.discountAmount = 0;
    }

    this.salesTaxPercentage = +this.salesTaxPercentage.toFixed(3);
    this.salesTaxAmount = +((this.subTotalAmount - this.discountAmount) * this.salesTaxPercentage / 100).toFixed(2);

    if (subTotal > 0) {
      this.processingFee = parseFloat(((this.salesTaxAmount + ((this.subTotalAmount + this.shipping + this.postage) - this.discountAmount - this.paid) + this.fixedFee) / (1 - this.fixedPercenatge) - (((this.subTotalAmount + this.shipping + this.postage) - this.discountAmount - this.paid) + this.salesTaxAmount)).toFixed(2));
    } else {
      this.processingFee = 0;
    }

    this.total = +(this.subTotalAmount + this.salesTaxAmount + this.processingFee).toFixed(2);
    if (this.postage) { this.total = this.total + this.postage; }
    if (this.shipping) { this.total = this.total + this.shipping; }
    this.total = this.total - this.discountAmount;

    this.balance = this.total;
    if (this.paid) { this.balance = this.balance - this.paid; }
  }

  resetValues() {
    this.invoiceItems.forEach(_item => {
      let index = this.previousInvoiceItems.findIndex(i => i.referenceId == _item.referenceId);
      _item.amount = this.previousInvoiceItems[index].amount;
    });
  }

  onSaveAsDraft() {
    this.saveAsDraft = true;
    this.submitInvoice();
  }

  onSave() {
    if (this.data.isPrinterPlanInvoice) {
      if (!this.data.job.buyerInfo || !this.data.job.buyerInfo.email) {
        this.showToast("Please add email address in printer's plan");
        return;
      }
    }

    this.saveAsDraft = false;
    this.submitInvoice();
  }

  submitInvoice() {
    if (this.isUpdating) { this.updateInvoice(); }
    else { this.createInvoice(); }
  }

  createInvoice() {
    if (this.isLoading) {
      return;
    }

    let hasError = false;
    let errorMsg = "";

    var reqModel = new Invoice();
    reqModel.invoiceItems = [];
    this.invoiceItems.forEach(item => {
      if (item.amount == null || item.amount == undefined || item.amount < 0) { item.amount = 0; }
      if (item.amount == 0 || item.amount < 0) {
        hasError = true;
        errorMsg = "All items amount value should be greater than zero!";
      }
      let invoiceItem = new InvoiceItem();
      invoiceItem.orderItemId = item.referenceId;
      invoiceItem.amount = item.amount;
      reqModel.invoiceItems.push(invoiceItem);
    });

    if (hasError) {
      this.showToast(errorMsg);
      return;
    }

    reqModel.isInDraft = this.saveAsDraft;
    reqModel.referenceId = this.data.invoice.referenceId;
    reqModel.discount = this.discountAmount;
    reqModel.discountPercentage = this.discountPercentage;
    reqModel.shipping = this.shipping;
    reqModel.postage = this.postage;
    reqModel.paid = this.paid;
    reqModel.taxPercentage = this.salesTaxPercentage;
    reqModel.tax = this.salesTaxAmount;
    reqModel.processingFee = this.processingFee;
    reqModel.balance = this.balance;

    if (this.saveAsDraft) {
      this.dialogRef.disableClose = true;
      this.isLoading = true;
      this.api.request("CREATE_INVOICE", { data: reqModel }).subscribe(res => {
        this.isLoading = false;
        if (res['success']) {
          this.showToast("Invoice Created Successfully");
          this.data.invoice.id = res.result.id;
          this.data.invoice.shipping = res.result.shipping;
          this.data.invoice.postage = res.result.postage;
          this.data.invoice.isInDraft = res.result.isInDraft;
          this.data.invoice.discount = res.result.discount;
          this.data.invoice.discountPercentage = res.result.discountPercentage;
          this.data.invoice.paid = res.result.paid;
          this.data.invoice.invoiceItems.forEach(_i => {
            let index = res.result.invoiceItems.findIndex(_ => _.referenceId == _i.referenceId);
            _i.id = res.result.invoiceItems[index].id;
          });

          this.dialogRef.close(res.result);
        }
        else {
          this.resetValues();
          this.showToast(res['message']);
          this.dialogRef.close(null);
        }
      }, err => {
        this.dialogRef.disableClose = false;
        this.isLoading = false;
      });
    } else {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: { type: 'Confirm', message: 'Are you sure want to share invoice with customer?' },
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.disableClose = true;
          this.isLoading = true;
          this.api.request("CREATE_INVOICE", { data: reqModel }).subscribe(res => {
            this.isLoading = false;
            if (res['success']) {
              if (this.data.job && !res.result.isInDraft) {
                this.data.job.isInvoiceSharedWithCustomer = true;
              }
              this.showToast("Invoice Created Successfully");
              this.data.invoice.id = res.result.id;
              this.data.invoice.shipping = res.result.shipping;
              this.data.invoice.isInDraft = res.result.isInDraft;
              this.data.invoice.postage = res.result.postage;
              this.data.invoice.discount = res.result.discount;
              this.data.invoice.discountPercentage = res.result.discountPercentage;
              this.data.invoice.paid = res.result.paid;
              this.data.invoice.invoiceItems.forEach(_i => {
                let index = res.result.invoiceItems.findIndex(_ => _.referenceId == _i.referenceId);
                _i.id = res.result.invoiceItems[index].id;
              });

              this.dialogRef.close(res.result);
            }
            else {
              this.resetValues();
              this.showToast(res['message']);
              this.dialogRef.close(null);
            }
          }, err => {
            this.dialogRef.disableClose = false;
            this.isLoading = false;
          });
        }
      });
    }
  }

  updateInvoice() {
    if (this.isLoading) {
      return;
    }

    let invoiceItemsReqDto: InvoiceItem[] = [];
    let hasError = false;
    let errorMsg = "";

    this.invoiceItems.forEach(invoiceItem => {
      if (invoiceItem.amount == null || invoiceItem.amount == undefined || invoiceItem.amount < 0) { invoiceItem.amount = 0; }
      if (invoiceItem.amount == 0 || invoiceItem.amount < 0) {
        hasError = true;
        errorMsg = "The amount value for all items should be greater than zero";
      }
      var invoiceItemReq = new InvoiceItem();
      invoiceItemReq.amount = invoiceItem.amount;
      invoiceItemReq.orderItemId = invoiceItem.referenceId;
      invoiceItemsReqDto.push(invoiceItemReq);
    });

    if (hasError) {
      this.showToast(errorMsg);
      return;
    }

    var apiReqModel = {
      params: { ID: this.data.invoice.id },
      data: {
        invoiceItems: invoiceItemsReqDto,
        isInDraft: this.saveAsDraft,
        referenceId: this.data.invoice.referenceId,
        id: this.data.invoice.id,
        shipping: this.shipping,
        postage: this.postage,
        discount: this.discountAmount,
        discountPercentage: this.discountPercentage,
        paid: this.paid,
        taxPercentage: this.salesTaxPercentage,
        tax: this.salesTaxAmount,
        processingFee: this.processingFee,
        balance: this.balance
      }
    }
    if (this.saveAsDraft) {
      this.dialogRef.disableClose = true;
      this.isLoading = true;
      this.api.request("UPDATE_INVOICE", apiReqModel).subscribe(res => {
        this.isLoading = false;
        if (res['success']) {
          this.data.invoice.shipping = res.result.shipping;
          this.data.invoice.isInDraft = res.result.isInDraft;
          this.data.invoice.postage = res.result.postage;
          this.data.invoice.discount = res.result.discount;
          this.data.invoice.discountPercentage = res.result.discountPercentage;
          this.data.invoice.paid = res.result.paid;

          this.showToast("Invoice Updated Successfully");
          this.dialogRef.close(res.result);
        }
        else {
          this.resetValues();
          this.showToast(res['message']);
        }
      }, err => {
        this.dialogRef.disableClose = false;
        this.isLoading = false;
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: { type: 'Confirm', message: 'Are you sure you want to share invoice with customer?' },
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.disableClose = true;
          this.isLoading = true;
          this.api.request("UPDATE_INVOICE", apiReqModel).subscribe(res => {
            this.isLoading = false;
            if (res['success']) {
              if (this.data.job && !res.result.isInDraft) {
                this.data.job.isInvoiceSharedWithCustomer = true;
              }
              this.data.invoice.shipping = res.result.shipping;
              this.data.invoice.postage = res.result.postage;
              this.data.invoice.isInDraft = res.result.isInDraft;
              this.data.invoice.discount = res.result.discount;
              this.data.invoice.discountPercentage = res.result.discountPercentage;
              this.data.invoice.paid = res.result.paid;
              if (this.data.isPrinterPlanInvoice) {
                this.data.invoice.isPrinterPlanInvoiceSharedWithCustomer = true;
                this.data.job.isInvoiceSharedWithCustomer = true;
              }

              this.showToast("Invoice Updated Successfully");
              this.dialogRef.close(res.result);
            }
            else {
              this.resetValues();
              this.showToast(res['message']);
            }
          }, err => {
            this.dialogRef.disableClose = false;
            this.isLoading = false;
          });
        }
      });
    }

  }



  getCurrentTimeAndDate() {
    var today = new Date();
    var hours = today.getHours();
    var minutes: any = today.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    var res = dd + '/' + mm + '/' + yyyy + " " + hours + ':' + minutes + ' ' + ampm;
    return res;
  }

  onClickMarkAsPaid() {
    if (this.data.invoice.invoiceItems.filter(ii => ii.amount == 0 || ii.amount == null).length > 0) {
      this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: { type: 'Info', message: 'Please add the cost details for all the order items' },
      });
      return;
    }

    let dialogRef = this.dialog.open(MarkAsPaidComponent, {
      width: '650px',
      data: { invoiceId: this.data.invoice.id },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res['success']) {
          this.showToast(res['message']);
          this.dialog.closeAll();
        } else {
          this.showToast(res['message']);
        }
      }
    });
  }

  onClickshareLinkWithCustomer() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { type: 'Confirm', message: 'Are you sure you want to share link with customer?' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        var reqObject = {
          params: {},
          data: {
            orderId: this.data.invoice.referenceId,
            isPrinterPlan: false
          }
        }

        this.api.request("SHARE_ORDER_URL", reqObject).subscribe(res => {
          if (res['success']) {
            this.showToast(res['message']);
          }
          else {
            this.showToast(res['message']);
          }
        });
      }
    });
  }

  onClickshareCustomerPortalLinkWithCustomer() {

    if (this.data.isPrinterPlanInvoice) {
      if (!this.data.job.buyerInfo || !this.data.job.buyerInfo.email) {
        this.showToast("Please add email address in printer's plan");
        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { type: 'Confirm', message: 'Are you sure you want to share link with customer?' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        var reqObject = {
          params: {},
          data: {
            referenceId: this.data.invoice.referenceId,
            isPrinterPlan: this.data.isPrinterPlanInvoice ? true : false
          }
        }

        this.api.request("SHARE_ORDER_DETAILS_URL", reqObject).subscribe(res => {
          this.showToast(res['message']);
          this.dialogRef.close(null);
        });

      }
    });
  }

  copyToClipBoard(text: string) {
    if (!this.isStripeGuestPaymentLinkAvailable) {
      return;
    }

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showToast("Link Copied!");
  }

  onClickGenerateGuestLink() {
    let dialogRef = this.dialog.open(PaymentLinkComponent, {
      width: '710px',
      data: { jobId: this.data.invoice.referenceId, email: this.data.job.buyerInfo ? this.data.job.buyerInfo.email : "", generatedLink: this.data.job.guestPaymentLink },
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res.isLinkGenerated) {
          this.stripeGuestPaymentLink = res.generatedLink;
          this.isStripeGuestPaymentLinkAvailable = true;
          this.isStripeGuestPaymentLinkActive = true;
          this.stripeLinkGenerationButtonText = "Re-generate Link";
          this.data.job["guestPaymentLink"] = this.stripeGuestPaymentLink;
        }

        if (res.isEmailUpdated) {
          if (this.data.job.buyerInfo) {
            this.data.job.buyerInfo.email = res.email;
          }
        }

        if (res.isPaymentLinkShared) {
          this.data.job.isPaymentLinkShared = true;
        }
      }
    });
  }

  onDeactiveLink() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { type: 'Confirm', message: 'Are you sure you want to Deactivate link ' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        var reqObject = {
          params: {},
          data: {
            Url: this.data.job.guestPaymentLink
          }
        }

        this.api.request("DEACTIVE_PAYMENT_LINK", reqObject).subscribe(res => {
          if (res) {
            this.showToast(res['message']);
            this.data.job.isGuestPaymentLinkActive = false;
            this.dialogRef.close(null);
          }
        });

      }
    });

  }
}


class Invoice {
  id?: number;
  referenceId: number;
  type: number;
  isInDraft: boolean;
  invoiceItems: InvoiceItem[];
  discount: number;
  discountPercentage: number;
  shipping: number;
  postage: number;
  paid: number;
  taxPercentage: number;
  tax: number;
  processingFee: number;
  balance: number;
}

class InvoiceItem {
  orderItemId: number;
  amount: number;
}
