import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { ApiService } from "src/app/shared/services/api.service";
import { UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngxs/store";
import { ConfigurationState } from "../../configuration/state/configuration.state";
import { ConfirmDialogComponent } from "./../../../shared/components/confirm-dialog/confirm-dialog.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { User } from "src/app/shared/interfaces/user";
import {
  LoadJobLogs,
  UpdateJob,
  ReloadeJobs,
  RemoveJobItem,
} from "../state/job.actions";
import { LoadSteps } from "../../configuration/state/configuration.actions";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NotificationHubService } from "src/app/shared/services/notification-hub.service";
import { JobImportComponent } from "../job-import/job-import.component";
import { JobNoteAlertComponent } from "./job-note-alert/job-note-alert.component";
import { JobSendBackComponent } from "./job-send-back/job-send-back.component";
import { JobMailingSortComponent } from "../job-mailing/job-mailing-sort/job-mailing-sort.component";
import { JobMailingInfoComponent } from "../job-mailing/job-mailing-info/job-mailing-info.component";
import { JobImportWorkflowComponent } from "../job-import/job-import-workflow/job-import-workflow.component";
import { Subject, Subscription, interval } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { PermissionService } from "src/app/shared/services/permission.service";
import { delay, takeUntil } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JobOrderImportComponent } from "../job-import/job-order-import/job-order-import.component";
import * as Tiff from "tiff.js";
import { JobsPermissionService } from "src/app/shared/services/job.permissions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Order } from "src/app/shared/interfaces/order";
import { PaymentLinkComponent } from "./payment-link/payment-link.component";
import { CreateInvoiceComponent } from "./create-invoice/create-invoice.component";
import { InvoiceDto, InvoiceItemDto } from "src/app/shared/interfaces/invoice";
import { DocumentService } from "src/app/shared/services/document.service";
import { jobItemNote } from "src/app/shared/interfaces/jobItem";
import { PrinterPlanDocumentService } from "src/app/shared/services/printer-plan-document.service";
import { SendJobItem } from "src/app/shared/interfaces/sendJobItem";
import { ThemeService } from "src/app/theme.service";
import {
  EnumSwitchUploadFilesMode,
  UploadToSwitchComponent,
} from "./upload-to-switch/upload-to-switch.component";
import { JobUpdateWithPPComponent } from "./job-update-with-pp/job-update-with-pp.component";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
})
export class JobDetailsComponent implements OnInit {
  currentToast = null;
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  job;
  isWorkflowView;
  today: string;
  now = new Date();
  jobNote = "";
  jobSteps = [];
  showAllNotes = false;
  showAllFiles = false;
  jobItem;
  selectedJobItemIndex = 0;
  selectedNoteStep: any = ""; // user select step for add note.
  selectedFileStep: any = ""; // user for select file to job step.
  countHandel;
  stepCounter = 0;
  addNoteValidate = true;
  addNoteLoading = false;
  addFileLoading = false;
  fileURL: any;
  isShowFile = false;
  isRush = false;
  file: any;
  docType = "Document";
  isLoadJobItem = true;
  isPaid = false;
  canBypassPayment = false;
  @ViewChild("departmentFile") departmentFile;
  @ViewChild("jobFile") jobFile;
  @ViewChild("externalPdfViewer") public externalPdfViewer;
  user: User;
  stepChangeLoading: boolean;
  stepCompleteLoading: boolean;
  rushDateLoading: boolean;
  notePriority = "low";
  steps = [];
  pageLoad = false;
  selectedUplodefiles = [];
  minDate = new Date();
  noteFilesTabIndex = 0;
  currentDepartmentId = 0;
  isAdminOrCSR = false;
  isAdmin = false;
  killCounterTrigger: Subject<void> = new Subject();
  refreshCounter = interval(1000).pipe(takeUntil(this.killCounterTrigger));
  isProofFile = false;
  isPrintFile = false;
  isProofFileSharing = false;
  isFilePreviewLoading = false;
  excelFileResourceURL: string = "";
  fileResourceUrl: string = "";
  allDepartments: any[] = [];
  showPreviewUnavailableMsg = false;
  showDepartmentChangeMsg = false;
  invoiceLinkText: string = "";
  Extension = "";
  BypassSvgIcon: string;
  isCheckingForJobUpdate: boolean = false;
  isUpdatingWithPP: boolean = false;
  addFileValidate: boolean = true;

  //Tax Data
  orderItems = [];
  taxPercentage: number = 0;
  errorMsgInTaxGeneration: string = "";
  isErrorInTaxGeneration: Boolean = false;
  selectedInvoice: InvoiceDto = null;
  isInvoiceCreated: Boolean = false;
  orderId: number = 0;
  checkboxtik;

  //Preview Order doc (Chat)
  isChatOrderDocShowPreview = false;
  chatOrderDocPreviewDocument;
  chatOrderDocPreviewUrl;

  noteAlertdialogRef: MatDialogRef<JobNoteAlertComponent, any> = null;
  noteAlertdialogRefAfterClosedSubscription: Subscription | null = null;
  selectJobNote: string = 'Job Notes';
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<JobDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private api: ApiService,
    private auth: AuthUserService,
    public sanitizer: DomSanitizer,
    private notificationHub: NotificationHubService,
    private activatedRoute: ActivatedRoute,
    private permission: PermissionService,
    private http: HttpClient,
    private jobPermission: JobsPermissionService,
    private _snackBar: MatSnackBar,
    public documentService: DocumentService,
    public printerPlanDocumentService: PrinterPlanDocumentService,
    private matIconRegistry: MatIconRegistry,
    private themeService: ThemeService
  ) {
    this.job = JSON.parse(JSON.stringify(data.job));
    this.job["reload"] = false;
    matIconRegistry.addSvgIcon(
      "pacific_bypass_payment",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/BypassPaymentIcon.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "pacific_bypass_payment_Dark",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/Bypass_Payment_Icon_Dark.svg"
      )
    );
  }

  ngOnInit() {
    if (this.job.isPrinterPlan) {
      this.checkForJobUpdates();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentDepartmentId = params["id"] ? params["id"] : 0;

      this.themeService.BypassSvgIcon.subscribe((data) => {
        this.BypassSvgIcon = data;
      });

      var IsDarkTheme = localStorage.getItem("IsThemeDark");
      if (IsDarkTheme == "true") {
        this.checkboxtik = "white";
      } else {
        this.checkboxtik = "black";
      }
    });

    this.getDepartments();

    this.user = this.auth.getUser();
    this.today = this.now.toISOString();
    this.isAdminOrCSR = this.permission.isAdminOrCSR();
    this.isAdmin = this.permission.isAdmin();

    // load job steps
    this.steps = this.store.selectSnapshot(ConfigurationState.AllSteps);
    if (this.steps.length === 0) {
      this.store.dispatch(new LoadSteps());
      this.steps = this.store.selectSnapshot(ConfigurationState.AllSteps);
    }

    if (this.steps.length > 0) {
      this.loadJobDetails();
    }
  }

  checkForJobUpdates() {
    this.isCheckingForJobUpdate = true;
    const params = {
      params: {
        JOB_ID: this.job["jobId"],
      },
    };
    this.api
      .request("GET_COMPARE_JOB_DETAIL_WITH_PP", params)
      .subscribe((response) => {
        if (response.result.length != 0) {
          this.isCheckingForJobUpdate = false;
          this.isUpdatingWithPP = true;
          this.updateJobFromPP();
        } else {
          this.isCheckingForJobUpdate = false;
        }
      });
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500,
    });
  }

  getDepartments() {
    var params = {
      params: {
        PAGE_NO: 1,
        PAGE_SIZE: 0,
      },
    };

    this.api.request("GET_DEPARTMENTS", params).subscribe((res) => {
      this.allDepartments = res["departments"];
    });
  }

  // set the step counter value.
  setStepCounter() {
    this.stepCounter =
      this.job.items.length > 0
        ? this.job.items[this.selectedJobItemIndex]["currentStepLogTime"]
        : this.stepCounter;
    this.refreshCounter.subscribe((val) => {
      if (this.job.items != null) {
        this.job.items.forEach((item) => {
          ++item["currentStepLogTime"];
        });
        this.stepCounter =
          this.job.items[this.selectedJobItemIndex]["currentStepLogTime"];
      }
    });
  }

  ngOnDestroy() {
    this.killCounterTrigger.next();
  }

  myFiles() {
    try {
      var currentStepIndex =
        this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
      var currentStepDeptName =
        this.job.items[this.selectedJobItemIndex]["stepActions"][
          currentStepIndex
        ]["stepDepartment"].toLowerCase();
      var files: any[] = this.job.items[this.selectedJobItemIndex]["files"];
      if (
        currentStepDeptName.toLowerCase() === "design" ||
        currentStepDeptName.toLowerCase() === "sales"
      ) {
        var userDept = this.auth
          .getUser()
          .currentDepartment.departmentName.toLowerCase();
        if (userDept === "administration" || userDept === "csr") {
          return files;
        }
        if (userDept === currentStepDeptName) {
          return files;
        }
      }
      return files.filter((f) => f["isUploadedByCustomer"] != true);
    } catch (Error) {
      return [];
    }
  }

  showHighPriorityNotes() {
    const jobItem = this.job.items[this.selectedJobItemIndex];
    let notes = jobItem.notes.filter((n) => n.priority === "high");
    jobItem.stepActions.forEach((stp, index) => {
      if (stp.notes.length > 0 && index == jobItem.currentStepIndex) {
        const stpNotes = stp.notes.filter((n) => n.priority === "high");
        if (stpNotes.length > 0) {
          stpNotes.forEach((note) => {
            notes.push(note);
          });
        }
      }
    });
    //check notes is available or job item is completed.
    if (notes.length === 0 || jobItem.status === 2) {
      return;
    }

    if (this.noteAlertdialogRef) {
      return;
    }

    this.noteAlertdialogRef = this.dialog.open(JobNoteAlertComponent, {
      height: "400px",
      maxHeight: "500px",
      width: "650px",
      disableClose: true,
      data: {
        title: jobItem.description,
        notes: notes,
      },
    });

    this.noteAlertdialogRefAfterClosedSubscription = this.noteAlertdialogRef
      .afterClosed()
      .subscribe((res) => {
        this.noteAlertdialogRef = null;
        this.noteAlertdialogRefAfterClosedSubscription = null;
      });
  }

  loadJobDetails(): void {
    var isAdminOrCSR = this.permission.isAdminOrCSR();
    const params = {
      params: {
        JOB_ID: this.job["jobId"],
      },
    };

    this.api.request("GET_JOB", params).subscribe((job) => {
      //Colllcting info for passing to create or update invoice
      this.orderItems = job.orderItems;
      if (!job.isPrinterPlan) {
        this.taxPercentage = job.taxPercentage;
        if (job.taxExceptions && job.taxExceptions.length > 0) {
          var clientExceptionIndex = job.taxExceptions.findIndex(
            (e) => e.type == 0
          );
          var serverExceptionIndex = job.taxExceptions.findIndex(
            (e) => e.type == 1
          );
          if (clientExceptionIndex != -1) {
            this.isErrorInTaxGeneration = true;
            this.errorMsgInTaxGeneration =
              job.taxExceptions[clientExceptionIndex].message;
          }
          if (serverExceptionIndex != -1) {
            this.isErrorInTaxGeneration = true;
            this.errorMsgInTaxGeneration =
              job.taxExceptions[serverExceptionIndex].message;
          }
        }
        if (job.invoices && job.invoices.length > 0) {
          this.selectedInvoice = job.invoices[0];
          this.isInvoiceCreated = true;

          this.selectedInvoice.invoiceItems.forEach((_invoiceItem) => {
            var index = this.orderItems.findIndex(
              (oi) => oi.id == _invoiceItem.referenceId
            );
            _invoiceItem.title = this.orderItems[index].title;
          });
        } else {
          var invoiceItems: InvoiceItemDto[] = [];
          this.orderItems.forEach((_item) => {
            if (_item.status != "CANCELED") {
              var invoiceItem: InvoiceItemDto = {
                id: 0,
                type: 0,
                title: _item.title,
                referenceId: _item.id,
                amount: 0,
                invoiceId: 0,
              };

              invoiceItems.push(invoiceItem);
            }
          });

          this.selectedInvoice = {
            id: 0,
            isInDraft: true,
            paymentDate: null,
            status: null,
            subTotal: 0,
            referenceId: this.orderId,
            discount: 0,
            discountPercentage: 0,
            shipping: 0,
            postage: 0,
            paid: 0,
            processingFees: 0,
            salesTax: 0,
            balance: 0,
            salesTaxPercentage: 0,
            total: 0,
            type: 0,
            taxable: 0,
            invoiceItems: invoiceItems,
            isPrinterPlanInvoiceSharedWithCustomer: false,
            isPrinterPlanInvoiceViewActionNeeded: false,
          };
        }
        this.orderId = job.orderId;
      } else {
        if (job.invoices && job.invoices.length > 0) {
          this.selectedInvoice = job.invoices[0];
          this.isInvoiceCreated = true;

          this.selectedInvoice.invoiceItems.forEach((_invoiceItem) => {
            var index = this.orderItems.findIndex(
              (oi) => oi.id == _invoiceItem.referenceId
            );
            _invoiceItem.title = this.orderItems[index].title;
          });
        } else {
          this.isInvoiceCreated = false;
        }
      }

      //Job Details
      this.job.jobNumber = job.jobNumber;
      this.job.title = job.title;
      this.job.isRush = job.isRush;
      this.job.dateIn = job.dateIn;
      this.job.dateDue = job.dateDue;
      this.job.totalItem = job.totalItem;
      this.isPaid = job["isPaid"];
      this.canBypassPayment = job["canBypassPayment"];
      this.pageLoad = true;
      this.job.remainingItemCount = job.remainingItemCount;
      this.job.shippingAttention = job.shippingAttention;
      this.job.shippingCompany = job.shippingCompany;
      this.formatedNumber(job.shippingPhone);
      this.job.shippingPhone = job.shippingPhone;
      this.job.shippingAddressLine1 = job.shippingAddressLine1;
      this.job.shippingAddressLine2 = job.shippingAddressLine2;
      this.job.shippingCity = job.shippingCity;
      this.job.shippingState = job.shippingState;
      this.job.shippingZipCode = job.shippingZipCode;
      this.job.shippingCountry = job.shippingCountry;

      this.job.billingAddressLine1 = job.billingAddressLine1;
      this.job.billingAddressLine2 = job.billingAddressLine2;
      this.job.billingCity = job.billingCity;
      this.job.billingState = job.billingState;
      this.job.billingZipCode = job.billingZipCode;
      this.job.billingCountry = job.billingCountry;

      this.job.isPrinterPlan = job.isPrinterPlan;
      this.job.buyerInfo = job.buyerInfo;
      this.job.isBillToAndShipToSame = job.isBillToAndShipToSame;

      this.job.isAllowedToAssignCsr = job.isAllowedToAssignCsr;
      this.job.csrNo = job.csrNo;
      this.job.csrName = job.csrName;

      this.job.refundStatus = job.refundStatus;
      this.job.guestPaymentLink = job.guestPaymentLink;
      this.job.isGuestPaymentLinkActive = job.isGuestPaymentLinkActive;
      this.job.isPaid = this.isPaid;

      this.job.customerName = job.customerName;
      this.job.customerEmail = job.customerEmail;
      this.job.isApproveArtwork = job.isApproveArtwork;

      this.mapAddress();

      if (this.currentDepartmentId == 0) {
        if (this.data.isTopNavSearch) {
          this.job.items = job.items.filter((item) => item.serNo === 0);
        } else {
          if (isAdminOrCSR || this.permission.isCurrentDepartment("shipping")) {
            this.job.items = job.items.filter((item) => item.serNo === 0);
          } else {
            this.job.items = job.items.filter(
              (item) =>
                item.serNo === 0 &&
                item.departmentId == this.user.currentDepartment.departmentId
            );
          }
        }
      } else {
        if (this.data.isTopNavSearch) {
          this.job.items = job.items.filter((item) => item.serNo === 0);
        } else {
          this.job.items = job.items.filter(
            (item) =>
              item.serNo === 0 && item.departmentId == this.currentDepartmentId
          );
        }
      }

      this.setStepCounter();
      this.job.items.forEach((item, index) => {
        var itemIndex = index;
        //item rush date
        if (item["rushDate"]) {
          var date = new Date(item["rushDate"]);
          var ldate = date.toLocaleString() + " UTC";
          item["rushDate"] = new Date(ldate);
        }

        if (!isAdminOrCSR) {
          item.notes = item.notes.filter((n) => n.type != "SYSTEM_GENERATED");
        }

        //set selected job items index to material tabs
        this.selectedJobItemIndex =
          item["jobItemId"] == this.data.selectedJobItemId
            ? index
            : this.selectedJobItemIndex;
        item["stepChangeLoading"] = false;
        item["visibleSwitchFileUpload"] = false;
        const stepString = item.steps.split("|");
        item.stepActions = [];
        stepString.forEach((stepstr, index) => {
          const stepActionIds = stepstr.split("-");
          const stepAction = this.steps.find(
            (x) => x["stepId"] === parseInt(stepActionIds[0])
          );
          const stepLogComplete = item.logs.find(
            (l) => l.toStep === index && l.isAssign === false
          );
          const stepLogAssign = item.logs.find(
            (l) => l.toStep === index && l.isAssign === true
          );

          // create step of action.
          const stepActions: any = stepAction["stepActions"];
          const step = {
            stepIndex: index,
            step: stepstr,
            stepId: stepActionIds[0],
            actionId: stepActionIds[1],
            stepName: stepAction["stepTitle"],
            stepDepartment: stepAction["department"]["name"],
            stepDepartmentId: stepAction["department"]["departmentId"],
            notes: item.notes
              ? item.notes.filter(
                  (n) => n.step === stepstr && n.stepIndex === index
                )
              : [],
            files: item.files
              ? item.files.filter(
                  (n) => n.step === stepstr && n.stepIndex === index
                )
              : [],
            completedBy: stepLogComplete ? stepLogComplete.actionBy : "",
            totalCompletionTime: stepLogComplete
              ? stepLogComplete.totalTime
              : "",
            startedAt: stepLogAssign ? stepLogAssign.createdAt : "",
            completedAt: stepLogComplete ? stepLogComplete.createdAt : "",
            actionName: stepActions.find(
              (x) => x.actionId === parseInt(stepActionIds[1])
            ).actionName,
            mailingInfo:
              item["mailingInfos"] != null
                ? item["mailingInfos"].find(
                    (m) =>
                      m.step === stepstr &&
                      m.stepIndex === index &&
                      m.jobItemId === item.id
                  )
                : null,
            mailingSort:
              item["mailingSorts"] != null
                ? item["mailingSorts"].find(
                    (m) =>
                      m.step === stepstr &&
                      m.stepIndex === index &&
                      m.jobItemId === item.id
                  )
                : null,
            allowMarkAsComplete: true,
            allowSendTo: true,
          };

          if (item.files != null) {
            item.files.forEach((_file) => {
              if (_file.isSharedToSalesDept == null) {
                _file.isSharedToSalesDept = false;
              }
            });
          }

          if (
            item["isRequiredApproveArtwork"] !== true ||
            item["isImportedToProductionWithoutValidations"]
          ) {
            step.allowSendTo = true;
            step.allowMarkAsComplete = true;
          } else {
            if (
              item.firstStepDepartmentName.toLowerCase() == "design" &&
              (item.currentStepIndex == 0 || item.currentStepIndex == 1)
            ) {
              if (stepAction["department"]["name"].toLowerCase() === "design") {
                step.allowSendTo = false;
                if (
                  !item.files.find(
                    (f) => f.isProofFile && !f.isSharedToSalesDept
                  )
                ) {
                  step.allowMarkAsComplete = false;
                }
                if (item["isApprovedProofFile"] === true) {
                  step.allowMarkAsComplete = true;
                  step.allowSendTo = true;
                }

                if (
                  item.files != null &&
                  item.files != undefined &&
                  item.files.length > 0
                ) {
                  if (
                    item.files[0].isProofFile == true &&
                    item.files[0].isSharedToSalesDept == false
                  ) {
                    step.allowSendTo = false;
                  }
                }
              }
              if (stepAction["department"]["name"].toLowerCase() === "sales") {
                if (item.isApprovedProofFile) {
                  step.allowSendTo = true;
                  step.allowMarkAsComplete = true;
                } else {
                  step.allowSendTo = true;
                  step.allowMarkAsComplete = false;
                }
              }
            }

            if (
              item.firstStepDepartmentName.toLowerCase() == "sales" &&
              (item.currentStepIndex == 0 || item.currentStepIndex == 1)
            ) {
              if (stepAction["department"]["name"].toLowerCase() === "design") {
                step.allowMarkAsComplete = false;
                var filesNotSharedWithSalesDept = item.files.filter(
                  (f) => f.isProofFile == true && f.isSharedToSalesDept == false
                );
                if (filesNotSharedWithSalesDept.length > 0) {
                  step.allowSendTo = true;
                  step.allowMarkAsComplete = false;
                } else {
                  step.allowSendTo = false;
                  if (item.isApprovedProofFile == true) {
                    step.allowSendTo = true;
                    step.allowMarkAsComplete = true;
                  }
                }
              }

              if (stepAction["department"]["name"].toLowerCase() === "sales") {
                if (item.isApprovedProofFile) {
                  step.allowSendTo = true;
                  step.allowMarkAsComplete = true;
                } else {
                  step.allowSendTo = false;
                  step.allowMarkAsComplete = true;
                }

                if (item.files.find((f) => f.isProofFile)) {
                  if (item.isApprovedProofFile != true) {
                    step.allowSendTo = true;
                    step.allowMarkAsComplete = false;
                  }
                }
              }
            }

            if (item.currentStepIndex !== 0 && item.currentStepIndex !== 1) {
              step.allowMarkAsComplete = true;
              step.allowSendTo = true;
            }
          }

          item.selectedStepIndex =
            item.currentStepIndex === index ? index : item.selectedStepIndex; // set current step index.
          item.stepActions.push(step);
        });

        if (item.status === 2) {
          item.selectedStepIndex = item.selectedStepIndex + 1;
        }
        item.notes = item.notes
          ? item.notes.filter((n) => n.step === null)
          : [];
        item.files = item.files ? item.files.filter((f) => f.step == null) : [];
        item.isLoadingStatusForReadyForPickupNotification = false;

        if (item.rfpNotifications) {
          item.rfpNotifications.forEach((_rfpNotification) => {
            var date = new Date(_rfpNotification.modifiedAt);
            var withUTC = date.toLocaleString() + " UTC";
            _rfpNotification.modifiedAt = new Date(withUTC);
          });
        }

        if (item.notes) {
          item.notes.forEach((_note) => {
            if (_note.appendMetaData) {
              _note.text =
                _note.text +
                " by " +
                _note.createdBy +
                " on " +
                this.convertToLocalTimeZone(_note.createdAt);
            }
          });
        }

        item.currentStepAction = item.stepActions[item.currentStepIndex];
      });

      this.loadSelacedJobItemSteps();
      this.showHighPriorityNotes();

      if (this.isPaid) {
        this.invoiceLinkText = "PAID : View Invoice";
      }

      switch (this.job.refundStatus) {
        case "NO_ACTION":
          break;
        case "REFUNDED":
          this.invoiceLinkText = "REFUNDED : View Invoice";
          break;
        case "PARTIAL_REFUND":
          this.invoiceLinkText = "PARTIALLY REFUNDED : View Invoice";
          break;
        case "REFUND_PENDING":
          this.invoiceLinkText = "REFUND PENDING : View Invoice";
          break;
        default:
          break;
      }

      this.job.items.forEach((item, index) => {
        this.updateShouldShowUploadFilesToSwitchButton(index);
      });
    });
  }

  updateShouldShowUploadFilesToSwitchButton(index) {
    var item = this.job.items[index];
    if (
      (item?.departmentName?.toLowerCase() === "print" &&
      item?.currentStepAction?.stepName?.toLowerCase() === "switch" &&
      item?.currentStepAction?.actionName?.toLowerCase() === "switch") || 
      (item?.departmentName?.toLowerCase() === "metal plates" &&
      item?.currentStepAction?.stepName?.toLowerCase() === "metal plates" &&
      item?.currentStepAction?.actionName?.toLowerCase() === "metal plates") ||
       (item?.departmentName?.toLowerCase() === "switch" &&
      item?.currentStepAction?.stepName?.toLowerCase() === "switch" &&
      item?.currentStepAction?.actionName?.toLowerCase() === "switch")
    ) {
      this.job.items[index].visibleSwitchFileUpload = true;
    } else {
      this.job.items[index].visibleSwitchFileUpload = false;
    }
  }

  formatedNumber(phone) {
    if (phone != null) {
      var No = phone
        .split(",")
        .join("")
        .split(")")
        .join("")
        .split("(")
        .join("")
        .split(".")
        .join("")
        .split(" ")
        .join("")
        .split("/")
        .join("")
        .split("*")
        .join("")
        .split("-")
        .join("")
        .split("_")
        .join("");

      var TempNo = No.trim();

      var a = TempNo.toLowerCase().split("ext");

      if (a.length == 1) {
        a = TempNo.toLowerCase().split("x");
        if (a.lenght != 1) {
          this.Extension = a[1];
        }
      } else {
        this.Extension = a[1];
      }
    }
  }

  loadSelacedJobItemSteps() {
    this.jobSteps = [];
    const stepActions = this.job.items[this.selectedJobItemIndex].stepActions;
    stepActions.forEach((stepAction, index) => {
      this.jobSteps.push({
        key: stepAction.step,
        step: stepAction.stepName,
        action: stepAction.actionName,
        index: index,
        departmentName: stepAction.departmentName,
      });
    });

    var self = this;
    setTimeout(function () {
      self.isLoadJobItem = true;
    }, 1000);
  }

  onRemoveFile(fileId, jobItemFiles) {
    var currentJobItem = this.job.items[this.selectedJobItemIndex];
    if (!this.canAccessAddNoteAndFile()) {
      this.showPreviewUnavailableMessage();
      return;
    }

    if (this.job.status === "complete") {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "170px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this file ?",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.api
          .request("DELETE_JOB_ITEM_DOCUMENT", {
            params: { DOCUMENT_ID: fileId },
          })
          .subscribe((r) => {
            const index = jobItemFiles.findIndex((f) => f.fileId === fileId);
            jobItemFiles.splice(index, 1);
            this.updateJobItem();
          });
      }
    });
  }

  onRemoveNote(noteId, notes) {
    if (this.isCheckingForJobUpdate || this.isUpdatingWithPP) {
      return;
    }
    var currentJobItem = this.job.items[this.selectedJobItemIndex];
    if (!this.canAccessAddNoteAndFile()) {
      this.showChangeDepartmentMessage();
      return;
    }

    if (this.job.status === "complete") {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "170px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this note ?",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.api
          .request("DELETE_NOTE", {
            params: { NOTE_ID: noteId },
          })
          .subscribe((r) => {
            const index = notes.findIndex((n) => n.noteId === noteId);
            notes.splice(index, 1);
          });
      }
    });
  }

  canAccessAddNoteAndFile(): boolean {
    try {
      var currentStepIndex =
        this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
      var currentStepDeptName =
        this.job.items[this.selectedJobItemIndex]["stepActions"][
          currentStepIndex
        ]["stepDepartment"].toLowerCase();
      return this.permission.canAccessJobItemDepartment(currentStepDeptName);
    } catch (Error) {
      if (!currentStepDeptName) {
        return true;
      }
      return false;
    }
  }

  IsProofCheckBoxVisible() {
    var item = this.job.items[this.selectedJobItemIndex];
    return (
      item["isRequiredApproveArtwork"] &&
      !item["isImportedToProductionWithoutValidations"] &&
      item["stepActions"][item["currentStepIndex"]][
        "stepDepartment"
      ].toLowerCase() === "design"
    );
  }
  catch(Error) {
    return false;
  }

  IsPrintCheckBoxVisible() {
    let flag = false;
    var item = this.job.items[this.selectedJobItemIndex];
    for (let file of this.myFiles()) {
      if (
        file["isApproveArtwork"] == true &&
        item["stepActions"][item["currentStepIndex"]][
          "stepDepartment"
        ].toLowerCase() === "design"
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  addNote(job) {
    if (!this.canAccessAddNoteAndFile()) {
      return;
    }

    this.addNoteValidate = true;
    if (this.jobNote === "") {
      this.addNoteValidate = false;
      return;
    }

    const note = {
      jobItemId: job.jobItemId,
      step:
        this.selectedNoteStep === ""
          ? null
          : this.jobSteps[this.selectedNoteStep].key,
      stepIndex: this.selectedNoteStep,
      text: this.jobNote,
      priority: this.notePriority,
      type: "DEFAULT",
    };

    this.addNoteLoading = true;

    this.api.request("CREATE_JOB_ITEM_NOTE", { data: note }).subscribe(
      (note: any) => {
        this.jobNote = "";
        note["noteId"] = note["id"];
        note["canDelete"] = note["canDelete"];
        // note['createdBy'] = res['createdBy'];
        // note['createdBy'] = this.user.firstName + ' ' + this.user.lastName;
        // note['noteId'] = res['createdAt'];
        // note['createdAt'] = res['createdAt'];

        if (this.selectedNoteStep !== "") {
          const actionStep = job.stepActions.find(
            (a) => a.stepIndex == this.selectedNoteStep
          );
          actionStep.notes.unshift(note);
        } else {
          job.notes.unshift(note);
        }
        this.addNoteLoading = false;
      },
      (error) => {
        this.addNoteLoading = true;
      }
    );
  }

  uploadFiles(jobItem) {
    if (!this.canAccessAddNoteAndFile()) {
      this.openToast("Please change the department to access.");
      return;
    }
    this.addFileValidate = true;
    if (this.selectedUplodefiles.length === 0) {
      return (this.addFileValidate = false);
    }

    this.addFileLoading = true;
    const formdata = new FormData();
    const fileName = [];

    const uploadFiles = this.selectedUplodefiles.map((f) => {
      return f.file;
    });
    uploadFiles.forEach((_file) => {
      fileName.push(_file["name"]);
      formdata.append("files", _file);
    });

    var createdByDepartment = {
      name: this.auth.getUser().currentDepartment.departmentName.toLowerCase(),
      id: this.auth.getUser().currentDepartment.departmentId,
    };
    if (
      this.auth.getUser().currentDepartment.departmentName.toLowerCase() ===
        "administration" ||
      this.auth.getUser().currentDepartment.departmentName.toLowerCase() ===
        "csr"
    ) {
      var currentStepIndex = jobItem["currentStepIndex"];
      var currentStepDeptName =
        jobItem["stepActions"][currentStepIndex][
          "stepDepartment"
        ].toLowerCase();
      var tempdept = this.allDepartments.find(
        (dept) => dept.name.toLowerCase() === currentStepDeptName
      );
      createdByDepartment = {
        name: tempdept["name"],
        id: tempdept["departmentId"],
      };
    }

    formdata.append("JobItemIds", jobItem.jobItemId);
    formdata.append("IsProofFile", this.isProofFile.toString());
    formdata.append("isPrintFile", this.isPrintFile.toString());
    formdata.append("DepartmentId", createdByDepartment.id.toString());
    const isProofFile = this.isProofFile;
    const isPrintFile = this.isPrintFile;
    this.api.request("CREATE_JOB_ITEM_DOCUMENT", { data: formdata }).subscribe(
      (res: any) => {
        res.forEach((doc, index) => {
          const file = {
            createdBy: doc["createdBy"],
            fileId: doc["id"],
            fileName: doc["name"],
            createdAt: doc["createdAt"],
            isProofFile: isProofFile,
            isPrintFile: isPrintFile,
            isSharedToCustomer: false,
            isSharedToSalesDept: false,
            createdByDepartment: createdByDepartment,
            canDelete: true,
          };
          jobItem.files.unshift(file);
          var currentStepIndex =
            this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
          var currentStepDeptName =
            this.job.items[this.selectedJobItemIndex]["stepActions"][
              currentStepIndex
            ]["stepDepartment"].toLowerCase();
          if (isProofFile) {
            // jobItem.files.forEach(f => {
            //   f['isShowApprovalStatus'] =false;
            // });
            jobItem["approvedRequiredArtwork"] = false;
            if (jobItem.firstStepDepartmentName.toLowerCase() == "sales") {
              var currentStepIndex =
                this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
              var currentStepDeptName =
                this.job.items[this.selectedJobItemIndex]["stepActions"][
                  currentStepIndex
                ]["stepDepartment"].toLowerCase();
              if (currentStepDeptName.toLowerCase() === "design") {
                jobItem.stepActions[
                  jobItem.currentStepIndex
                ].allowMarkAsComplete = false;
                jobItem.stepActions[jobItem.currentStepIndex].allowSendTo =
                  true;
              }
            } else if (
              jobItem.firstStepDepartmentName.toLowerCase() == "design"
            ) {
              var currentStepIndex =
                this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
              var currentStepDeptName =
                this.job.items[this.selectedJobItemIndex]["stepActions"][
                  currentStepIndex
                ]["stepDepartment"].toLowerCase();
              if (currentStepDeptName.toLowerCase() === "design") {
                jobItem.stepActions[
                  jobItem.currentStepIndex
                ].allowMarkAsComplete = true;
                jobItem.stepActions[jobItem.currentStepIndex].allowSendTo =
                  false;
              }
            }
          }
          this.selectedUplodefiles = [];
        });
        this.store.dispatch(new ReloadeJobs());
        this.addFileLoading = false;
        this.updateJobItem();
      },
      (error) => {
        this.addFileLoading = false;
      }
    );
  }

  onClickMarkComplete(action, jobItem) {
    if (!this.isAdminOrCSR) {
      if (!this.canAccessAddNoteAndFile()) {
        return;
      }
    }

    if (!jobItem.isImportedToProductionWithoutValidations) {
      var canSendToProduction = this.canSendToProduction();
      if (!canSendToProduction) {
        this.openToast("Payment is required!");
        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "200px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to complete this job item step ?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeJobStep(action, jobItem);
      }
    });
  }

  onSendToClick(jobItemId: number) {
    let steps = [];

    //get all current job steps.
    let jobItems = [];
    var jobItem = this.job.items.find((i) => i.jobItemId === jobItemId);

    if (!this.isAdminOrCSR) {
      if (!this.canAccessAddNoteAndFile()) {
        return;
      }
    }

    let warningMessage = "";

    if (!jobItem.isImportedToProductionWithoutValidations) {
      if (
        jobItem["isRequiredApproveArtwork"] &&
        (jobItem.currentStepIndex == 0 || jobItem.currentStepIndex == 1)
      ) {
        if (
          jobItem.firstStepDepartmentName.toLowerCase() == "design" &&
          (jobItem.currentStepIndex == 0 || jobItem.currentStepIndex == 1)
        ) {
          if (
            (jobItem["isRequiredApproveArtwork"] &&
              !jobItem["isApprovedProofFile"]) ||
            (!this.isPaid && !jobItem["isBypassed"])
          ) {
            if (
              !this.isPaid &&
              jobItem["isRequiredApproveArtwork"] &&
              jobItem["isApprovedProofFile"]
            ) {
              //this.openToast(" Job Item Cannot be 'send to' next steps because payment is pending.");
              warningMessage =
                "Job Item Cannot be 'send to' next steps (production) because payment is pending.";
            }
            var currentStepIndex =
              this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
            var currentStepDeptName =
              this.job.items[this.selectedJobItemIndex]["stepActions"][
                currentStepIndex
              ]["stepDepartment"];
            if (currentStepDeptName.toLowerCase() == "sales") {
              const sa = jobItem["stepActions"][0];
              steps.push({
                stepAction: sa["stepName"] + "/" + sa["actionName"],
                stepActionId: sa["stepId"] + "-" + sa["actionId"],
              });
              jobItems.push(jobItem);
            } else if (currentStepDeptName.toLowerCase() == "design") {
              const sa = jobItem["stepActions"][1];
              steps.push({
                stepAction: sa["stepName"] + "/" + sa["actionName"],
                stepActionId: sa["stepId"] + "-" + sa["actionId"],
              });
              jobItems.push(jobItem);
            } else {
              const designStep = jobItem["stepActions"][0];
              steps.push({
                stepAction:
                  designStep["stepName"] + "/" + designStep["actionName"],
                stepActionId:
                  designStep["stepId"] + "-" + designStep["actionId"],
              });
              jobItems.push(jobItem);
              const salesStep = jobItem["stepActions"][1];
              steps.push({
                stepAction:
                  salesStep["stepName"] + "/" + salesStep["actionName"],
                stepActionId: salesStep["stepId"] + "-" + salesStep["actionId"],
              });
              jobItems.push(jobItem);
            }
          } else {
            this.job.items.forEach((item) => {
              if (
                item.status != 2 &&
                (!item["isRequiredApproveArtwork"] ||
                  (item["isRequiredApproveArtwork"] &&
                    item["isApprovedProofFile"]))
              ) {
                item["stepActions"]
                  .filter((sa) => sa.stepIndex != item.currentStepIndex)
                  .forEach((sa) => {
                    const exist = steps.find(
                      (s) =>
                        s["stepActionId"] ===
                        sa["stepId"] + "-" + sa["actionId"]
                    );
                    if (!exist) {
                      steps.push({
                        stepAction: sa["stepName"] + "/" + sa["actionName"],
                        stepActionId: sa["stepId"] + "-" + sa["actionId"],
                      });
                    }
                  });

                jobItems.push(item);
              }
            });
          }
        }

        if (
          jobItem.firstStepDepartmentName.toLowerCase() == "sales" &&
          (jobItem.currentStepIndex == 0 || jobItem.currentStepIndex == 1)
        ) {
          if (
            (jobItem["isRequiredApproveArtwork"] &&
              !jobItem["isApprovedProofFile"]) ||
            (!this.isPaid && !jobItem["isBypassed"])
          ) {
            if (
              !this.isPaid &&
              jobItem["isRequiredApproveArtwork"] &&
              jobItem["isApprovedProofFile"]
            ) {
              warningMessage =
                "Job Item Cannot be 'send to' next steps (production) because payment is pending.";
              //this.openToast("Some steps are hidden due to pending payment!");
            }
            var currentStepIndex =
              this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
            var currentStepDeptName =
              this.job.items[this.selectedJobItemIndex]["stepActions"][
                currentStepIndex
              ]["stepDepartment"];
            if (currentStepDeptName.toLowerCase() == "sales") {
              const sa = jobItem["stepActions"][1];
              steps.push({
                stepAction: sa["stepName"] + "/" + sa["actionName"],
                stepActionId: sa["stepId"] + "-" + sa["actionId"],
              });
              jobItems.push(jobItem);
            } else if (currentStepDeptName.toLowerCase() == "design") {
              const sa = jobItem["stepActions"][0];
              steps.push({
                stepAction: sa["stepName"] + "/" + sa["actionName"],
                stepActionId: sa["stepId"] + "-" + sa["actionId"],
              });
              jobItems.push(jobItem);
            } else {
              const salesStep = jobItem["stepActions"][0];
              steps.push({
                stepAction:
                  salesStep["stepName"] + "/" + salesStep["actionName"],
                stepActionId: salesStep["stepId"] + "-" + salesStep["actionId"],
              });
              jobItems.push(jobItem);
              const designStep = jobItem["stepActions"][1];
              steps.push({
                stepAction:
                  designStep["stepName"] + "/" + designStep["actionName"],
                stepActionId:
                  designStep["stepId"] + "-" + designStep["actionId"],
              });
              jobItems.push(jobItem);
            }
          } else {
            if (
              jobItem.status != 2 &&
              (!jobItem["isRequiredApproveArtwork"] ||
                (jobItem["isRequiredApproveArtwork"] &&
                  jobItem["isApprovedProofFile"]))
            ) {
              jobItem["stepActions"]
                .filter((_sa) => _sa.stepIndex !== jobItem.currentStepIndex)
                .forEach((sa) => {
                  const exist = steps.find(
                    (s) =>
                      s["stepActionId"] === sa["stepId"] + "-" + sa["actionId"]
                  );
                  if (!exist) {
                    steps.push({
                      stepAction: sa["stepName"] + "/" + sa["actionName"],
                      stepActionId: sa["stepId"] + "-" + sa["actionId"],
                    });
                  }
                });

              jobItems.push(jobItem);
            }

            var currentStepIndex =
              this.job.items[this.selectedJobItemIndex]["currentStepIndex"];
            var currentStepDeptName =
              this.job.items[this.selectedJobItemIndex]["stepActions"][
                currentStepIndex
              ]["stepDepartment"];
            var currentStepFiles =
              this.job.items[this.selectedJobItemIndex]["files"];
            if (currentStepDeptName.toLowerCase() == "design") {
              if (
                this.job.items[this.selectedJobItemIndex][
                  "isRequiredApproveArtwork"
                ]
              ) {
                var filesNotSharedWithSalesDept = currentStepFiles.filter(
                  (f) => f.isProofFile == true && f.isSharedToSalesDept == false
                );
                if (filesNotSharedWithSalesDept.length > 0) {
                  //Add only sales dept here
                  steps = [];
                  const sa = jobItem["stepActions"][0];
                  steps.push({
                    stepAction: sa["stepName"] + "/" + sa["actionName"],
                    stepActionId: sa["stepId"] + "-" + sa["actionId"],
                  });
                }
              } else {
                //Just pass jobitems as it is
              }
            }
          }
        }
      } else {
        this.job.items.forEach((item) => {
          if (
            item.status != 2 &&
            (!item["isRequiredApproveArtwork"] ||
              (item["isRequiredApproveArtwork"] &&
                item["isApprovedProofFile"]) ||
              (item.currentStepIndex != 0 && item.currentStepIndex != 1))
          ) {
            item["stepActions"]
              .filter((sa) => sa.stepIndex != item.currentStepIndex)
              .forEach((sa) => {
                const exist = steps.find(
                  (s) =>
                    s["stepActionId"] === sa["stepId"] + "-" + sa["actionId"]
                );
                if (!exist) {
                  steps.push({
                    stepAction: sa["stepName"] + "/" + sa["actionName"],
                    stepActionId: sa["stepId"] + "-" + sa["actionId"],
                  });
                }
              });

            jobItems.push(item);
          }
        });
      }
    } else {
      this.job.items.forEach((item) => {
        if (
          item.status != 2 &&
          (!item["isRequiredApproveArtwork"] ||
            item["isImportedToProductionWithoutValidations"] ||
            (item["isRequiredApproveArtwork"] && item["isApprovedProofFile"]) ||
            (item.currentStepIndex != 0 && item.currentStepIndex != 1))
        ) {
          item["stepActions"]
            .filter((sa) => sa.stepIndex != item.currentStepIndex)
            .forEach((sa) => {
              const exist = steps.find(
                (s) => s["stepActionId"] === sa["stepId"] + "-" + sa["actionId"]
              );
              if (!exist) {
                steps.push({
                  stepAction: sa["stepName"] + "/" + sa["actionName"],
                  stepActionId: sa["stepId"] + "-" + sa["actionId"],
                });
              }
            });

          jobItems.push(item);
        }
      });
    }

    const dialogRef = this.dialog.open(JobSendBackComponent, {
      height: "auto",
      width: "900px",
      data: {
        steps: steps,
        jobItems: jobItems,
        currentJobItemId: jobItemId,
        warningMessage: warningMessage,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var sendJobItems: SendJobItem[] = [];
        res.selectedItems.forEach((selectedItem, index) => {
          let isLast = res.selectedItems.length === index + 1 ? true : false;
          sendJobItems.push({
            jobItem: selectedItem.jobItem,
            changeStepIndex: selectedItem.changeStepIndex,
            jobItemIndex: selectedItem.jobItemIndex,
            reason: res.reason,
            isLast: isLast,
          });
        });
        this.sendBackJobItem(sendJobItems);
      }
    });
  }

  sendBackJobItem(sendJobItems: SendJobItem[]) {
    if (!sendJobItems) {
      return;
    }

    var jobId = this.job.jobId;
    var jobItems = sendJobItems.map((e) => e.jobItem);
    sendJobItems.forEach((element) => {
      this.job.items[element.jobItemIndex].stepChangeLoading = true;
    });

    const data = {
      JobItemIds: sendJobItems.map((e) => e.jobItem.jobItemId),
      ChangeStepIndex: sendJobItems[0].changeStepIndex,
      Reason: sendJobItems[0].reason,
    };

    this.api.request("CHANGE_CURRENT_STEP", { data: data }).subscribe(
      (r) => {
        sendJobItems.forEach(
          ({ jobItem, changeStepIndex, jobItemIndex, isLast, reason }) => {
            // send notification.
            this.isProofFile = false;
            this.sendNotification(jobItem, changeStepIndex);

            // change job item current step
            this.job.items[jobItemIndex]["currentStepLogStatus"] = false;
            this.job.items[jobItemIndex]["currentStepLogTime"] = 0;
            this.job.items[jobItemIndex]["stepActions"][
              jobItem.currentStepIndex
            ].completedBy = "";

            // change current step.
            const peviousStepIndex = jobItem.currentStepIndex;
            jobItem.currentStepIndex = changeStepIndex;
            jobItem.selectedStepIndex = changeStepIndex;
            jobItem.departmentName =
              jobItem.stepActions[jobItem.selectedStepIndex].stepDepartment;
            jobItem.departmentId =
              jobItem.stepActions[jobItem.selectedStepIndex].stepDepartmentId;
            jobItem.currentStepAction =
              jobItem.stepActions[jobItem.currentStepIndex];

            this.addReasonToChangeJobStep(
              this.job.items[jobItemIndex],
              jobItem.currentStepIndex,
              reason
            );

            if (!this.permission.isAdminOrCSR()) {
              var havePermissionToViewJobItem =
                this.permission.isCurrentDepartment(
                  jobItem.stepActions[jobItem.currentStepIndex].stepDepartment
                );
              if (!havePermissionToViewJobItem) {
                this.store.dispatch(
                  new RemoveJobItem({
                    JobId: jobId,
                    JobItemId: jobItem.jobItemId,
                  })
                );
                this.dialogRef.close();
                return;
              }
            }

            //set started datetime on current stepaction
            var now = new Date();
            jobItem.stepActions[jobItem.currentStepIndex].startedAt =
              now.toISOString();

            // add action completed by and total completed time of action.
            jobItem.stepActions[peviousStepIndex].completedBy =
              this.user.firstName + " " + this.user.lastName;
            jobItem.stepActions[peviousStepIndex].totalCompletionTime =
              this.stepCounter;
            jobItem.stepActions[peviousStepIndex].completedAt =
              now.toISOString();
            // load updated jobs and load logs.
            this.store.dispatch(new LoadJobLogs(jobItem.jobItemId));
            //this.updateJobItem();
            if (isLast) {
              //this.store.dispatch(new LoadJobs(this.user.filter[this.currentDepartmentId]));
              this.store.dispatch(new ReloadeJobs());
            }

            this.job.items[jobItemIndex].stepChangeLoading = false;

            //Change Mark as complete and send to
            if (
              this.job.items[jobItemIndex]["isRequiredApproveArtwork"] &&
              !this.job.items[jobItemIndex][
                "isImportedToProductionWithoutValidations"
              ] &&
              (this.job.items[jobItemIndex]["currentStepIndex"] == 0 ||
                this.job.items[jobItemIndex]["currentStepIndex"] == 1)
            ) {
              if (
                this.job.items[
                  jobItemIndex
                ].firstStepDepartmentName.toLowerCase() == "design"
              ) {
                if (jobItem.currentStepIndex === 0) {
                  var stepAction =
                    this.job.items[jobItemIndex]["stepActions"][
                      jobItem.currentStepIndex
                    ];
                  stepAction.allowSendTo = false;
                  var files = this.job.items[jobItemIndex]["files"];
                  files.forEach((_file) => {
                    if (_file.isProofFile) {
                      _file.isSharedToSalesDept = true;
                    }
                  });
                  var filesNotSharedToSalesDept = files.find(
                    (f) => f.isProofFile && f.isSharedToSalesDept == false
                  );
                  if (
                    filesNotSharedToSalesDept != null &&
                    filesNotSharedToSalesDept != undefined
                  ) {
                    if (filesNotSharedToSalesDept.length == 0) {
                      stepAction.allowMarkAsComplete = false;
                    }
                  } else {
                    stepAction.allowMarkAsComplete = false;
                  }

                  if (
                    this.job.items[jobItemIndex]["isApprovedProofFile"] == true
                  ) {
                    stepAction.allowMarkAsComplete = true;
                    stepAction.allowSendTo = true;
                  }

                  if (files[0] != null && files[0] != undefined) {
                    if (
                      files[0].isProofFile == true &&
                      files[0].isSharedToSalesDept == false
                    ) {
                      stepAction.allowSendTo = false;
                    }
                  }
                }

                if (jobItem.currentStepIndex === 1) {
                  var stepAction =
                    this.job.items[jobItemIndex]["stepActions"][
                      jobItem.currentStepIndex
                    ];
                  stepAction.allowSendTo = false;
                  var files = this.job.items[jobItemIndex]["files"];
                  files.forEach((_file) => {
                    if (_file.isProofFile) {
                      _file.isSharedToSalesDept = true;
                    }
                  });
                  if (
                    this.job.items[jobItemIndex]["isApprovedProofFile"] == true
                  ) {
                    stepAction.allowMarkAsComplete = true;
                    stepAction.allowSendTo = true;
                  }
                }
              }

              if (
                this.job.items[
                  jobItemIndex
                ].firstStepDepartmentName.toLowerCase() == "sales"
              ) {
                if (jobItem.currentStepIndex === 0) {
                  var stepAction =
                    this.job.items[jobItemIndex]["stepActions"][
                      jobItem.currentStepIndex
                    ];
                  var files = this.job.items[jobItemIndex]["files"];
                  if (files.filter((f) => f.isProofFile).length > 0) {
                    stepAction.allowSendTo = true;
                    stepAction.allowMarkAsComplete = false;
                  }
                  if (this.job.items[jobItemIndex].isApprovedProofFile) {
                    stepAction.allowSendTo = true;
                    stepAction.allowMarkAsComplete = true;
                  }
                }
                if (jobItem.currentStepIndex === 1) {
                  var stepAction =
                    this.job.items[jobItemIndex]["stepActions"][
                      jobItem.currentStepIndex
                    ];
                  stepAction.allowMarkAsComplete = false;
                  stepAction.allowSendTo = false;
                  var files = this.job.items[jobItemIndex]["files"];
                  files.forEach((_file) => {
                    if (_file.isProofFile) {
                      _file.isSharedToSalesDept = true;
                    }
                  });
                  if (
                    this.job.items[jobItemIndex]["isApprovedProofFile"] == true
                  ) {
                    stepAction.allowMarkAsComplete = true;
                    stepAction.allowSendTo = true;
                  }
                }
              }
            } else {
              var stepAction =
                this.job.items[jobItemIndex]["stepActions"][
                  jobItem.currentStepIndex
                ];
              stepAction.allowSendTo = true;
              stepAction.allowMarkAsComplete = true;
            }
          }
        );
        this.getSendNotificationForReadyForPickupStatus();
        this.updateShouldShowUploadFilesToSwitchButton(
          this.selectedJobItemIndex
        );
      },
      (error) => {
        sendJobItems.forEach((element) => {
          this.job.items[element.jobItemIndex].stepChangeLoading = false;
        });
      }
    );
  }

  addReasonToChangeJobStep(jobItem, stepIndex, reason) {
    const note = {
      jobItemId: jobItem.jobItemId,
      stepIndex: stepIndex,
      text: reason,
      step: jobItem.steps.split("|")[stepIndex],
      priority: "low",
    };

    this.api
      .request("CREATE_JOB_ITEM_NOTE", { data: note })
      .subscribe((note: any) => {
        this.jobNote = "";
        note["noteId"] = note["id"];
        jobItem.stepActions[jobItem.currentStepIndex].notes.unshift(note);
      });
  }

  changeJobStep(action, jobItem) {
    var jobId = this.job.jobId;
    var jobItemId = jobItem.jobItemId;

    const data = {
      jobItemIds: [jobItem.jobItemId],
    };
    this.stepCompleteLoading = true;
    this.api.request("COMPLETE_CURRENT_STEP", { data: data }).subscribe(
      (res) => {
        this.isProofFile = false;
        this.stepCompleteLoading = false;
        this.sendNotification(jobItem, jobItem.currentStepIndex + 1);

        // add action completed by and total completed time of action.
        action.completedBy = this.user.firstName + " " + this.user.lastName;
        action.totalCompletionTime = this.stepCounter;

        // change job item current step
        this.job.items[this.selectedJobItemIndex]["currentStepLogStatus"] =
          false;
        this.job.items[this.selectedJobItemIndex]["currentStepLogTime"] = 0;

        jobItem.currentStepIndex = jobItem.currentStepIndex + 1;
        jobItem.selectedStepIndex = jobItem.selectedStepIndex + 1; // navigate to next step.
        jobItem.departmentName =
          jobItem &&
          jobItem.stepActions &&
          jobItem.stepActions[jobItem.selectedStepIndex] &&
          jobItem.stepActions[jobItem.selectedStepIndex].stepDepartment
            ? jobItem.stepActions[jobItem.selectedStepIndex].stepDepartment
            : null;
        jobItem.departmentId =
          jobItem &&
          jobItem.stepActions &&
          jobItem.stepActions[jobItem.selectedStepIndex] &&
          jobItem.stepActions[jobItem.selectedStepIndex].stepDepartmentId
            ? jobItem.stepActions[jobItem.selectedStepIndex].stepDepartmentId
            : 0;

        //mark job as complete
        const stpCnt = jobItem["steps"].split("|").length;
        if (stpCnt === jobItem.currentStepIndex) {
          jobItem.status = 2;
        }

        if (!this.permission.isAdminOrCSR()) {
          var havePermissionToViewJobItem = this.permission.isCurrentDepartment(
            jobItem.stepActions[jobItem.currentStepIndex].stepDepartment
          );
          if (!havePermissionToViewJobItem) {
            this.store.dispatch(
              new RemoveJobItem({ JobId: jobId, JobItemId: jobItemId })
            );
            this.dialogRef.close();
            return;
          }
        }

        //set started time of current stepaction
        if (jobItem.stepActions[jobItem.currentStepIndex]) {
          var now = new Date();
          jobItem.stepActions[jobItem.currentStepIndex].startedAt =
            now.toISOString();
          jobItem.stepActions[jobItem.currentStepIndex - 1].completedAt =
            now.toISOString();
          jobItem.currentStepAction =
            jobItem.stepActions[jobItem.currentStepIndex];
        }

        //Update job and load loags.
        this.store.dispatch(new LoadJobLogs(jobItem.jobItemId));
        //this.store.dispatch(new LoadJobs(this.user.filter[this.currentDepartmentId]));

        if (
          jobItem["approvedRequiredArtwork"] &&
          !jobItem["isImportedToProductionWithoutValidations"] &&
          (jobItem.currentStepIndex == 0 || jobItem.currentStepIndex == 1)
        ) {
          if (jobItem.firstStepDepartmentName.toLowerCase() == "design") {
            if (
              jobItem["approvedRequiredArtwork"] &&
              jobItem.currentStepIndex == 1
            ) {
              let file = jobItem.files.find(
                (f) => f.isProofFile && f.isSharedToCustomer
              );
              if (file) {
                jobItem.stepActions[
                  jobItem.currentStepIndex
                ].allowMarkAsComplete = file.isApproveArtwork;
              }
            }
          }

          if (jobItem.firstStepDepartmentName.toLowerCase() == "sales") {
            if (
              jobItem["approvedRequiredArtwork"] &&
              jobItem.currentStepIndex == 1
            ) {
              let file = jobItem.files.find(
                (f) => f.isProofFile && f.isSharedToCustomer
              );
              if (file) {
                jobItem.stepActions[
                  jobItem.currentStepIndex
                ].allowMarkAsComplete = file.isApproveArtwork;
              } else {
                jobItem.stepActions[
                  jobItem.currentStepIndex
                ].allowMarkAsComplete = false;
                jobItem.stepActions[jobItem.currentStepIndex].allowSendTo =
                  false;
              }
            }
          }
        }

        this.store.dispatch(new ReloadeJobs());
        this.getSendNotificationForReadyForPickupStatus();
        this.updateShouldShowUploadFilesToSwitchButton(
          this.selectedJobItemIndex
        );
      },
      (error) => (this.stepCompleteLoading = false)
    );
  }

  updateJobItem() {
    const params = {
      params: {
        JOB_ID: this.job["jobId"],
        DEPARTMENT_ID: this.currentDepartmentId,
      },
    };

    this.api.request("GET_JOB_ITEMS", params).subscribe((job) => {
      this.store.dispatch(new UpdateJob(job));
    });
  }

  onJobItemChange(index) {
    var self = this;
    if (this.isLoadJobItem) {
      this.isLoadJobItem = false;
      this.selectedJobItemIndex = index;
      this.showHighPriorityNotes();
      this.notePriority = "low";
      this.selectedNoteStep = "";
      this.stepCounter =
        self.job.items[self.selectedJobItemIndex]["currentStepLogTime"];
      this.loadSelacedJobItemSteps();
      if (this.currentToast) {
        this.currentToast.dismiss();
      }
    }
  }

  onShowFile(file) {
    if (!this.canAccessAddNoteAndFile()) {
      this.showChangeDepartmentMessage();
      return;
    }

    if (file["isUploadedByCustomer"]) {
      this.fileResourceUrl = `${environment.customerPortalUrl}/api/documents/${file.fileId}`;
      this.excelFileResourceURL = `${environment.customerPortalUrl}/api/documents/${file.fileId}`;
      this.fileURL = `${environment.customerPortalUrl}/api/documents/${file.fileId}`;
    } else {
      this.fileResourceUrl = `${environment.baseUrl}/api/documents/${file.fileId}`;
      this.excelFileResourceURL = `${environment.baseUrl}/api/documents/${file.fileId}`;
      this.fileURL = `${environment.baseUrl}/api/documents/${file.fileId}`;
    }
    if (!this.isPreview(file.fileName)) {
      return;
    }
    if (!file["isUploadedByCustomer"]) {
      this.api
        .request("CHECK_PREVIEW_AVAILABEL", {
          params: { DOCUMENT_ID: file.fileId },
        })
        .subscribe(
          (isPreview) => {
            if (isPreview) {
              const fileView = document.getElementById("fileView");
              this.isWorkflowView = true;
              this.isShowFile = true;
              this.file = file;
              this.docType = this.isImageTypeDocument(file.fileName);
            } else {
              this.showPreviewUnavailableMessage();
            }
          },
          (error) => {
            this.showPreviewUnavailableMessage();
          }
        );
    }
    if (file["isUploadedByCustomer"]) {
      fetch(
        `${environment.customerPortalUrl}/api/documents/${file.fileId}/preview`
      )
        .then(function (response) {
          return response.json();
        })
        .then((isPreview) => {
          if (isPreview) {
            const fileView = document.getElementById("fileView");
            this.isWorkflowView = true;
            this.isShowFile = true;
            this.file = file;
            this.docType = this.isImageTypeDocument(file.fileName);
          } else {
            this.showPreviewUnavailableMessage();
          }
        })
        .catch(() => {
          this.showPreviewUnavailableMessage();
        });
    }
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  onFileViewClose() {
    this.isWorkflowView = false;
    this.isShowFile = false;
    this.selectedJobItemIndex = this.selectedJobItemIndex;
    this.noteFilesTabIndex = 1;
  }

  isImageTypeDocument(fileName: string) {
    let docType = "Document";
    const fileExtension = fileName.split(".")[fileName.split(".").length - 1];
    return this.getDocumentType(fileExtension);
  }

  canAccess(departmentName) {
    return this.permission.canAccessJobItemDepartment(departmentName);
  }

  getFileSrc(file) {
    let docType = "Document";
    let documentURL = "";
    docType = this.isImageTypeDocument(file.fileName);
    if (docType === "Image") {
      if (file["isUploadedByCustomer"]) {
        documentURL = `${environment.customerPortalUrl}/api/documents/${file.fileId}/thumbnail`;
      } else {
        documentURL = `${environment.baseUrl}/api/documents/${file.fileId}/thumbnail`;
      }
    } else {
      documentURL = this.getDocumentUrl(docType);
    }
    return documentURL;
  }

  onFileDownload(file: any) {
    if (!this.canAccessAddNoteAndFile()) {
      this.showChangeDepartmentMessage();
      return;
    }

    var fileId = file.fileId;
    var url = "";
    if (file["isUploadedByCustomer"]) {
      url = `${environment.customerPortalUrl}/api/documents/${fileId}/download`;
    } else {
      url = `${environment.baseUrl}/api/documents/${fileId}/download`;
    }
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", url);
    linkElement.dispatchEvent(clickEvent);
  }

  sendNotification(jobItem, changeStepIndex) {
    const notification = {
      JobId: this.job.jobId,
      JobNumber: this.job.jobNumber,
      JobItemNo: jobItem.itemNo,
      jobItemName: jobItem.description,
      steps: jobItem.steps,
      CurrentStepIndex: jobItem.currentStepIndex,
      changeStepIndex: changeStepIndex,
    };
    this.notificationHub.sendNotification(notification);
  }

  importJobItem(isImportToProduction) {
    if (this.job.isPrinterPlan) {
      this.dialog.closeAll();

      this.dialog.open(JobImportComponent, {
        width: "95vw",
        maxWidth: "95vw",
        data: Object.assign({}, this.job),
      });
      return;
    }

    this.api
      .request("GET_ORDER", { params: { ID: this.job["jobNumber"] } })
      .subscribe((res: Order) => {
        if (isImportToProduction) {
          if (res.items) {
            res.items.forEach((_item) => {
              _item.isImportToProduction = true;
            });
          }
        }

        if (!isImportToProduction) {
          if (
            res.items.filter(
              (i) => i.isRequiredApproveArtwork == true && !i.isImport
            ).length == 0 &&
            !res.canBypassPayment &&
            res.paymentStatus == 1
          ) {
            this.openToast("Cannot Import job item since Payment is Pending.");
            return;
          }
        }

        this.dialog.closeAll();

        let order = Object.assign({}, this.job);
        order["id"] = this.job["jobNumber"];
        order.isImportToProduction = isImportToProduction;
        this.dialog.open(JobOrderImportComponent, {
          width: "95vw",
          maxWidth: "95vw",
          data: Object.assign({}, order),
        });
      });
  }

  editJobItem(jobItemId) {
    if (this.isCheckingForJobUpdate || this.isUpdatingWithPP) {
      return;
    }
    this.dialog.closeAll();
    const _job = Object.assign({}, this.job);
    _job.items = this.job.items.filter((ji) => ji.jobItemId === jobItemId);

    this.dialog.open(JobImportWorkflowComponent, {
      width: "95vw",
      maxWidth: "95vw",
      data: _job,
    });
  }

  onShowingInfo(step: any, type: string) {
    const component: any =
      type === "info" ? JobMailingInfoComponent : JobMailingSortComponent;
    this.dialog.open(component, {
      width: "950px",
      data: step,
    });
  }

  selectFileToUpload(event) {
    for (let index = 0; index < event.length; index++) {
      const reader = new FileReader();
      const selectedFile = {
        file: event[index],
      };

      const fileName = event[index].name;
      const fileExtension = fileName
        .split(".")
        [fileName.split(".").length - 1].replace('"', "");
      let docType = "Document";
      docType = this.getDocumentType(fileExtension);

      if (docType == "Document") {
        this.openToast(
          "Please Select PDF FILES , EXCEL FILES and IMAGE FILES Only."
        );
        continue;
      }

      if (docType != "Image") {
        selectedFile["url"] = this.getDocumentUrl(docType);
      } else {
        reader.readAsDataURL(event[index]);
        reader.onload = (event: any) => {
          selectedFile["url"] = event.target["result"];
        };
      }
      this.selectedUplodefiles.push(selectedFile);
    }
  }

  removeSelectedFile(index) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "200px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to remove this file?",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.selectedUplodefiles.splice(index, 1);
      }
    });
  }

  getDocumentUrl(docType: string) {
    switch (docType.toLowerCase()) {
      case "pdf":
        return "assets/images/pdf_thumbnail.svg";
      case "document":
        return "assets/images/doc_thumbnail.svg";
      case "excel":
        return "assets/images/xls_thumbnail.png";
      case "tiff":
        return "assets/images/tiff_thumbnail.svg";
      case "tif":
        return "assets/images/tiff_thumbnail.svg";
      case "svg":
        return "assets/images/svg_thumbnail.svg";
      case "zip":
        return "assets/images/zip_thumbnail.svg";
      default:
        return "assets/images/doc_thumbnail.svg";
    }
  }

  getDocumentType(fileExtension: string) {
    switch (fileExtension.toLowerCase()) {
      case "jpg":
        return "Image";
      case "jpeg":
        return "Image";
      case "png":
        return "Image";
      case "svg":
        return "Svg";
      case "gif":
        return "Image";
      case "tiff":
        return "Tiff";
      case "tif":
        return "Tiff";
      case "pdf":
        return "Pdf";
      case "xlsx":
        return "Excel";
      // case 'csv':
      //   return 'Csv';
      case "xls":
        return "Excel";
      // case 'zip':
      //   return 'zip';
      default:
        return "Document";
    }
  }

  updateRushDate(jobItemId, rushDate) {
    if (!rushDate) {
      return;
    }

    const apiData = {
      jobItemId: jobItemId,
      rushDate: rushDate,
    };

    this.api.request("UPDATE_RUSH_DATE", { data: apiData }).subscribe(
      (res) => {
        if (res.success) {
          this.updateJobItem();
          this.job["isRush"] = true;
          this.openToast("Rush date update successfully.");
        } else {
          this.openToast("Rush date not update.");
        }
      },
      (error) => {
        this.openToast("Rush date not update.");
      }
    );
  }

  isPreview(fileName: string) {
    const fileExtension = fileName
      .split(".")
      [fileName.split(".").length - 1].replace('"', "")
      .toLowerCase();
    if (
      fileExtension === "pdf" ||
      fileExtension === "png" ||
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "svg" ||
      fileExtension === "tiff" ||
      fileExtension == "xlsx" ||
      fileExtension == "xls" ||
      fileExtension == "tif"
    ) {
      return true;
    }

    return false;
  }

  updateJobFromPP() {
    this.job["reload"] = true;
    this.api
      .request("UPDATE_JOB_From_PP", {
        data: { jobNumber: this.job.jobNumber },
      })
      .subscribe(
        (res) => {
          if (res.success) {
            this.store.dispatch(new ReloadeJobs());
            this.loadJobDetails();
            this.openToast(res["message"]);
            this.isUpdatingWithPP = false;
          } else {
            this.openToast(res["message"]);
          }
        },
        (error) => {
          this.openToast("job is not update.");
        }
      );
  }

  mapAddress() {
    let address = "";
    address += !this.job.shippingAddressLine1
      ? ""
      : this.job.shippingAddressLine1.trim();
    address += !this.job.shippingAddressLine2
      ? ""
      : `/ ${this.job.shippingAddressLine2.trim()}`;
    address += " \n ";
    address += !this.job.shippingCity ? "" : `${this.job.shippingCity}`;
    address += !this.job.shippingState ? "" : ",";
    address += !this.job.shippingState ? "" : ` ${this.job.shippingState}`;
    address += !this.job.shippingZipCode ? "" : ",";
    address += !this.job.shippingZipCode ? "" : ` ${this.job.shippingZipCode}`;
    address += !this.job.shippingCountry ? "" : ",";
    address += !this.job.shippingCountry ? "" : ` ${this.job.shippingCountry}`;
    this.job["address"] = address;
  }

  shareToCustomer(item, file) {
    if (!this.canAccessAddNoteAndFile()) {
      this.openToast("Please change the department to access");
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "200px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to share this proof file to customer?",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.shareProofFile(item, file);
      }
    });
  }

  showPreviewUnavailableMessage() {
    this.showPreviewUnavailableMsg = true;

    setTimeout(() => {
      this.showPreviewUnavailableMsg = false;
    }, 2500);
  }

  showChangeDepartmentMessage() {
    this.showDepartmentChangeMsg = true;

    setTimeout(() => {
      this.showDepartmentChangeMsg = false;
    }, 2500);
  }

  shareProofFile(item, file) {
    this.isProofFileSharing = true;
    const url = `${environment.baseUrl}/api/documents/${file.fileId}/download`;
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("responseType", "blob");
    headers = headers.set("observe", "response");

    this.http
      .get(url, {
        responseType: "blob",
        headers: headers,
      })
      .subscribe(
        (res: any) => {
          const userName = this.user.firstName + " " + this.user.lastName;

          var newfile = new File([res], file.fileName, {
            type: res["type"],
          });

          let formdata = new FormData();
          formdata.append("files", newfile);
          formdata.append("orderItemId", item.itemId.toString());
          formdata.append("csrName", userName);
          formdata.append("ReferenceId", file.fileId);
          formdata.append("IsRequiredApproveArtwork", "true");

          this.api
            .request("ADD_ORDER_ITEM_DOCUMENTS", { data: formdata })
            .subscribe(
              (res) => {
                if (res["success"]) {
                  item.files.forEach((f) => {
                    f["isShowApprovalStatus"] = false;
                  });
                  file["isSharedToCustomer"] = true;
                  file["isShowApprovalStatus"] = true;
                  item["isApprovedProofFile"] = false;
                  if (item.firstStepDepartmentName.toLowerCase() == "design") {
                    item.stepActions[1].allowMarkAsComplete = false;
                  }
                  if (item.firstStepDepartmentName.toLowerCase() == "sales") {
                    item.stepActions[0].allowMarkAsComplete = false;
                  }

                  this.openToast(
                    "Artwork is shared with customer successfully."
                  );
                  this.isProofFileSharing = false;
                }
              },
              (error) => {
                this.isProofFileSharing = false;
              }
            );
        },
        (error) => {
          this.isProofFileSharing = false;
        }
      );
  }

  canSendToProduction(): Boolean {
    var selectedJobItem = this.job.items[this.selectedJobItemIndex];
    return this.jobPermission.canMoveToProduction(
      selectedJobItem,
      selectedJobItem.firstStepDepartmentName,
      this.isPaid,
      selectedJobItem["isBypassed"]
    );
  }

  logJobItem() {
    var jobItem = this.job.items[this.selectedJobItemIndex];
  }

  createPaymentLinkSharedJobNote() {
    const note = {
      jobId: this.job.jobId,
      text: "A Payment link has been shared with the customer",
      priority: "low",
      type: "SYSTEM_GENERATED",
      appendMetadata: true,
    };

    this.api
      .request("CREATE_JOB_NOTE", { data: note })
      .subscribe((res: any) => {
        if (res.success) {
          note["noteId"] = res["result"]["id"];
          note["canDelete"] = false;
          note["createdBy"] = res["result"]["createdBy"];
          note["createdById"] = res["result"]["createdById"];
          note["createdAt"] = res["result"]["createdAt"];
          note["type"] = "SYSTEM_GENERATED";
          if (note["appendMetadata"]) {
            note["text"] =
              note["text"] +
              " by " +
              note["createdBy"] +
              " on " +
              this.convertToLocalTimeZone(note["createdAt"]);
          }

          this.job.items.forEach((item) => {
            var notes = item["notes"];
            notes.splice(0, 0, note);
          });
        }
      });
  }

  createInvoiceSharedJobNote() {
    var date = this.getCurrentTimeAndDate();
    const note = {
      jobId: this.job.jobId,
      text: `Invoice has been shared with customer`,
      priority: "low",
      type: "SYSTEM_GENERATED",
      appendMetadata: true,
    };

    this.api
      .request("CREATE_JOB_NOTE", { data: note })
      .subscribe((res: any) => {
        if (res.success) {
          note["noteId"] = res["result"]["id"];
          note["canDelete"] = false;
          note["createdBy"] = res["result"]["createdBy"];
          note["createdById"] = res["result"]["createdById"];
          note["createdAt"] = res["result"]["createdAt"];
          note["type"] = "SYSTEM_GENERATED";
          if (note["appendMetadata"]) {
            note["text"] =
              note["text"] +
              " by " +
              note["createdBy"] +
              " on " +
              this.convertToLocalTimeZone(note["createdAt"]);
          }
          this.job.items.forEach((item) => {
            var notes = item["notes"];
            notes.splice(0, 0, note);
          });
        }
      });
  }

  convertToLocalTimeZone(date) {
    date = date.replace("T", " ");
    date = date.replace("t", " ");
    date = date + " utc ";
    let localDate = new Date(date);

    var hours = localDate.getHours();
    var minutes: any = localDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const yyyy = localDate.getFullYear();
    let mm: any = localDate.getMonth() + 1;
    let dd: any = localDate.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    var res =
      dd + "/" + mm + "/" + yyyy + " " + hours + ":" + minutes + " " + ampm;
    return res;
  }

  viewInvoice(isCustomerPortalOrder: boolean) {
    if (this.isCheckingForJobUpdate || this.isUpdatingWithPP) {
      return;
    }
    let fileName = "";
    if (isCustomerPortalOrder) {
      this.externalPdfViewer.pdfSrc = `${environment.customerPortalUrl}/api/invoice/generate-invoice/${this.selectedInvoice.id}/download`;
      fileName = `INVOICENO${this.selectedInvoice.id}`;
    } else {
      this.externalPdfViewer.pdfSrc = `${environment.baseUrl}/api/invoice/generate-invoice/${this.job.jobId}/download`;
      fileName = `INVOICENO${this.job.jobNumber}`;
    }
    this.externalPdfViewer.downloadFileName = fileName;
    this.externalPdfViewer.refresh();
  }

  createInvoiceSharedOrderNote() {
    var orderNote = {
      note: `Invoice has been shared with customer`,
      type: "SYSTEM_GENERATED",
      orderId: this.orderId,
      workflowUserId: 0,
      appendMetaData: true,
    };

    this.api
      .request("CREATE_ORDER_NOTE", { data: orderNote })
      .subscribe((res: any) => {
        if (res.success) {
          var createdNote = {
            noteId: res["result"]["id"],
            canDelete: false,
            createdBy: res["result"]["createdBy"],
            createdAt: res["result"]["createdAt"],
            appendMetaData: res["result"]["appendMetaData"],
            text: res["result"]["note"],
            priority: "low",
            type: "SYSTEM_GENERATED",
          };

          if (createdNote["appendMetaData"]) {
            createdNote["text"] =
              createdNote["text"] +
              " by " +
              createdNote["createdBy"] +
              " on " +
              this.convertToLocalTimeZone(createdNote["createdAt"]);
          }

          this.job.items.forEach((item) => {
            var notes = item["notes"];
            notes.splice(0, 0, createdNote);
          });
        }
      });
  }

  createInvoice(isCustomerPortalOrder: boolean) {
    if (isCustomerPortalOrder) {
      if (this.isErrorInTaxGeneration) {
        this.openToast(this.errorMsgInTaxGeneration);
        return;
      }

      this.selectedInvoice.referenceId = this.orderId;
      this.job.isInvoiceSharedWithCustomer = false;

      let dialogRef = this.dialog.open(CreateInvoiceComponent, {
        width: "650px",
        data: {
          invoice: this.selectedInvoice,
          taxPercentage: this.taxPercentage,
          isViewOnlyMode: false,
          isPrinterPlanInvoice: false,
          job: this.job,
        },
      });

      dialogRef.afterClosed().subscribe((data) => {
        if (data.id > 0) {
          this.isInvoiceCreated = true;
          this.isPaid = false;
          if (this.job.isInvoiceSharedWithCustomer) {
            this.job.isInvoiceSharedWithCustomer = false;
            this.createInvoiceSharedOrderNote();
          }
        }
      });
    } else {
      if (!this.isInvoiceCreated) {
        this.openToast(
          "Please add cost details in printer plan and update the job."
        );
      }
    }
  }

  IsAccessUser(file) {
    if (file.isProofFile || file.isAllowReimport) {
      var item = this.job.items[this.selectedJobItemIndex];
      var dept = this.auth.getUser();
      if (item.departmentName.toLowerCase() == "design") {
        if (
          dept.currentDepartment.departmentName.toLowerCase() === "design" ||
          "administration" ||
          "csr"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.departmentName.toLowerCase() == "sales") {
        if (
          dept.currentDepartment.departmentName.toLowerCase() === "sales" ||
          "administration" ||
          "csr"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  editInvoice(isCustomerPortalOrder: boolean) {
    if (isCustomerPortalOrder) {
      if (this.isErrorInTaxGeneration) {
        this.openToast(this.errorMsgInTaxGeneration);
        return;
      }

      let dialogRef = this.dialog.open(CreateInvoiceComponent, {
        width: "650px",
        data: {
          invoice: this.selectedInvoice,
          taxPercentage: this.taxPercentage,
          isViewOnlyMode: false,
          isPrinterPlanInvoice: false,
          job: this.job,
        },
      });

      dialogRef.afterClosed().subscribe((data) => {
        if (this.job.isInvoiceSharedWithCustomer) {
          this.job.isInvoiceSharedWithCustomer = false;
          this.createInvoiceSharedOrderNote();
        }
      });
    } else {
      this.job.isPaymentLinkShared = false;
      this.job.isInvoiceSharedWithCustomer = false;

      let dialogRef = this.dialog.open(CreateInvoiceComponent, {
        width: "650px",
        data: {
          invoice: this.selectedInvoice,
          taxPercentage: this.taxPercentage,
          isViewOnlyMode: true,
          isPrinterPlanInvoice: true,
          job: this.job,
        },
      });

      dialogRef.afterClosed().subscribe((data) => {
        if (this.job.isInvoiceSharedWithCustomer) {
          this.job.isInvoiceSharedWithCustomer = false;
          this.createInvoiceSharedJobNote();
        }

        if (this.job.isPaymentLinkShared) {
          this.job.isPaymentLinkShared = false;
          this.createPaymentLinkSharedJobNote();
        }
      });
    }
  }

  //Order Chat Methods

  onShowChatDocumentPreview(event) {
    this.isChatOrderDocShowPreview = true;
    this.isWorkflowView = true;
    this.chatOrderDocPreviewDocument = event.previewDocument;
    this.chatOrderDocPreviewUrl = event.previewUrl;
  }

  onCloseChatDocumentPreview() {
    this.isChatOrderDocShowPreview = false;
    this.isWorkflowView = false;
    this.chatOrderDocPreviewDocument = "";
    this.chatOrderDocPreviewUrl = "";
  }

  downloadChatOrderDoc(doc) {
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    const linkElement = document.createElement("a");
    if (doc.jobItemId) {
      linkElement.setAttribute(
        "href",
        this.printerPlanDocumentService.getDownloadDocumentUrl(doc.id)
      );
    } else {
      linkElement.setAttribute(
        "href",
        this.documentService.getCustomerPortalDownoadUrl(doc.id)
      );
    }

    linkElement.dispatchEvent(clickEvent);
  }

  getCurrentTimeAndDate() {
    var today = new Date();
    var hours = today.getHours();
    var minutes: any = today.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    var res =
      dd + "/" + mm + "/" + yyyy + " " + hours + ":" + minutes + " " + ampm;
    return res;
  }

  onApproveSendNotificationForReadyForPickup(rfpNotification) {
    if (this.job.isPrinterPlan) {
      if (!(this.job && this.job.buyerInfo && this.job.buyerInfo.email)) {
        this.dialog.open(ConfirmDialogComponent, {
          height: "225px",
          width: "560px",
          data: {
            type: "Info",
            message:
              "Couldn't locate the customer email.Please add an email address in the customer account associated with this job in the printers plan and update the job.",
          },
        });
        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "170px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to approve notification?",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        var currentJobItem = this.job.items[this.selectedJobItemIndex];
        currentJobItem.isLoadingStatusForReadyForPickupNotification = true;
        var reqObj = {
          id: rfpNotification.id,
          isApproved: true,
          isRejected: false,
        };
        this.api
          .request("UPDATE_SEND_NOTIFICATION_FOR_READY_FOR_PICKUP", {
            data: reqObj,
          })
          .subscribe((res) => {
            if (res) {
              this.openToast(res["message"]);
              if (res.result) {
                res.result.forEach((_rfpNotification) => {
                  var date = new Date(_rfpNotification.modifiedAt);
                  var withUTC = date.toLocaleString() + " UTC";
                  _rfpNotification.modifiedAt = new Date(withUTC);
                });
              }
              currentJobItem.rfpNotifications = res.result;
              currentJobItem.isLoadingStatusForReadyForPickupNotification =
                false;
            }
          });
      }
    });
  }

  onRejectSendNotificationForReadyForPickup(rfpNotification) {
    if (this.job.isPrinterPlan) {
      if (!(this.job && this.job.buyerInfo && this.job.buyerInfo.email)) {
        this.dialog.open(ConfirmDialogComponent, {
          height: "225px",
          width: "560px",
          data: {
            type: "Info",
            message:
              "Couldn't locate the customer email.Please add an email address in the customer account associated with this job in the printers plan and update the job.",
          },
        });
        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: "170px",
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to reject notification?",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        var currentJobItem = this.job.items[this.selectedJobItemIndex];
        currentJobItem.isLoadingStatusForReadyForPickupNotification = true;
        var reqObj = {
          id: rfpNotification.id,
          isApproved: false,
          isRejected: true,
        };
        this.api
          .request("UPDATE_SEND_NOTIFICATION_FOR_READY_FOR_PICKUP", {
            data: reqObj,
          })
          .subscribe((res) => {
            if (res) {
              this.openToast(res["message"]);
              if (res.result) {
                res.result.forEach((_rfpNotification) => {
                  var date = new Date(_rfpNotification.modifiedAt);
                  var withUTC = date.toLocaleString() + " UTC";
                  _rfpNotification.modifiedAt = new Date(withUTC);
                });
              }

              currentJobItem.rfpNotifications = res.result;
              currentJobItem.isLoadingStatusForReadyForPickupNotification =
                false;
            }
          });
      }
    });
  }

  getSendNotificationForReadyForPickupStatus() {
    if (!this.isAdminOrCSR) {
      return;
    }

    var currentJobItem = this.job.items[this.selectedJobItemIndex];
    currentJobItem.isLoadingStatusForReadyForPickupNotification = true;
    var reqObj = {
      data: {},
      params: { JOB_ITEM_ID: currentJobItem.jobItemId },
    };
    this.api
      .request("GET_READY_FOR_PICKUP_NOTIFICATION_STATUS", reqObj)
      .subscribe((res) => {
        if (res) {
          if (res.result) {
            res.result.forEach((_rfpNotification) => {
              var date = new Date(_rfpNotification.modifiedAt);
              var withUTC = date.toLocaleString() + " UTC";
              _rfpNotification.modifiedAt = new Date(withUTC);
            });
          }

          currentJobItem.rfpNotifications = res.result;
          currentJobItem.isLoadingStatusForReadyForPickupNotification = false;
        }
      });
  }

  openSwitchFilesUploadModal(jobItem) {
    const dialogRef = this.dialog.open(UploadToSwitchComponent, {
      width: "75vw",
      height: "665px",
      maxHeight: "665px",
      data: {
        jobItemIds: [jobItem.jobItemId],
        mode: EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_FILES_SELECTION_MODE,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  toggleAllNotes(value: string){
    if(value == 'Job Notes'){
      this.showAllNotes = false;
     }
     if(value == 'All notes'){
      this.showAllNotes = true;
     }
  }
}
