import { NotificationModel } from './topnav.state';
import { notification } from '../../../../shared/interfaces/notification';


export class GetNotification {
    static readonly type = '[Notification] Get Notification';
    constructor(public notification: NotificationModel) {}
}

export class LoadNotification {
    static readonly type = '[Notification] Load Notification';
    constructor() {}
}

export class ReceiveNotification {
    static readonly type = '[Notification] Receive Notification';
    constructor(public notification: notification) {}
}

export class ChangeUserDepartment{
    static readonly type = '[UserDepartment] User Change Department';
    constructor(){}
}

export class FullScreenView{
    static readonly type = '[Layout] Full Screen View';
    constructor (public isFullScreenView : boolean){}
}


