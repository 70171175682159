import { Job } from 'src/app/shared/interfaces/job';

export class LoadJobs {
    static readonly type = '[Job] Load Jobs';
    constructor(public filter?:any) { }
}

export class GetJob {
    static readonly type = '[Job] Get Job';
    constructor(public jobNumber: number) { } 
}

export class SetJobStatus {
    static readonly type = '[Job] Set Job Status';
    constructor(public status: string) { }
}

export class SearchJob {
    static readonly type = '[Job] Search Job';
    constructor(public jobNumber: number) { }
}

export class AddJob {
    static readonly type = '[Job] Add Job';
    constructor(public job: Job) { }
}

export class UpdateJob {
    static readonly type = '[Job] Update Job';
    constructor(public job: Job) { }
}

export class DeleteJob {
    static readonly type = '[Job] Delete Job';
    constructor(public job: Job) { }
}

export class ImportJob {
    static readonly type = '[Job] Import Job';
    constructor(public job: Job) { }
}

export class ExpandAndCollapsJob {
    static readonly type = '[Job] Expand and collaps Job';
    constructor(public showJobDetail: boolean){}
}

export class LoadJobLogs {
    static readonly type = '[Job] Load Log';
    constructor(public jobItemId: Number){}
}

export class LoadCurrentDepartmentJobs{
    static readonly type = '[Job] Load Department Jobs';
    constructor (public departmentId:Number){}
}

export class ReloadeJobs {
    static readonly type = '[Job] Reload Jobs';
    constructor(){}
}

export class ResetState{
    static readonly type = '[Job] Reset Jobs';
    constructor(){}
}

export class RemoveJobItem{
    static readonly type = '[Job] Remove Job Item';
    constructor(public payload : {JobId : Number,JobItemId : Number}){}
}


