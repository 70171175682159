<div fxLayout="column" class="padding-10">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5px"
    style="font-size: 20px"
  >
    <Strong class="primary-text-color">Create Link</Strong>
  </div>

  <mat-dialog-content>
    <strong
      fxLayout="row"
      fxLayoutAlign="center center"
      style="padding: 8px; font-size: 16px; margin-bottom: 30px"
      class="primary-text-color"
    >
      Please enter the customer email ID to generate a link
    </strong>

    <div class="p-20-r-l fw-600 p-b10 primary-text-color">Email</div>
    <div
      fxLayout="row"
      class="p-20-r-l"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
    >
      <input
        type="text"
        placeholder="Email"
        [(ngModel)]="email"
        class="primary-text-color"
      />
      <div
        class="customButton"
        style="background-color: #e4e9f8; color: #5580fb"
        (click)="generatePaymentLink()"
      >
        Generate Link
      </div>
    </div>

    <div
      fxLayout="row"
      class="p-20-r-l"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      style="
        padding-top: 8px;
        text-shadow: 0px 0px 0px #000000;
        font-size: 14px;
      "
    >
      <mat-checkbox
        color="primary"
        labelPosition="after"
        [(ngModel)]="updateEmail"
        class="primary-text-color"
      >
        Please check to update the Email ID in the database
      </mat-checkbox>
    </div>

    <br />

    <div class="p-20-r-l fw-600 p-b10 primary-text-color">Link</div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      class="p-20-r-l"
    >
      <div fxFlex class="input-like" style="font-size: 14px">
        {{ paymentLink }}
      </div>
      <div
        class="customButton"
        style="background: #d5e8d4; color: #82b366"
        (click)="copyToClipBoard(paymentLink)"
      >
        Copy Link
      </div>
    </div>
  </mat-dialog-content>

  <div
    fxLayout="row"
    fxFlexOffset="35px"
    fxLayoutAlign="end center"
    fxLayoutGap="3%"
    class="p-20-r-l"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="shareLink()"
      [disabled]="isLoading"
    >
      SHARE WITH CUSTOMER
    </button>
    <button mat-raised-button (click)="close()">CLOSE</button>
  </div>

  <br />
</div>
