import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Job } from 'src/app/shared/interfaces/job';
import { ApiService } from 'src/app/shared/services/api.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ReloadeJobs } from '../../state/job.actions';



@Component({
  selector: 'app-retrive-jobs',
  templateUrl: './retrive-jobs.component.html',
  styleUrls: ['./retrive-jobs.component.scss']
})
export class RetriveJobsComponent implements OnInit, AfterViewInit {
  isShowConfirmation: boolean = false;
  isLoading: boolean;
  @ViewChild('searchInput') searchInput: ElementRef;

  selectedJobItems:Array<any> = [];
  isJobItemSelected : boolean = false;


  constructor(public dialogRef: MatDialogRef<RetriveJobsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permission: PermissionService,
    private api: ApiService,
    private apiService: ApiService,
    private store: Store) { }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.search(text);
    });
  }

  jobs: Array<Job> = [];
  filterJobs: Array<Job> = [];
  jobLoading = true;
  searchKey = '';

  ngOnInit() {
    this.LoadDeletedJobs(this.data);
  }

  clearSearch() {
    this.searchKey = '';
    this.search(this.searchKey);
  }

  close() {
    if (this.isLoading) { return; }
    this.dialogRef.close();
  }
  LoadDeletedJobs(data) {

    let requestObject = {
      pageNo: 0,
      pageSize: 0,
    };

    this.apiService.request('GET_RETRIVE_JOBS', {
      data: requestObject
    }).subscribe(res => {
      if (res['success']) {
        this.filterJobs = res['result']
        this.jobs = res['result']
        this.jobLoading = false;

        return;
      }
      this.jobLoading = false;

    })

  }

  search(search) {
    this.filterJobs = !search || search.trim() === '' ?
      this.jobs :
      this.jobs.filter(j => j.jobNumber.toString().includes(search.trim()) || j.customerName.toLowerCase().includes(search.toLowerCase().trim())) ;
  }

  RetriveJobs() {
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find(i => i['isSelect'])) {
        this.isShowConfirmation = true;
        this.isJobItemSelected = true;
        this.selectedJobItems.push(job.items.filter(_item => _item['isSelect']));
      }
      if (this.isShowConfirmation) { break; }
    }
    this.selectedJobItems.length > 0 ? this.isJobItemSelected = true : this.isJobItemSelected = false;
  }

  confirmRetriveJobs() {
    this.isShowConfirmation = false;
    this.isLoading = true;
    let jobItems = [];
    this.jobs.forEach((j: any) => {
      let items = j.items.filter(i => i['isSelect']);
      items.forEach(item => {
        item.jobNumber = j.jobNumber;
      });
      jobItems = [...jobItems, ...items];
    });

    this.RetriveJobsAndDisplayInJobTab(jobItems);
  }

  RetriveJobsAndDisplayInJobTab(jobItems) {
    const data = {
      jobIds: jobItems.map(i => { return i.jobId }),
      ItemNos: jobItems.map(j => { return j.itemNo })
    };
    this.api.request('RETRIVE_JOBS', { data: data })
      .subscribe(res => {
        if (res.success) {
          this.dialogRef.close();
          this.isLoading = false;
          this.store.dispatch(new ReloadeJobs())
        }

      }, error => this.isLoading = false);
    this.isLoading = false;
  }

  onItemChecked(event, item){
    this.selectedJobItems = [];
    for (let index = 0; index < this.jobs.length; index++) {
      const job = this.jobs[index];
      if (job.items.find(i => i['isSelect'])) {
        this.isJobItemSelected = true;
        this.selectedJobItems.push(job.items.filter(_item => _item['isSelect']));
      }
      if (this.isShowConfirmation) { break; }
    }
    this.selectedJobItems.length > 0 ? ((this.isJobItemSelected = true ) && (this.isShowConfirmation = false)) : ((this.isJobItemSelected = false) && (this.isShowConfirmation = false));

  }

}
