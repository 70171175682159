import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-mark-as-paid',
  templateUrl: './mark-as-paid.component.html',
  styleUrls: ['./mark-as-paid.component.scss']
})
export class MarkAsPaidComponent implements OnInit {

  CASH : string = "CASH";
  CHECK : string = "CHEQUE";
  CARD : string = "CARD";

  selectedModeOfPayment : string = this.CASH;
  modeOfPayments : ModeOfPayment[] = [
    {value : this.CASH, displayValue : "Cash"},
    {value : this.CHECK, displayValue : "Cheque"},
    {value : this.CARD, displayValue : "Debit/Credit Card"}
  ];

  constructor(public dialogRef: MatDialogRef<MarkAsPaidComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {invoiceId : number},
    private api: ApiService,
    private toast:ToastService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
  }

  onClickMarkAsPaid(){
    var reqObject = {
      params: { },
      data: {
        invoiceId : this.data.invoiceId,
        paymentMethod : this.selectedModeOfPayment
      }
    }

    this.api.request("MARK_AS_PAID", reqObject).subscribe(res => {
      this.dialogRef.close(res);
    });
  }

}

interface ModeOfPayment {
  value : string;
  displayValue : string;
} 
