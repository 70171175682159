import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
  CanActivateChildFn,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthUserService } from "./auth-user.service";

// @Injectable()
// export class RoleGuard {
//   constructor(private _authService: AuthUserService, private _router: Router) {}

//   // canActivate(
//   //   next: ActivatedRouteSnapshot,
//   //   state: RouterStateSnapshot
//   // ): Observable<boolean> | Promise<boolean> | boolean {
//   //   const user = this._authService.getUser();

//   //   if (!next.data.role) {
//   //     return true;
//   //   }

//   //   if (next.data.role && next.data.role.length > 0) {
//   //     for (let i = 0; i < next.data.role.length; i++) {
//   //       if (
//   //         user &&
//   //         user.currentDepartment.departmentName.toLowerCase() ===
//   //           next.data.role[i]
//   //       ) {
//   //         return true;
//   //       }
//   //     }
//   //   }

//   //   // navigate to not found page
//   //   this._router.navigate(["account/login"]);
//   //   return false;
//   // }

//   canActivateChild(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {
//     return true;
//   }
// }

export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  const _authService = inject(AuthUserService);
  const _router = inject(Router);
  const user = _authService.getUser();

  if (!next.data.role) {
    return true;
  }

  if (next.data.role && next.data.role.length > 0) {
    for (let i = 0; i < next.data.role.length; i++) {
      if (
        user &&
        user.currentDepartment.departmentName.toLowerCase() ===
          next.data.role[i]
      ) {
        return true;
      }
    }
  }

  // navigate to not found page
  _router.navigate(["account/login"]);
  return false;
};

export const authGuardChild: CanActivateChildFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  return true;
};
