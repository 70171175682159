<!--Row for job-->

<div
  *ngIf="jobItem == null"
  fxLayout="row"
  class="grid-row"
  fxLayoutAlign="center center"
  fxLayoutGap="2px"
  (click)="onLoadDetailsView()"
>
  <small class="text-ellipsis" fxFlex="5">{{ job.jobNumber }}</small>
  <small class="text-ellipsis pointer" fxFlex="16">
    <strong *ngIf="job.customerName" class="text-ellipsis pointer">{{
      job.customerName
    }}</strong>
    <span *ngIf="job['isPrinterPlan']" class="ispp" fxFlex="14">PP</span>
  </small>
  <small class="text-ellipsis" fxFlex="10">{{ job.csrName }}</small>
  <small class="text-ellipsis" fxFlex="6">-</small>
  <small class="text-ellipsis" fxFlex="11">-</small>
  <small class="text-ellipsis" fxFlex="8">-</small>
  <small class="text-ellipsis" fxFlex="12">-</small>
  <small class="text-ellipsis" fxFlex="11">
    <span *ngIf="job.status === 1" class="status inactive">In progress</span>
    <span *ngIf="job.status === 2" class="status active">Invoice</span>
    <span *ngIf="job.status === 3" class="status inactive">Archive</span>
  </small>
  <small class="text-ellipsis" fxFlex="8">
    <span
      *ngIf="job.dateDue != '0001-01-01T00:00:00'"
      [class.warn]="job.dateDue < today"
      >{{ job.dateDue | date : "mediumDate" }}
    </span>
  </small>
  <small fxFlex="4"
    ><strong class="item-count">{{ job.totalItem }}</strong></small
  >
  <div fxFlex="2">
    <mat-icon
      class="small-icon"
      color="warn"
      *ngIf="job.isRush"
      matToolTip=""
      style="cursor: pointer"
      >date_range</mat-icon
    >
  </div>
  <div fxFlex="7" fxLayoutAlign="end center" fxLayoutGap="1px">
    <div fxFlex="50" fxLayoutAlign="space-between center" fxLayoutGap="3px">
      <img
        *ngIf="!job.isInvoiceDrafted && isAdminOrCsr"
        [src]="src"
        matTooltip="Invoice shared with customer"
      />
      <mat-icon
        matRipple
        matTooltip="Shipment"
        class="pointer icon-size"
        *ngIf="allowShipment && showDetailsView"
        (click)="shipment($event)"
        >local_shipping</mat-icon
      >
    </div>
    <div *ngIf="isShowRyobiJobPdfIcon()" fxLayoutAlign="center center">
      <mat-icon *ngIf="job.status === 1 && items.length > 0 && showDetailsView"
            matTooltip="Download Job PDF"
            (click)="onPrintRyobiJobPdf($event,job)"
            class="icon icon-size"
            >receipt</mat-icon
          >
    </div>
    <div fxFlex="50">
      <span fxFlex></span>
      <div
        matRipple
        fxLayoutAlign="center center"
        matTooltip="Move all job items into next department"
        *ngIf="job.status === 1 && items.length > 0 && showDetailsView"
      >
        <mat-icon
          class="pointer icon-size"
          *ngIf="!loading"
          (click)="markAsComplete($event, null, true)"
          >done_all
        </mat-icon>
        <span class="loader" *ngIf="loading"></span>
      </div>

      <div
        matRipple
        fxLayoutAlign="center center"
        matTooltip="Job archive"
        *ngIf="job.status === 2"
      >
        <mat-icon class="pointer icon-size" *ngIf="!loading" (click)="archiveJob($event)"
          >archive
        </mat-icon>
        <span class="loader" *ngIf="loading"></span>
      </div>

      <div
        matRipple
        fxLayoutAlign="center center"
        matTooltip="Delete job"
        *ngIf="(job.totalItem === 0 || job.status === 1) && isDeletePermission"
      >
        <mat-icon
          class="pointer icon-size"
          *ngIf="!deleteLoading"
          (click)="deleteJob($event)"
          >delete
        </mat-icon>
        <span class="loader" *ngIf="deleteLoading"></span>
      </div>
    </div>
  </div>
</div>

<!--Row for job item-->
<div
  *ngIf="jobItem != null"
  fxLayout="row"
  class="grid-row job-item-row"
  fxLayoutAlign="center center"
  fxLayoutGap="2px"
>
  <small class="text-ellipsis" fxFlex="5">{{ jobItem.jobNumber }}</small>
  <small
    (click)="jobItemSelection(jobItem['jobItemId'])"
    class="text-ellipsis job-item-selection"
    fxFlex="16"
  >
    {{ jobItem.description }}</small
  >
  <small class="text-ellipsis" fxFlex="10">-</small>
  <small class="text-ellipsis" fxFlex="6">{{ jobItem.chargeQty }}</small>
  <small class="text-ellipsis" fxFlex="11">
    <ng-container *ngIf="jobItem.stock || jobItem.finishSize">
      <span *ngIf="jobItem.stock"> {{ jobItem.stock }} </span>
      <span *ngIf="!jobItem.stock"> - </span>
      /
      <span *ngIf="jobItem.finishSize"> {{ jobItem.finishSize }} </span>
      <span *ngIf="!jobItem.finishSize"> - </span>
    </ng-container>
    <ng-container *ngIf="!jobItem.stock && !jobItem.finishSize">
      -
    </ng-container>
  </small>
  <small class="text-ellipsis" fxFlex="8">{{ jobItem.departmentName }}</small>
  <small class="text-ellipsis" fxFlex="12"
    >{{ jobItem.currentStepName }}/{{ jobItem.currentActionName }}</small
  >
  <small class="text-ellipsis" fxFlex="11">
    <span *ngIf="jobItem.status === 1" class="status inactive"
      >In progress</span
    >
    <span *ngIf="jobItem.status === 2" class="status active">Invoice</span>
    <span *ngIf="jobItem.status === 3" class="status inactive">Archive</span>
  </small>
  <small class="text-ellipsis" fxFlex="8">-</small>
  <small class="text-ellipsis" fxFlex="4"></small>
  <div fxFlex="2">
    <mat-icon
      class="small-icon pointer"
      color="warn"
      matInput
      [matTooltip]="jobItem.rushDate | date : 'mediumDate'"
      *ngIf="jobItem.rushDate != null"
      >date_range</mat-icon
    >
  </div>
  <div fxFlex="7" fxLayoutAlign="end center" fxLayoutGap="4px">
    <mat-icon class="small-icon" *ngIf="jobItem.artwork">file_copy</mat-icon>
    <span fxFlex></span>
    <ng-container *ngIf="isShowRyobiJobPdfIcon()">
      <mat-icon
      *ngIf="job.status === 1"
              matTooltip="Download JobItem PDF"
              (click)="onPrintRyobiJobPdf($event,job,jobItem)"
              class="icon icon-size"
              >receipt</mat-icon
            >
     </ng-container>
    <mat-icon
      *ngIf="jobItem.canMoveToSwitch"
      matTooltip="Move Files to Switch"
      class="medium-size-icon pointer"
      (click)="openSwitchFilesUploadModal(jobItem)"
      >cloud_upload</mat-icon
    >
    <div
      matRipple
      fxLayoutAlign="center center"
      matTooltip="Move into next department"
      *ngIf="jobItem.status === 1"
    >
      <mat-icon
        class="pointer icon-size"
        *ngIf="!loading"
        (click)="markAsComplete($event, jobItem, false)"
        >done_all
      </mat-icon>
      <span class="loader" *ngIf="loading"></span>
    </div>
  </div>
</div>

<ng-container *ngIf="showDetailsView && jobItem == null">
  <app-job-grid-row
    *ngFor="let item of items; let index = index"
    [job]="job"
    [jobItem]="item"
    [isExpand]="isExpand"
    [currentDepartmentId]="currentDepartmentId"
    [currentDepartmentName]="currentDepartmentName"
  ></app-job-grid-row>
</ng-container>
