import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RyobiPdfPrintService {

  constructor(private api: ApiService) { }

   printRyobiJobs(payload) {
     this.api.request('GET_RYOBI_JOBS_PDF_DETAIL', {
       data: payload
     }).subscribe((response)=> {
       if(response.statusCode == 200 && response.result.length > 0){
         let ryobiJobPdfData = response.result
         return this.loadRyobiJobPdf(ryobiJobPdfData);
       }
     });
   
   }

  async loadRyobiJobPdf(ryobiJobPdfData){
    if(!ryobiJobPdfData) return;
    var WindowPrt = await window.open('', '', 'width=800,height=600,left=200,top=200');
    var jobIndex = 0;
      for(let jobData of ryobiJobPdfData){
        jobIndex++;
        var data = `
        <!DOCTYPE html>
          <html>
            <head>
              <style>
                @media print {
                  .onePage{
                    display:flex;
                    align-items: center;
                    justify-content: start;
                    width:100%;
                    height:100%; 
                  }
  
                  .pagebreak {
                    .jobname {
                      color:black;
                    }
                }
  
                @page 
                {
                  size:  auto;
                  margin: 10px ;
                  border:1px solid;
                }
  
                html
                { 
                  margin:0px 0px 0px 0px;
                }
  
                body
                { 
                  margin: 10px 20px 10px 20px;
                }
                .step-name {
                  color: green ;
                }
              </style>
            </head>
            <body>`;
        data = data + `<div class="onePage pagebreak">
                  <div>
            <div class="jobname" style="background:#c1c1c1">
                <h3>${jobIndex}. JobName: #${jobData.jobNumber} - ${jobData.title}</h3>
            </div>`;
        data = data + `<h5>JOB ITEM DETAILS:-</h5>`;
  
        jobData.items.forEach((item,index) =>{
          data = data + `<h4>${index + 1}. ${item.description}</h4>
                    <div>
                    <div>
                        <span>Quantity: ${item.chargeQty !== null ? item.chargeQty : ''}</span>, &nbsp;
                        <span>PaperColor: ${item.paperColor !== null ? item.paperColor : ''} </span>
                    </div>
                    <div>
                        <span>Stock: ${item.stock !== null ? item.stock : ''}</span>, &nbsp;
                        <span>Size: ${item.finishSize !== null ? item.finishSize : ''}</span>,
                    </div>
                    <div>
                        <span>Side1Color: ${item.side1Color !== null ? item.side1Color : ''}</span>, &nbsp;
                        <span>Side2Color: ${item.side2Color !== null ? item.side2Color : ''} </span>
                    </div>
                    <div>
                    </div>`
          if (item.foldServices.length > 0) {
            for (let fold of item.foldServices) {
              data = data + `<div>
                        <span>Fold: ${fold !== null ? fold : ''} </span>
                    </div>`;
            }
          }
          if (item.foldNotes.length > 0) {
            for (let foldNote of item.foldNotes) {
              data = data + `<div>
                        <span>FoldNote: ${foldNote !== null ? foldNote : ''} </span>
                    </div>`
            }
          }
          if (item.cutServices.length > 0) {
            for (let cut of item.cutServices) {
              data = data + `<div>
                        <span>Cut: ${cut !== null ? cut : ''} </span>
                    </div>`
            }
          }
          data = data + `<h5>STEPS:-</h5>`;
          if (item.steps) {
            item.steps.forEach((step, index) => {
              if (step.stepName == item.currentStepName) {
                data = data + `<div>
                            <span class="step-name">Current Step ${index + 1}: ${step.stepName} / ${step.actionName}</span>
                            </div>`;
                item.notes.forEach((stepNote) => {
                  if (stepNote.stepIndex == index) {
                    data = data + `<div style="margin-left:30px">
                    <span class="step-name">Note :- &nbsp;${stepNote.text}</span>
                            </div>`;
                  }
                });
              }
              else {
                data = data + `<div>
                <span>Step ${index + 1}: ${step.stepName} / ${step.actionName}</span>
                </div>`;
                item.notes.forEach((stepNote) => {
                  if (stepNote.stepIndex == index) {
                    data = data + `<div style="margin-left:30px">
                      <span id="current-step">Note :- &nbsp;${stepNote.text}</span>
                      </div>`;
                  }
                });
              }
            });
          };
  
          data = data + `</div>
                    <hr>`;
        });
        await WindowPrt.document.write(data);
        
      }
  
      data = `</body></html>`;
  
          await WindowPrt.document.write(data);
          await WindowPrt.document.close();
           WindowPrt.onload = function () {
              WindowPrt.focus();
              WindowPrt.print();
            };
    }
}
