import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { JobItem } from 'src/app/shared/interfaces/jobItem';
import { ConfigurationState } from 'src/app/features/configuration/state/configuration.state';
import { Store } from '@ngxs/store';
import { LoadSteps } from 'src/app/features/configuration/state/configuration.actions';
import { JobTemplateCreateComponent } from './../job-template-create/job-template-create.component';
import { JobMailingSortCreateComponent } from '../../job-mailing/job-mailing-sort-create/job-mailing-sort-create.component';
import { JobMailingInfoCreateComponent } from '../../job-mailing/job-mailing-info-create/job-mailing-info-create.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoadJobs } from '../../state/job.actions';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/shared/interfaces/user';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
  selector: 'app-job-import-workflow',
  templateUrl: './job-import-workflow.component.html',
  styleUrls: ['./job-import-workflow.component.scss']
})
export class JobImportWorkflowComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() job;
  @Output() close = new EventEmitter();

  newStep = {};
  newStepAction = {};
  jobItemsValidate = true;
  stepsValidate = true;
  unionBug = undefined;
  unionBugValidate = false;
  isRush = false;
  isWorkflowView = false;
  selectedTemplate;
  selectedSteps = [];
  allNotification = false;
  selectedStep;
  isAddStep = false;
  rushDate;
  minDate = new Date();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  jobItemCtrl = new UntypedFormControl();
  filteredJobItems: Observable<any[]>;
  selectedJobItems = [];
  email = new UntypedFormControl('', [Validators.required]);
  jobItemFiles: any = new Set<File>();
  jobItemNote = '';
  jobItemNotePriority = 'low';
  steps = [];
  templates = [];
  isEdit = false;
  departmentValidate = true;
  jobItemRequiredApproveArtwork = false;
  departmentValidateErrorMessage = "";
  currentToast = null;
  isPrinterPlanJob: boolean = false;
  isNotifyDeptUsers: boolean = false;
  departments = [];
  user: User = null;

  @ViewChild('jobItemInput') jobItemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('note', {
    read: MatInput
  }) note: MatInput;
  @ViewChild('notePriority', {
    read: MatSelect
  }) notePriority: MatSelect;
  @ViewChild('file') file;


  assignedCsrPageNo: number = 0;
  assignedCsrPageSize: number = 8;
  assignedCsrfilterText: string = "";
  loadingAssignedCsrList: boolean = false;
  assignedCsrs: User[] = [];
  TotalAssignedCsr = 0;
  selectedAssignedCsr = null;
  selectedAssignedCsrIsInvalid = true;
  @ViewChild('assignedCsrMatAutocomplete') assignedCsrSelectElem: MatAutocomplete;
  @ViewChild('assignedCsrSearchInput') assignedCsrSearchInput: ElementRef;
  assignedCsrForm = this.formBuilder.group({
    assignedCsrFilterControl: ['']
  });
  isErrorForAssignedCsr: boolean = false;
  errorMsgForAssignedCsr: string = "";
  assignedCsrfilterTextChangeSubscription: Subscription = null;

  //Department Dropdown variables
  departmentInputText = "";
  // isAllDepartmentsOptionChecked = false;
  selectedDepartments = new UntypedFormControl();
  isDepartmentsLoaded = false;
  allDepartment = {
    name: "All",
    departmentId: 0
  };

  constructor(private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private userService: AuthUserService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (this.data && !this.job) {
      this.isEdit = true;
      this.job = this.data;
      this.unionBug = this.job.items[0].isUnion === true ? 'true' : 'false';
      this.jobItemRequiredApproveArtwork = this.job.items[0].isRequiredApproveArtwork;
      if (this.job.items[0]['rushDate']) {
        this.rushDate = this.job.items[0]['rushDate'];
        this.isRush = true;
      }

    }

    this.user = this.userService.getUser();
    this.store.dispatch(new LoadSteps());

    this.filteredJobItems = this.jobItemCtrl.valueChanges.pipe(
      startWith(null),
      map((jobItem) => {
        return jobItem ? this._filter(jobItem) : this.filterJobItemsOnTheBasisOfPayment(this.job.items);
      }));

    if (this.job && this.job.isImportToProduction) {
      this.job.items.forEach(item => {
        item.isImportToProduction = true;
      });
    }

    this.selectedJobItems = this.isEdit
      ? this.job.items
      : this.job.items.map((jobItem) => jobItem);

    if (this.job && !this.job.isImportToProduction) {
      if (
        !this.job["isPaid"] &&
        !this.job["canBypassPayment"] &&
        !this.job.isPrinterPlan
      ) {
        let originalItemsLength =
          this.selectedJobItems != null ? this.selectedJobItems.length : 0;
        let requiredApprovalItemsLength =
          this.selectedJobItems.filter(
            (ji) => ji["isRequiredApproveArtwork"] == true || ji["isImportToProduction"] == true || ji["isImportedToProductionWithoutValidations"] == true
          ) != null
            ? this.selectedJobItems.filter(
              (ji) => ji["isRequiredApproveArtwork"] == true || ji["isImportToProduction"] == true || ji["isImportedToProductionWithoutValidations"] == true
            ).length
            : 0;
        if (originalItemsLength != requiredApprovalItemsLength) {
          this.openToast("Some job items may be hidden due to pending payment!");
        }
        this.selectedJobItems = this.selectedJobItems.filter(
          (ji) =>
            ji["isRequiredApproveArtwork"] == true ||
            ji["isImportToProduction"] == true ||
            ji["isImportedToProductionWithoutValidations"] == true
        );
      }
    }


    if (this.job) {
      this.isPrinterPlanJob = this.job.isPrinterPlan;
    }

    this.loadSteps();
    this.loadTemplate();
    this.loadDepartments();
  }

  ngAfterViewInit() {
    if (!this.isPrinterPlanJob) {
      this.assignedCsrfilterTextChangeSubscription = fromEvent(this.assignedCsrSearchInput.nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        })
        , debounceTime(1000)
        , distinctUntilChanged()
      ).subscribe((text: string) => {
        this.selectedAssignedCsrIsInvalid = true;
        this.assignedCsrfilterText = text.toString().trim();
        this.assignedCsrPageNo = 0;
        this.loadingAssignedCsrList = true;
        this.loadAdminCsrs();
      });

      this.loadAdminCsrs();

      if (!this.isPrinterPlanJob) {
        if (this.job['csrNo'] && this.job['csrNo'] > 0) {
          this.assignedCsrForm.controls['assignedCsrFilterControl'].setValue(this.job['csrName']);
          this.selectedAssignedCsrIsInvalid = false;
        }
      }

      if (!this.job['isAllowedToAssignCsr']) {
        this.assignedCsrForm.controls['assignedCsrFilterControl'].disable();
      }

    }

    if (this.notePriority) {
      this.notePriority.value = 'low';
    }
  }

  ngOnDestroy(): void {
    if (this.assignedCsrfilterTextChangeSubscription != null) {
      this.assignedCsrfilterTextChangeSubscription.unsubscribe();
    }
  }

  filterJobItemsOnTheBasisOfPayment(items) {
    if (this.job.isPrinterPlan) {
      return this.job.items;
    }

    if (items) {
      if (!this.job['isPaid'] && !this.job['canBypassPayment']) {
        return items.filter(ji => ji['isRequiredApproveArtwork'] == true);
      }
    }
    return items;
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500
    });
  }

  loadSteps() {
    this.steps = JSON.parse(JSON.stringify(this.store.selectSnapshot(ConfigurationState.AllSteps)));
    this.steps = this.steps.filter(stp => !stp['isDelete']).slice().sort((a, b) => (a.stepTitle.toLowerCase() > b.stepTitle.toLowerCase()) ? 1 : -1);
    this.steps.forEach(step => {
      step['additionalInfo'] = step['additionalInfo'] ? step['additionalInfo'] : null;
      step.stepActions = step.stepActions.filter(act => !act['isDelete']).slice().sort((a, b) => (a.actionName.toLowerCase() > b.actionName.toLowerCase()) ? 1 : -1);
    });

    if (this.isEdit) {
      this.loadSelectdStep();
    }

  }

  loadTemplate() {
    this.api.request('GET_ALL_TEMPLATES')
      .subscribe((res: any) => {
        this.templates = res.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
      });
  }

  newUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  onAddStep() {

    this.selectedSteps.push({
      id: this.newUUID(),
      steps: this.steps,
      step: null,
      action: null,
      additionalInfo: this.newStep['additionalInfo'],
      notification: this.allNotification,
      note: '',
      notePriority: 'low'
    });

    this.newStep = this.newStepAction = {};
    this.isAddStep = false;
    this.stepsValidate = true;
  }

  onSelectStep(step) {
    if (this.selectedStep == step) {
      this.selectedStep = null;
      this.note.value = this.jobItemNote;
      this.notePriority.value = this.jobItemNotePriority;
      return;
    }

    this.note.value = step.note;
    this.notePriority.value = step.notePriority;
    this.selectedStep = step;
  }

  onRemoveStep(stepIndex) {
    let meassage = 'Are you sure you want to remove this job item step?'
    let type = 'Confirm';
    const step = this.selectedSteps[stepIndex];
    if (step && step['isCurrentStep']) { //check this condition only when job items is edit.
      meassage = 'Cannot remove the current step of job items. Please move job items into other step.';
      type = 'Info';
    }
    this.removeStep(meassage, type, stepIndex);
  }

  removeStep(message, type, stepIndex) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        type: type,
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && type === 'Confirm') {
        this.selectedSteps.splice(stepIndex, 1);
        if (this.selectedSteps.length === 0) {
          this.stepsValidate = false;
        }
        this.selectedStep = null;
      }
    });
  }

  templateSelection($event) {
    this.selectedStep = null;
    const tmpSteps = $event.value.steps.split('|');
    this.selectedSteps = tmpSteps.map((tmpStep, index) => {
      const stepIds = tmpStep.split('-');
      const step: any = this.steps.find(s => s['stepId'] == stepIds[0]);
      if (!step) {
        return null;
      }
      const action = step['stepActions'].find(a => a['actionId'] == stepIds[1]);
      return {
        id: this.newUUID(),
        steps: this.steps,
        action: action,
        step: step,
        notification: false,
        additionalInfo: step.additionalInfo,
        note: '',
        notePriority: 'low'
      };
    });

    this.selectedStep = this.selectedStep == null ? null : this.selectedStep.filter(s => s !== null);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.jobItemRequiredApproveArtwork && (event.currentIndex == 0 || event.currentIndex == 1)) {
      return;
    }
    moveItemInArray(this.selectedSteps, event.previousIndex, event.currentIndex);
  }

  onClose() {
    if (this.isEdit) { this.dialog.closeAll(); return; }
    this.close.emit();
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onRemoveFile(file) {
    this.jobItemFiles.delete(file);
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
      }

      if (input) {
        input.value = '';
      }

      this.jobItemCtrl.setValue(null);
    }
  }

  remove(jobItem: JobItem): void {
    const index = this.selectedJobItems.findIndex(x => x.description === jobItem.description);

    if (index >= 0) {
      this.selectedJobItems.splice(index, 1);
    }

    // job items validate.
    if (this.selectedJobItems.length === 0) {
      this.jobItemsValidate = false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.selectedJobItems.indexOf(event.option.value);
    if (index < 0) {
      this.selectedJobItems.push(event.option.value);
      this.jobItemInput.nativeElement.value = '';
      this.jobItemCtrl.setValue(null);
      this.jobItemsValidate = true;
    }
  }

  private _filter(value: JobItem): JobItem[] {
    const filterValue = value.description.toLowerCase();

    return this.job.items.filter(jobItem => jobItem.description.toLowerCase().indexOf(filterValue) === 0);
  }

  onAssign() {
    this.jobItemsValidate = this.departmentValidate = this.stepsValidate = true;

    if (this.selectedJobItems.length === 0) {
      this.jobItemsValidate = false;
      return;
    }

    if (this.selectedSteps.length === 0) {
      this.stepsValidate = false;
      return;
    }

    const isExist = this.selectedSteps.find(s => s.action === null);
    if (isExist) {
      this.stepsValidate = false;
      return;
    }

    if (this.selectedJobItems[0].hasOwnProperty('isRequiredApproveArtwork') && this.selectedJobItems.find(ji => ji.isRequiredApproveArtwork && !ji.isImportToProduction)) {

      if (this.selectedSteps.length < 2) {
        this.departmentValidateErrorMessage = "You must select 'Design' or 'Sales' as first department."
        this.departmentValidate = false;
        return;
      }

      const departmentFirst = this.selectedSteps[0].step.department.name;
      const departmentSecond = this.selectedSteps[1].step.department.name;

      // if (departmentFirst.toLowerCase() !== 'design' || departmentSecond.toLowerCase() !== 'sales') {
      //   this.departmentValidate = false;
      //   return;
      // }
      if (departmentFirst.toLowerCase() === 'design') {
        if (departmentSecond.toLowerCase() !== 'sales') {
          this.departmentValidateErrorMessage = "You must select 'Sales' as second department when 'Design' is first department."
          this.departmentValidate = false;
          return;
        }
      }
      else if (departmentFirst.toLowerCase() === 'sales') {
        if (departmentSecond.toLowerCase() !== 'design') {
          this.departmentValidateErrorMessage = "You must select 'Design' as second department when 'Sales' is first department."
          this.departmentValidate = false;
          return;
        }
      }
      else {
        this.departmentValidateErrorMessage = "You must select 'Design' or 'Sales' as first department."
        this.departmentValidate = false;
        return;
      }

    }

    if (this.selectedAssignedCsrIsInvalid && !this.isPrinterPlanJob) {
      this.isErrorForAssignedCsr = true;
      this.errorMsgForAssignedCsr = "Please assign CSR";
    } else {
      this.isErrorForAssignedCsr = false;
    }

    if (this.isErrorForAssignedCsr) {
      return;
    }

    if (this.isRush && !this.rushDate) {
      return;
    }

    if (this.unionBug === undefined) {
      this.unionBugValidate = true;
      return;
    }

    this.selectedJobItems.forEach(item => {
      item.steps = this.selectedSteps;
      item.isUnion = this.unionBug;
      item['note'] = this.jobItemNote;
      item['notePriority'] = this.jobItemNotePriority;
      item['isNotifyDepartmentUsers'] = this.isNotifyDeptUsers;
      // item['departmentsToNotify'] = this.departments.filter(d => d.selected).map(d => d.departmentId);
      item['departmentsToNotify'] = this.selectedDepartments.value ? this.selectedDepartments.value.filter(d => d.departmentId != 0).map(d => d.departmentId) : [];
    });

    this.selectedJobItems['files'] = this.jobItemFiles;
    this.job['rushDate'] = this.isRush ? this.rushDate : null;

    if (this.isEdit) {
      this.updateJob();
      return;
    }

    this.close.emit(this.selectedJobItems);
  }

  onUnionBugChange($event) {
    if ($event.value === 'true' || $event.value === 'false') {
      this.unionBugValidate = false;
    }
  }

  onClickMailingSort(stepAction: any) {
    const dialogRef = this.dialog.open(JobMailingSortCreateComponent, {
      height: '550px',
      width: '1100px',
      data: stepAction['mailingSort'] == undefined ? null : stepAction['mailingSort']
    });

    dialogRef.afterClosed().subscribe(mailingSort => {
      if (mailingSort)
        stepAction['mailingSort'] = mailingSort;
    });
  }

  onClickMailingInfo(stepAction: any) {

    const dialogRef = this.dialog.open(JobMailingInfoCreateComponent, {
      height: '550px',
      width: '1100px',
      data: stepAction['mailingInfo'] == undefined ? null : stepAction['mailingInfo']

    });

    dialogRef.afterClosed().subscribe(mailingInfo => {
      if (mailingInfo)
        stepAction['mailingInfo'] = mailingInfo;
    });
  }

  onNotificationClick() {
    const step = this.selectedSteps.find(s => s.notification === false);
    if (step) {
      this.allNotification = false;
    } else {
      this.allNotification = true;
    }
  }

  onAllNotificationClick() {
    this.selectedSteps.map(step => {
      step.notification = this.allNotification;
      return step;
    });
  }

  onSaveTemplate() {
    this.stepsValidate = true;
    const isExist = this.selectedSteps.find(s => s.action === null);
    if (isExist) {
      this.stepsValidate = false;
      return;
    }

    let steps = '';
    this.selectedSteps.forEach(stepAction => {

      if (steps.length > 0) {
        steps += '|';
      }
      if (stepAction.step !== null && stepAction.action !== null) {
        const step = stepAction.step.stepId + '-' + stepAction.action.actionId;
        steps += step;
      }
    });

    if (steps === '') {
      return;
    }

    const dislogRef = this.dialog.open(JobTemplateCreateComponent, {
      width: '600px',
      height: '200px',
      data: steps
    });

    dislogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadTemplate();
      }
    })
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const reader = new FileReader();
      const selectedFile = {
        file: event[index]
      };

      const fileName = event[index].name;
      const fileExtension = fileName.split('.')[fileName.split('.').length - 1].replace('"', '');

      if (this.getDocumentType(fileExtension) == "Document") {
        this.openToast('Please Select PDF FILES , EXCEL FILES and IMAGE FILES Only');
        continue;
      }

      if (this.getDocumentType(fileExtension) != 'Image') {
        switch (fileExtension) {
          case 'pdf':
            selectedFile['url'] = 'assets/images/pdf_thumbnail.svg';
            break;
          case 'xlsx':
            selectedFile['url'] = 'assets/images/xls_thumbnail.png';
            break;
          case 'xls':
            selectedFile['url'] = 'assets/images/xls_thumbnail.png';
            break;
          case 'tiff':
            selectedFile['url'] = 'assets/images/tiff_thumbnail.svg';
            break;
          case 'tif':
            selectedFile['url'] = 'assets/images/tiff_thumbnail.svg';
            break;
          case 'svg':
            selectedFile['url'] = 'assets/images/svg_thumbnail.svg';
            break;
          case 'zip':
            selectedFile['url'] = 'assets/images/zip_thumbnail.svg';
            break;
          default:
            selectedFile['url'] = 'assets/images/doc_thumbnail.svg';
        }
      } else {
        reader.readAsDataURL(event[index]);
        reader.onload = (event: any) => {
          selectedFile['url'] = event.target['result'];
        }
      }
      this.jobItemFiles.add(selectedFile);
    }
  }

  getDocumentType(fileExtension: string) {
    switch (fileExtension.toLowerCase()) {
      case 'jpg':
        return 'Image';
      case 'jpeg':
        return 'Image';
      case 'png':
        return 'Image';
      case 'svg':
        return 'SVG';
      case 'gif':
        return 'Image';
      case 'tiff':
        return 'Tiff';
      case 'tif':
        return 'Tif';
      case 'pdf':
        return 'Pdf';
      case 'xlsx':
        return 'Excel';
      case 'xls':
        return 'Excel';
      // case 'csv':
      //   return 'CSV';
      // case 'zip':
      //   return 'zip';
      default:
        return 'Document';
    }
  }
  addNote() {
    if (this.selectedStep) {
      this.selectedStep.note = this.note.value;
    } else {
      this.jobItemNote = this.note.value;
    }
  }

  setNotePriority() {
    if (this.selectedStep) {
      this.selectedStep.notePriority = this.notePriority.value
    } else {
      this.jobItemNotePriority = this.notePriority.value
    }
  }

  loadSelectdStep() {
    this.job.items.forEach(item => {
      const steps = item.steps.split('|');
      steps.forEach((stp, index) => {
        const _stp = stp.split('-');
        const step = this.steps.find(s => s.stepId == _stp[0]);
        const notify = item.notifies.find(n => n.step === stp && n.stepIndex == index);
        const jobItemStepAction = item.stepActions.find(sa => sa.step === stp);
        if (step) {
          const stepAction = step.stepActions.find(sa => sa.actionId == _stp[1]);
          this.selectedSteps.push({
            id: this.newUUID(),
            steps: this.steps,
            action: stepAction,
            step: step,
            additionalInfo: step['additionalInfo'],
            notification: notify ? notify.isNotify : false,
            note: '',
            notePriority: 'low',
            mailingSort: jobItemStepAction ? jobItemStepAction['mailingSort'] : null,
            mailingInfo: jobItemStepAction ? jobItemStepAction['mailingInfo'] : null,
            currentIndex: index,
            isCurrentStep: index === item['currentStepIndex'] ? true : false
          });
        }
      });
    });

    const notify = this.selectedSteps.find(s => !s.notification);
    if (!notify) { this.allNotification = true; }
  }

  updateJob() {
    const jobItems = this.job.items.map(item => {

      let steps = '';
      let itemSteps = [];
      let notifications = [];
      let notes = [];
      const currentStep = item.steps[0].stepId + '-' + item.steps[0].actionId;
      let mailingSorts = [];
      let mailingInfos = [];

      //job item note
      if (item.note) {
        notes.push({
          jobItemNo: item.itemNo,
          text: item.note,
          stepIndex: null,
          priority: item.notePriority
        });
      }

      item.steps.forEach((stepAction, index) => {
        if (steps.length > 0) {
          steps += '|';
        }
        const newStep = stepAction.step.stepId + '-' + stepAction.action.actionId;
        steps += newStep;

        itemSteps.push({
          step: newStep,
          currentIndex: stepAction['currentIndex'] == undefined ? -1 : stepAction['currentIndex'],
          isCurrentStep: stepAction['isCurrentStep'] ? stepAction['isCurrentStep'] : false,
          newIndex: index,
        })

        notifications.push({// add all step notification
          jobItemNo: item.itemNo,
          step: newStep,
          stepIndex: index,
          isNotify: stepAction.notification,
        });

        if (stepAction.note) { // add all steps note
          const note =
            notes.push({
              jobItemNo: item.itemNo,
              step: newStep,
              stepIndex: index,
              text: stepAction.note,
              priority: stepAction.notePriority
            });
        }


        let mailingSort = stepAction['mailingSort'];
        let mailingInfo = stepAction['mailingInfo'];
        if (mailingSort != undefined) {
          mailingSort['step'] = newStep;
          mailingSort['stepIndex'] = index;
          mailingSorts.push(mailingSort);
        }

        if (mailingInfo != undefined) {
          mailingInfo['step'] = newStep;
          mailingInfo['stepIndex'] = index;
          mailingInfos.push(mailingInfo);
        }

      });

      return {
        jobItemId: item.jobItemId,
        isUnion: item.isUnion,
        steps: steps,
        ItemSteps: itemSteps,
        notifications: notifications,
        notes: notes,
        mailingSorts: mailingSorts,
        mailingInfos: mailingInfos,
        rushDate: this.job['rushDate'],
        isNotifyDepartmentUsers: this.isNotifyDeptUsers,
        // departmentsToNotify : this.departments.filter(d => d.selected).map(d => d.departmentId)
        departmentsToNotify: this.selectedDepartments.value ? this.selectedDepartments.value.filter(d => d.departmentId != 0).map(d => d.departmentId) : []
      };
    });


    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to update this job item step?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveJobDetails(jobItems[0]);
      }
    });
  }

  saveJobDetails(jobItem) {
    jobItem['csrNo'] = this.job['csrNo'];
    this.api.request('UPDATE_JOBITEM', { data: jobItem }).subscribe(response => {
      if (response) {
        this.saveFiles();
      } else {
        this.openToast('JobItem is not updated.')
      }
    }, error => {
      this.openToast('JobItem is not updated.')
    });
  }

  saveFiles() {
    if (this.job.items.files && this.job.items.files.size > 0) {
      const formdata = new FormData();
      this.job.items.files.forEach(file => {
        formdata.append('files', file.file);
      });
      formdata.append('JobItemIds', this.job.items[0].jobItemId);
      this.api.request('CREATE_JOB_ITEM_DOCUMENT', { data: formdata }).subscribe((res: any) => {
        this.onUpdateSuccessfull();
      });
    } else {
      this.onUpdateSuccessfull();
    }
  }

  onUpdateSuccessfull() {
    this.store.dispatch(new LoadJobs(this.userService.getUser().filter[0]));
    this.dialog.closeAll();
    this.router.navigate(['/jobs']); // naviate to job list page
    this.openToast('JobItem updated successfully');
  }


  /**
   * Admin Csr drop down Functions
   */
  registerPanelScrollEventForAdminCsrField() {
    if (this.assignedCsrSelectElem.panel != undefined) {
      const panel = this.assignedCsrSelectElem.panel.nativeElement;
      panel.addEventListener('scroll', event => this.loadNextBatchOfAdminCsrOnScroll(event));
    }
  }

  loadNextBatchOfAdminCsrOnScroll(event) {
    var i = this.TotalAssignedCsr / this.assignedCsrPageSize;
    i = Math.ceil(i);
    if (this.loadingAssignedCsrList) { return; }
    if (this.assignedCsrPageNo < i) {
      if (Math.ceil(event.target.scrollTop + event.target.offsetHeight) == event.target.scrollHeight) {
        this.assignedCsrPageNo = this.assignedCsrPageNo + 1;
        this.loadingAssignedCsrList = true;
        this.loadAdminCsrs();
      }
    }
  }

  loadAdminCsrs() {
    const params = {
      PAGE_NO: this.assignedCsrPageNo,
      PAGE_SIZE: this.assignedCsrPageSize,
      SEARCHED_NAME: this.assignedCsrfilterText,
      EXCLUDE_ADMINS: true,
      EXCLUDE_CSRS: false,
      EXCLUDE_DEPT_UDERS: true
    }

    this.api.request('GET_USERS', { params: params }).subscribe((res) => {
      if (this.assignedCsrPageNo == 0) {
        this.TotalAssignedCsr = res['totalRecord'];
        this.assignedCsrs = res['users'];
        if (this.assignedCsrs && this.assignedCsrs.length > 0) {
          this.assignedCsrs.forEach(_user => {
            if (_user.csrName && _user.csrName != "") {
              _user.firstName = _user.csrName;
              _user.lastName = "";
            }
          });
        }
        this.loadingAssignedCsrList = false;
      } else {
        this.TotalAssignedCsr = res['totalRecord'];
        var prevAdminCsrList = this.assignedCsrs.slice();
        var adminCsrRes = res['users'];
        if (adminCsrRes && adminCsrRes.length > 0) {
          adminCsrRes.forEach(_user => {
            if (_user.csrName && _user.csrName != "") {
              _user.firstName = _user.csrName;
              _user.lastName = "";
            }
          });
        }
        this.assignedCsrs = prevAdminCsrList.concat(adminCsrRes);
        this.loadingAssignedCsrList = false;
      }
    });
  }

  selectAssignedCsr(event : MatAutocompleteSelectedEvent) {
    let user = event.option.value;
    if(user === null){
      this.removeAssignedCsr();
      return;
    }
    this.selectedAssignedCsr = user;
    this.selectedAssignedCsrIsInvalid = false;
    this.assignedCsrForm.controls['assignedCsrFilterControl'].setValue(user.firstName + ' ' + user.lastName);
    this.job['csrNo'] = user.userId;
    this.job['csrName'] = user.firstName + ' ' + user.lastName;
  }

  removeAssignedCsr() {
    this.selectedAssignedCsrIsInvalid = true;
    this.selectedAssignedCsr = null;
    this.assignedCsrfilterText = "";
    this.assignedCsrPageNo = 0;
    this.job['csrNo'] = 0;
    this.job['csrName'] = "";
    this.loadAdminCsrs();
    this.registerPanelScrollEventForAdminCsrField();
  }
  /**End Of Admin Csr drop down functions */


  /**Department Drop down*/
  loadDepartments() {
    const params = {
      PAGE_NO: 0,
      PAGE_SIZE: 0
    }

    this.api.request('GET_DEPARTMENTS', { params: params }).subscribe(res => {

      this.departments = res['departments'];
      this.departments.forEach(_department => {
        _department.selected = false;
      });

      this.isDepartmentsLoaded = true;
      if (this.job.items && this.job.items.length == 1) {
        var item = this.job.items[0];
        this.isNotifyDeptUsers = item.isNotifyDepartmentUsers;
        if (item.departmentsToNotify) {
          var selectedDepartments = [];
          // selectedDepartments.forEach(_selectedDepartment => {
          //   var selectedDepartment = this.departments.find(d => d.departmentId == _selectedDepartment);
          //   if (selectedDepartment) {
          //     selectedDepartment.selected = true;
          //   }
          // });
          item.departmentsToNotify.forEach(_selectedDepartment => {
            var selectedDepartment = this.departments.find(d => d.departmentId == _selectedDepartment);
            if (selectedDepartment) {
              selectedDepartments.push(selectedDepartment);
            }
          });
          this.selectedDepartments.setValue(selectedDepartments);
          this.toggleDepartmentSelectPerOne();
          // this.prepareResultText();
          // this.manageDepartmentAllOptionValue();
        }
        else {
          this.selectedDepartments.setValue([]);
        }
      }
    });
  }

  // onDepartmentOptionClicked(event,department){
  //   event.stopPropagation();
  // }

  // onDepartmentAllOptionClicked(event){
  //   event.stopPropagation();
  // }

  // toggleDepartmentSelection(department){
  //   department.selected = !department.selected;
  //   this.prepareResultText();
  //   this.manageDepartmentAllOptionValue();
  // }

  // toggleAllDepartmentSelection(isCheckedAllOption){
  //   this.manageDepartmentOptionsValue(isCheckedAllOption);
  // }

  // prepareResultText(){
  //   var selectedDepartments = this.departments.filter(d => d.selected);
  //   this.departmentInputText = selectedDepartments.map(d => d.name).join(",");
  // }

  // manageDepartmentOptionsValue(isAllOptionChecked){
  //   if(isAllOptionChecked){
  //     this.departments.forEach(_department => {
  //       _department.selected = true;
  //     });
  //     this.prepareResultText();
  //   }
  //   else{
  //     this.departments.forEach(_department => {
  //       _department.selected = false;
  //     });
  //     this.prepareResultText();
  //   }
  // }

  // manageDepartmentAllOptionValue(){
  //   if(this.departments.filter(d => d.selected).length === this.departments.length){
  //     this.isAllDepartmentsOptionChecked = true;
  //   }
  //   else{
  //     this.isAllDepartmentsOptionChecked = false;
  //   }
  // }


  getSelectDepartmentsText() {
    if (this.selectedDepartments && this.selectedDepartments.value) {
      var exceptForAll = this.selectedDepartments.value.filter(d => d.departmentId != 0);
      if (exceptForAll.length == this.departments.length) {
        return "All";
      }
      return this.selectedDepartments.value.map(d => d.name).join(",");
    }
    else {
      return "";
    }
  }

  toggleDepartmentSelectPerOne() {
    var exceptForAll = this.selectedDepartments.value.filter(d => d.departmentId != 0);
    if (exceptForAll.length == this.departments.length) {
      this.selectedDepartments.setValue([...this.departments, this.allDepartment]);
    } else {
      this.selectedDepartments.setValue([...exceptForAll]);
    }
  }

  toggleDepartmentSelectAllOption() {
    var allOptionSelected = this.selectedDepartments.value.filter(d => d.departmentId == 0);
    if (allOptionSelected.length > 0) {
      this.selectedDepartments.setValue([...this.departments, this.allDepartment]);
    }
    else {
      this.selectedDepartments.setValue([]);
    }
  }
  /**End of Department dropdown */
}
