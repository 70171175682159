import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import { Job } from "src/app/shared/interfaces/job";
import { JobState } from "../state/job.state";
import { Observable, Subscriber, Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import {
  Store,
  Select,
  Actions,
  ofActionSuccessful,
  ofActionCompleted,
} from "@ngxs/store";
import {
  LoadJobs,
  ExpandAndCollapsJob,
  ReloadeJobs,
  ResetState,
} from "../state/job.actions";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { ActivatedRoute } from "@angular/router";
import { ConfigurationState } from "../../configuration/state/configuration.state";
import { UntypedFormControl } from "@angular/forms";
import { startWith, map, debounce, debounceTime } from "rxjs/operators";
import {
  ChangeUserDepartment,
  FullScreenView,
} from "src/app/core/layout/topnav/state/topnav.action";
import { JobListMoveComponent } from "./job-list-move/job-list-move.component";
import { ShipmentCreateComponent } from "../../shipment/shipment-create/shipment-create.component";
import { PermissionService } from "src/app/shared/services/permission.service";
import { JobTemplateLabelDialogComponent } from "../job-template-label-dialog/job-template-label-dialog.component";
import { RetriveJobsComponent } from "./retrive-jobs/retrive-jobs.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatIconRegistry } from "@angular/material/icon";
import {
  EnumSwitchUploadFilesMode,
  UploadToSwitchComponent,
} from "../job-details/upload-to-switch/upload-to-switch.component";
import { RyobiPdfPrintService } from "src/app/shared/services/ryobi-pdf-print.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { RYOBI_DEPARTMENT } from "src/app/shared/constant/department";
import { JobListRyobiPrintComponent } from "./job-list-ryobi-print/job-list-ryobi-print.component";

export interface Csr {
  name: string;
  isSelect: boolean;
}

export interface Customer {
  name: string;
  isSelect: boolean;
}

export interface JobNo {
  no: number;
  isSelect: boolean;
}

export interface status {
  name: string;
  value: number;
  isSelect: boolean;
}

@Component({
  selector: "app-job-list",
  templateUrl: "./job-list.component.html",
  styleUrls: ["./job-list.component.scss"],
})
export class JobListComponent implements OnInit, OnDestroy {
  subTitles = [
    { text: "Home", route: "" },
    { text: "Jobs", route: "" },
  ];
  title = "Jobs";
  statusList: Array<status> = [
    { name: "All", value: 1, isSelect: false },
    { name: "In Progress", value: 3, isSelect: false },
    { name: "Invoice", value: 4, isSelect: false },
  ];
  csrList: Array<Csr>;
  customerList: Array<Customer>;
  jobNoList: Array<JobNo>;
  currentDepartmentId: number = 0;
  statusFromControl = new UntypedFormControl();
  csrFormControl = new UntypedFormControl();
  customerFormControl = new UntypedFormControl();
  jobNoFormControl = new UntypedFormControl();
  filteredCsr: Observable<Csr[]>;
  filteredCustomer: Observable<Customer[]>;
  filteredJobNo: Observable<JobNo[]>;
  @Select(JobState.AllJobs) jobs$: Observable<Job[]>;
  @Select(JobState.TotalJobsCount) totalRecord: Observable<number>;
  @Select(JobState.IsJobsLoading) isJobsLoading: Observable<boolean>;
  filterDateDue = "";
  filterDateIn = "";
  showFilter = false;
  pageNo = 0;
  pageSize = 50;
  isExpand: boolean;
  myControl = new UntypedFormControl();
  isFullScreenView = false;
  jobView: any;
  currentDepartmentName = "";
  allowCreateShipment = false;
  allDepartments = [];
  allowTemplateLabel = false;
  allowRetriveButton = false;
  @ViewChild("jobpaginator") paginator: MatPaginator;
  resetStateSubscription: any;
  queryParamSubscription: any;
  jobs: any;
  isJobTab = false;
  //Subscriptions
  fullScreenViewSubscription: Subscription;
  reloadeJobsSubscription: Subscription;
  userDepartmentChangeSubscription: Subscription;

  constructor(
    sanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    private store: Store,
    private api: ApiService,
    private userService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private actions$: Actions,
    private dialog: MatDialog,
    private auth: AuthUserService,
    private permission: PermissionService,
    private ryobiPrint: RyobiPdfPrintService,
    private toast: ToastService
  ) {
    iconRegistry.addSvgIcon(
      "filter",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/filter-icon.svg")
    );
    iconRegistry.addSvgIcon(
      "expanded",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/expanded.svg")
    );
    iconRegistry.addSvgIcon(
      "closed",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/closed.svg")
    );
    iconRegistry.addSvgIcon(
      "fullscreen",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/fullscreen.svg")
    );
    iconRegistry.addSvgIcon(
      "right-arrow",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/right-arrow.svg")
    );
    iconRegistry.addSvgIcon(
      "retrieve",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/retrieve.svg")
    );
  }
  ngOnDestroy(): void {
    this.resetStateSubscription.unsubscribe();
    this.queryParamSubscription.unsubscribe();
    this.fullScreenViewSubscription.unsubscribe();
    this.reloadeJobsSubscription.unsubscribe();
    this.userDepartmentChangeSubscription.unsubscribe();
  }

  ngOnInit() {
    // Allow Retrive Button
    this.allowRetriveButton =
      this.permission.isCurrentDepartment("Administration") ||
      this.permission.isCurrentDepartment("Csr")
        ? true
        : false;

    this.jobView = document.getElementById("app");
    const self = this;
    this.document.addEventListener("fullscreenchange", function () {
      if (self.document.fullscreenElement === null) {
        self.store.dispatch(new FullScreenView(false));
        self.isFullScreenView = false;
      }
    });

    this.fullScreenViewSubscription = this.actions$
      .pipe(ofActionCompleted(FullScreenView))
      .subscribe((res) => {
        this.isFullScreenView = res.action.isFullScreenView;
      });

    this.reloadeJobsSubscription = this.actions$
      .pipe(ofActionSuccessful(ReloadeJobs))
      .subscribe((res) => {
        const id = this.activatedRoute.snapshot.queryParams["id"];
        this.currentDepartmentId = id ? parseInt(id) : 0;
        this.onFilterChange();
      });

    this.userDepartmentChangeSubscription = this.actions$
      .pipe(ofActionSuccessful(ChangeUserDepartment))
      .subscribe((_) => {
        //Shipment create option modification
        if (this.currentDepartmentId == 0) {
          this.allowCreateShipment =
            this.permission.isCurrentDepartment("shipping") ||
            this.permission.isCurrentDepartment("Administration") ||
            this.permission.isCurrentDepartment("Csr")
              ? true
              : false;
        } else {
          this.allowCreateShipment =
            (this.currentDepartmentName.toLowerCase() == "shipping" &&
              this.permission.isCurrentDepartment("shipping")) ||
            (this.currentDepartmentName.toLowerCase() == "Administration" &&
              this.permission.isCurrentDepartment("Administration")) ||
            (this.currentDepartmentName.toLowerCase() == "Csr" &&
              this.permission.isCurrentDepartment("Csr"))
              ? true
              : false;
        }

        //Template label option modification
        var isShippingDept = this.permission.isCurrentDepartment("shipping")
          ? true
          : false;
        var isBinderyDept = this.permission.isCurrentDepartment("bindery")
          ? true
          : false;
        const shippingDept = this.allDepartments.find(
          (d) => d.name.toLowerCase() === "shipping"
        );
        const binderyDept = this.allDepartments.find(
          (d) => d.name.toLowerCase() === "bindery"
        );
        this.allowTemplateLabel = false;
        if (isShippingDept || isBinderyDept) {
          if (isShippingDept) {
            if (this.currentDepartmentId === shippingDept.departmentId) {
              this.allowTemplateLabel = true;
            }
          }
          if (isBinderyDept) {
            if (this.currentDepartmentId === binderyDept.departmentId) {
              this.allowTemplateLabel = true;
            }
          }
          if (isBinderyDept || isShippingDept) {
            if (this.currentDepartmentId === 0) {
              this.allowTemplateLabel = true;
            }
          }
        }
      });

    this.resetStateSubscription = this.activatedRoute.queryParams.subscribe(
      (queryPar) => this.store.dispatch(new ResetState())
    );

    this.queryParamSubscription = this.activatedRoute.queryParams
      .pipe(debounceTime(1000))
      .subscribe((queryParams) => {
        this.allowCreateShipment = false;
        const navigations = this.store.selectSnapshot(
          ConfigurationState.AllNavigations
        );
        this.currentDepartmentId = queryParams["id"]
          ? parseInt(queryParams["id"])
          : 0;
        const navigationDept = navigations.find(
          (n) => n.departmentId == this.currentDepartmentId
        );

        this.currentDepartmentName =
          navigationDept === undefined ? "" : navigationDept.departmentName;

        //disable/enable status filter
        this.statusFromControl.enable({ onlySelf: true });
        if (this.currentDepartmentId) {
          this.statusFromControl.disable({ onlySelf: true });
        }

        if (
          this.currentDepartmentId > 0 &&
          navigations &&
          navigations.length > 0
        ) {
          this.allowCreateShipment =
            (this.currentDepartmentName.toLowerCase() == "shipping" &&
              this.permission.isCurrentDepartment("shipping")) ||
            (this.currentDepartmentName.toLowerCase() == "Administration" &&
              this.permission.isCurrentDepartment("Administration")) ||
            (this.currentDepartmentName.toLowerCase() == "Csr" &&
              this.permission.isCurrentDepartment("Csr"))
              ? true
              : false;
          this.loadJobs(navigations);
        } else {
          this.allowCreateShipment =
            this.permission.isCurrentDepartment("shipping") ||
            this.permission.isCurrentDepartment("Administration") ||
            this.permission.isCurrentDepartment("Csr")
              ? true
              : false;
          this.api
            .request("GET_NAVIGATIONS", {
              params: { PAGE_NO: 0, PAGE_SIZE: 0 },
            })
            .subscribe((res: any) => {
              this.loadJobs(res["navigations"]);
            });
        }

        this.loadDepartments();
      });
  }

  private loadDepartments() {
    this.api
      .request("GET_DEPARTMENTS", { params: { PAGE_NO: 1, PAGE_SIZE: 0 } })
      .subscribe((res) => {
        var isShippingDept = this.permission.isCurrentDepartment("shipping")
          ? true
          : false;
        var isBinderyDept = this.permission.isCurrentDepartment("bindery")
          ? true
          : false;
        this.allDepartments = res["departments"];
        const shippingDept = this.allDepartments.find(
          (d) => d.name.toLowerCase() === "shipping"
        );
        const binderyDept = this.allDepartments.find(
          (d) => d.name.toLowerCase() === "bindery"
        );
        if (isShippingDept || isBinderyDept) {
          this.allowTemplateLabel = false;
          if (isShippingDept) {
            if (this.currentDepartmentId === shippingDept.departmentId) {
              this.allowTemplateLabel = true;
            }
          }
          if (isBinderyDept) {
            if (this.currentDepartmentId === binderyDept.departmentId) {
              this.allowTemplateLabel = true;
            }
          }
          if (isBinderyDept || isShippingDept) {
            if (this.currentDepartmentId === 0) {
              this.allowTemplateLabel = true;
            }
          }
        }
      });
  }

  private loadJobs(navigations) {
    const navigationDept = navigations.find(
      (n) => n.departmentId == this.currentDepartmentId
    );
    this.pageNo = 0;
    this.paginator.pageIndex = 0;
    this.title = navigationDept === undefined ? "Jobs" : navigationDept.title;
    if (!navigationDept) {
      this.isJobTab = true;
    }

    const user = this.userService.getUser();
    let currentFilter = user ? user.filter[this.currentDepartmentId] : null;
    if (
      (!this.csrList || !this.customerList || !this.jobNoList) &&
      user.filter &&
      currentFilter &&
      ((currentFilter.jobNumbers && currentFilter.jobNumbers.length > 0) ||
        (currentFilter.customerNames &&
          currentFilter.customerNames.length > 0) ||
        (currentFilter.csrNames && currentFilter.csrNames.length > 0))
    ) {
      this.getAllFilterList();
    } else {
      this.setApplyFilter();
    }
  }

  private filterByValue(type: string, value: string): any[] {
    const filterValue = value.toString().toLowerCase();
    switch (type) {
      case "csr":
        return this.csrList.filter(
          (csr) => csr.name.toLowerCase().indexOf(filterValue) === 0
        );

      case "customer":
        return this.customerList.filter(
          (customer) => customer.name.toLowerCase().indexOf(filterValue) === 0
        );

      case "jobNo":
        return this.jobNoList.filter(
          (jobNo) =>
            jobNo.no.toString().toLowerCase().indexOf(filterValue) === 0
        );
      case "status":
        return this.statusList.filter(
          (status) =>
            status.name.toString().toLowerCase().indexOf(filterValue) === 0
        );
    }
  }

  setApplyFilter() {
    //cleare selected filter
    if (this.csrList) {
      this.csrList.map((csr) => (csr.isSelect = false));
    }
    if (this.customerList) {
      this.customerList.map((cust) => (cust.isSelect = false));
    }
    if (this.jobNoList) {
      this.jobNoList.map((job) => (job.isSelect = false));
    }
    if (this.statusList) {
      this.statusList.map((status) => (status.isSelect = false));
    }
    this.isExpand = false;

    const user = this.userService.getUser();

    if (user.filter == null || !user.filter[this.currentDepartmentId]) {
      var filter = this.getFilterObject();
      this.store.dispatch(new LoadJobs(filter));
      return;
    }

    //isExpnad jobs or not
    this.isExpand = user.filter[this.currentDepartmentId]["isExpand"];
    this.store.dispatch(new ExpandAndCollapsJob(this.isExpand));

    this.filterDateDue = user.filter[this.currentDepartmentId]["dateDue"];
    this.filterDateIn = user.filter[this.currentDepartmentId]["dateIn"];

    // status filter
    this.statusList.map((status) => {
      if (status.value === user.filter[this.currentDepartmentId]["status"]) {
        status.isSelect = true;
      }
      return status;
    });

    //csr filter
    if (user.filter[this.currentDepartmentId]["csrNames"] && this.csrList) {
      this.csrList.map((csr: Csr) => {
        const index = user.filter[this.currentDepartmentId][
          "csrNames"
        ].findIndex((_name) => _name === csr.name);
        if (index >= 0) {
          csr.isSelect = true;
        }
        return csr;
      });
    }

    //customer filter
    if (
      user.filter[this.currentDepartmentId]["customerNames"] &&
      this.customerList
    ) {
      this.customerList.map((customer: Customer) => {
        const index = user.filter[this.currentDepartmentId][
          "customerNames"
        ].findIndex((_name) => _name === customer.name);
        if (index >= 0) {
          customer.isSelect = true;
        }
        return customer;
      });
    }

    // job number filter
    if (user.filter[this.currentDepartmentId]["jobNumbers"] && this.jobNoList) {
      this.jobNoList.map((jobNo: JobNo) => {
        const index = user.filter[this.currentDepartmentId][
          "jobNumbers"
        ].findIndex((_jobNo) => _jobNo === jobNo.no);
        if (index >= 0) {
          jobNo.isSelect = true;
        }
        return jobNo;
      });
    }

    this.onFilterChange();
  }

  onExpandAndCollapse() {
    this.isExpand = !this.isExpand;
    this.store.dispatch(new ExpandAndCollapsJob(this.isExpand));
    const filterObject = this.getFilterObject();
    this.store.dispatch(new LoadJobs(filterObject));
  }

  getAllFilterList(isloadJob = true) {
    this.api.request("GET_FILTER_LOOKUP_LIST").subscribe((res) => {
      if (res.csrNames.length > 0) {
        this.csrList = res.csrNames.map((csr) => {
          return {
            name: csr,
            isSelect: false,
          };
        });
        this.filteredCsr = this.csrFormControl.valueChanges.pipe(
          startWith(""),
          map((csr) =>
            csr ? this.filterByValue("csr", csr) : this.csrList.slice()
          )
        );
      }

      if (res.customerNames.length > 0) {
        this.customerList = res.customerNames.map((customer) => {
          return {
            name: customer,
            isSelect: false,
          };
        });

        this.filteredCustomer = this.customerFormControl.valueChanges.pipe(
          startWith(""),
          map((customer) =>
            customer
              ? this.filterByValue("customer", customer)
              : this.customerList.slice()
          )
        );
      }

      if (res.jobNumbers.length > 0) {
        this.jobNoList = res.jobNumbers.map((jobNo) => {
          return {
            no: jobNo,
            isSelect: false,
          };
        });

        this.filteredJobNo = this.jobNoFormControl.valueChanges.pipe(
          startWith(""),
          map((jobNo) =>
            jobNo ? this.filterByValue("jobNo", jobNo) : this.jobNoList.slice()
          )
        );
      }
      if (isloadJob) {
        this.setApplyFilter();
      }
    });
  }

  /**
   * on sub filter selected
   */
  onFilterSelect(filterType, filter) {
    if (filterType === "status") {
      this.statusList.map((f) => {
        f.isSelect = false;
      });
    }

    filter.isSelect = !filter.isSelect;
    this.onFilterChange();
    this.clearFilterSelection(filterType);
  }

  clearFilterSelection(filterType: string) {
    switch (filterType) {
      case "status":
        this.statusFromControl.reset();
        break;
      case "csr":
        this.csrFormControl.reset();
        break;
      case "job":
        this.jobNoFormControl.reset();
        break;
      case "customer":
        this.customerFormControl.reset();
        break;
      default:
        break;
    }
    this.document.activeElement.blur();
  }

  onRemoveFilter(type, filter = null) {
    if (type === "dateIn") {
      this.filterDateIn = "";
    }

    if (type === "dateDue") {
      this.filterDateDue = "";
    }

    if (filter != null) {
      filter.isSelect = !filter.isSelect;
    }

    this.onFilterChange();
  }

  /**
   * On date filter change value.
   */
  onDateFilterChange(filterType, $event) {
    const date = new Date($event.target.value);

    if (filterType === "dateIn") {
      this.filterDateIn = date.toLocaleDateString();
    }

    if (filterType === "dateDue") {
      this.filterDateDue = date.toLocaleDateString();
    }

    this.onFilterChange();
  }

  /**
   * Apply filter of jobs.
   */
  onFilterChange() {
    this.paginator.pageIndex = 0;
    this.pageNo = 0;
    const filterObject = this.getFilterObject();
    this.store.dispatch(new LoadJobs(filterObject));
  }

  getFilterObject() {
    let selectedStatus = 2;

    // get selected job status filter.
    const status = this.statusList.find((f) => f.isSelect === true);
    if (status) {
      selectedStatus = status.value;
    }

    var userFilter = this.userService.getUser().filter;
    const filterObject = {
      status: selectedStatus,
      dateIn: this.filterDateIn,
      dateDue: this.filterDateDue,
      csrNames:
        this.csrList && this.csrList.length > 0
          ? this.csrList
              .filter((csr) => csr.isSelect === true)
              .map((csr) => {
                return csr.name;
              })
          : null,
      customerNames:
        this.csrList && this.customerList.length > 0
          ? this.customerList
              .filter((customer) => customer.isSelect === true)
              .map((customer) => {
                return customer.name;
              })
          : null,
      jobNumbers:
        this.jobNoList && this.jobNoList.length > 0
          ? this.jobNoList
              .filter((jobNo) => jobNo.isSelect === true)
              .map((jobNo) => {
                return jobNo.no;
              })
          : null,
      isExpand: this.isExpand,
      currentDepartmentId: this.currentDepartmentId,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      sortByJobNumber:
        userFilter && userFilter[this.currentDepartmentId]
          ? userFilter[this.currentDepartmentId].sortByJobNumber
          : null,
      sortByCustomer:
        userFilter && userFilter[this.currentDepartmentId]
          ? userFilter[this.currentDepartmentId].sortByCustomer
          : null,
      sortByCsr:
        userFilter && userFilter[this.currentDepartmentId]
          ? userFilter[this.currentDepartmentId].sortByCsr
          : null,
      sortByDueDate:
        userFilter && userFilter[this.currentDepartmentId]
          ? userFilter[this.currentDepartmentId].sortByDueDate
          : null,
    };

    userFilter[this.currentDepartmentId] = filterObject;
    //save user filter.
    this.userService.setUserFilters(filterObject, this.currentDepartmentId);

    return filterObject;
  }

  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    const filterObject = this.getFilterObject();
    this.store.dispatch(new LoadJobs(filterObject));
  }

  async toggleFullScreenView() {
    if (!this.isFullScreenView) {
      const elem = this.jobView;
      if (elem.fullscreenEnabled) await elem.requestFullscreen("auto");
      else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
      else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
      else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
      this.store.dispatch(new FullScreenView(true));
      this.isFullScreenView = true;
    } else {
      if (this.document.exitFullScreen) this.document.exitFullScreen();
      else if (this.document.webkitExitFullscreen)
        this.document.webkitExitFullscreen();
      else if (this.document.msExitFullscreen) this.document.msExitFullscreen();
      else if (this.document.mozCancelFullScreen)
        this.document.mozCancelFullScreen();
      this.store.dispatch(new FullScreenView(false));
      this.isFullScreenView = false;
    }
  }

  moveMultipleJobs() {
    const filterObject = this.getFilterObject();
    filterObject["userDepartmentId"] =
      this.auth.getUser().currentDepartment.departmentId;

    const dialogRef = this.dialog.open(JobListMoveComponent, {
      width: "75vw",
      height: "665px",
      data: { request: filterObject },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new LoadJobs(filterObject));
      }
    });
  }

  onClickPrintMultipleRyobiJobs() {
    const filterObject = this.getFilterObject();
    filterObject["userDepartmentId"] =
      this.auth.getUser().currentDepartment.departmentId;

    const dialogRef = this.dialog.open(JobListRyobiPrintComponent, {
      width: "75vw",
      maxHeight: "auto",
      data: { request: filterObject },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new LoadJobs(filterObject));
      }
    });
  }

  retriveJobs() {
    const dialogRef = this.dialog.open(RetriveJobsComponent, {
      width: "75vw",
      height: "665px",
      data: { request: this.jobs },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new LoadJobs(this.jobs));
      }
    });
  }

  showFilterContainer() {
    this.showFilter = !this.showFilter;
    if (!this.csrList || !this.customerList || !this.jobNoList) {
      this.getAllFilterList(false);
    }
  }

  createShipment() {
    const dialogRef = this.dialog.open(ShipmentCreateComponent, {
      width: "95vw",
      maxWidth: "95vw",
      height: "90vh",
      maxHeight: "90vh",
      data: {
        shipmentForJob: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.loadShipments();
      }
    });
  }

  openTemplateLabelDialog() {
    const dialogRef = this.dialog.open(JobTemplateLabelDialogComponent, {
      width: "650px",
      height: "75vh",
      maxHeight: "75vh",
      autoFocus: false,
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openSwitchFilesUploadModal() {
    const filterObject = this.getFilterObject();
    filterObject["userDepartmentId"] =
      this.auth.getUser().currentDepartment.departmentId;

    const dialogRef = this.dialog.open(UploadToSwitchComponent, {
      width: "75vw",
      height: "665px",
      maxHeight: "665px",
      data: {
        jobItemIds: [],
        mode: EnumSwitchUploadFilesMode.SHOW_JOB_ITEM_SELECTION_MODE,
        getJobsRequestObject: filterObject,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  async onPrintRyobiJobsPdf(jobs) {
    if (!jobs.length) {
      return this.toast.show("Ryobi Department Jobs Not Found");
    }

    let printJobs = jobs.reduce((res, job) => {
      let itemIds = [];
      job.items.forEach((item) => {
        if (item.currentStepName.toLowerCase() == RYOBI_DEPARTMENT) {
          itemIds.push(item.jobItemId);
        }
      });
      res.push({ jobId: job.jobId, jobItemIds: itemIds });
      return res;
    }, []);

    const payload = {
      jobs: printJobs,
    };
    this.ryobiPrint.printRyobiJobs(payload);
  }

  isShowRyobiJobPdfIcon(): boolean {
    //If on Jobs tab and Department selected is Ryobi
    if (
      this.currentDepartmentId == 0 &&
      this.permission.isCurrentDepartment(RYOBI_DEPARTMENT)
    ) {
      return true;
    }

    //If on Dept Tabs and Department selected is Ryobi
    if (
      this.currentDepartmentName &&
      this.currentDepartmentName.toLowerCase() == RYOBI_DEPARTMENT &&
      (this.permission.isCurrentDepartment(RYOBI_DEPARTMENT) ||
        this.permission.isAdminOrCSR())
    ) {
      return true;
    }
    return false;
  }
}
