import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConfigurationState } from '../../../state/configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Department } from 'src/app/shared/interfaces/department';
import { LoadDepartments } from '../../../state/configuration.actions';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MatIconComponent } from '../mat-icon/mat-icon.component';

@Component({
  selector: 'app-navigation-menu-create',
  templateUrl: './navigation-menu-create.component.html',
  styleUrls: ['./navigation-menu-create.component.scss']
})
export class NavigationMenuCreateComponent implements OnInit {
  form: UntypedFormGroup;
  title = 'Add Navigation';
  loading = false;

  @Select(ConfigurationState.AllDepartments) departments$: Observable<Department[]>;
  icon: any;
  iconvalue: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private store: Store,
    private dialogRef: MatDialogRef<NavigationMenuCreateComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public navigation,
    private toast: ToastService,
    private dialog: MatDialog) { }

  ngOnInit() {

    this.store.dispatch(new LoadDepartments());
    this.form = this.initForm();
    this.form.controls.status.patchValue(true);
    this.iconvalue = "chrome_reader_mode";
    if (this.navigation) {
      this.title = 'Edit Navigation';
      this.form.patchValue(this.navigation);
      this.form.controls.icon.setValue(this.navigation.icon);
      this.iconvalue = this.navigation.icon == null ? 'chrome_reader_mode' : this.navigation.icon;
    }
  }

  initForm = (): UntypedFormGroup =>
    this.formBuilder.group({
      title: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      icon: [''],
      status: ['']
    })

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false

  onSubmitClick() {
    //validate add department form.
    if (this.form.invalid || this.loading) {
      return;
    }

    let navigation = this.form.value;
    navigation.icon = this.iconvalue;
    if (this.navigation) {
      this.updateNavigation(navigation);
    } else {
      this.saveNavigation(navigation);
    }
  }

  saveNavigation(navigation) {
    this.loading = true;
    this.apiService.request('CREATE_NAVIGATION', { data: navigation }).subscribe(res => {
      if (res['success']) {
        navigation.navigationId = res['result'];
        this.dialogRef.close(navigation);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false; });
  }

  updateNavigation(navigation) {
    this.loading = true;
    navigation.navigationId = this.navigation.navigationId;

    let apiRequest = {
      params: { NAVIGATION_ID: this.navigation.navigationId },
      data: navigation
    }
    this.apiService.request('EDIT_NAVIGATION', apiRequest).subscribe(res => {
      if (res['success']) {
        this.dialogRef.close(navigation);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false; });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  selectIcon() {

    const dialogRef = this.dialog.open(MatIconComponent, {
      width: "880px",
      height: "calc(100vh - 200px)",
      data: '',
    });

    dialogRef.afterClosed().subscribe(icon => {

      if (icon) {
        this.icon = icon;
        this.iconvalue = icon;
      }
    })
  }

}
