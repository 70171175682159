<div fxLayout="column" fxLayoutGap="30px" style="padding:0px 10px" >
    <div fxLayout="row">
        <strong class="header-text">{{title}} </strong>
    </div>

    <div style="overflow: auto;" fxFlex="380px">
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="mailingInfoForm" autocomplete="off"
            style="padding: 0 10px">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Folding </span>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="foldingLetter">Letter</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="half">Half</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="ra">R/A</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="spiral">Spiral</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Quantity</mat-label>
                    <input matInput placeholder="Quantity" formControlName="foldingQuantity"
                        pattern="[0-9]+(\.[0-9][0-9]?)?">
                    <mat-error *ngIf="hasError('foldingQuantity','pattern')">You must enter valid folding quantity.</mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Labelling </span>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="cheshire">Cheshire</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="piggyback">Piggyback</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="sa">S/A</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="xerox">Xerox</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="inkjet">Ink Jet</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="laser">Laser</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Quantity</mat-label>
                    <input matInput placeholder="Quantity" formControlName="labellingQuantity"
                        pattern="[0-9]+(\.[0-9][0-9]?)?">
                    <mat-error *ngIf="hasError('labellingQuantity','pattern')">You must enter valid labeling quantity.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Labels Per Sheet </span>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="oneUp">1UP</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="twoUp">2UP</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="threeUp">3UP</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="fourUp">4UP</mat-checkbox>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="fiveUp">5UP</mat-checkbox>
                </div>
            </div>
            <mat-divider></mat-divider>


            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Tabbing </span>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="tabbingMachine">Machine</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="tabbingHand">Hand</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="special">Special</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="clear">Clear</mat-checkbox>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="white">White</mat-checkbox>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Inserting </span>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="insertingMachine">Machine</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="insertingHand">Hand</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label >Env Size</mat-label>
                    <input matInput placeholder="Env Size" formControlName="envSize"  pattern="[0-9]+(\.[0-9][0-9]?)?">
                    <mat-error *ngIf="hasError('envSize','pattern')">You must enter valid env size.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>No of Inserts</mat-label>
                    <input matInput placeholder="No of inserts" formControlName="noOfInsert"  pattern="[0-9]+(\.[0-9][0-9]?)?">
                    <mat-error *ngIf="hasError('noOfInsert','pattern')">You must enter valid no of insert.
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>


            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Mail Classification </span>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="first">1st</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="firstPS">1st P.S</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="periodicals">Periodicals</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="standart">Standart</mat-checkbox>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Size & Shape </span>
            </div>


            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="sizeLetter">Letter</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="postCards">Post Cards</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="flat">Flat</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="meter">Meter</mat-checkbox>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="stamps">Stamps</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="auto">Auto</mat-checkbox>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Surplus Materials</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" >
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="clientToPickUp">Client To Pick Up</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="dump">Dump</mat-checkbox>
                </div>
                <div fxFlex="25">
                    <mat-checkbox color="primary" formControlName="inventory">Inventory</mat-checkbox>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <span class="header-text">Scheduling Information</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <input matInput [matDatepicker]="dp" [min]="minDate" placeholder="1st Due"  formControlName="firstDueDate">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <input matInput [matDatepicker]="dp1" [min]="minDate" placeholder="Labelling Material Due"  formControlName="labellingMaterialDueDate">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <input matInput [matDatepicker]="dp2" [min]="minDate" placeholder="Insert Material Due"  formControlName="insertMaterialDueDate">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2></mat-datepicker>
                </mat-form-field>

            </div>

            <mat-divider></mat-divider>

        </form>
    </div>
    <mat-dialog-actions fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="end end">
        <button mat-raised-button (click)="onSubmit()">SAVE</button>
        <button mat-button mat-dialog-close>CANCEL</button>
    </mat-dialog-actions>
</div>