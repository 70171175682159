<div fxLayout="row" matRipple [matMenuTriggerFor]="userMenu" >
  <mat-icon class="user-icon">perm_identity</mat-icon>
  <div fxLayout="column" class="user-info">
    <span  class="secondary-text-color" matTooltip="{{userName| titlecase}}" >{{userName| titlecase}}</span>
    <small class="department secondary-text-color">{{user?.currentDepartment.departmentName | titlecase}}</small>
  </div>
  <mat-icon class="mat-icon">arrow_drop_down</mat-icon>
</div>
<!-- User departments -->
<mat-menu #departments="matMenu" >
    <button mat-menu-item  (click) ="onChangeDepartment(department)" *ngFor="let department of user?.departments;let index = index" class="primary-text-color grid-header-bg">
      <mat-icon color="primary" class="mat-icon" *ngIf="user?.currentDepartment.departmentId === department?.departmentId">check_circle</mat-icon>
      <mat-icon *ngIf="user?.currentDepartment.departmentId !== department?.departmentId" class="mat-icon"></mat-icon>
      <span class="secondary-text-color">{{department?.departmentName | titlecase}}</span>
    </button>
  </mat-menu>
  
<mat-menu #userMenu="matMenu" xPosition="before" >
  <button mat-menu-item (click)="OnProfileClick()" class="primary-text-color grid-header-bg">
    <mat-icon class="mat-icon">person</mat-icon>
    <span class="secondary-text-color">Profile</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="departments" class="primary-text-color grid-header-bg">
    <mat-icon class="mat-icon">store</mat-icon>
    <span class="secondary-text-color">Departments</span>
  </button>
  <button mat-menu-item class="primary-text-color grid-header-bg">
    <mat-icon class="mat-icon">settings</mat-icon>
    <span class="secondary-text-color">Settings</span>
  </button>
  <button routerLinkActive="active" mat-menu-item (click)="signOff()" class="primary-text-color grid-header-bg">
    <mat-icon class="mat-icon">logout</mat-icon>
    <span class="secondary-text-color">Sign Off</span>
  </button>
</mat-menu>



