import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobMailingSortCreateComponent } from '../job-mailing-sort-create/job-mailing-sort-create.component';

@Component({
  selector: 'app-job-mailing-sort',
  templateUrl: './job-mailing-sort.component.html',
  styleUrls: ['./job-mailing-sort.component.scss']
})
export class JobMailingSortComponent implements OnInit {

  mailingSort:any;

  constructor( @Inject(MAT_DIALOG_DATA) public stepAction: any,
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<JobMailingSortComponent>) { }

  ngOnInit() {
    this.mailingSort = this.stepAction.mailingSort;
  }

  onSubmit(){
    const dialogRef = this.dialog.open(JobMailingSortCreateComponent, {
      height: '550px',
      width: '1100px',
      data:this.stepAction['mailingSort'] 

    });

    dialogRef.afterClosed().subscribe(mailingSort => {
      if(mailingSort && mailingSort !== ''){
        this.stepAction['mailingSort']= mailingSort;
        this.mailingSort = mailingSort;
      }
    });
  }

  onClose(){
    this.dialogRef.close();
  }
}
