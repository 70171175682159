<div fxLayout="column" fxLayoutAlign="center center" class="padding-15">
  <strong
    class="header-text warn"
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <span>Delete Selected Job</span>
  </strong>
  <mat-dialog-content>
    <br />
    <div fxLayout="column" fxLayoutAlign="start start">
      <input
        placeholder="Enter Job No"
        [(ngModel)]="jobNumber"
        class="grid-header-bg primary-text-color"
      />
      <small class="warn" *ngIf="isInValid"
        >Please enter valid job number.</small
      >
    </div>
    <br />
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="warn" class="deleteBtn" (click)="delete()">
      DELETE
    </button>
    <button
      mat-button
      mat-dialog-close
      class="primary-bg-color primary-text-color"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
</div>
