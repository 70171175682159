import { Selector, State, Action, StateContext } from '@ngxs/store';
import { LoadNotification, ReceiveNotification} from './topnav.action';
import { notification } from '../../../../shared/interfaces/notification';
import { ApiService } from 'src/app/shared/services/api.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';

export interface NotificationModel {
 notifications: notification[];
 total:number
}

@State<NotificationModel>({
    name: 'notification',
    defaults: {
       notifications: [],
       total:0,
    }
})

@Injectable()
export class TopnavState {
    constructor(public api: ApiService) {}

    @Selector()
    static AllNotification(state: NotificationModel) {
        return state;
    }

    // get all notificatins.
    @Action(LoadNotification)
    LoadNotification(ctx: StateContext<NotificationModel>) {
        this.api.request(
                'GET_NOTIFICATIONS',
                {
                    params: {
                      PAGE_NO:0,
                      PAGE_SIZE:10
                    }
                }).subscribe((res: any) => {
                    if (res.result.length) {
                        const notifications = res.result.map(notify => {
                            const result: notification = {
                                id: notify.notificationId,
                                message: notify.message,
                                isRead: notify.isRead,
                                jobNumber: notify.jobNumber,
                                jobItemNo: notify.jobItemNo,
                                type: notify.type,
                                createdAt: notify.createdAt
                            };

                            return result;
                        });
                        ctx.setState({ notifications: notifications , total:res.total });
                    } else {
                        ctx.setState({ notifications: [] ,total:0 });
                    }
                });
    }

    @Action(ReceiveNotification)
    ReciveNotification(ctx: StateContext<NotificationModel>, action: ReceiveNotification) {
        const state = ctx.getState();
        if (!state.notifications.find(n => n.id === action.notification.id)) {
            ctx.patchState({
                ...state,
                notifications: [action.notification, ...state.notifications],
            });

            ctx.patchState({
                total: state.total + 1,
            });
        }
    }
}
