<div
  *ngIf="!isWorkflowView"
  fxLayout="column"
  fxLayoutGap="8px"
  class="padding-15"
>
  <div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="auto">
      <div
        class="job-info"
        fxLayout="row"
        fxFlex="auto"
        fxLayoutAlign="space-between none"
      >
        <div fxLayout="column" fxFlex="80" fxFlexOffset="2" fxFlexAlign="start">
          <div fxLayout="row">
            <div fxFlex style="font-size: 1.4rem" class="primary-text-color">
              {{ job.jobNumber }} - {{ job.title }}
            </div>
          </div>

          <div fxLayoutAlign="start center">
            <i class="material-icons mat-icon">person</i>
            <span *ngIf="isLoad" class="primary-text-color">
              {{ job.customerName }}</span
            >
            <span fxFlex="10px"></span>

            <div fxLayoutGap="5px" fxLayoutAlign="start center">
              <mat-icon>date_range</mat-icon>
              <small class="primary-text-color">Due on </small>
              <span
                [class.warn]="job.dateDue < today"
                *ngIf="job.dateDue && job.dateDue != '0001-01-01T00:00:00'"
              >
                {{ job.dateDue | date : "mediumDate" }}</span
              >
            </div>
          </div>
          <br />

          <div *ngIf="isLoad" fxLayout="row wrap" fxLayoutGap="6px">
            <span fxFlex="45" class="primary-text-color"
              >CSR:
              <span *ngIf="isLoad" class="primary-text-color"
                >{{ job.csrName }} ({{ job.csrNo }})</span
              >
            </span>
            <span class="primary-text-color">
              Date In:
              <strong> {{ job.dateIn | date : "mediumDate" }}</strong>
            </span>
          </div>
        </div>

        <div fxFlex fxLayoutAlign="end start">
          <mat-icon class="cursor-pointer" (click)="dialogRef.close()"
            >close</mat-icon
          >
        </div>
      </div>

      <mat-divider></mat-divider>
    </div>

    <div>
      <div fxLayout="row" fxLayoutAlign="end">
        <button
          *ngIf="job.items.length > 0"
          (click)="isWorkflowView = !isWorkflowView"
          color="primary"
          mat-stroked-button
        >
          <mat-icon>transform</mat-icon>
          Create Steps
        </button>
      </div>
      <br />
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let item of job?.items"
          class="secondary-bg-color"
        >
          <mat-expansion-panel-header class="secondary-bg-color">
            <mat-panel-title
              fxLayout="row"
              fxFillFlex
              fxLayoutAlign="space-between center"
            >
              <span fxFlex class="primary-text-color">{{
                item.description
              }}</span>
              <span fxFlex class="primary-text-color">
                Qty: {{ item.chargeQty }}</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <mat-list fxFlex>
              <mat-list-item class="secondary-bg-color">
                <div fxLayout="row" fxFlex fxLayoutGap="5px">
                  <strong fxFlex
                    ><small class="primary-text-color">Type</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Paper</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Ink</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Fold</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Side</small></strong
                  >
                  <strong fxFlex
                    ><small class="primary-text-color">Size</small></strong
                  >
                </div>
              </mat-list-item>
              <mat-list-item class="primary-bg-color">
                <div fxLayout="row" fxFlex fxLayoutGap="5px">
                  <small fxFlex class="primary-text-color">{{
                    item["type"]
                  }}</small>
                  <small fxFlex class="primary-text-color">{{
                    item["paper"]
                  }}</small>
                  <small fxFlex class="primary-text-color">{{
                    item["ink"]
                  }}</small>
                  <small fxFlex class="primary-text-color">{{
                    item["fold"]
                  }}</small>
                  <small fxFlex class="primary-text-color">{{
                    item["side"]
                  }}</small>
                  <small fxFlex class="primary-text-color"
                    >{{ item["height"] }} * {{ item["width"] }}</small
                  >
                </div>
              </mat-list-item>
            </mat-list>
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div
      fxLayout="row"
      fxFlex="auto"
      fxFlexOffset="1"
      fxLayoutGap="15px"
      fxLayoutAlign="end center"
    >
      <small *ngIf="!isValidate" class="warn">
        You must set steps for job items.</small
      >
      <button
        [disabled]="isLoading"
        fxFlex="100px"
        mat-raised-button
        color="primary"
        (click)="onImportJob()"
      >
        IMPORT
      </button>
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        CLOSE
      </button>
    </div>
  </div>
</div>

<app-job-import-workflow
  *ngIf="isWorkflowView"
  [job]="job"
  (close)="onCloseWorkflow($event)"
></app-job-import-workflow>
