import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthUserService } from '../../services/auth-user.service';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { LoadActiveUser } from 'src/app/features/account/state/account.actions';
import { Department, User } from '../../interfaces/user';
import { LoadJobs } from 'src/app/features/jobs/state/job.actions';
import { ChangeUserDepartment } from 'src/app/core/layout/topnav/state/topnav.action';
import { ThemeService } from 'src/app/theme.service';


@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  user: User ;
  userName: string;
  selectedDepartment:Department;

  constructor(private router: Router,
    private userService: AuthUserService,
    private actions$: Actions,
    private store: Store,
    private activatedRoute:ActivatedRoute,
  private themeService: ThemeService) { }

  ngOnInit() {
    this.getLoginUser();
    this.actions$
    .pipe(ofActionSuccessful(LoadActiveUser))
    .subscribe(data => {this.getLoginUser(); });
  }

  getLoginUser() {
    this.user = this.userService.getUser();
    if (this.user) {
      this.userName = this.user.firstName + ' ' + this.user.lastName;
        this.selectedDepartment = this.user.currentDepartment;
    } else {
      this.signOff();
    }
  }

  signOff() {
    this.userService.clear();
    this.themeService.setDarkTheme(false);
    localStorage.setItem('IsThemeDark','false');
    this.router.navigate([`account/login`]);
  }
  
  OnProfileClick(){
    this.router.navigate([`profile`]);
  }

 /**
 * on change user current department.
 * @param department
 */
 onChangeDepartment(department: Department){
  let currentDepartmentId = 0;
  this.activatedRoute.queryParams.subscribe(params => {
    currentDepartmentId = params['id'] ? params['id'] : 0;
  });

  this.user = this.userService.getUser();
  this.user.currentDepartment = department;
  this.userService.saveUser(this.user);
  this.store.dispatch(new LoadJobs(this.user.filter[currentDepartmentId]));
  this.store.dispatch(new ChangeUserDepartment())
  }
}
