
<div fxLayout="column" fxLayoutGap="15px" class="increase">
  <div fxLayout="row">
    <span fxFlex class="primary-text-color" style="font-size:18px">
      Create Template
    </span>
    <!-- <mat-icon (click)="dialogRef.close()">close</mat-icon> -->
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div fxLayout="row">
      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" autofocus="true" placeholder="Name" formControlName="name" required />
        <mat-error *ngIf="hasError('name','required')">You must enter template name.</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="3%">
      <button mat-raised-button fxFlex="100px" color="primary" (click)="onSubmit()">SUBMIT</button>
      <button mat-flat-button type="button" (click)="dialogRef.close()">CLOSE</button>
    </div>
  </form>
</div>
