<div fxLayout="row">
  <app-breadcrumb title="Profile" [subTitles]="subTitles"></app-breadcrumb>
  <span fxFlex></span>
</div>

<br>

<div fxLayout="row"  class="user-profile">

  <!-- Profile picture and change password -->
  <div fxLayout="column" fxFlex="300px" class="avatar mat-elevation-z1">
    <div fxLayoutAlign="center center" fxFlexOffset="10">
      <img [src]="profilePictureUrl">
      <mat-icon color="primary" matTooltip="Change profile picture" (click)="fileInput.click()">camera_alt</mat-icon>
      <input type="file" #fileInput accept=".png, .jpg, .jpeg" (change)="onSelectFile($event)" />
    </div>

    <div class="user-name" fxLayoutAlign="center center" >
      <h3 class="primary-text-color">{{user.firstName | titlecase}} {{user.lastName| titlecase}} </h3>
     </div>

    <mat-divider></mat-divider>

    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" >
      <button mat-raised-button color="primary" (click)="changePassword()" class="primary-button whiteColor">CHANGE PASSWORD</button>
    </div>
  </div>

  <!-- Update the detail of user -->
  <div fxFlex="100" fxLayoutAlign="center center">
    <form [formGroup]="userForm" autocomplete="off" fxFlex="75">

      <div fxLayout="row" fxLayoutGap="2%" class="padding-bottom-10">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label class="mat-label">First Name</mat-label>
          <input matInput type="text" autofocus="true" placeholder="First Name" formControlName="firstName" required  class="primary-text-color"/>
          <mat-error *ngIf="hasError('firstName','required')">You must enter first name.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label class="mat-label">Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="lastName" required class="primary-text-color"/>
          <mat-error *ngIf="hasError('lastName','required')">You must enter last name.</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="2%" class="padding-bottom-10">
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label class="mat-label">Email</mat-label>
          <input matInput type="email" placeholder="Email" formControlName="email" readonly class="primary-text-color"/>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label class="mat-label">Phone</mat-label>
          <input matInput type="text" placeholder="Phone" minlength="10" maxlength="10" pattern="^[0-9]*$"
            formControlName="phone" required class="primary-text-color"/>
          <mat-error *ngIf="hasError('phone','required')">You must enter phone number.</mat-error>
          <mat-error *ngIf="hasError('phone','pattern')">You must enter valid phone number.</mat-error>
          <mat-error *ngIf=" !hasError('phone','pattern') && hasError('phone','minlength')">You must enter valid phone
            number.</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="4px" class="department">
        <strong class="primary-text-color">Department</strong>
        <mat-chip-listbox>
          <mat-chip-option color="primary" selected *ngFor="let department of user?.departments">
            {{department.departmentName | titlecase}}</mat-chip-option>
        </mat-chip-listbox>
      </div>

      <br>

      <div fxLayout="row" fxLayoutAlign="center">
        <button fxFlex="100px" mat-raised-button color="primary" type="submit" (click)="submit()" class="primary-button whiteColor">SAVE</button>
      </div>

    </form>
  </div>
</div>