<div [class.disable]="isLoading" fxLayout="column" class="padding-15" style="height: 100%;">
  <div fxLayout="row" fxFlex="9">
    <!-- <mat-icon class="pointer" (click)="close()">arrow_back</mat-icon> -->
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Move Jobs To Next Department
    </span>
    <div class="search">
      <input
        #searchInput
        matInput
        type="text"
        placeholder="Search"
        [(ngModel)]="searchKey"
      />
      <mat-icon
        class="pointer"
        matSuffix
        *ngIf="searchKey"
        (click)="clearSearch()"
        >close</mat-icon
      >
    </div>
  </div>

  <div fxLayout="column" fxFlex="89" fxLayoutGap="10px">
    <div style="height: 66vh; overflow: auto;">
      <div *ngIf="filterJobs.length === 0 || jobLoading" fxLayoutAlign="center">
        <h4 class="primary-text-color">
          {{ jobLoading ? "Loading..." : "No Jobs Available!" }}
        </h4>
      </div>
      <div class="grid" fxLayout="column" *ngIf="filterJobs.length > 0">
        <ng-container *ngFor="let job of filterJobs">
          <div class="grid-row" fxFlex fxLayoutGap="10px">
            <!-- <mat-checkbox [(ngModel)]="job['isSelect']" color="primary"></mat-checkbox> -->
            <span class="primary-text-color"
              >{{ job.jobNumber }} - {{ job.customerName }}</span
            >
          </div>
          <div
            class="grid-item-row"
            fxFlex
            *ngFor="let item of job['items']"
            fxLayout="row"
          >
            <div
              fxLayoutAlign="start center"
              fxLayout="row"
              fxLayoutGap="10px"
              fxFlex="70"
            >
              <mat-checkbox (change)="onChangeCheckbox($event, item)"
                [disabled]="item['isDisabled']"
                [(ngModel)]="item['isSelect']"
                color="primary"
              ></mat-checkbox>
              <span class="primary-text-color">{{ item.description }}</span>
            </div>
            <div
              fxLayoutAlign="end center"
              fxLayout="row"
              fxLayoutGap="10px"
              fxFlex="30"
            >
              <span class="primary-text-color"
                >{{ item.currentStepName | titlecase }} /
                {{ item.currentActionName | titlecase }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div fxLayoutGap="10px" *ngIf="this.filterJobs.length != 0">
      <div fxLayoutGap="10px" *ngIf="isShowConfirmation && !isLoading && isJobItemSelected">
        <small class="warn">
          Do you want to move jobs to next department?</small
        >
        <button mat-stroked-button color="primary" (click)="confirmMoveJobs()">
          YES
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="isShowConfirmation = false"
        >
          NO
        </button>
      </div>
      <span fxFlex></span>
      <div fxLayoutGap="10px" *ngIf="!isJobItemSelected && !isShowConfirmation && !isLoading">
        <small class="warn">
          Select atleast one jobitem to move jobs to next department.</small
        >
        </div>
      <button mat-raised-button color="primary" [disabled]="!isJobItemSelected" (click)="moveJobs()">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <span> <strong>MOVE JOBS </strong></span>
          <span *ngIf="isLoading" class="loader"></span>
        </div>
      </button>
      <button mat-raised-button type="button" (click)="close()">CLOSE</button>
    </div>
  </div>
</div>
