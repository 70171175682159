import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { JobImportComponent } from "src/app/features/jobs/job-import/job-import.component";
import { Job } from "src/app/shared/interfaces/job";
import { NotificationHubService } from "src/app/shared/services/notification-hub.service";
import {
  Select,
  Store,
  Actions,
  ofActionSuccessful,
  ofActionCompleted,
} from "@ngxs/store";
import { TopnavState } from "./state/topnav.state";
import { notification } from "../../../shared/interfaces/notification";
import {
  LoadNotification,
  ChangeUserDepartment,
  FullScreenView,
} from "./state/topnav.action";
import { ApiService } from "src/app/shared/services/api.service";
import {
  LoadSteps,
  LoadNavigations,
} from "src/app/features/configuration/state/configuration.actions";
import { SelectDatasourceComponent } from "src/app/features/configuration/configurations/data-source/select-datasource/select-datasource.component";
import { AuthUserService } from "src/app/shared/services/auth-user.service";
import { LoadActiveUser } from "src/app/features/account/state/account.actions";
import { JobDetailsComponent } from "src/app/features/jobs/job-details/job-details.component";
import { JobState } from "src/app/features/jobs/state/job.state";
import { ConfigurationState } from "src/app/features/configuration/state/configuration.state";
import { User } from "src/app/shared/interfaces/user";
import { PermissionService } from "src/app/shared/services/permission.service";
import { OrderHubService } from "src/app/shared/services/orders-hub-service";
import { Observable, Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ThemeService } from "src/app/theme.service";
import { MatIconRegistry } from "@angular/material/icon";

interface MenuItem {
  title: string;
  icon: string;
  route: string;
  id: number;
  isOrderTab?: boolean;
  isCustomIcon: boolean;
}

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TopnavComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[];
  appMenuItems: MenuItem[];

  // @Select(ConfigurationState.AllNavigations) navigations$: Observable<Navigation[]>;

  selectedMenuItem;
  stickyHeader = false;
  notifications: Array<notification>;
  user: User;
  isThemeDark: Observable<boolean>;
  notificationCount = 0;
  selectedDataSource = "DATA-SOURCE";
  isFullScreenView = false;
  unseenOrderCount = 0;
  orderHubUnseenCountSubscription : Subscription = null;
  allNotificationSubscription : Subscription = null;
  changeDepartmentSubscription: Subscription = null;
  src ;
  IsLight = true;
  IsDark = false;
  svgIcon: string;
  InvoiceSrc: string;
  isAdmin: boolean;

  constructor(private router: Router,
    private dialog: MatDialog,
    private notificationHub: NotificationHubService,
    private orderHub: OrderHubService,
    private store: Store,
    private api: ApiService,
    private auth: AuthUserService,
    private actions$: Actions,
    private permission: PermissionService,
    private sanitizer: DomSanitizer,
    private themeService: ThemeService,
    private matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.addSvgIcon(
      "Collections",
      sanitizer.bypassSecurityTrustResourceUrl("assets/images/Collections.svg")
    );
  }

  ngOnInit() {
    this.auth.showJobFullScreen("false");
    this.getDefaultMenu();
    this.notificationHub.startConnection(); // connect to notification hub.
    this.orderHub.startConnection(); //connect to order hub.
    this.getUnseenOrderCount();
    window.addEventListener("scroll", this.scroll, true); // third parameter.
    this.showMenuItems(); // show all user added menus.
    this.store.dispatch(new LoadNotification());
    this.store.dispatch(new LoadSteps());
    this.store.dispatch(new LoadNavigations());
    this.getNotfication();

    this.user = this.auth.getUser();
    this.selectedDataSource =
      this.user && this.user.dataSourceName
        ? this.user.dataSourceName
        : this.selectedDataSource;

    this.actions$.pipe(ofActionSuccessful(LoadActiveUser)).subscribe((data) => {
      this.selectedDataSource = this.auth.getUser()
        ? this.auth.getUser().dataSourceName
        : "";
    });

    this.changeDepartmentSubscription = this.actions$
      .pipe(ofActionSuccessful(ChangeUserDepartment))
      .subscribe((data) => {
        this.user = this.auth.getUser();
        this.updateIsAdmin();

        let currentAppMenuTabIndex = this.getTheSelectedAppMenuTabIndex();
        if (
          currentAppMenuTabIndex > -1 &&
          !this.canAccess(this.appMenuItems[currentAppMenuTabIndex])
        ) {
          this.router.navigate([`jobs`]);
        }
      });

    this.actions$.pipe(ofActionCompleted(LoadNavigations)).subscribe(data => {
      this.showMenuItems();
    });

    this.actions$.pipe(ofActionCompleted(FullScreenView)).subscribe((res) => {
      this.isFullScreenView = res.action.isFullScreenView;
    });

    this.themeService.setDarkTheme(false);
    this.src = this.themeService.src;
    this.IsLight  = true;
    this.IsDark  = false;

    var IsDarkTheme = localStorage.getItem('IsThemeDark');
    (IsDarkTheme == "true") ?  this.DarkModeOn() : this.LightModeOn();
    this.updateIsAdmin();
  }

  getUnseenOrderCount() {
    this.orderHubUnseenCountSubscription =
      this.orderHub.unseenOrderCountUpdated.subscribe((data) => {
        this.unseenOrderCount = data;
      });
  }

  getNotfication() {
    this.allNotificationSubscription = this.store
      .select(TopnavState.AllNotification)
      .subscribe((n) => {
        this.notifications = [];
        this.notificationCount = 0;
        if (n && n.notifications.length > 0) {
          this.notifications = n.notifications;
          this.notificationCount = n.total;
        }
      });
  }

  isLinkActive(menu: MenuItem): boolean {
    const url = menu.id !== null ? menu.route + "?id=" + menu.id : menu.route;
    this.store.dispatch(new FullScreenView(this.isFullScreenView));
    return url === this.router.url;
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
    if (this.orderHubUnseenCountSubscription) {
      this.orderHubUnseenCountSubscription.unsubscribe();
    }

    if (this.allNotificationSubscription) {
      this.allNotificationSubscription.unsubscribe();
    }

    if (this.changeDepartmentSubscription)
      this.changeDepartmentSubscription.unsubscribe();
  }

  scroll = (event: any): void => {
    if (event.srcElement.scrollingElement) {
      const number = event.srcElement.scrollingElement.scrollTop;
      this.stickyHeader = number >= 64;
    }
  };

  onSelectMenu(menuItem: MenuItem): void {
    this.selectedMenuItem = menuItem;
    this.router.navigate([menuItem.route]);
  }

  selectDatasource = () => {
    this.dialog.open(SelectDatasourceComponent, {
      width: "500px",
    });
  };

  onJobSelected(job: Job): void {
    this.dialog.open(JobImportComponent, {
      width: '95vw',
      maxWidth : '95vw',
      data: job,
    });
  }

  readNotification = (notification) => {
    if (!notification) return;

    this.api
      .request("READ_NOTIFICATION", {
        params: { NOTIFICATION_ID: notification.id },
      })
      .subscribe((res) => {
        this.store.dispatch(new LoadNotification());
        const jobDetail = this.store
          .selectSnapshot(JobState.AllJobs)
          .find((j) => j.jobNumber == notification.jobNumber);
        if (jobDetail) {
          const input = {
            job: jobDetail,
            selectdoJobItem: notification.jobItemNo - 1,
          };

          this.dialog.open(JobDetailsComponent, {
            width: "98vw",
            maxWidth: "98vw",
            height: "95vh",
            maxHeight: "95vh",
            data: input,
          });
        }
      });
  };

  readAllNotification = () => {
    if (this.notificationCount !== 0) {
      this.api.request("READ_ALL_NOTIFICATIONS").subscribe((res) => {
        this.store.dispatch(new LoadNotification());
      });
    }
  };

  // get user added jobs department menus which loaded jobs basis of department.
  showMenuItems() {
    this.store
      .select(ConfigurationState.AllNavigations)
      .subscribe((navigations) => {
        if (!this.isFullScreenView) {
          this.getDefaultMenu();
        }
        let menus = JSON.parse(JSON.stringify(navigations));
        menus = menus.sort((a, b) => a.navigationId - b.navigationId);
        menus.forEach((m) => {
          const menu: MenuItem = {
            title: m.title,
            icon: m.icon == null ? "chrome_reader_mode" : m.icon,
            route: "/jobs",
            id: m.departmentId,
            isCustomIcon: false,
          };
          this.menuItems.splice(this.menuItems.length - 2, 0, menu);
        });
      });
  }

  getDefaultMenu() {
    this.menuItems = [
      {
        title: "JOBS",
        icon: "chrome_reader_mode",
        route: "/jobs",
        id: null,
        isOrderTab: false,
        isCustomIcon: false,
      },
      {
        title: "ORDERS",
        icon: "local_shipping",
        route: "/orders",
        id: null,
        isOrderTab: true,
        isCustomIcon: false,
      },
      {
        title: "COLLECTIONS",
        icon: "Collections",
        route: "/collections",
        id: null,
        isOrderTab: false,
        isCustomIcon: true,
      },
      {
        title: "CUSTOMERS",
        icon: "people",
        route: "/customers",
        id: null,
        isOrderTab: false,
        isCustomIcon: false,
      },
      {
        title: "SHIPMENTS",
        icon: "local_shipping",
        route: "/shipments",
        id: null,
        isOrderTab: false,
        isCustomIcon: false,
      },
    ];

    this.appMenuItems = [
      {
        title: "DASHBOARD",
        icon: "dashboard",
        route: "/dashboard",
        id: null,
        isOrderTab: false,
        isCustomIcon: false,
      },
      {
        title: "CONFIGURATION",
        icon: "settings",
        route: "/configuration",
        id: null,
        isOrderTab: false,
        isCustomIcon: false,
      },
    ];
  }

  canAccess(menuItem) {
    return this.permission.canAccessNavigationMenu(menuItem);
  }

  updateIsAdmin(): void {
    this.isAdmin = this.permission.isCurrentDepartment("Administration");
  }

  LightModeOn(){
    localStorage.setItem('IsThemeDark' , 'false');
    this.themeService.setDarkTheme(false);
    this.src = this.themeService.src;
    this.IsLight  = true;
    this.IsDark  = false;

  }

  DarkModeOn() {
    localStorage.setItem("IsThemeDark", "true");
    this.themeService.setDarkTheme(true);
    this.src = this.themeService.src;
    this.IsDark = true;
    this.IsLight = false;
  }

  getTheSelectedTabIndex() {
    if (this.menuItems && this.menuItems.length > 0 && this.router.url) {
      return this.menuItems.map(menu => menu.id !== null ? menu.route + '?id=' + menu.id : menu.route).findIndex((r) => r === this.router.url);
    }
    return -1;
  }

  getTheSelectedAppMenuTabIndex() {
    if (this.appMenuItems && this.appMenuItems.length > 0 && this.router.url) {
      return this.appMenuItems
        .map((menu) => menu.route)
        .findIndex((r) => r === this.router.url);
    }
    return -1;
  }
}
