import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-svg-preview',
  templateUrl: './svg-preview.component.html',
  styleUrls: ['./svg-preview.component.scss']
})
export class SvgPreviewComponent implements OnInit {

  @Input('svgImageResourceURL') public SvgImageResourceURL : string;
  isFilePreviewLoading: boolean;
  fileData: any;
  constructor(private toast: ToastService,
    public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.previewSVG();
  }


  previewSVG(){
    var request = new XMLHttpRequest();
          request.open('GET',this.SvgImageResourceURL , true);
          request.responseType = 'blob';
          request.onload = () => {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onloadend = (e: any) => {
              this.isFilePreviewLoading = false;
              var safeString = e.target.result as string;
              var splitted = safeString.toString().split(';');
              this.fileData = this.sanitizer.bypassSecurityTrustUrl("data:image/svg+xml;"+splitted[1]);
            };
          };
          request.onerror = (e)=>{
            this.isFilePreviewLoading = false;
            this.toast.show('Preview is unavailable.');
          }
          request.send();
          this.isFilePreviewLoading = true;
  }

}
