import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from './theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Pacific Printing';
  themeSubscription: Subscription = null;

  constructor(public overlayContainer: OverlayContainer, private theme: ThemeService) { }
  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.themeSubscription = this.theme.isThemeDark.subscribe(isThemeDark => {
      if (isThemeDark) {
        this.onSetTheme('app-theme-dark');
      } else {
        this.onSetTheme('app-theme-light');
      }
    })
  }

  @HostBinding('class') componentCssClass;

  onSetTheme(theme: 'app-theme-dark' | 'app-theme-light') {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;

    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses)
      .filter((item: string) => item.includes('app-theme-'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
  }
}
