import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationViewComponent } from './configuration-view/configuration-view.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DepartmentsComponent } from './configurations/departments/departments.component';
import { UsersComponent } from './configurations/users/users.component';
import { ActionStepsComponent } from './configurations/action-steps/action-steps.component';
import { UserCreateComponent } from './configurations/users/user-create/user-create.component';
import { DepartmentCreateComponent } from './configurations/departments/department-create/department-create.component';
import { ActionStepsCreateComponent } from './configurations/action-steps/action-steps-create/action-steps-create.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DataSourceComponent } from './configurations/data-source/data-source.component';
import { DataSourceCreateComponent } from './configurations/data-source/data-source-create/data-source-create.component';
import { ChangePasswordComponent } from './configurations/users/change-password/change-password.component';
import { UserProfileComponent } from './configurations/users/user-profile/user-profile.component';
import { SelectDatasourceComponent } from './configurations/data-source/select-datasource/select-datasource.component';
import { NavigationMenusComponent } from './configurations/navigation-menus/navigation-menus.component';
import { NgxsModule } from '@ngxs/store';
import { ConfigurationState } from './state/configuration.state';
import { NavigationMenuCreateComponent } from './configurations/navigation-menus/navigation-menu-create/navigation-menu-create.component';
import { CompaniesComponent } from './configurations/companies/companies.component';
import { CompanyCreateComponent } from './configurations/companies/company-create/company-create.component';
import { MatIconComponent } from './configurations/navigation-menus/mat-icon/mat-icon.component';



@NgModule({
    declarations: [
        ConfigurationViewComponent,
        DepartmentsComponent,
        UsersComponent,
        ActionStepsComponent,
        UserCreateComponent,
        DepartmentCreateComponent,
        ActionStepsCreateComponent,
        DataSourceComponent,
        DataSourceCreateComponent,
        ChangePasswordComponent,
        UserProfileComponent,
        SelectDatasourceComponent,
        NavigationMenusComponent,
        NavigationMenuCreateComponent,
        CompaniesComponent,
        CompanyCreateComponent,
        MatIconComponent,
    ],
    imports: [
        SharedModule,
        ConfigurationRoutingModule,
        NgxsModule.forFeature([ConfigurationState])
    ],
    exports: [
        SelectDatasourceComponent,
        MatIconComponent
    ]
})
export class ConfigurationModule { }
