import { State, Action, StateContext, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { ApiService } from 'src/app/shared/services/api.service';
import { Department, WorkflowStep } from 'src/app/shared/interfaces/department';
import { LoadDepartments, AddDepartment, LoadSteps, LoadNavigations, AddNavigation, UpdateNavigation, DeleteNavigation } from './configuration.actions';
import { Navigation } from 'src/app/shared/interfaces/navigation';
import { updateItem, patch } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';


export interface ConfigurationStateModel {
    departments: Department[];
    steps: [],//WorkflowStep[];
    workflowTemplates: any[];
    navigations:Navigation []
}

@State<ConfigurationStateModel>({
    name: 'configuration',
    defaults: {
        departments: [],
        steps: [],
        workflowTemplates: [],
        navigations:[]
    }
})

@Injectable()
export class ConfigurationState implements NgxsOnInit {

    constructor(private api: ApiService, private store:Store) { }

    ngxsOnInit(ctx: StateContext<ConfigurationStateModel>) {
    }

    @Selector()
    static AllDepartments(state: ConfigurationStateModel) {
        return state.departments;
    }

    @Selector()
    static AllNavigations(state: ConfigurationStateModel) {
         return state.navigations;
    }

    @Selector()
    static AllSteps(state: ConfigurationStateModel) {
        
        return state.steps;
    }

    
    @Selector()
    static Steps(state: ConfigurationStateModel) {
        let steps = state.steps.filter(stp=>!stp['isDelete']);
        steps.forEach((stp:any) => {
            stp['stepActions'].filter(act=>!act['isDelete'])
        });
        
        return steps;
    }

    /**
     * Get Departments
     * @param ctx
     * @param action
     */
    @Action(LoadDepartments)
    LoadDepartments(ctx: StateContext<ConfigurationStateModel>, action: LoadDepartments) {
        const params = {
            PAGE_NO: 0,
            PAGE_SIZE: 0
        }

        this.api.request('GET_DEPARTMENTS',{params:params}).subscribe(res => {
                const state = ctx.getState();
                ctx.patchState({
                    ...state,
                    departments: [...res['departments']]
                });
        })
    }

    /**
    * Get Jobs
    * @param ctx
    * @param action
    */
    @Action(AddDepartment)
    AddDepartment(ctx: StateContext<ConfigurationStateModel>, action: AddDepartment) {

        const state = ctx.getState();
        ctx.patchState({
            ...state,
            departments: [...state.departments, action.department]
        });
    }

    /**
     * Get Steps
     * @param ctx
     * @param action
     */
    @Action(LoadNavigations)
    LoadNavigations(ctx: StateContext<ConfigurationStateModel>, action: LoadSteps) {
        const params = {
            PAGE_NO: 0,
            PAGE_SIZE: 0
        }
       
        this.api.request('GET_NAVIGATIONS' , {params:params})
            .subscribe((res:any) => {
                const state = ctx.getState();
                ctx.patchState({
                    ...state,
                    navigations: res['navigations']
                });
            });
    }

    /**
    * Get Jobs
    * @param ctx
    * @param action
    */
   @Action(AddNavigation)
   AddNavigation(ctx: StateContext<ConfigurationStateModel>, action: AddNavigation) {

       const state = ctx.getState();
       ctx.patchState({
           ...state,
           navigations: [...state.navigations, action.navigation]
       });
   }

    @Action(UpdateNavigation)
    UpdateNavigation(ctx: StateContext<ConfigurationStateModel>, action: UpdateNavigation) {

        ctx.setState(
            patch({
                navigations: updateItem<Navigation>(j => j.navigationId === action.navigation.navigationId, action.navigation)
            }));

    }

    @Action(DeleteNavigation)
    DeleteNavigation(ctx:StateContext<ConfigurationStateModel>, action: DeleteNavigation){

        const state = ctx.getState();
        ctx.patchState({
            ...state,
            navigations:state.navigations.filter(n=>n.navigationId != action.navigationId)
        })
    }


    /**
     * Get Steps
     * @param ctx
     * @param action
     */
    @Action(LoadSteps)
    LoadSteps(ctx: StateContext<ConfigurationStateModel>, action: LoadSteps) {
        const params = {
            PAGE_NO: 0,
            PAGE_SIZE: 0
        }
        
        this.api.request('GET_STEPS' , {params:params})
            .subscribe((res:any) => {
                const state = ctx.getState();
                ctx.patchState({
                    ...state,
                    steps: res['steps']
                });
            });
    }
}
