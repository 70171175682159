import { Component, OnInit, Input } from '@angular/core';
import { Job } from 'src/app/shared/interfaces/job';
import { ApiService } from 'src/app/shared/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { JobDetailsComponent } from '../job-details/job-details.component';
import { Store, ofActionSuccessful, Actions } from '@ngxs/store';
import { ExpandAndCollapsJob, UpdateJob } from '../state/job.actions';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ConfigurationState } from '../../configuration/state/configuration.state';
import { Step } from 'src/app/shared/interfaces/jobItem';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { NotificationHubService } from 'src/app/shared/services/notification-hub.service';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {

  @Input() job: Job;
  @Input() showDetailsView:boolean;
  date = Date.now();
  now = new Date;
  today: string;
  loading= false;
  items;

  constructor(private api: ApiService,
    private dialog: MatDialog,
    private store: Store,
    private notificationHub:NotificationHubService) {
    }

  ngOnInit() {
    this.today = this.now.toISOString();
    this.items = this.job.items;
  }

  jobItemSelection($event, index) {
    $event.stopPropagation();
    const input = {
      job: this.job,
      selectdoJobItem: index
    };
    this.dialog.open(JobDetailsComponent, {
      width: '98vw',
      maxWidth: '98vw',
      height: '95vh',
      maxHeight: '95vh',
      data: input
    });
  }

  onLoadDetailsView() {
    this.showDetailsView = !this.showDetailsView;

    if (this.showDetailsView)
      this.loadJobDetails();
  }

  private loadJobDetails(): void {

    if (this.items && this.items.length > 0){
     // this.findDarptments();
      return;
    }
    
    const params = {
      params: {
        JOBNUMBER: this.job['jobNumber'],
        DEPARTMENT_ID:0
      }
    };
    this.api
      .request('GET_JOB_ITEMS', params)
      .subscribe(response => {
        this.items =response.items;
      });
  }

  markAsComplete($event) {
    $event.stopPropagation();

    if(!this.items.find(x=>x.status !=2)){return;}// check all job items is completed.

    const data = {
      jobItemIds: this.items.map( x => {return x.jobItemId})
    };
    this.loading = true;
    this.api.request('COMPLETE_CURRENT_STEP', { data: data })
      .subscribe(res => {  
        this.updateJobItem();
        this.sendNotification();
      }, error => this.loading = false);
  }

  updateJobItem() {
    const params = {
      params: {
        JOB_NUMBER: this.job['jobNumber'],
        DEPARTMENT_ID:0
      }
    };

    this.api.request('GET_JOB_ITEMS', params).subscribe(job => {
      switch (job.status) {
        case 1:
          job.status = 'in progress';
          break;
        case 2:
          job.status = 'complete';
          break;
        default:
          job.status = 'in progress';
          break;
      }
      this.loading = false;
      this.store.dispatch(new UpdateJob(job));
    },error => {this.loading = false;});
  }

  sendNotification() {
    this.items.forEach(item => {
      const notification = {
        JobNumber: this.job.jobNumber,
        JobItemNo: item.itemNo,
        jobItemName: item.description,
        steps: item.steps,
        currentStepIndex: item.currentStepIndex,
        changeStepIndex: item.currentStepIndex + 1
      };
      this.notificationHub.sendNotification(notification);
    });
  }
}
