<div fxLayout="row">
  <app-breadcrumb title="Companies" [subTitles]="subTitles"></app-breadcrumb>
  <span fxFlex></span>
  <div fxLayoutAlign="center center">
    <button
      mat-raised-button
      color="primary"
      (click)="create()"
      class="primary-button"
    >
      <mat-icon class="scaleOneDotFive">add</mat-icon>
      <span>CREATE COMPANY</span>
    </button>
  </div>
</div>

<div *ngIf="companies?.length === 0" fxLayoutAlign="center">
  <h3 class="primary-text-color">No Companies Available!</h3>
</div>
<br />
<div class="table-container" *ngIf="companies?.length > 0" style="height: Calc(100vh - 250px)" fxLayout="column">
  <div style="overflow:auto; height: calc(100vh - 310px);">
    <table mat-table [dataSource]="companies">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef class="table-header">No.</th>
        <td
          mat-cell
          *matCellDef="let companies; let index = index"
          class="table-row"
        >
          {{ pageNo * pageSize + index + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-header">Name</th>
        <td mat-cell *matCellDef="let companies" class="table-row">
          {{ companies["name"] }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="website">
        <th mat-header-cell *matHeaderCellDef class="table-header">Website</th>
        <td mat-cell *matCellDef="let companies" class="table-row">
          {{ companies["website"] }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="table-header">Email</th>
        <td mat-cell *matCellDef="let companies" class="table-row">
          {{ companies["email"] }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef class="table-header">Phone</th>
        <td mat-cell *matCellDef="let companies" class="table-row">
          {{ companies["phone"] }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="table-header"></th>
        <td mat-cell *matCellDef="let companies" class="table-row">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="edit(companies)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              mat-menu-item
              *ngIf="companies.printerPlanCusNo"
              (click)="sync(companies)"
            >
              <mat-icon>sync</mat-icon>
              <span>Sync</span>
            </button>
            <button mat-menu-item (click)="delete(companies.id)">
              <mat-icon>delete</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  
  <mat-paginator
    [length]="totalRecord"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 100]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
