import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { ReloadeJobs } from '../state/job.actions';
import { User } from 'src/app/shared/interfaces/user';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-job-grid',
  templateUrl: './job-grid.component.html',
  styleUrls: ['./job-grid.component.scss']
})
export class JobGridComponent implements OnInit , OnDestroy{

  columnsToDisplay = ['status','job', 'customer', 'csr', 'duedate'];
  @Input() jobs;
  @Input()isExpand;
  @Input()isFullScreenView;
  @Input()currentDepartmentId;
  @Input() currentDepartmentName;
  @Input() isJobsLoading;

  showDetail = false;
  now = new Date;
  today: string;
  loading= false;
  sortByJobNumber=null;
  sortByCustomer=null;
  sortByCsr=null;
  sortByDueDate=null;
  user:User;
  sortEnable = true;
  renderDefaultLoadingJobs = false;
  queryParamSubscription : Subscription = null;
  isLoadingJobsSubscription : Subscription = null;

  constructor(private store: Store,
    private userService:AuthUserService,
    private activatedRoute: ActivatedRoute,) {
     }

  ngOnDestroy(): void {
    if(this.queryParamSubscription){
      this.queryParamSubscription.unsubscribe();
    }
    if(this.isLoadingJobsSubscription){
      this.isLoadingJobsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
     this.today = this.now.toISOString();
     this.user = this.userService.getUser();
     
     this.sortByJobNumber = this.user.filter[this.currentDepartmentId]?this.user.filter[this.currentDepartmentId].sortByJobNumber: null;
     this.sortByCsr =this.user.filter[this.currentDepartmentId]?  this.user.filter[this.currentDepartmentId].sortByCsr : null;
     this.sortByCustomer = this.user.filter[this.currentDepartmentId]?this.user.filter[this.currentDepartmentId].sortByCustomer:null;
     this.sortByDueDate = this.user.filter[this.currentDepartmentId]?this.user.filter[this.currentDepartmentId].sortByDueDate : null;
  
     this.queryParamSubscription = this.activatedRoute.queryParams
      .subscribe(queryParams => {
        this.renderDefaultLoadingJobs = true;
      })

     this.isLoadingJobsSubscription = this.isJobsLoading.subscribe(value => {
      if(value){
        this.renderDefaultLoadingJobs = false;
      }
     })
    }

  onSortClick(value, type) {
   
    if (!this.sortEnable) { return; }
    
    this.sortEnable = false;
    var self = this;
    
    this.sortJobs(value, type);
    setTimeout(function () {
      self.sortEnable = true;
    }, 800);

  }

  sortJobs(value , type){
    switch(value){
      case 'asc':value='desc';
      break;
      case 'desc': value=null;
      break;
      default: value='asc'
    }

    switch(type){
      case 'job':this.sortByJobNumber = value;
      break;
      case 'customer':this.sortByCustomer = value;
      break;
      case 'csr' : this.sortByCsr = value;
      break;
      case 'duedate' : this.sortByDueDate = value;
      break;
      default: 
    }

    if(this.user.filter[this.currentDepartmentId]){
      this.user.filter[this.currentDepartmentId].sortByJobNumber = this.sortByJobNumber;
      this.user.filter[this.currentDepartmentId].sortByCustomer = this.sortByCustomer;
      this.user.filter[this.currentDepartmentId].sortByCsr = this.sortByCsr;
      this.user.filter[this.currentDepartmentId].sortByDueDate = this.sortByDueDate;
    }
   
    this.userService.setUserFilters(this.user.filter[this.currentDepartmentId] , this.currentDepartmentId);

    this.store.dispatch(new ReloadeJobs());
  }

}
