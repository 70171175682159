import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShipmentRoutingModule } from './shipment-routing.module';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { ShipmentCreateComponent } from './shipment-create/shipment-create.component';
import { RateUpsService } from './services/rate-ups.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { JobState } from '../jobs/state/job.state';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
import { UPSService } from './services/ups.service';
import { ShipmentUpsService } from './services/shipment-ups.service';
import { ShipmentPackageCreateComponent } from './shipment-create/shipment-package-create/shipment-package-create.component';
import { ShipmentUploadAddressesComponent } from './shipment-upload-addresses/shipment-upload-addresses.component';
import { ShipmentSelectAddressComponent } from './shipment-select-address/shipment-select-address.component';

@NgModule({
    declarations: [
        ShipmentListComponent,
        ShipmentCreateComponent,
        ShipmentTrackingComponent,
        ShipmentPackageCreateComponent,
        ShipmentUploadAddressesComponent,
        ShipmentSelectAddressComponent
    ],
    imports: [
        CommonModule,
        ShipmentRoutingModule,
        SharedModule,
        NgxsModule.forFeature([JobState])
    ],
    exports: [ShipmentCreateComponent],
    providers: [
        RateUpsService,
        ShipmentUpsService,
        UPSService
    ]
})
export class ShipmentModule { }
