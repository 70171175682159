import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { Job } from "src/app/shared/interfaces/job";
import { ApiService } from "src/app/shared/services/api.service";
import { ReloadeJobs } from "../../state/job.actions";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-job-update-with-pp",
  templateUrl: "./job-update-with-pp.component.html",
  styleUrl: "./job-update-with-pp.component.scss",
})
export class JobUpdateWithPPComponent implements OnInit {
  job: Job;
  updatedDataLogs: string[];
  currentToast = null;
  isLoading: boolean = false;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<JobUpdateWithPPComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { job: any; updatedDataLogs: string[] },
    private store: Store,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.job = this.data.job;
    this.updatedDataLogs = this.data.updatedDataLogs;
  }

  updateJobWithPP() {
    this.job["reload"] = true;
    this.isLoading = true;
    this.api
      .request("UPDATE_JOB_From_PP", {
        data: { jobNumber: this.job.jobNumber },
      })
      .subscribe(
        (res) => {
          if (res.success) {
            // this.store.dispatch(new ReloadeJobs());
            this.openToast(res["message"]);
            this.isLoading = false;
            this.dialogRef.close({shouldReloadJob : true});
            // this.openToast("Job Has Been Updated!");
          } else {
            this.openToast(res["message"]);
          }
        },
        (error) => {
          this.openToast("Job Has Not Been Updated!");
          this.isLoading = false;
        }
      );
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500,
    });
  }
}
