import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private _themeDark: Subject<boolean> = new Subject<boolean>();

  isThemeDark = this._themeDark.asObservable();
  src: string;

  public InvoiceSrc: BehaviorSubject<string> = new BehaviorSubject('');
  public BypassSvgIcon: BehaviorSubject<string> = new BehaviorSubject('');
  public cardColor: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }

  setDarkTheme(isThemeDark: boolean) {
    this._themeDark.next(isThemeDark);
    if (isThemeDark == true) {
      document.documentElement.style.setProperty('--app-secondary-text-color', '#E9E9E9');
      document.documentElement.style.setProperty('--app-primary-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--app-secondary-color', '#0B1218');
      document.documentElement.style.setProperty('--app-primary-color', '#15202B');
      document.documentElement.style.setProperty('--app-icon', '#E9E9E9');
      document.documentElement.style.setProperty('--app-primary-icon', '#E9E9E9');
      document.documentElement.style.setProperty('--app-border', '#8A9095');
      document.documentElement.style.setProperty('--app-primary-button', '#346cb0');
      document.documentElement.style.setProperty('--app-secondary-button', '#2b2b3d');
      this.src = '../../../../assets/images/PP Dark Logo.svg';
      this.InvoiceSrc.next('../../../../../assets/images/Invoice_Link.svg');
      document.documentElement.style.setProperty('--app-green-text', '#119836');
      document.documentElement.style.setProperty('--app-green-bg', '#063312');
      document.documentElement.style.setProperty('--app-red-text', '#c43333');
      document.documentElement.style.setProperty('--app-red-bg', '#3e1010');
      document.documentElement.style.setProperty('--app-blue-text', '#0f65b4');
      document.documentElement.style.setProperty('--app-blue-bg', '#052440');
      document.documentElement.style.setProperty('--app-orange-text', '#bc7521');
      document.documentElement.style.setProperty('--app-orange-bg', '#40280b');
      document.documentElement.style.setProperty('--app-grey-text', '#cccccc');
      document.documentElement.style.setProperty('--app-grey-bg', '#333333');
      document.documentElement.style.setProperty('--app-pp-icon', '#24b299');
      document.documentElement.style.setProperty('--app-ar-icon', '#3f405a');
      document.documentElement.style.setProperty('--app-pr-icon', '#3b68e7');
      document.documentElement.style.setProperty('--app-approved-arthwork', '#23ab94');
      document.documentElement.style.setProperty('--app-rejected-arthwork', '#ce5657');
      document.documentElement.style.setProperty('--app-mat-step-active', '#FFFFFF');
      document.documentElement.style.setProperty('--app-mat-step-text', '#0B1218');
      document.documentElement.style.setProperty('--app-view-invoice-btn', '#346cb0');
      document.documentElement.style.setProperty('--app-edit-invoice-btn', '#2b2b3d');
      document.documentElement.style.setProperty('--app-cancel-order-btn', '#2b2b3d');
      document.documentElement.style.setProperty('--app-status-cancel-text', '#d23737');
      document.documentElement.style.setProperty('--app-status-cancel-bg', '#3e1010');
      document.documentElement.style.setProperty('--app-status-production-text', '#e4cd74');
      document.documentElement.style.setProperty('--app-status-production-bg', '#292215');
      document.documentElement.style.setProperty('--app-status-pre-aproval-text', '#1585ee');
      document.documentElement.style.setProperty('--app-status-pre-aproval-bg', '#052440');
      document.documentElement.style.setProperty('--app-status-out-for-delivery-text', '#c6b006');
      document.documentElement.style.setProperty('--app-status-out-for-delivery-bg', '#2f2d1c');
      document.documentElement.style.setProperty('--app-status-pre-production-text', ' #40a7bc');
      document.documentElement.style.setProperty('--app-status-pre-production-bg', '#222c2e');
      document.documentElement.style.setProperty('--app-status-approve-text', '#129b37');
      document.documentElement.style.setProperty('--app-status-approve-bg', '#063312');
      document.documentElement.style.setProperty('--app-status-post-production-text', '#d16c56');
      document.documentElement.style.setProperty('--app-status-post-production-bg', '#3a2b2d');
      document.documentElement.style.setProperty('--app-status-ready-for-pickup-text', '#f1962a');
      document.documentElement.style.setProperty('--app-status-ready-for-pickup-bg', '#40280b');
      document.documentElement.style.setProperty('--app-grid-header-bg', '#0b1218');
      document.documentElement.style.setProperty('--app-steper-header-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-mat-chip-border', '#202446');
      document.documentElement.style.setProperty('--app-file-upload-border', '#8A9095');
      document.documentElement.style.setProperty('--app-main-containt-page-bg', '#15202B');
      document.documentElement.style.setProperty('--app-badge-bg', '#FFFFFF');
      document.documentElement.style.setProperty('--app-order-item-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-profile-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-select-Component-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-graph-Total-count', '#f96e1a');
      document.documentElement.style.setProperty('--app-graph-complete-count', '#24b299');
      document.documentElement.style.setProperty('--app-graph-inprogress-count', '#eb5e5e');
      document.documentElement.style.setProperty('--app-graph-card-color', '#15202b');
      this.cardColor.next("#15202b");
      document.documentElement.style.setProperty('--app-placeholder-color', '#b3b3b3');
      document.documentElement.style.setProperty('--app-dropdown-hover-color', '#15202b');
      document.documentElement.style.setProperty('--app-mat-label-color', '#c1c2c4');
      document.documentElement.style.setProperty('--app-upload-shipment-address-dropdown-bg', '#0B1218');
      this.BypassSvgIcon.next('pacific_bypass_payment_Dark');
      document.documentElement.style.setProperty('--app-bypass-payment-label-bg', '#2b2b3d');
      document.documentElement.style.setProperty('--app-assign-chat-history-Tag', '#d85859');
      document.documentElement.style.setProperty('--app-complete-chat-history-Tag', '#23ab93');
      document.documentElement.style.setProperty('--app-view-invoice-link', '#23a590');
      document.documentElement.style.setProperty('--app-filter-bg', '#0b1218');
      document.documentElement.style.setProperty('--app-filter-border', '#FFFFFF');
      document.documentElement.style.setProperty('--app-markAsComplete-bg', '#346cb0');
      document.documentElement.style.setProperty('--app-markAsComplete-color', '#ededf1');
      document.documentElement.style.setProperty('--app-markAsCompleteDisable-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-markAsCompleteDisable-color', '#aeaeae ');
      document.documentElement.style.setProperty('--app-sendTo-bg', '#15202B');
      document.documentElement.style.setProperty('--app-sendTo-color', '#346cb0');
      document.documentElement.style.setProperty('--app-sendToDisable-bg', '#0B1218');
      document.documentElement.style.setProperty('--app-sendToDisable-color', '#aeaeae ');
      document.documentElement.style.setProperty('--app-progress-bar-color', '#346cb0');
      document.documentElement.style.setProperty('--app-checkbox-color', '#346cb0');
      document.documentElement.style.setProperty('--app-psudo-checkbox-color', '#b8bcbf');
      document.documentElement.style.setProperty('--app-mat-dialog-bg-color', '#15202b');
      document.documentElement.style.setProperty('--app-mat-paginator-bg-color', '#23303c');
      document.documentElement.style.setProperty('--app-serach-box-bg-color', '#15202b');
      document.documentElement.style.setProperty('--app-search-box-text-color', '#ffffff');
      document.documentElement.style.setProperty('--app-snack-bar-bg-color','#5c7daf');
      document.documentElement.style.setProperty('--app-mat-pseudo-checkbox-checked-color','#FFFFFF');
      document.documentElement.style.setProperty('--app-collection-link-color','#6a6aff');
    }
    else {
      document.documentElement.style.setProperty('--app-secondary-text-color', '#282858');
      document.documentElement.style.setProperty('--app-primary-text-color', '#282858');
      document.documentElement.style.setProperty('--app-secondary-color', '#e9eef3');
      document.documentElement.style.setProperty('--app-primary-color', '#fff');
      document.documentElement.style.setProperty('--app-icon', '#282858');
      document.documentElement.style.setProperty('--app-primary-icon', '#3f51b5');
      document.documentElement.style.setProperty('--app-border', '#e1e1e1');
      document.documentElement.style.setProperty('--app-primary-button', '#282858');
      document.documentElement.style.setProperty('--app-secondary-button', '#282858');
      this.src = '../../../../assets/images/logo_1.png';
      this.InvoiceSrc.next('../../../../../assets/images/job-invoice-icon.svg');
      document.documentElement.style.setProperty('--app-green-text', '#1fad4a');
      document.documentElement.style.setProperty('--app-green-bg', '#daf9e2');
      document.documentElement.style.setProperty('--app-red-text', '#E54638');
      document.documentElement.style.setProperty('--app-red-bg', '#ffe8e6');
      document.documentElement.style.setProperty('--app-blue-text', '#4FAEFF');
      document.documentElement.style.setProperty('--app-blue-bg', '#E0F2FF');
      document.documentElement.style.setProperty('--app-orange-text', '#FFA500');
      document.documentElement.style.setProperty('--app-orange-bg', '#FFE9C1');
      document.documentElement.style.setProperty('--app-grey-text', '#545A69');
      document.documentElement.style.setProperty('--app-grey-bg', '#EBEEF1');
      document.documentElement.style.setProperty('--app-pp-icon', '#3eb03e');
      document.documentElement.style.setProperty('--app-ar-icon', '#e7da3b');
      document.documentElement.style.setProperty('--app-pr-icon', '#3b68e7');
      document.documentElement.style.setProperty('--app-approved-arthwork', '#1fad4a');
      document.documentElement.style.setProperty('--app-rejected-arthwork', '#ff1500');
      document.documentElement.style.setProperty('--app-mat-step-active', '#3d55ba');
      document.documentElement.style.setProperty('--app-mat-step-text', '#FFFFFF');
      document.documentElement.style.setProperty('--app-view-invoice-btn', '#3d55ba');
      document.documentElement.style.setProperty('--app-edit-invoice-btn', '#282858');
      document.documentElement.style.setProperty('--app-cancel-order-btn', '#f44336');
      document.documentElement.style.setProperty('--app-status-cancel-text', '#e063a3');
      document.documentElement.style.setProperty('--app-status-cancel-bg', '#fdf4f6');
      document.documentElement.style.setProperty('--app-status-production-text', '#ffa500');
      document.documentElement.style.setProperty('--app-status-production-bg', '#fff7e6');
      document.documentElement.style.setProperty('--app-status-pre-aproval-text', '#1f4a7f');
      document.documentElement.style.setProperty('--app-status-pre-aproval-bg', '#f2f4f8');
      document.documentElement.style.setProperty('--app-status-out-for-delivery-text', '#1fad4a');
      document.documentElement.style.setProperty('--app-status-out-for-delivery-bg', '#ebf9f4');
      document.documentElement.style.setProperty('--app-status-pre-production-text', '#5461E8');
      document.documentElement.style.setProperty('--app-status-pre-production-bg', '#EEF2FF');
      document.documentElement.style.setProperty('--app-status-approve-text', '#1fad4a');
      document.documentElement.style.setProperty('--app-status-approve-bg', '#daf9e2');
      document.documentElement.style.setProperty('--app-status-post-production-text', '#A67A46');
      document.documentElement.style.setProperty('--app-status-post-production-bg', '#F9EDCE');
      document.documentElement.style.setProperty('--app-status-ready-for-pickup-text', '#348ABC');
      document.documentElement.style.setProperty('--app-status-ready-for-pickup-bg', '#E1F1F9');
      document.documentElement.style.setProperty('--app-grid-header-bg', '#ffffff');
      document.documentElement.style.setProperty('--app-steper-header-bg', '#f4f4f4');
      document.documentElement.style.setProperty('--app-mat-chip-border', '#e1e1e1');
      document.documentElement.style.setProperty('--app-file-upload-border', '#282858');
      document.documentElement.style.setProperty('--app-main-containt-page-bg', '#f9fbfd');
      document.documentElement.style.setProperty('--app-badge-bg', '#e9eef3');
      document.documentElement.style.setProperty('--app-order-item-bg', '#fafafa');
      document.documentElement.style.setProperty('--app-profile-bg', '#e1e1e1');
      document.documentElement.style.setProperty('--app-select-Component-bg', '#efefef');
      document.documentElement.style.setProperty('--app-graph-Total-count', '#f96e1a');
      document.documentElement.style.setProperty('--app-graph-complete-count', '#24b299');
      document.documentElement.style.setProperty('--app-graph-inprogress-count', '#eb5e5e');
      this.cardColor.next("#282858");
      document.documentElement.style.setProperty('--app-placeholder-color', '#babcbe');
      document.documentElement.style.setProperty('--app-dropdown-hover-color', '#f5f5f5');
      document.documentElement.style.setProperty('--app-mat-label-color', '#929292');
      document.documentElement.style.setProperty('--app-upload-shipment-address-dropdown-bg', '#fbfbfb');
      this.BypassSvgIcon.next('pacific_bypass_payment');
      document.documentElement.style.setProperty('--app-bypass-payment-label-bg', '#e9eef3');
      document.documentElement.style.setProperty('--app-assign-chat-history-Tag', '#fc978e');
      document.documentElement.style.setProperty('--app-complete-chat-history-Tag', '#7ed397');
      document.documentElement.style.setProperty('--app-view-invoice-link', '#9bc284');
      document.documentElement.style.setProperty('--app-filter-bg', '#f5f5f5');
      document.documentElement.style.setProperty('--app-filter-border', '#848484');
      document.documentElement.style.setProperty('--app-markAsComplete-bg', '#282858');
      document.documentElement.style.setProperty('--app-markAsComplete-color', '#ededf1');
      document.documentElement.style.setProperty('--app-markAsCompleteDisable-bg', '#e0e0e0');
      document.documentElement.style.setProperty('--app-markAsCompleteDisable-color', '#aeaeae ');
      document.documentElement.style.setProperty('--app-sendTo-bg', '#ffffff');
      document.documentElement.style.setProperty('--app-sendTo-color', '#2d2d2d');
      document.documentElement.style.setProperty('--app-sendToDisable-bg', '#e0e0e0');
      document.documentElement.style.setProperty('--app-sendToDisable-color', '#aeaeae ');
      document.documentElement.style.setProperty('--app-markascompleteicon', '#fffff');
      document.documentElement.style.setProperty('--app-markascompleteicon-Disable', '#aeaeae ');
      document.documentElement.style.setProperty('--app-progress-bar-color', '#3f51b5');
      document.documentElement.style.setProperty('--app-checkbox-color', '#3f51b5');
      document.documentElement.style.setProperty('--app-psudo-checkbox-color', '#838383');
      document.documentElement.style.setProperty('--app-mat-dialog-bg-color', '#ffffff');
      document.documentElement.style.setProperty('--app-mat-paginator-bg-color', '#ffffff');
      document.documentElement.style.setProperty('--app-serach-box-bg-color', '#e9eef3');
      document.documentElement.style.setProperty('--app-search-box-text-color', '#000000');
      document.documentElement.style.setProperty('--app-snack-bar-bg-color','#282858');
      document.documentElement.style.setProperty('--app-mat-pseudo-checkbox-checked-color','#282858');
      document.documentElement.style.setProperty('--app-collection-link-color','blue');
    }
  }
}
