<div fxLayout="column" class="padding-10">
  <h2 mat-dialog-title>
    <strong class="primary-text-color"
      >Choose the step for process again</strong
    >
  </h2>

  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="50" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Steps</mat-label>
        <mat-select
          placeholder="Select Steps"
          [(value)]="selectedStep"
          (selectionChange)="selectStep()"
        >
          <mat-option *ngFor="let step of steps" [value]="step.stepActionId">
            {{ step.stepAction }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Reason</mat-label>
        <textarea matInput placeholder="Reason" [(ngModel)]="reason"></textarea>
      </mat-form-field>
    </div>

    <div class="item-container" fxFlex="50" fxLayout="column">
      <strong class="primary-text-color">Select Job Items</strong>
      <div
        fxLayout="column"
        style="max-height: 150px;overflow: auto;height: 150px"
      >
        <mat-checkbox
          color="primary"
          [(ngModel)]="stepJobItem.select"
          *ngFor="let stepJobItem of stepJobItems"
          >{{ stepJobItem.jobItem.description }}</mat-checkbox
        >
    </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="10px"
    fxFlexOffset="10px"
    fxLayoutAlign="center"
  >
    <div fxLayout="column" fxLayoutGap="10px">
      <small *ngIf="!isValidate" class="warn">
        <strong
          >You must select step and enter reason to change step.
        </strong></small
      >
      <small *ngIf="!jobItemSelected" class="warn">
        <strong>You must select job items. </strong></small
      >
      <small *ngIf="showWarningMessage" class="warn">
        <strong>{{ warningMessage }}</strong></small
      >
    </div>

    <span fxFlex></span>
    <button mat-raised-button (click)="onSubmit()" color="primary">
      <strong>SEND TO</strong>
    </button>
    <button mat-raised-button mat-dialog-close>
      <strong>CANCEL</strong>
    </button>
  </div>
</div>
