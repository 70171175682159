import { Department } from 'src/app/shared/interfaces/department';
import { Navigation } from 'src/app/shared/interfaces/navigation';

export class LoadDepartments {
    static readonly type = '[Departments] Load Departments';
    constructor() { }
}

export class AddDepartment {
    static readonly type = '[Departments] Add Department';
    constructor(public department: Department) { }
}

export class LoadSteps {
    static readonly type = '[Steps] Load Steps';
    constructor() { }
}


export class LoadNavigations{
    static readonly type = '[Navigations] Load Navigations';
    constructor(){}
}

export class AddNavigation {
    static readonly type = '[Navigations] Add Navigation'
    constructor(public navigation:Navigation){}
}

export class UpdateNavigation {
    static readonly type ='[Navigation] Update Navigation'
    constructor(public navigation:Navigation){}
}

export class DeleteNavigation {
    static readonly type ='[Navigation] Delete Navigation'
    constructor(public navigationId:number){}
}

export class NavigationLoadCompletely {
    static readonly type ='[Naigation] Load Completely'
    constructor(){};
}