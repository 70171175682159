<div fxLayout="column" style="height: 600px">

  <div class="chat-container"  [ngStyle]="{'min-height':JobItem.jobItemNotesAndDocs.length > 0 ? '150px' : '10px','max-height': !addNoteValidate || !addFileValidate ? '340px' : '390px'}">
    <div *ngFor="let noteOrDoc of JobItem.jobItemNotesAndDocs; let index = index" fxLayout="column">
      
      <div *ngIf="noteOrDoc['isDoc']" fxLayout="row" fxLayoutAlign="center center" class="note-item"
                  [ngClass]="{'csr-note' : noteOrDoc['isCsrDoc'] , 'customer-note':!noteOrDoc['isCsrDoc']}">
                  <div class="note text-ellipsis" fxFlex="95" fxLayout="row" fxFlex fxLayoutAlign=" center center">
                    <img [src]=noteOrDoc.url (click)="onShowFile(noteOrDoc)">
                    <small fxLayout="row" fxFlex (click)="onShowFile(noteOrDoc)" class="primary-text-color"> {{noteOrDoc.name}}</small>
                  </div>
                  <div class="font-smaller" fxLayout="column" fxLayoutAlign="start end" fxFlex="200px">
                    <small class="primary-text-color">{{noteOrDoc['createdBy'] | titlecase}}</small>
                    <small class="primary-text-color">{{noteOrDoc['createdAt']|date :'MMM d, y, h:mm' }}</small>
                  </div>
                  
                  <button mat-icon-button [matMenuTriggerFor]="menu" >
                    <mat-icon class="icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="download(noteOrDoc['id'])" class="primary-text-color grid-header-bg">
                      <mat-icon class="icon">get_app</mat-icon>
                      <span class="primary-text-color">Download</span>
                    </button>
                    <button mat-menu-item class="primary-text-color grid-header-bg"
                      *ngIf="noteOrDoc['type'].toLowerCase() === 'image' || noteOrDoc['type'].toLowerCase() === 'pdf' || noteOrDoc['type'].toLowerCase() === 'tiff' || noteOrDoc['type'].toLowerCase() === 'svg' || noteOrDoc['type'].toLowerCase() === 'tif' || noteOrDoc['type'].toLowerCase() === 'excel'"
                      (click)="preview(noteOrDoc)">
                      <mat-icon class="icon">open_in_new</mat-icon>
                      <span class="primary-text-color" >Preview</span>
                    </button>
                    <button mat-menu-item *ngIf="noteOrDoc['canDelete']" class="primary-text-color grid-header-bg"
                      (click)="deleteDocument(noteOrDoc['id'],index)">
                      <mat-icon class="icon">delete</mat-icon>
                      <span class="primary-text-color">Delete</span>
                    </button>
                  </mat-menu>
                </div>

      <div *ngIf="noteOrDoc['isNote']" fxLayout="row" class="note-item"
        [ngClass]="{'csr-note' : noteOrDoc['isCsrNote'] , 'customer-note':!noteOrDoc['isCsrNote']}">
        <div class="note" fxLayout="row" fxFlex="100" fxLayoutAlign=" center center">

          <div style="padding-bottom: 10px;overflow: auto;">
            <small fxFlex class="preserve-spaces">
              <strong><small *ngIf="noteOrDoc.isFrontInkNote">Front Ink <br></small></strong><strong><small
                  *ngIf="noteOrDoc.isBackInkNote">Back Ink <br></small></strong>{{noteOrDoc['text']}}
            </small>
          </div>

          <span fxFlex></span>
          <div class="font-smaller" fxLayout="column" fxLayoutAlign="start end" style="width: 200px;">
            <small>{{noteOrDoc['createdBy'] | titlecase}}</small>
            <small>{{noteOrDoc['createdAt']|date :'MMM d, y, h:mm'}}</small>
          </div>
          <div fxFlex="20px" fxFlexOffset="10px" *ngIf="noteOrDoc.canEdit || noteOrDoc.canDelete" fxLayout="row" class="margin-top-4px" style="padding-right: 20px;">
            <button mat-icon-button [matMenuTriggerFor]="noteMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #noteMenu="matMenu">
              <button mat-menu-item *ngIf="noteOrDoc.canEdit" (click)="editNote(noteOrDoc['id'],index)" class="primary-text-color grid-header-bg">
                <mat-icon class="icon">edit</mat-icon>
                <span class="primary-text-color">Edit</span>
              </button>
              <button mat-menu-item *ngIf="noteOrDoc.canDelete" (click)="deleteNote(noteOrDoc['id'],index)" class="primary-text-color grid-header-bg">
                <mat-icon class="icon">delete</mat-icon>
                <span class="primary-text-color">Delete</span>
              </button>
            </mat-menu>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px" style="margin: 5px;">
    <span fxFlex></span>
    <div class="toggle-chat" fxFlex="33">
      <mat-select class="select-chat-type primary-text-color" [(ngModel)]="leaveNote">
        <mat-option #matOption1 value="Leave Note" (click)="selectChatSection(matOption1.value)">
          <span clas="primary-text-color">Add Note</span>
        </mat-option>
        <mat-option #matOption2 value="Document" (click)="selectChatSection(matOption2.value)">
          <span clas="primary-text-color">Add Document</span>
        </mat-option>
      </mat-select>
  </div>
  </div>

  <div fxLayout="row" fxLayoutGap="5px">
    
    <div fxFlex="100" *ngIf="isLeaveNote">
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" style="width:100%">
          <textarea matInput placeholder="Leave a Note" rows="2" [(ngModel)]="note"
            class="preserve-spaces primary-text-color" ></textarea>
        </mat-form-field>
        <div fxLayoutAlign="start start" *ngIf="!addNoteValidate">
          <small class="warn">You must enter note</small>
          <br /><br />
        </div>

      </div>
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px" style="margin-top: -15px;">
        <button [matMenuTriggerFor]="inkSelectMenu" mat-stroked-button fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="primary-text-color">{{NoteTypeSelected.type}}</span>&nbsp;&nbsp;
            <span>
            <mat-icon
              style="margin-bottom: 3px;">arrow_drop_down</mat-icon>
            </span>
        </div>
          </button>
        <mat-menu #inkSelectMenu="matMenu">
          <button *ngFor="let noteType of NoteTypes" mat-menu-item
            (click)="selectNoteType(noteType)" class="primary-text-color grid-header-bg">{{noteType.type}}</button>
        </mat-menu>

        <button [disabled]="addNoteLoading" mat-stroked-button color="primary" (click)="saveNote()">
          <div fxLayoutAlign="center center">
            <mat-icon>add</mat-icon>
            <span class="primary-text-color"> {{currentSelectedNote ? "Update Note" : "Add Note"}} &nbsp;&nbsp;</span>
            <span *ngIf="addNoteLoading" class="loader"></span>
          </div>
        </button>
      </div>
    </div>

    <div fxFlex="100" *ngIf="!isLeaveNote">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div class="drag-drop" fxlayout="column" fxLayoutAlign="center center" (click)="fileInput.click()"
          pacificPrintingDragDrop (fileDropped)="selectDocuments($event)">
          <div class="dropzone" id="myDrop">
            <div fxLayoutAlign="center center" fxLayoutGap="2px">
              <mat-icon class="icon">note_add</mat-icon>
              <small class="primary-text-color">Click/Drag files here to upload</small>
            </div>
            <input hidden type="file" #fileInput (change)="selectDocuments($event.target.files)" multiple>
          </div>
        </div>
        <div *ngIf="!addFileValidate" class="file-validation" fxLayoutAlign="start start">
          <small class="warn">You must select file</small>
          <br />
        </div>
        <div fxLayout="row" fxLayoutAlign="center center " style="width: 100%;">

          <strong fxFlexOffset=23px *ngIf=" itemDocuments.length > 0">
            <small class="primary-text-color">Added files</small>
          </strong>

          <span fxFlex></span>

          <button mat-stroked-button fxFlex="220px" fxFlexOffset="10px" fxLayoutGap="10px" [disabled]="addDocumentLoading"  color="primary"
            (click)="saveDocuments()" style="margin-top: 8px;">
            <div fxLayoutAlign="center center" fxLayoutGap="4px">
              <mat-icon>backup</mat-icon>
              <span class="primary-text-color"> Upload Document </span>
              <span *ngIf="addDocumentLoading" class="loader"></span>
            </div>
          </button>

        </div>
      </div>

      <div fxLayout="column" *ngIf=" itemDocuments.length > 0">

        <div class="selected-files">
          <div class="note-item" fxLayout="row" fxLayoutAlign=" center center"
            *ngFor="let  doc of itemDocuments; let index = index">
            <div class="note" fxLayout="row" fxFlex fxLayoutAlign=" center center">
              <img [src]=doc.url>
              <small fxFlex class="primary-text-color"> {{doc.name}}</small>
            </div>
            <button mat-icon-button (click)="removeSelectedDocument(index)">
              <mat-icon class="pointer">close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>