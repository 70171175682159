import { Injectable } from "@angular/core";
@Injectable()
export class RateUpsService {

    constructor() {
    }

    package() {
        return {
            "PackagingType": {
                "Code": "",
                "Description": ""
            },
            "Dimensions": {
                "UnitOfMeasurement": {
                    "Code": "IN"
                },
                "Length": "",
                "Width": "",
                "Height": ""
            },
            "PackageWeight": {
                "UnitOfMeasurement": {
                    "Code": "LBS"
                },
                "Weight": ""
            }
        }
    }

    request() {
        return {
            "RateRequest": {
                "Request": {
                    //"SubVersion": "1703",
                    "TransactionReference": {
                        "CustomerContext": " "
                    }
                },
                "Shipment": {
                    "ShipmentRatingOptions": {
                        "UserLevelDiscountIndicator": "TRUE"
                    },
                    "Shipper": {
                        "Name": "",
                        "ShipperNumber": " ",
                        "Address": {
                            "AddressLine": "",
                            "City": "Marietta",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": "US"
                        }
                    },
                    "ShipTo": {
                        "Name": "",
                        "Address": {
                            "AddressLine": "",
                            "City": "Marietta",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": ""
                        }
                    },
                    "ShipFrom": {
                        "Name": "Billy Blanks",
                        "Address": {
                            "AddressLine": "",
                            "City": "Marietta",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": ""
                        }
                    },
                    "Service": {
                        "Code": "",
                        "Description": ""
                    },
                    "ShipmentTotalWeight": {
                        "UnitOfMeasurement": {
                            "Code": "LBS",
                            "Description": "Pounds"
                        },
                        "Weight": ""
                    },
                    "Package": []
                }
            }
        }
    }
}