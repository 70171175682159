<div #jobView [class.show-full-view]="isFullScreenView">
  <div class="main-container-bg">
    <app-breadcrumb fxFlex="250px" [title]="title" [subTitles]="subTitles"></app-breadcrumb>
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutGap="1px" fxLayoutAlign="start start">
      <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="end center" style="margin-bottom: 10px">
        <!-- Mterial chip for status filter selected -->
        <ng-container *ngFor="let filter of statusList">
          <mat-chip-option *ngIf="filter.isSelect" class="m-5">
            <b>Status : {{ filter.name }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('status', filter)">cancel</mat-icon>
          </mat-chip-option>
        </ng-container>

        <!-- Mterial chip for job no filter selected -->
        <ng-container *ngFor="let filter of jobNoList">
          <mat-chip-option *ngIf="filter.isSelect" class="m-5">
            <b>JobNo : {{ filter.no }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('jobNo', filter)">cancel</mat-icon>
          </mat-chip-option>
        </ng-container>

        <!-- Mterial chip for csr filter selected -->
        <ng-container *ngFor="let filter of csrList">
          <mat-chip-option *ngIf="filter.isSelect" class="m-5">
            <b>Csr : {{ filter.name }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('csr', filter)">cancel</mat-icon>
          </mat-chip-option>
        </ng-container>

        <!-- Mterial chip for customer filter selected -->
        <ng-container *ngFor="let filter of customerList">
          <mat-chip-option *ngIf="filter.isSelect" class="m-5">
            <b>Customer : {{ filter.name }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('customer', filter)">cancel</mat-icon>
          </mat-chip-option>
        </ng-container>

        <mat-chip-listbox>
          <!-- Material chip for date due filter selected -->
          <mat-chip-option *ngIf="filterDateDue != '' && filterDateDue != null" class="m-5">
            <b> Date Due : {{ filterDateDue | date }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('dateDue')">cancel</mat-icon>
          </mat-chip-option>

          <!-- Material chip for date in filter selected -->
          <mat-chip-option *ngIf="filterDateIn != '' && filterDateIn != null" class="m-5">
            <b> Date In : {{ filterDateIn | date }}</b>
            <mat-icon matChipRemove (click)="onRemoveFilter('dateIn')">cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <!-- Print Ryobi job-->
      <div *ngIf="isShowRyobiJobPdfIcon()">
        <button *ngIf="(jobs$ | async) as jobs" mat-icon-button matTooltip="Print Ryobi Jobs PDF" color="primary"
          (click)="onClickPrintMultipleRyobiJobs(jobs)">
          <mat-icon class="icon">receipt</mat-icon>
        </button>
      </div>

      <!--Print Template-->
      <button *ngIf="allowTemplateLabel" mat-icon-button matTooltip="Template Label" color="primary"
        (click)="openTemplateLabelDialog()">
        <mat-icon>local_print_shop</mat-icon>
      </button>
      <!-- Filter menu button -->
      <button mat-icon-button matTooltip="Filter" color="primary" (click)="showFilterContainer()">
        <mat-icon svgIcon="filter"></mat-icon>
      </button>
      <button *ngIf="allowCreateShipment" mat-icon-button matTooltip="Create Shipment" color="primary"
        (click)="createShipment()">
        <mat-icon class="ml-4">local_shipping</mat-icon>
      </button>

      <!-- Retrive Job -->
      <button mat-icon-button matTooltip="Retrieve Job" color="primary" (click)="retriveJobs()"
        *ngIf="allowRetriveButton && isJobTab">
        <mat-icon svgIcon="retrieve"></mat-icon>
      </button>

      <button mat-icon-button matTooltip="Move Files To Switch" color="primary" (click)="openSwitchFilesUploadModal()"
        *ngIf="(jobs$ | async).length > 0">
        <mat-icon>cloud_upload</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Move Jobs" color="primary" (click)="moveMultipleJobs()"
        *ngIf="(jobs$ | async).length > 0">
        <mat-icon svgIcon="right-arrow"></mat-icon>
      </button>

      <button mat-icon-button color="primary" (click)="onExpandAndCollapse()">
        <mat-icon *ngIf="!isExpand" matTooltip="Expand" svgIcon="expanded"></mat-icon>
        <mat-icon *ngIf="isExpand" matTooltip="Collapse" svgIcon="closed"></mat-icon>
      </button>

      <button mat-icon-button color="primary" (click)="toggleFullScreenView()">
        <mat-icon [matTooltip]="!isFullScreenView ? 'Full Screen' : 'Exit Full Screen'"
          svgIcon="fullscreen">zoom_out_map</mat-icon>
      </button>
    </div>
  </div>

  <div class="grid" [class.full-screen-view]="isFullScreenView" style="height: Calc(100vh - 230px)" fxLayout="column">
    <!-- filter panel -->
    <ng-template *ngTemplateOutlet="filterTemplate"> </ng-template>
    <div style="overflow: auto;">
      <app-job-grid [jobs]="jobs$" [isJobsLoading]="isJobsLoading" [isExpand]="isExpand"
        [isFullScreenView]="isFullScreenView" [currentDepartmentId]="currentDepartmentId"
        [currentDepartmentName]="currentDepartmentName"></app-job-grid>
    </div>
    <span fxFlex></span>
    <mat-paginator #jobpaginator [length]="totalRecord | async" [pageSize]="pageSize" [pageSizeOptions]="[50, 100, 200]"
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>

<ng-template #filterTemplate>
  <div class="filter-panel" *ngIf="showFilter" fxLayout="row" fxLayoutGap="20px">
    <mat-form-field appearance="fill" fxFlex="14" class="remove-field-wrapper">
      <mat-label class="primary-text-color">Status</mat-label>
      <mat-select [formControl]="statusFromControl">
        <mat-option [class.bold-line]="status.isSelect" *ngFor="let status of this.statusList" [value]="status.name"
          (click)="onFilterSelect('status', status)" style="font-size: 0.97rem">
          <span>{{ status.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="16" class="remove-field-wrapper">
      <mat-label class="primary-text-color">Job no</mat-label>
      <input matInput placeholder="Job no" [matAutocomplete]="jobNoAuto" [formControl]="jobNoFormControl" />
      <mat-autocomplete #jobNoAuto="matAutocomplete">
        <mat-option [class.bold-line]="job.isSelect" *ngFor="let job of filteredJobNo | async" [value]="job.no"
          (click)="onFilterSelect('job', job)">
          <span>{{ job.no }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="16" class="remove-field-wrapper">
      <mat-label class="primary-text-color">Csr</mat-label>
      <input matInput placeholder="Csr" [matAutocomplete]="csrAuto" [formControl]="csrFormControl" />
      <mat-autocomplete #csrAuto="matAutocomplete">
        <mat-option [class.bold-line]="csr.isSelect" *ngFor="let csr of filteredCsr | async" [value]="csr.name"
          (click)="onFilterSelect('csr', csr)">
          <span>{{ csr.name }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="32" class="remove-field-wrapper">
      <mat-label class="primary-text-color">Customer</mat-label>
      <input matInput placeholder="Customer" [matAutocomplete]="customerAuto" [formControl]="customerFormControl" />
      <mat-autocomplete #customerAuto="matAutocomplete">
        <mat-option [class.bold-line]="customer.isSelect" *ngFor="let customer of filteredCustomer | async"
          [value]="customer.name" (click)="onFilterSelect('customer', customer)">
          <span>{{ customer.name }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="dateInAndDateDue" fxLayout="row" fxFlex="22" fxLayoutAlign="space-between" fxLayoutGap="20px">
      <button mat-menu-item class="date-filter" fxLayoutAlign="center center">
        <div style="display: flex; align-items: center; justify-content: center">
          <span class="primary-text-color">Date in</span>
          <input type="text" readonly [matDatepicker]="toDateInPicker"
            (dateChange)="onDateFilterChange('dateIn', $event)" />
          <mat-datepicker-toggle [for]="toDateInPicker" [ngClass]="{ 'date-picker-color': filterDateIn !== '' }">
          </mat-datepicker-toggle>
          <mat-datepicker #toDateInPicker></mat-datepicker>
        </div>
      </button>

      <button mat-menu-item class="date-filter" fxLayoutAlign="center center">
        <div style="display: flex; align-items: center; justify-content: center">
          <span class="primary-text-color">Date due</span>
          <input type="text" readonly [matDatepicker]="toDateDuePicker"
            (dateChange)="onDateFilterChange('dateDue', $event)" />
          <mat-datepicker-toggle [for]="toDateDuePicker" [ngClass]="{ 'date-picker-color': filterDateDue !== '' }">
          </mat-datepicker-toggle>
          <mat-datepicker #toDateDuePicker></mat-datepicker>
        </div>
      </button>
    </div>
  </div>
</ng-template>