<div  >
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <!-- <button mat-icon-button >  
            <mat-icon (click)="onClose()" >arrow_back</mat-icon>
       </button> -->
         <strong fxFlex style="font-size:18px">
             {{stepAction.stepName|titlecase}} / {{stepAction.actionName|titlecase}} - Mailing Sort
     
         </strong>
         <button mat-icon-button >  
             <mat-icon color="primary" (click)="onSubmit()">edit</mat-icon>
           </button>
       </div>
       <br>
    <div fxLayout="column" fxLayoutGap="15px"  >
      <div fxLayout="row" fxLayoutGap="10px" >
        <div fxFlex  fxLayoutGap="5px" ><span>Weight:</span> <b>{{mailingSort.weight}}</b></div>
        <div fxFlex  fxLayoutGap="5px" ><span>Width:</span> <b>{{mailingSort.width}}</b></div>
        <div fxFlex  fxLayoutGap="5px"><span>Height:</span> <b>{{mailingSort.height}}</b> </div>
     
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex  fxLayoutGap="5px" ><span>Thickness: </span><b>{{mailingSort.thickness}}</b></div>
        <div fxFlex  fxLayoutGap="5px" ><span>Post Office:</span> <b>{{mailingSort.postOffice}}</b></div>
        <div fxFlex  fxLayoutGap="5px" ><span>Permit#:</span> <b>{{mailingSort.permit}}</b></div>
     
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex fxLayoutAlign="start center" ><span>Letter:</span>
           <mat-icon *ngIf="mailingSort.letter" color="primary">done</mat-icon>
           <mat-icon *ngIf="!mailingSort.letter" color="warn">clear</mat-icon>
        </div>
        <div fxFlex  fxLayoutAlign="start center"><span>Flat:</span>
          <mat-icon *ngIf="mailingSort.flat" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.flat" color="warn">clear</mat-icon>
        </div>
        <div fxFlex  fxLayoutAlign="start center"><span>Postcard:</span>
          <mat-icon *ngIf="mailingSort.postcard" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.postcard" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex  fxLayoutAlign="start center"><span>Automation:</span>
          <mat-icon *ngIf="mailingSort.automation" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.automation" color="warn">clear</mat-icon>
        </div>
        <div fxFlex fxLayoutAlign="start center" ><span>Non-Auto:</span>
          <mat-icon *ngIf="mailingSort.nonAuto" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.nonAuto" color="warn">clear</mat-icon>
        </div>
        <div fxFlex  fxLayoutAlign="start center"><span>Non-Machinable:</span>
          <mat-icon *ngIf="mailingSort.nonMachinable" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.nonMachinable" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex fxLayoutAlign="start center"><span>Non-Profit:</span>
          <mat-icon *ngIf="mailingSort.nonProfit" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.nonProfit" color="warn">clear</mat-icon>
        </div>
        <div fxFlex fxLayoutAlign="start center"><span>Ancillary Svcs:</span>
          <mat-icon *ngIf="mailingSort.ancillarySvcs" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.ancillarySvcs" color="warn">clear</mat-icon>
        </div>
        <div fxFlex fxLayoutAlign="start center"><span>Std Prsrt:</span>
          <mat-icon *ngIf="mailingSort.stdPrsrt" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.stdPrsrt" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex fxLayoutAlign="start center"><span>Full Rate First Class:</span>
          <mat-icon *ngIf="mailingSort.fullRateFirstClass" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.fullRateFirstClass" color="warn">clear</mat-icon>
        </div>
        <div fxFlex fxLayoutAlign="start center"><span>First Class Part:</span>
          <mat-icon *ngIf="mailingSort.firstClassPart" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.firstClassPart" color="warn">clear</mat-icon>
        </div>
        <div fxFlex fxLayoutAlign="start center" ><span>Periodical:</span>
          <mat-icon *ngIf="mailingSort.periodical" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.periodical" color="warn">clear</mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex fxLayoutAlign="start center"><span>Foreign:</span>
          <mat-icon *ngIf="mailingSort.foreign" color="primary">done</mat-icon>
          <mat-icon *ngIf="!mailingSort.foreign" color="warn">clear</mat-icon>
        </div>
      </div>
      <mat-dialog-actions fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="end end">
        <button mat-button mat-dialog-close>CLOSE</button>
      </mat-dialog-actions>
    </div>
    
    
</div>
