<div fxLayout="column" fxLayoutGap="15px" class="padding-15">
  <span class="header-text primary-text-color"><b>Change Password</b></span>

  <form [formGroup]="form" fxLayout="column" fxLayoutGap="6px">
    <mat-form-field appearance="outline" fxFlex="50">
      <mat-label>Password</mat-label>
      <input
        matInput
        minlength="6"
        [type]="!isShowPassword ? 'password' : 'text'"
        autocomplete="off"
        placeholder="Password"
        formControlName="password"
        required
      />
      <mat-icon
        matSuffix
        (click)="isShowPassword = !isShowPassword"
        class="material-icons gray eye-icon"
        style="cursor: pointer"
      >
        {{ isShowPassword ? "visibility_off" : "visibility" }}</mat-icon
      >
      <mat-error *ngIf="hasError('password', 'required')"
        >You must enter password.</mat-error
      >
      <mat-error *ngIf="hasError('password', 'minlength')"
        >Password length should be 6 digit.</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="50">
      <mat-label class="primary-text-color">Confirm Password</mat-label>
      <input
        matInput
        minlength="6"
        [type]="!isShowConfPassword ? 'password' : 'text'"
        autocomplete="off"
        placeholder="Password"
        formControlName="confirmPassword"
        required
      />
      <mat-icon
        matSuffix
        (click)="isShowConfPassword = !isShowConfPassword"
        class="material-icons gray eye-icon"
        style="cursor: pointer"
      >
        {{ isShowConfPassword ? "visibility_off" : "visibility" }}</mat-icon
      >
      <mat-error *ngIf="hasError('confirmPassword', 'required')"
        >You must enter password.</mat-error
      >
      <mat-error *ngIf="form.controls['confirmPassword'].errors?.MatchPassword"
        >Password does not match
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
      <button
        mat-raised-button
        color="primary"
        fxFlex="100px"
        type="submit"
        (click)="submit()"
      >
        SUBMIT
      </button>
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        CLOSE
      </button>
    </div>
  </form>
</div>
