import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { LoadActiveUser } from 'src/app/features/account/state/account.actions';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  subTitles = [
    { text: 'Home', 'route': '' },
    { text: 'Profile', 'route': '' }
  ];

  user: any;
  file: any;
  userForm: UntypedFormGroup;
  userName = '';
  profilePictureUrl = 'assets/images/profile-pic.png';
  loading = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private dialog: MatDialog,
    private auth: AuthUserService,
    private store: Store,
    private toastService: ToastService) { }

  ngOnInit() {
    this.userForm = this.initForm(this.formBuilder);
    this.user = this.auth.getUser();
    if (this.user) {
      this.userForm.patchValue(this.user);
      this.userName = this.user.firstName + ' ' + this.user.lastName;
      this.profilePictureUrl = this.user.isProfilePicture ? `${environment.baseUrl}/api/Users/${this.user.userId}/picture` :
      this.profilePictureUrl ;
    }
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['']
    })

  hasError = (field: string, errorName: string): boolean =>
    this.userForm.get(field).errors ? this.userForm.get(field).errors[errorName] : false

  /**
   * load selacted new profile picture
   */
  onSelectFile = event => {
    if (event.target.files && event.target.files[0]) {
      this.loadUrl(event.target.files[0]);
    }
  }

  public loadUrl = (file: any) => {
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.profilePictureUrl = event.target['result'];
      this.uploadeProfilePic(file);
    };
  }

  uploadeProfilePic(file: any) {
    const formdata = new FormData();
    formdata.append('file', file);
    const apiRequest = {
      params: {USER_ID : this.user.userId },
      data: formdata
    };

    this.apiService .request('SET_PROFILE_PIC', apiRequest)
    .subscribe((res: any) => {
      if(res['success']){
        this.toastService.show('Profile picture is update successfully.');
        this.user.isProfilePicture = true;
        this.auth.saveUser(this.user);
        return;
      }
      this.toastService.show(res['message']);
    });
  }

  /**
   * Update user detail
   */
  submit() {

    if (this.userForm.invalid || this.loading) {
      return;
    }

    const user = {
      userId: this.user.userId,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phone: this.userForm.value.phone,
    };

    const apiRequest = {
      params: { USER_ID: this.user.userId },
      data: user
    };

    this.loading = true;
    this.apiService.request('UPDATE_PROFILE', apiRequest).subscribe((res: any) => {
      if (res['success']) {
        this.user.firstName = this.userForm.value.firstName;
        this.user.lastName = this.userForm.value.lastName;
        this.user.phone = this.userForm.value.phone;
        this.auth.saveUser(this.user);
        this.store.dispatch(new LoadActiveUser());
        this.toastService.show('Profile is update successfully.');
       return;
      }
      this.toastService.show(res['message']);
      this.loading = false;
    },error=>{ this.loading = false;});
  }

  /**
   * On change Pasword click
   */
  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: this.user.userId
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
