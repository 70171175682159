import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { SearchTextComponent } from './components/search-text/search-text.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormateTimePipe } from './pipes/formate-time.pipe';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { WorkflowMockupComponent } from './components/workflow-mockup/workflow-mockup.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { DragDropDirective } from './directive/drag-drop.directive';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MomentModule } from 'ngx-moment';
import { ReplaceCharPipe } from './pipes/replace-char.pipe';
import { SvgPreviewComponent } from './components/svg-preview/svg-preview.component';
import { TiffPreviewComponent } from './components/tiff-preview/tiff-preview.component';
import { ExcelPreviewComponent } from './components/excel-preview/excel-preview.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';

const materialModules = [
 
  MatToolbarModule,
  MatListModule,
  MatDividerModule,
  MatTooltipModule,
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatInputModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatTabsModule,
  MatCardModule,
  MatExpansionModule,
  MatBadgeModule,
  MatSidenavModule,
  MatSelectModule,
  MatExpansionModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatChipsModule,
  DragDropModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatStepperModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatMomentDateModule,
  MatRippleModule
];


@NgModule({
  declarations: [
    SearchTextComponent,
    UserAvatarComponent,
    BreadcrumbComponent,
    ConfirmDialogComponent, 
    FormateTimePipe, 
    WorkflowMockupComponent,
    TimeAgoPipe,
    DragDropDirective,
    ReplaceCharPipe,
    SvgPreviewComponent,
    TiffPreviewComponent,
    ExcelPreviewComponent,
  ],
 
  imports: [
    CommonModule,
    ...materialModules,
    FlexLayoutModule,
    ReactiveFormsModule    
  ],

  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ...materialModules,
    ReactiveFormsModule,
    SearchTextComponent,
    UserAvatarComponent,
    BreadcrumbComponent,
    FormateTimePipe,
    TimeAgoPipe,
    DragDropDirective,
    // PerfectScrollbarModule,
    ReplaceCharPipe,
    SvgPreviewComponent,
    TiffPreviewComponent,
    ExcelPreviewComponent,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class SharedModule { }
