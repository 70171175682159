import { OrderDocument } from './orderDocument';
import { OrderNote } from './orderNote';

export class OrderItem {
    id:number;
    fold: string;
    foldId: number;
    height: number;
    frontInk: number;
    frontInkId: number;
    backInk: string;
    backInkId: number;
    paper: string;
    paperId: number;
    paperSize:string;
    paperSizeId:number;
    quantity: number;
    side: string;
    sideId: 0
    status: string;
    title: string;
    type: string;
    typeId: number;
    width: number;
    coatingFieldId:number;
    coatingField:string;
    bindingTypeId:number;
    bindingType:string;
    cornerId:number;
    corner:string;
    limitSizeId:number;
    limitSize:string;
    perforationId:number;
    perforation:string;
    scoringId:number;
    scoring:string;
    finishingId:number;
    finishing:string;
    isDocumentApproved:boolean;
    isRequiredApproveArtwork: boolean;
    isUsedExistingArtwork:boolean;
    isReorderItem:boolean;
    notes:OrderNote[];
    documents:OrderDocument[];
    notesAndDocs : any[];
    isImport?: boolean;
    isInvoiceCreated? : boolean;
    amountToBePaid : number;
    isAddedByCSR : boolean; 
    deletedDocumentIds : number[];
    orderId? : number;
    isImportToProduction? : boolean;
    importToProductionWithoutValidations? : boolean;
}