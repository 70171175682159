import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobMailingInfoCreateComponent } from '../job-mailing-info-create/job-mailing-info-create.component';

@Component({
  selector: 'app-job-mailing-info',
  templateUrl: './job-mailing-info.component.html',
  styleUrls: ['./job-mailing-info.component.scss']
})
export class JobMailingInfoComponent implements OnInit {
  mailingInfo:any;
  constructor( @Inject(MAT_DIALOG_DATA) public stepAction: any,
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<JobMailingInfoComponent>) { }

  ngOnInit() {
    this.mailingInfo = this.stepAction.mailingInfo;
  }

  onSubmit(){
    const dialogRef = this.dialog.open(JobMailingInfoCreateComponent, {
      height: '550px',
      width: '1100px',
      data:this.stepAction['mailingInfo'] 

    });

    dialogRef.afterClosed().subscribe(mailingInfo => {
      if(mailingInfo && mailingInfo !== ''){
        this.stepAction['mailingInfo']= mailingInfo;
        this.mailingInfo = mailingInfo;
      }
    });
  }

  onClose(){
    this.dialogRef.close();
  }

}
