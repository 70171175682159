import { Component, OnInit } from "@angular/core";
import { Store, Select } from "@ngxs/store";
import { ConfigurationState } from "../../state/configuration.state";
import { Department } from "src/app/shared/interfaces/department";
import { Observable } from "rxjs";
import { LoadDepartments, AddDepartment } from "../../state/configuration.actions";
import { MatDialog } from "@angular/material/dialog";
import { DepartmentCreateComponent } from "./department-create/department-create.component";
import { ConfirmDialogComponent } from "./../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ApiService } from "src/app/shared/services/api.service";
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: "app-departments",
  templateUrl: "./departments.component.html",
  styleUrls: ["./departments.component.scss"]
})
export class DepartmentsComponent implements OnInit {
  subTitles = [
    { text: "Configuration", route: "" },
    { text: "Departments", route: "" }
  ];
  displayedColumns = ["position", "name", "description", "status", "action"];
  departments: Array<Department>;
  pageNo = 0;
  pageSize = 10;
  totalRecord = 0;
  // @Select(ConfigurationState.AllDepartments) departments$: Observable< Department[]>;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private api: ApiService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    // this.store.dispatch(new LoadDepartments());
    this.loadDepartments();
  }


  loadDepartments() {

    const params = {
      PAGE_NO: this.pageNo,
      PAGE_SIZE: this.pageSize
    }

    this.api.request('GET_DEPARTMENTS', { params: params }).subscribe(res => {
      this.totalRecord = res['totalRecord'];
      this.departments = res['departments'];
    });
  }

  /**
   * Open add department popup
   */
  onCreateDepartment() {
    //show add department popup
    const dialogRef = this.dialog.open(DepartmentCreateComponent, {
      width: "600px"
    });

    dialogRef.afterClosed().subscribe(department => {
      if (department) {
        this.loadDepartments();
      }
    });
  }

  /**
   * open edit user dialog.
   */
  onEditClick(department) {
    const dialogRef = this.dialog.open(DepartmentCreateComponent, {
      width: "500px",
      data: department
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.loadDepartments();
    });
  };

  /**
   * on delete click.
   */
  onDeleteClick(departmentId: string) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "450px",
      data: {
        type: "Confirm",
        message: "Are you sure you want to delete this department ?"
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.api
          .request("DELETE_DEPARTMENT", {
            params: { DEPARTMENT_ID: departmentId }
          })
          .subscribe(res => {
            if (res.success) {
              this.loadDepartments();
            }
            this.toast.show(res['message']);
          });
    });
  };

  /**
  * on page change
  */
  onPageChange($event) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.loadDepartments();
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
// ];
