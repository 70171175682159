import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ApiService } from "src/app/shared/services/api.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UPSService {
  // public AccessLicenseNumber = '9D8C72B5E43ED9B2';
  // public userName = 'pacprint';
  // public password = 'Pacific1'
  public shipperNumber = 'E94080';
  // public shipperName = "Billy Blanks"
  // public shipperAttentionName = 'Billy Blanks';
  // public shipperPhone = '1231231233';
  // public shipperAddress = '366 Robin LN SE';
  // public shipperCity = 'Marietta';
  // public shipperStateProvinceCode = 'GA';
  // public shipperPostalCode = '30067';
  // public shipperCountryCode = 'US';

  UpsServices = [
    { code: '01', description: 'Next Day Air' },
    { code: '02', description: '2nd Day Air' },
    { code: '03', description: 'Ground' },
    { code: '07', description: 'Express' },
    { code: '08', description: 'Expedited' },
    { code: '11', description: 'UPS Standard' },
    { code: '12', description: '3 Day Select' },
    { code: '13', description: 'Next Day Air Saver' },
    { code: '14', description: 'UPS Next Day Air® Early' },
    { code: '17', description: 'UPS Worldwide Economy DDU' },
    { code: '54', description: 'Express Plus' },
    { code: '59', description: '2nd Day Air A.M' },
    { code: '65', description: 'UPS Saver' }
  ];

  packageTypes = [
    { code: '01', description: 'UPS Letter' },
    { code: '02', description: 'Customer Supplied Package' },
    { code: '03', description: 'Tube 04 = PAK' },
    { code: '21', description: 'UPS Express Box' },
    { code: '24', description: 'UPS 25KG Box' },
    { code: '25', description: 'UPS 10KG Box' },
    { code: '30', description: 'Pallet' },
    { code: '2a', description: 'Small Express Box' },
    { code: '2b', description: 'Medium Express Box' },
    { code: '2c', description: 'Large Express Box' },
    { code: '56', description: 'Flats' },
    { code: '57', description: 'Parcels' },
    { code: '58', description: 'BPM' },
    { code: '59', description: 'First Class' },
    { code: '60', description: 'Priority' },
    { code: '61', description: 'Machineables' },
    { code: '62', description: 'Irregulars' },
    { code: '63', description: 'Parcel Post' },
    { code: '64', description: 'BPM Parcel' },
    { code: '65', description: 'Media Mail' },
    { code: '66', description: 'BPM Flat' },
    { code: '67', description: 'Standard Flat' },
  ];

  // countryCode = [
  //   { code: 'AU', country: 'Australia' },
  //   { code: 'AT', country: 'Austria' },
  //   { code: 'BE', country: 'Belgium' },
  //   { code: 'CA', country: 'Canada' },
  //   { code: 'CN', country: 'China Mainland' },
  //   { code: 'DK', country: 'Denmark' },
  //   { code: 'DO', country: 'Dominican Republic' },
  //   { code: 'DO', country: 'Dominican Republic' },
  //   { code: 'FI', country: 'Finland' },
  //   { code: 'FR', country: 'France' },
  //   { code: 'DE', country: 'Germany' },
  //   { code: 'HK', country: 'Hong Kong SAR, China' },
  //   { code: 'IN', country: 'India' },
  //   { code: 'IN', country: 'India' },
  //   { code: 'ID', country: 'Indonesia' },
  //   { code: 'IT', country: 'Italy' },
  //   { code: 'MO', country: 'Macau SAR, China' },
  //   { code: 'IN', country: 'India' },
  // ]

  constructor(private api: ApiService,
    private toast: ToastService,
    private dialog: MatDialog,
    private http: HttpClient,
    private _sanitizer: DomSanitizer) {
  }

  public downloadLabel(trackingNumber: string) {
    var req = {
      "LabelRecoveryRequest": {
        "LabelSpecification": {
          "HTTPUserAgent": "",
          "LabelImageFormat": {
            "Code": "ZPL"
          },
          "LabelStockSize": {
            "Height": "6",
            "Width": "4"
          }
        },
        "Translate": {
          "LanguageCode": "eng",
          "DialectCode": "US",
          "Code": "01"
        },
        "LabelDelivery": {
          "LabelLinkIndicator": "",
          "ResendEMailIndicator": "",
          "EMailMessage": {
            "EMailAddress": ""
          }
        },
        "TrackingNumber": '1ZTESTAN8480418532',
      }
    }

    this.api
      .request(
        'LABEL_RECOVERY',
        {
          data: { 'LabelRecovery': req }
        })
      .subscribe(res => {
        if (res.response && res.response.errors && res.response.errors.length > 0) {
          this.labelError(res.response.errors[0].message);
        } else {
          this.download(res.LabelRecoveryResponse.LabelResults.LabelImage.GraphicImage, trackingNumber);
        }
      });
  }
  
  downloadFile(url) {
    return new Promise<File>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('responseType', 'blob');
      headers = headers.set('observe', 'response');

      this.http.get(
        url, {
        responseType: "blob",
        headers: headers
      }).subscribe((res: any) => {
        var imagefile = new File([res], "filename", {
          type: res['type'],
        });
        resolve(imagefile);
      }, error => { reject(null) });
    });
  }

  async createObjectUrl(imageFile) {
    return new Promise<string>((resolve, reject) => {
      var url = window.URL.createObjectURL(imageFile);
      resolve(url);
    });
  }

  public async downloadLabelFromServerAndShow(identificationNumbers: string[]) {

    if (!identificationNumbers) { return; }
    if (identificationNumbers.length == 0) { return; }

    const WindowPrt = await window.open('', '', 'width=800,height=600,left=200,top=200');
    var data = `
                <!DOCTYPE html>
                  <html>
                    <head>
                      <style>
                        @media print {
                          .onePage{
                            display:flex;
                            align-items: center;
                            justify-content: center;
                            width:100%;
                            height:100%; 
                          }

                          .pagebreak { page-break-before: always; }

                        }
                        
                        @page 
                        {
                          size:  auto;
                          margin: 0mm;  
                        }

                        html
                        { 
                          margin: 0px; 
                        }

                        body
                        { 
                        margin: 0px 0px 0px 0px;
                        }

                      </style>
                    </head>
                    <body>`

    for (const identificationNumber of identificationNumbers) {
      const resourceUrl = `${environment.baseUrl}/api/documents/shipmentlabel/${identificationNumber}`;

      var file = await this.downloadFile(resourceUrl);
      var ImageURL = await this.createObjectUrl(file);

      //Rotate Image
      var rotatedImageBase64Data = await this.rotateAsync(ImageURL, 90);
      var rotatedImageData = await this.fetchAsync(rotatedImageBase64Data);
      var rotatedImageBlob = await this.toBlobAsync(rotatedImageData);
      var rotatedImageURL = await this.createObjectUrl(rotatedImageBlob);

      //Crop Image
      var croppedImageBase64Data = await this.cropAsync(rotatedImageURL, 800, 1200,);
      var croppedImageData = await this.fetchAsync(croppedImageBase64Data);
      var croppedImageBlob = await this.toBlobAsync(croppedImageData);

      var croppedImageURL = await this.createObjectUrl(croppedImageBlob);


      data = data + `<div class="onePage pagebreak">
    <img src="${croppedImageURL}" id="image" width="384" height="576" alt=""/>
  </div>`;
    }


    data = data + `</body></html>`;

    await WindowPrt.document.write(data);
    await WindowPrt.document.close();
    WindowPrt.onload = function () {
      WindowPrt.focus();
      WindowPrt.print();
    };

  }

  fetchAsync(base64Data: string) {
    return new Promise<Response>((resolve, reject) => {
      fetch(base64Data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });

    })
  }

  toBlobAsync(data: Response) {
    return new Promise<Blob>((resolve, reject) => {
      data.blob().then(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
  }

  cropAsync(srcBase64, finalWidth, finalHeight) {
    return new Promise<string>((resolve, reject) => {
      const canvas = document.createElement('canvas');
      let ctx = canvas.getContext("2d");
      let image = new Image();

      image.onload = function () {
        canvas.width = finalWidth;
        canvas.height = finalHeight;

        ctx.drawImage(image, 0, 0, finalWidth, finalHeight, 0, 0, finalWidth, finalHeight);

        resolve(canvas.toDataURL());
      };

      image.src = srcBase64;
    });
  }

  rotateAsync(srcBase64, degrees) {
    return new Promise<string>((resolve, reject) => {
      const canvas = document.createElement('canvas');
      let ctx = canvas.getContext("2d");
      let image = new Image();

      image.onload = function () {

        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(degrees * Math.PI / 180);
        ctx.drawImage(image, image.width / -2, image.height / -2);

        resolve(canvas.toDataURL());
      };

      image.src = srcBase64;
    });
  }

  private labelError(message) {
    this.dialog.open(ConfirmDialogComponent, {
      height: '225px',
      width: '600px',
      data: {
        type: 'Info',
        message: message
      }
    });
  }

  private convertBase64ToBlobData(base64Data: string) {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/png' });
    return blob;
  }

  private download(base64Data: string, shipmentIdentificationNo: string): void {
    const blobData = this.convertBase64ToBlobData(base64Data);
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    var printWindow = window.open(url, '', 'width=800,height=600,left=200,top=200');
    printWindow.focus();
    printWindow.print();
    printWindow.document.close();
  }

}