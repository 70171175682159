<div class="container padding-15">
  <strong class="primary-text-color">Upload Address</strong>
  <div
    class="drag-drop"
    fxlayout="column"
    fxLayoutAlign="center center"
    (click)="fileInput.click()"
    pacificPrintingDragDrop
    (fileDropped)="selectCSV($event)"
  >
    <div class="dropzone" id="myDrop">
      <div fxLayoutAlign="center center" fxLayoutGap="2px">
        <mat-icon>note_add</mat-icon>
        <small class="primary-text-color"
          >Click/Drag files here to upload</small
        >
      </div>
      <input
        hidden
        type="file"
        #fileInput
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        (change)="selectCSV($event.target.files)"
      />
    </div>
  </div>
  <div class="grid" fxLayout="column" style="height: calc(100vh - 280px)">
    <span
      *ngIf="isLoading"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: calc(100vh - 280px)"
      class="primary-text-color"
    >
      Loading...</span
    >
    <div
      class="grid-header"
      *ngIf="excelData.length > 0"
      fxLayoutGap="2px"
      fxLayoutAlign="center center"
    >
      <small fxFlex="10"
        ><strong class="primary-text-color">Customer</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Company</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Attention</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Address</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">State</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">City</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">PostalCode</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">CountryCode</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Phone</strong></small
      >
      <small fxFlex="10"
        ><strong class="primary-text-color">Email</strong></small
      >
    </div>
    <div style="overflow: auto;">
      <div
        class="grid-row"
        fxLayoutGap="2px"
        fxLayoutAlign="center center"
        *ngFor="let data of excelData"
      >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="data['customer']"
          >{{ data["customer"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="data['company']"
          >{{ data["company"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="data['attention']"
          >{{ data["attention"] }}</small
        >
        <small
          fxFlex="10"
          class="text-ellipsis primary-text-color"
          [matTooltip]="data['address']"
          >{{ data["address"] }}</small
        >
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["stateProvinceCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["city"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["postalCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["countryCode"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["phone"]
        }}</small>
        <small fxFlex="10" class="text-ellipsis primary-text-color">{{
          data["email"]
        }}</small>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="end center"
    style="margin-top: 5px"
  >
    <button
      mat-raised-button
      color="primary"
      [disabled]="excelData.length === 0 || uploadLoading"
      (click)="upload()"
    >
      <div fxLayoutAlign="center center">
        <span> UPLOAD </span>
        <span *ngIf="uploadLoading" class="loader"></span>
      </div>
    </button>

    <button mat-raised-button (click)="this.dialog.closeAll()">
      <span> CLOSE</span>
    </button>
  </div>
</div>
