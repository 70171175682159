<div fxLayout="column" style="padding:20px 0px!important">
        <div fxLayout="row">
                <strong class="header-text" >{{title}} </strong>
            </div>
            <br>

  <div style="overflow: auto;"  fxFlex="370px"> 
  <form [formGroup]="mailingSortForm" fxLayout="column" autocomplete="off">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">

          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Weight</mat-label>
              <input matInput placeholder="Weight" formControlName="weight" pattern="[0-9]+(\.[0-9][0-9]?)?">
              <mat-error *ngIf="hasError('weight','pattern')">You must enter valid weight.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Width</mat-label>
              <input matInput placeholder="Width" formControlName="width" pattern="[0-9]+(\.[0-9][0-9]?)?" >
              <mat-error *ngIf="hasError('width','pattern')">You must enter valid width.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Height</mat-label>
              <input matInput placeholder="Height" formControlName="height" pattern="[0-9]+(\.[0-9][0-9]?)?" >
              <mat-error *ngIf="hasError('height','pattern')">You must enter valid height.</mat-error>
          </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px"  >
          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Thickness</mat-label>
              <input matInput placeholder="Thickness" formControlName="thickness" pattern="[0-9]+(\.[0-9][0-9]?)?" >
              <mat-error *ngIf="hasError('thickness','pattern')">You must enter valid thickness.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Post Office</mat-label>
              <input matInput placeholder="Post Office" formControlName="postOffice" pattern="^[0-9]*$" >
              <mat-error *ngIf="hasError('postOffice','pattern')">You must enter valid post office.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="30">
              <mat-label>Permit #</mat-label>
              <input matInput placeholder="Permit #" formControlName="permit">
          </mat-form-field>


      </div>

      <mat-divider fxLayoutGap="20px"></mat-divider>
      <br>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="letter">Letter</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="flat">Flat</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="postcard">Postcard</mat-checkbox>
          </div>
      </div>

      <br>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="automation">Automation</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="nonAuto">Non-Auto</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="nonMachinable">Non-Machinable</mat-checkbox>
          </div>
      </div>

      <br>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="nonProfit">Non-Profit</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="ancillarySvcs">Ancillary Svcs</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="stdPrsrt">Std Prsrt</mat-checkbox>
          </div>
      </div>

      <br>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="fullRateFirstClass">Full Rate First Class</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="firstClassPart"> First Class Part</mat-checkbox>
          </div>
          <div fxFlex="30">
              <mat-checkbox color="primary" formControlName="periodical">Periodical</mat-checkbox>
          </div>
      </div>

      <br>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">

          <div fxFlex="95" fxLayoutGap="30px">
              <span>Foreign?</span>
              <mat-radio-group fxLayoutGap="10px" formControlName="foreign"  >
                  <mat-radio-button  color="primary" [value]=true>Yes</mat-radio-button>
                  <mat-radio-button color="primary" [value]=false>No</mat-radio-button>
              </mat-radio-group>
          </div>
      </div>

      <br>

      <mat-divider fxLayoutGap="20px"></mat-divider>

      <br>
  </form>
</div>
  <mat-dialog-actions fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="end end">
          <button mat-raised-button class="mat-expansion-panel" type="submit" (click)="onSubmit()">SAVE</button>
          <button mat-button mat-dialog-close type="button">CANCEL</button>
      </mat-dialog-actions>
</div>