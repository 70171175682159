import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { PasswordConfirm } from '../user-create/PasswordConfirm';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: UntypedFormGroup;
  title = 'Create User';
  isShowPassword = false;
  isShowConfPassword = false;

  constructor(@Inject(MAT_DIALOG_DATA) public userId: string,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private apiService: ApiService,
    private toast: ToastService) { }

  ngOnInit() {
    this.form = this.initForm(this.formBuilder);
  }

  initForm = (formBuilder: UntypedFormBuilder): UntypedFormGroup =>
    formBuilder.group({
      password: ['', [Validators.required, Validators.minLength]],
      confirmPassword: ['', [Validators.required, Validators.minLength]]
    },
      { validator: PasswordConfirm.MatchPassword })

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false


  submit() {
    if (this.form.invalid) {
      return;
    }

    const requestObject = {
      id: this.userId,
      password: this.form.value.password
    }

    this.apiService
      .request('CHANGE_PASSWORD',
        {
          params: { USER_ID: this.userId }
          , data: requestObject
        })
      .subscribe(result => {
        this.dialogRef.close(true);
        this.toast.show(result['message'])
      }, error => { });
  }
}
