<div class="padding-15">
  <div class="loading primary-text-color" *ngIf="!pageLoad">Please wait...</div>
  <div style="overflow: auto" *ngIf="!isWorkflowView && pageLoad">
    <div fxLayout="column">
      <div fxLayout="column" fxLayoutGap="10px">
        <!-- Job Detail-->
        <div fxLayout="column" fxFlex="auto" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxLayout="column" fxFlex="20">
              <div fxLayoutGap="4px">
                <strong class="primary-text-color">
                  # {{ job.jobNumber }} - {{ job.title }}</strong
                >
                <small class="rush primary-text-color" *ngIf="job.isRush"
                  >Rush</small
                >
              </div>
              <small fxLayoutGap="4px">
                <strong class="primary-text-color">CSR: </strong>
                <span class="primary-text-color"
                  >{{ job.csrName }} ({{ job.csrNo }})</span
                ></small
              >
              <small fxLayoutGap="4px" class="warn">
                <strong class="warn">Date In:</strong>
                <span *ngIf="job.dateIn != '0001-01-01T00:00:00'" class="warn">
                  {{ job.dateIn | date : "mediumDate" }}</span
                >
              </small>
              <small fxLayoutGap="4px">
                <strong class="primary-text-color">Due on: </strong>
                <span
                  *ngIf="job.dateDue != '0001-01-01T00:00:00'"
                  [class.warn]="job.dateDue < today"
                  class="primary-text-color"
                >
                  {{ job.dateDue | date : "mediumDate" }}</span
                >
              </small>
              <small fxLayoutGap="4px">
                <strong class="primary-text-color">Job Items:</strong>
                <span class="primary-text-color">{{ job.totalItem }}</span>
              </small>
            </div>
            <div fxLayout="column" fxFlex="20">
              <div fxLayoutAlign="start center">
                <mat-icon>person</mat-icon>
                <strong class="primary-text-color">{{
                  job.customerName
                }}</strong>
              </div>
              <small fxLayoutGap="4px">
                <strong fxFlexOffset="4px" class="primary-text-color"
                  >Attention:</strong
                >
                <span class="primary-text-color">{{
                  job.shippingAttention
                }}</span>
              </small>
              <small fxLayoutGap="4px">
                <strong fxFlexOffset="4px" class="primary-text-color"
                  >Company:</strong
                >
                <span class="primary-text-color">{{
                  job.shippingCompany
                }}</span>
              </small>
              <small fxLayoutGap="4px">
                <strong
                  style="width: 200px"
                  class="primary-text-color"
                  fxLayoutGap="4px"
                >
                  <strong class="primary-text-color" fxFlexOffset="4px"
                    >Phone:</strong
                  >
                  <span class="primary-text-color">{{
                    job.isPrinterPlan
                      ? ((job.buyerInfo ? job.buyerInfo.phone : "")
                        | replaceChar)
                      : (job.shippingPhone | replaceChar)
                  }}</span>
                </strong>
                <strong
                  fxFlexOffset="4px"
                  *ngIf="job.isPrinterPlan"
                  class="primary-text-color"
                  >Ext:
                </strong>
                <span class="primary-text-color">{{ Extension }}</span>
              </small>
              <small fxLayoutGap="4px">
                <strong fxFlexOffset="4px" class="primary-text-color"
                  >Shipping Address:</strong
                >
                <span
                  style="white-space: pre-line"
                  class="primary-text-color"
                  >{{ job?.address }}</span
                >
              </small>
            </div>

            <div
              fxLayout="row"
              fxLayoutGap="8px"
              fxLayoutAlign="end start"
              fxFlex="60" style="padding-top: 3px;"
            >
              <!--Buttons Related To Printer Plan Jobs-->
              <div
                class="check-job-update"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="8px"
                *ngIf="isCheckingForJobUpdate"
              >
                <div class="content">Checking For Updates...</div>
                <span class="loader"></span>
              </div>
              <div
                class="check-job-update"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="8px"
                *ngIf="isUpdatingWithPP && !isCheckingForJobUpdate"
              >
                <div class="content">Job is Updating With PrinterPlan...</div>
                <span class="loader"></span>
              </div>
              
              <!--Buttons Related To Customer portal Jobs-->
              <div
                *ngIf="
                  !job.isPrinterPlan && job.canBypassPayment && isAdminOrCSR
                "
                class="bypass-payment"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="8px"
              >
                <mat-icon
                  class="bypass-payment-icon mat-icon"
                  [svgIcon]="BypassSvgIcon"
                ></mat-icon>
                <div class="content">Bypass Payment</div>
              </div>&nbsp;&nbsp;
              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  !job.isPrinterPlan &&
                  !isInvoiceCreated &&
                  !isPaid &&
                  isAdminOrCSR
                "
                (click)="createInvoice(true)"
                mat-raised-button
                color="primary"
              >
                <mat-icon style="margin-right: 4px">add</mat-icon>
                <span>CREATE INVOICE</span>
              </button>&nbsp;&nbsp;
              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  !job.isPrinterPlan &&
                  !isPaid &&
                  isInvoiceCreated &&
                  isAdminOrCSR
                "
                (click)="viewInvoice(true)"
                mat-raised-button
                color="primary"
              >
                <mat-icon style="margin-right: 4px">remove_red_eye</mat-icon>
                <span>VIEW INVOICE</span>
              </button>&nbsp;&nbsp;
              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  !job.isPrinterPlan &&
                  isInvoiceCreated &&
                  !isPaid &&
                  isAdminOrCSR
                "
                (click)="editInvoice(true)"
                mat-raised-button
                color="primary"
              >
                <mat-icon style="margin-right: 4px">edit</mat-icon>
                <span>EDIT INVOICE</span>
              </button>
              <div [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                style="line-height: 24px"
                class="link mr-12"
                *ngIf="
                  !job.isPrinterPlan &&
                  isInvoiceCreated &&
                  isPaid &&
                  isAdminOrCSR
                "
                (click)="viewInvoice(true)"
              >
                <div class="view-invoice-link">{{ invoiceLinkText }}</div>
              </div> &nbsp;&nbsp;

              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  job.isPrinterPlan &&
                  !isInvoiceCreated &&
                  !isPaid &&
                  isAdminOrCSR
                "
                (click)="createInvoice(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon>add</mat-icon>
                <span>CREATE INVOICE</span>
              </button>&nbsp;&nbsp;
              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  job.isPrinterPlan &&
                  isInvoiceCreated &&
                  !isPaid &&
                  isAdminOrCSR
                "
                (click)="viewInvoice(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon>remove_red_eye</mat-icon>
                <span>VIEW INVOICE</span>
              </button>&nbsp;&nbsp;
              <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                *ngIf="
                  job.isPrinterPlan &&
                  isInvoiceCreated &&
                  !isPaid &&
                  isAdminOrCSR
                "
                (click)="editInvoice(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon>edit</mat-icon>
                <span>EDIT INVOICE</span>
              </button>&nbsp;&nbsp;
              <div
                style="line-height: 24px"
                class="link mr-12"
                *ngIf="
                  job.isPrinterPlan &&
                  isInvoiceCreated &&
                  isPaid &&
                  isAdminOrCSR
                "
                (click)="viewInvoice(false)"
              >
                <div class="view-invoice-link">{{ invoiceLinkText }}</div>
              </div>

              <!--Buttons Common in Customer portal Job and Printer plan job-->
              <div fxLayout="column" fxLayoutGap="8px">
                <button [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                  *ngIf="job.remainingItemCount && isAdminOrCSR"
                  mat-stroked-button
                  (click)="importJobItem(false)"
                  color="primary"
                >
                  Import {{ job.remainingItemCount }} Remaining job(s)
                </button>
                <button
                  *ngIf="
                    !job.isPrinterPlan && job.remainingItemCount && isAdmin
                  "
                  mat-stroked-button
                  color="primary"
                  (click)="importJobItem(true)"
                >
                  Import {{ job.remainingItemCount }} Remaining job(s) To
                  Production
                </button>
              </div>
            </div>

            <div fxFlex="5" fxLayoutAlign="end start" fxLayoutGap="5px" style="padding-top:5px">
              <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu"
                >more_vert</mat-icon
              >
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                (click)="updateJobFromPP()">
                  <span>Update Job</span>
                </button>
              </mat-menu>
              <mat-icon class="cursor-pointer" (click)="dialogRef.close()"
                >close</mat-icon
              >
              <span fxFlex="8px"></span>
            </div>
          </div>
          <div>
            <mat-tab-group animationDuration="0ms"
              mat-stretch-tabs="false"
              [selectedIndex]="selectedJobItemIndex"
              (selectedTabChange)="onJobItemChange($event.index)"
              *ngIf="job?.items && job?.items.length"
            >
              <mat-tab
                [label]="item.description"
                *ngFor="let item of job?.items; let index = index"
                [disabled]="!isLoadJobItem"
              >
                <ng-template matTabContent>
                  <div class="item-container padding-10">
                    <div
                      class="detail"
                      fxLayout="row "
                      fxLayoutGap="10px"
                      fxLayoutAlign="start start"
                    >
                      <div fxLayout="row wrap" class="detail-item" fxFlex="65">
                        <mat-chip-listbox>
                          <mat-chip-option>
                            <strong>Quantity:</strong>
                            <span>{{ item.chargeQty }}</span>
                          </mat-chip-option>
                          <mat-chip-option>
                            <strong>Paper Color:</strong>
                            <span> {{ item.paperColor }}</span>
                          </mat-chip-option>
                          <mat-chip-option>
                            <strong>Stock:</strong>
                            <span> {{ item.stock }}</span>
                          </mat-chip-option>
                          <mat-chip-option>
                            <strong>Size:</strong>
                            <span> {{ item.finishSize }}</span>
                          </mat-chip-option>
                          <mat-chip-option>
                            <strong>Side1Color: </strong>
                            <span> {{ item.side1Color }}</span>
                          </mat-chip-option>
                          <mat-chip-option>
                            <strong>Side2Color: </strong>
                            <span> {{ item.side2Color }}</span>
                          </mat-chip-option>

                          <mat-chip-option *ngFor="let cut of item.cutServices">
                            <strong>Cut: </strong>
                            <span> {{ cut }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let fold of item.foldServices"
                          >
                            <strong>Fold: </strong>
                            <span> {{ fold }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let collate of item.collateServices"
                          >
                            <strong>Collate: </strong>
                            <span> {{ collate }}</span>
                          </mat-chip-option>

                          <mat-chip-option *ngFor="let pad of item.padServices">
                            <strong>Pad: </strong>
                            <span> {{ pad }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let drill of item.drillServices"
                          >
                            <strong>Drill: </strong>
                            <span> {{ drill }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let bind of item.bindServices"
                          >
                            <strong>Bind: </strong>
                            <span> {{ bind }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let mail of item.mailServices"
                          >
                            <strong>Mail: </strong>
                            <span> {{ mail }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            *ngFor="let udbyhr of item.userDefinedServices"
                          >
                            <strong>User defined 4 by HR: </strong>
                            <span> {{ udbyhr }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            class="spec-note"
                            *ngFor="let note of item.foldNotes"
                          >
                            <strong>Fold: </strong>
                            <span> {{ note }}</span>
                          </mat-chip-option>

                          <mat-chip-option
                            class="spec-note"
                            *ngFor="let note of item.outsourcingNotes"
                          >
                            <strong>Outsourcing: </strong>
                            <span> {{ note }}</span>
                          </mat-chip-option>
                        </mat-chip-listbox>
                      </div>
                      <span fxFlex></span>

                      <div
                        class="rush-container"
                        fxLayoutAlign="start center"
                        fxLayoutGap="20px"
                        fxFlexOffset="10px"
                      >
                        <mat-checkbox
                          *ngIf="item['isUnion']"
                          color="primary"
                          [checked]="item['isUnion']"
                          (click)="$event.preventDefault()"
                        >
                          Union Bug
                        </mat-checkbox>

                        <div
                          fxLayout="row"
                          *ngIf="isAdminOrCSR"
                          fxLayoutAlign="start center"
                        >
                          <div fxLayout="column" style="width: 205px">
                            <small
                              *ngIf="item.rushDate"
                              class="primary-text-color"
                              >RushDate</small
                            >
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <input
                                matInput
                                readonly
                                [(ngModel)]="item.rushDate"
                                [min]="minDate"
                                [owlDateTime]="dt2"
                                placeholder="Rush date"
                                (ngModelChange)="
                                  updateRushDate(item.jobItemId, item.rushDate)
                                "
                                [disabled]="(item.status === 2 && !isAdminOrCSR) || isCheckingForJobUpdate||isUpdatingWithPP"
                                [required]="false"
                                style="font-size: 13px"
                              />
                              <mat-icon
                                matSuffix
                                [owlDateTimeTrigger]="dt2"
                                class="mat-icon"
                                >date_range</mat-icon
                              >
                              <owl-date-time [ariaDisabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                                class="pointer"
                                [hour12Timer]="true"
                                #dt2
                              ></owl-date-time>
                            </div>
                          </div>
                        </div>

                        <mat-icon 
                          style="width: 30px"
                          class="pointer"
                          (click)="editJobItem(item.jobItemId)"
                          *ngIf="item.status !== 2 || isAdminOrCSR"
                          >edit
                        </mat-icon>
                      </div>
                    </div>

                    <div fxLayout="row" class="padding-5" id="stepper-content">
                      <mat-list fxFlex="48">
                        <mat-vertical-stepper
                          #stepper
                          [selectedIndex]="item.selectedStepIndex"
                          disableRipple="true"
                          color="accent"
                          class="padding-top-5px"
                        >
                          <ng-template matStepperIcon="edit">
                            <mat-icon class="mat-icon">check</mat-icon>
                          </ng-template>

                          <mat-step
                            [completed]="item.selectedStepIndex > index"
                            [editable]="true"
                            *ngFor="
                              let action of item.stepActions;
                              let index = index
                            "
                            color="primary"
                          >
                            <ng-template matStepLabel>
                              <div
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                fxLayoutGap="5px"
                              >
                                <span
                                  fxFlex="300px"
                                  class="header"
                                  matTooltip="{{
                                    action.stepName | titlecase
                                  }}/{{ action.actionName | titlecase }}"
                                  ><b
                                    >{{ action.stepName | titlecase }} /
                                    {{ action.actionName | titlecase }}
                                  </b></span
                                >
                                <span
                                  fxLayout="column"
                                  fxLayoutGap="5px"
                                  *ngIf="action.completedBy != ''"
                                >
                                  <small
                                    ><b
                                      >{{ action.completedBy }} at
                                      {{
                                        action.totalCompletionTime | formateTime
                                      }}</b
                                    ></small
                                  >
                                  <small
                                    ><b
                                      >Completed on
                                      {{
                                        action.completedAt
                                          | date : "MM/dd/yyyy h:mm a"
                                      }}</b
                                    ></small
                                  >
                                </span>
                              </div>
                            </ng-template>

                            <div fxLayout="column" fxFlex fxLayoutGap="20px">
                              <div
                                fxLayout="row"
                                fxLayoutGap="10px"
                                fxLayoutAlign="start center"
                                fxFlexOffset="25px"
                              >
                                <strong class="primary-text-color">{{
                                  stepCounter | formateTime
                                }}</strong>
                                <small
                                  *ngIf="action?.startedAt"
                                  class="primary-text-color"
                                  >(Started on
                                  {{
                                    action.startedAt | date : "medium"
                                  }})</small
                                >
                                <small
                                  *ngIf="!action.startedAt"
                                  class="primary-text-color"
                                  >(Started on
                                  {{ today | date : "medium" }})</small
                                >
                              </div>
                              <div fxLayout fxFlex fxLayoutGap="20px">
                                <div fxFlex fxLayoutGap="4px">
                                  <strong class="primary-text-color"
                                    >Step notes</strong
                                  >
                                  <ng-template
                                    *ngTemplateOutlet="
                                      notesTemplate;
                                      context: {
                                        jobNotes: action,
                                        showRFPNotification:
                                          action.stepName &&
                                          action.actionName &&
                                          action.stepName.toLowerCase() ==
                                            'sales' &&
                                          action.actionName.toLowerCase() ==
                                            'will call'
                                      }
                                    "
                                  >
                                  </ng-template>
                                </div>
                              </div>
                              <br />
                              <div
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                              >
                                <div
                                  fxLayout="row"
                                  fxLayoutGap="10px"
                                  *ngIf="job.status !== 3"
                                >
                                  <button
                                    [disabled]="
                                      !canAccess(action.stepDepartment) ||
                                      !action.allowMarkAsComplete || isCheckingForJobUpdate ||isUpdatingWithPP
                                    "
                                    mat-raised-button
                                    (click)="onClickMarkComplete(action, item)"
                                    color="primary"
                                  >
                                    <mat-icon>done_all</mat-icon>
                                    <div fxLayoutAlign="center center">
                                      <span>
                                        Mark As Completed &nbsp;&nbsp;</span
                                      >
                                      <span
                                        *ngIf="stepCompleteLoading"
                                        class="loader"
                                      ></span>
                                    </div>
                                  </button>

                                  <button
                                    mat-stroked-button
                                    [disabled]="
                                      !canAccess(action.stepDepartment) ||
                                      job.status === 3 ||
                                      !action.allowSendTo ||
                                      item['AllowSendToInDesign'] || isCheckingForJobUpdate
                                      || isUpdatingWithPP
                                    "
                                    (click)="onSendToClick(item['jobItemId'])"
                                    color="primary"
                                  >
                                    <div fxLayoutAlign="center center">
                                      <span> Send to &nbsp;&nbsp;</span>
                                      <span
                                        *ngIf="item.stepChangeLoading"
                                        class="loader"
                                      ></span>
                                    </div>
                                  </button>

                                  <button 
                                    mat-stroked-button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                    *ngIf="item.visibleSwitchFileUpload"
                                    (click)="openSwitchFilesUploadModal(item)"
                                    color="primary"
                                  >
                                    <mat-icon>cloud_upload</mat-icon>
                                    <div fxLayoutAlign="center center">
                                      <span> Move To Swicth &nbsp;&nbsp;</span>
                                    </div>
                                  </button>
                                </div>
                                <div>
                                  <button
                                    mat-icon-button
                                    [disabled]="
                                      !canAccess(action.stepDepartment) ||
                                      isCheckingForJobUpdate || isUpdatingWithPP
                                    "
                                    [matMenuTriggerFor]="menu"
                                    *ngIf="
                                      action.mailingSort != null ||
                                      action.mailingInfo != null
                                    "
                                    color="primary"
                                  >
                                    <mat-icon>remove_red_eye</mat-icon>
                                  </button>

                                  <mat-menu #menu="matMenu" yPosition="above">
                                    <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                      *ngIf="action.mailingSort != null"
                                      mat-menu-item
                                      (click)="onShowingInfo(action, 'sort')"
                                    >
                                      Mailing Sort
                                    </button>
                                    <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                      *ngIf="action.mailingInfo != null"
                                      mat-menu-item
                                      (click)="onShowingInfo(action, 'info')"
                                    >
                                      Mailing Info
                                    </button>
                                  </mat-menu>
                                </div>
                              </div>
                            </div>
                          </mat-step>

                          <!--Done mat step-->
                          <mat-step [editable]="false">
                            <ng-template matStepLabel><b>Done</b></ng-template>
                          </mat-step>
                        </mat-vertical-stepper>
                      </mat-list>
                      <!-- Devide job steps and job files and note -->
                      <div fxFlex="5" fxFlexOffset="3.5">
                        <div fxFlex="1px" class="divider"></div>
                      </div>
                      <!-- Job files and notes -->
                      <div fxFlex="55" fxLayout="column">
                        <mat-tab-group animationDuration="0ms"
                          mat-stretch-tabs="false"
                          fxFlexOffset="8px"
                          (selectedTabChange)="noteFilesTabIndex = $event.index"
                        >
                          <!-- Jobs notes -->
                          <mat-tab label="Job notes" class="primary-text-color" [disabled]="isCheckingForJobUpdate || isUpdatingWithPP">
                            <br />

                            <!-- Filter menu button for job notes-->
                            <div   fxLayoutAlign="end center" >
                              <!-- Dislpay selected filter and remover selected filter -->

                              <!-- <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                mat-stroked-button
                                [matMenuTriggerFor]="menuNotes"
                                matTooltip="Filter"
                                color="primary" fxLayout="row" fxLayoutAlign="center"
                              >
                              <div fxLayoutAlign="space-between center">
                                <strong
                                  *ngIf="!showAllNotes"
                                  class="primary-text-color"
                                  >Job notes</strong
                                >
                                <strong
                                  *ngIf="showAllNotes"
                                  class="primary-text-color"
                                  >All notes</strong
                                >
                                <span>
                                <mat-icon class="mat-icon"
                                  >keyboard_arrow_down</mat-icon
                                >
                                </span>
                              </div>
                              </button>
                              <mat-menu #menuNotes="matMenu">
                                <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                  mat-menu-item
                                  (click)="showAllNotes = !showAllNotes"
                                  class="primary-text-color grid-header-bg" fxLayout="row" fxLayoutAlign="center center"
                                >
                                <div fxLayoutAlign="space-between center">
                                  <span>Job notes</span>&nbsp;&nbsp;
                                  <span *ngIf="!showAllNotes">
                                    <mat-icon
                                    color="primary"
                                    class="mat-icon"
                                    >check_circle</mat-icon
                                  >
                                  </span>
                                </div>
                                </button>
                                <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                  mat-menu-item
                                  (click)="showAllNotes = !showAllNotes"
                                  class="primary-text-color grid-header-bg" fxLayout="row" fxLayoutAlign="center center"
                                >
                                <div fxLayoutAlign="space-between center">
                                 <span> All notes</span>&nbsp;&nbsp;
                                 <span *ngIf="showAllNotes">
                                  <mat-icon
                                    color="primary"
                                    class="mat-icon"
                                    >check_circle</mat-icon
                                  >
                                  </span>
                                </div>
                                </button>
                              </mat-menu> -->
                            <div fxLayout="row" fxLayoutAlign="end center" fxFlex="28">
                              <mat-select class="select-job-note primary-text-color" [(ngModel)]="selectJobNote" [disabled]="isCheckingForJobUpdate || isUpdatingWithPP">
                                <mat-option #matOption1 value="Job Notes" (click)="toggleAllNotes(matOption1.value)">
                                  <span clas="primary-text-color">Job Notes</span>
                                </mat-option>
                                <mat-option #matOption2 value="All notes" (click)="toggleAllNotes(matOption2.value)">
                                  <span clas="primary-text-color">All notes</span>
                                </mat-option>
                              </mat-select>
                            </div>
                            </div>

                            <!-- job notes -->
                            <small class="primary-text-color"
                              ><strong> Job notes</strong></small
                            >
                            <ng-template
                              *ngTemplateOutlet="
                                notesTemplate;
                                context: {
                                  jobNotes: item,
                                  showRFPNotification:
                                    item['stepActions'] &&
                                    item['stepActions'][
                                      item.currentStepIndex
                                    ] &&
                                    item['stepActions'][item.currentStepIndex][
                                      'stepName'
                                    ].toLowerCase() == 'sales' &&
                                    item['stepActions'][item.currentStepIndex][
                                      'actionName'
                                    ].toLowerCase() == 'will call'
                                }
                              "
                            >
                            </ng-template>

                            <!-- Show all steps notes -->
                            <div *ngIf="showAllNotes">
                              <br />
                              <div *ngFor="let action of item.stepActions">
                                <small class="primary-text-color">
                                  <strong
                                    >{{ action.stepName | titlecase }} /
                                    {{ action.actionName | titlecase }}
                                  </strong>
                                </small>
                                <ng-template
                                  *ngTemplateOutlet="
                                    notesTemplate;
                                    context: {
                                      jobNotes: action,
                                      showRFPNotification:
                                        action.stepName &&
                                        action.actionName &&
                                        action.stepName.toLowerCase() ==
                                          'sales' &&
                                        action.actionName.toLowerCase() ==
                                          'will call'
                                    }
                                  "
                                >
                                </ng-template>
                                <br />
                              </div>
                            </div>
                            <br />
                            <!-- add new note of step or job -->
                            <div *ngIf="job.status != 'complete'">
                              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                <mat-form-field
                                  appearance="outline"
                                  fxFlex="80"
                                  class="jobdetails" 
                                >
                                  <textarea
                                    style="white-space: pre-wrap"
                                    matInput
                                    placeholder="Leave a Note"
                                    rows="4"
                                    [(ngModel)]="jobNote"
                                    name="jobNote"
                                    class="primary-text-color"
                                  ></textarea>
                                </mat-form-field>

                                  <button 
                                    [disabled]="addNoteLoading || isCheckingForJobUpdate 
                                    || isUpdatingWithPP"
                                     mat-raised-button color="primary"
                                    (click)="addNote(item)" fxFlex="20" class="margin-top-15"
                                  >
                                    <mat-icon >add</mat-icon>
                                    <div fxLayoutAlign="center center">
                                      <span >
                                        Add Note </span
                                      >
                                      <span
                                        *ngIf="addNoteLoading"
                                        class="loader"
                                      ></span>
                                    </div>
                                  </button>
                              </div>
                              <!-- validation message -->
                              <div *ngIf="!addNoteValidate">
                                <small class="warn">You must enter note</small>
                                <br /><br />
                              </div>
                              <div fxLayout="row" fxLayoutGap="30px">
                                <mat-select 
                                  class="select-step"
                                  fxFlex="40"
                                  [(ngModel)]="selectedNoteStep"
                                >
                                  <mat-option [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                                    value=""
                                    class="secondary-bg-color primary-text-color"
                                    >Select step (Optional)</mat-option
                                  >
                                  <mat-option [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                                    *ngFor="let jobStep of jobSteps"
                                    [value]="jobStep.index"
                                    class="grid-header-bg primary-text-color"
                                  >
                                    {{ jobStep.step | titlecase }} /
                                    {{ jobStep.action | titlecase }}</mat-option
                                  >
                                </mat-select>
                                <div
                                  fxFlex="32"
                                  fxLayoutAlign="center center"
                                  fxLayoutGap="2px"
                                >
                                  <small class="primary-text-color"
                                    >Priority:
                                  </small>
                                  <mat-select 
                                    class="select-step"
                                    [(ngModel)]="notePriority"
                                  >
                                    <mat-option [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                                      value="high"
                                      class="grid-header-bg primary-text-color"
                                    >
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                      >
                                        <span class="high"></span>
                                        <span>High (Alert)</span>
                                      </div>
                                    </mat-option>
                                    <mat-option [disabled]="isCheckingForJobUpdate||isUpdatingWithPP"
                                      value="medium"
                                      class="grid-header-bg primary-text-color"
                                    >
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                      >
                                        <span class="medium"></span>
                                        <span>Medium</span>
                                      </div>
                                    </mat-option>
                                    <mat-option
                                      value="low"
                                      class="grid-header-bg primary-text-color"
                                    >
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                      >
                                        <span class="low"></span>
                                        <span>Low</span>
                                      </div>
                                    </mat-option>
                                  </mat-select>
                                </div>
                                <!-- <div fxFlex="30" fxLayoutAlign="center center">
                                  <button
                                    [disabled]="addNoteLoading || isCheckingForJobUpdate 
                                    || isUpdatingWithPP"
                                    mat-button
                                    color="primary"
                                    (click)="addNote(item)"
                                    style="width: 100%"
                                  >
                                    <div fxLayoutAlign="center center">
                                      <mat-icon class="mat-icon">add</mat-icon>
                                      <span class="primary-text-color">
                                        Add Note &nbsp;&nbsp;</span
                                      >
                                      <span
                                        *ngIf="addNoteLoading"
                                        class="loader"
                                      ></span>
                                    </div>
                                  </button>
                                </div> -->
                              </div>
                              <br />
                              <div
                                class="error"
                                *ngIf="
                                  !this.canAccessAddNoteAndFile() &&
                                  item['status'] != 2
                                "
                              >
                                You do not have permission to add note.
                              </div>
                            </div>
                          </mat-tab>

                          <!-- job files -->
                          <mat-tab label="Job files" [disabled]="isCheckingForJobUpdate || isUpdatingWithPP">
                            <br />
                            <div style="height:600px">
                              <small class="primary-text-color"
                                ><strong>Job files</strong></small
                              >
                              <!-- job files -->
                              <ng-template
                                *ngTemplateOutlet="
                                  filesTemplate;
                                  context: { item: item }
                                "
                              >
                              </ng-template>
                              <br />

                              <div *ngIf="job.status != 'complete'">
                                <!-- File Drag And Drop Section Start  -->
                                <div
                                  class="drag-drop"
                                  fxlayout="column"
                                  fxLayoutAlign="center center"
                                  (click)="fileInput.click()"
                                  pacificPrintingDragDrop
                                  (fileDropped)="selectFileToUpload($event)"
                                >
                                  <div class="dropzone" id="myDrop">
                                    <div fxLayout="column">
                                      <small class="primary-text-color"
                                        >Click/Drag files here to upload</small
                                      >
                                    </div>
                                    <input
                                      hidden
                                      type="file"
                                      #fileInput
                                      (change)="
                                        selectFileToUpload($event.target.files)
                                      "
                                      multiple
                                    />
                                  </div>
                                </div>

                                <div *ngIf="!addFileValidate">
                                  <small class="warn">You must select file.</small>
                                  <br /><br />
                                </div>
                                <!-- File Drag And Drop Section End  -->

                                <div
                                  class="job-files"
                                  fxLayout="column"
                                  *ngIf="selectedUplodefiles.length > 0"
                                  style="margin-bottom: 5px"
                                >
                                  <div style="overflow: auto" fxLayout="column">
                                    <ng-container
                                      *ngFor="
                                        let selectedFile of selectedUplodefiles;
                                        let index = index
                                      "
                                    >
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        class="example-box"
                                      >
                                        <div
                                          fxFlex
                                          fxLayoutAlign="start center"
                                          fxLayoutGap="8px"
                                        >
                                          <img
                                            fxFlex="25px"
                                            [src]="selectedFile.url"
                                            accept="image/x-png,image/gif,image/jpeg"
                                          />
                                          <small
                                            fxFlex="auto"
                                            class="primary-text-color"
                                            >{{ selectedFile.file.name }}</small
                                          >
                                          <span fxFlex></span>
                                          <!-- <mat-checkbox fxFlex="85px" color="primary"  [(ngModel)]="selectedFile['isUnionBug']"  >
              <small>Union Bug</small>
              </mat-checkbox> -->
                                        </div>
                                        <ng-container matColumnDef="action">
                                          <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                                            mat-icon-button
                                            (click)="removeSelectedFile(index)"
                                          >
                                            <mat-icon class="mat-icon"
                                              >delete</mat-icon
                                            >
                                          </button>
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <div
                                  fxLayout="row"
                                  fxLayoutGap="10px"
                                  fxLayoutAlign="end center"
                                >
                                  <div fxLayoutAlign="center center">
                                    <mat-checkbox
                                      class="primary-text-color"
                                      color="primary"
                                      [(ngModel)]="isProofFile"
                                      style="margin-top: -4px"
                                      *ngIf="IsProofCheckBoxVisible()"
                                    >
                                      <small
                                        class="primary-text-color"
                                        style="padding-right: 10px"
                                      >
                                        Proof File</small
                                      >
                                    </mat-checkbox>
                                    <mat-checkbox
                                      color="primary"
                                      [(ngModel)]="isPrintFile"
                                      style="margin-top: -4px"
                                      *ngIf="IsPrintCheckBoxVisible()"
                                    >
                                      <small class="primary-text-color">
                                        Print File</small
                                      >
                                    </mat-checkbox>
                                    <button
                                      fxFlex="150px"
                                      [disabled]="addFileLoading || isCheckingForJobUpdate || isUpdatingWithPP"
                                      mat-stroked-button
                                      color="primary"
                                      (click)="uploadFiles(item)"
                                    >
                                      <div
                                        fxLayoutAlign="center center"
                                        fxLayoutGap="10px"
                                      >
                                        <mat-icon class="mat-icon"
                                          >cloud_upload</mat-icon
                                        >
                                        <span class="primary-text-color">
                                          Upload</span
                                        >
                                        <span
                                          *ngIf="addFileLoading"
                                          class="loader"
                                        ></span>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  class="error"
                                  *ngIf="
                                    !this.canAccessAddNoteAndFile() &&
                                    item['status'] != 2
                                  "
                                >
                                  You do not have permission to upload files.
                                </div>
                                <div
                                  class="error"
                                  *ngIf="showPreviewUnavailableMsg"
                                >
                                  Preview is unavailable.
                                </div>
                                <div
                                  class="error"
                                  *ngIf="showDepartmentChangeMsg"
                                >
                                  Please change Department.
                                </div>
                              </div>
                          </div>
                          </mat-tab>

                          <!--History-->
                          <mat-tab label="History" [disabled]="isCheckingForJobUpdate || isUpdatingWithPP">
                            <app-job-history
                              [tabId]="noteFilesTabIndex"
                              [jobItemId]="item.jobItemId"
                              [steps]="jobSteps"
                            >
                            </app-job-history>
                          </mat-tab>

                          <!--Chat-->
                          <mat-tab 
                            label="Chat"
                            *ngIf="!job.isPrinterPlan && isAdminOrCSR"
                          >
                            <app-job-chat
                              [JobItem]="item"
                              (ShowPreview)="onShowChatDocumentPreview($event)"
                            ></app-job-chat>
                          </mat-tab>

                          <!--Printer Plan Job Item Chat-->
                          <mat-tab [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
                            label="Chat"
                            *ngIf="job.isPrinterPlan && isAdminOrCSR"
                          >
                          <div fxLayout="column" fxFlex="100">
                            <app-printer-plan-job-item-chat
                              [JobItem]="item"
                              (ShowPreview)="onShowChatDocumentPreview($event)"
                            ></app-printer-plan-job-item-chat>
                          </div>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- open document -->
  <div *ngIf="isShowFile" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
      <div fxFlex="auto" style="display: flex">
        <mat-icon
          style="cursor: pointer; margin-right: 5px"
          (click)="onFileViewClose()"
          >arrow_back</mat-icon
        >
        <strong> {{ file.fileName }} </strong>
      </div>
      <div fxFlex fxLayoutAlign="end start">
        <mat-icon style="cursor: pointer" (click)="onFileDownload(file)"
          >get_app</mat-icon
        >
      </div>
    </div>
    <ng2-pdfjs-viewer
      *ngIf="docType === 'Pdf'"
      class="pdf-viewer"
      [downloadFileName]="file.fileName"
      [pdfSrc]="fileURL"
      [zoom]="100"
      viewerId="PacificPrinting"
    >
    </ng2-pdfjs-viewer>
    <div *ngIf="docType.toLowerCase() === 'image'" class="doc">
      <div style="overflow: auto">
        <div *ngIf="isFilePreviewLoading">Loading</div>
        <img [src]="fileURL" [alt]="fileURL" id="myImage" />
      </div>
    </div>
    <div *ngIf="docType.toLowerCase() === 'svg'" class="doc">
      <div style="overflow: auto">
        <app-svg-preview
          [svgImageResourceURL]="fileResourceUrl"
        ></app-svg-preview>
      </div>
    </div>
    <div
      *ngIf="
        docType.toLowerCase() === 'tiff' || docType.toLowerCase() === 'tif'
      "
      class="doc"
    >
      <div style="overflow: auto">
        <app-tiff-preview
          [TiffImageResourceURL]="fileResourceUrl"
        ></app-tiff-preview>
      </div>
    </div>
    <div *ngIf="docType.toLowerCase() === 'excel'" class="doc">
      <app-excel-preview
        [excelFileResourceUrl]="excelFileResourceURL"
      ></app-excel-preview>
    </div>
  </div>

  <div *ngIf="isChatOrderDocShowPreview" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
      <div fxFlex="auto" style="display: flex">
        <mat-icon
          style="cursor: pointer; margin-right: 5px"
          (click)="onCloseChatDocumentPreview()"
          >arrow_back
        </mat-icon>
        <strong> {{ chatOrderDocPreviewDocument["name"] }} </strong>
      </div>
      <div fxFlex fxLayoutAlign="end start">
        <mat-icon
          style="cursor: pointer"
          (click)="downloadChatOrderDoc(chatOrderDocPreviewDocument)"
          >get_app</mat-icon
        >
      </div>
    </div>
    <ng2-pdfjs-viewer
      *ngIf="chatOrderDocPreviewDocument['type'] === 'Pdf'"
      class="pdf-viewer"
      [downloadFileName]="chatOrderDocPreviewDocument['name']"
      [pdfSrc]="chatOrderDocPreviewUrl"
      [zoom]="100"
      viewerId="PacificPrinting"
    >
    </ng2-pdfjs-viewer>
    <div
      *ngIf="chatOrderDocPreviewDocument['type'].toLowerCase() === 'image'"
      class="doc"
    >
      <div style="overflow: auto">
        <img [src]="chatOrderDocPreviewUrl" [alt]="chatOrderDocPreviewUrl" />
      </div>
    </div>
    <div
      *ngIf="chatOrderDocPreviewDocument['type'].toLowerCase() === 'svg'"
      class="doc"
    >
      <div style="overflow: auto">
        <app-svg-preview
          [svgImageResourceURL]="chatOrderDocPreviewUrl"
        ></app-svg-preview>
      </div>
    </div>
    <div
      *ngIf="
        chatOrderDocPreviewDocument['type'].toLowerCase() === 'tiff' ||
        chatOrderDocPreviewDocument['type'].toLowerCase() === 'tif'
      "
      class="doc"
    >
      <div style="overflow: auto">
        <app-tiff-preview
          [TiffImageResourceURL]="chatOrderDocPreviewUrl"
        ></app-tiff-preview>
      </div>
    </div>
    <div
      *ngIf="chatOrderDocPreviewDocument['type'].toLowerCase() === 'excel'"
      class="doc"
    >
      <app-excel-preview
        [excelFileResourceUrl]="chatOrderDocPreviewUrl"
      ></app-excel-preview>
    </div>
  </div>
</div>
<!-- notes temlate -->
<ng-template
  #notesTemplate
  let-jobNotes="jobNotes"
  let-showRFPNotification="showRFPNotification"
>
  <div class="job-notes" fxLayout="column">
    <div
      fxFlex
      fxLayoutAlign="center center"
      *ngIf="
        (jobNotes?.notes.length == 0 &&
          this.job.items[this.selectedJobItemIndex]['rfpNotifications']
            .length == 0 &&
          showRFPNotification) ||
          (jobNotes?.notes.length == 0 && !showRFPNotification);
        else elseNotesTemplate
      "
    >
      <small class="primary-text-color"> No notes available. </small>
    </div>
    <div style="overflow: auto" fxLayout="column">
      <ng-container *ngIf="showRFPNotification">
        <ng-container
          *ngFor="
            let rfpNotification of this.job.items[this.selectedJobItemIndex][
              'rfpNotifications'
            ];
            let i = index
          "
        >
          <div
            fxLayout="row"
            fxFlex="100"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            class="example-box example-box-note"
          >
            <div class="low"></div>
            <span fxFlex class="preserve-spaces primary-text-color"
              >Please provide approval to share 'ready for pickup'
              notification/status with the customer</span
            >
            <ng-container
              *ngIf="
                rfpNotification[
                  'isConfirmationRequiredReadyForPickupNotification'
                ] &&
                !this.job.items[this.selectedJobItemIndex][
                  'isLoadingStatusForReadyForPickupNotification'
                ]
              "
            >
              <mat-icon
                fxFlexOffset="3px"
                style="cursor: pointer"
                class="approve-icon approve-document"
                matTooltip="Click to Approve."
                (click)="
                  onApproveSendNotificationForReadyForPickup(rfpNotification)
                "
                >assignment_turned_in</mat-icon
              >
              <mat-icon
                class="approve-icon reject-document"
                fxFlexOffset="3px"
                style="cursor: pointer"
                matTooltip="Click to Reject."
                (click)="
                  onRejectSendNotificationForReadyForPickup(rfpNotification)
                "
                >disabled_by_default</mat-icon
              >
            </ng-container>
            <ng-container
              *ngIf="
                !rfpNotification[
                  'isCsrAdminApprovedSendReadyForPickupNotification'
                ] &&
                !rfpNotification[
                  'isCsrAdminRejectedSendReadyForPickupNotification'
                ] &&
                this.job.items[this.selectedJobItemIndex][
                  'isLoadingStatusForReadyForPickupNotification'
                ]
              "
            >
              <span class="loader"></span>
            </ng-container>
            <div
              fxLayout="column"
              fxLayoutGap="2px"
              *ngIf="
                rfpNotification['isCsrAdminApprovedOrRejected'] &&
                rfpNotification['csrOrAdminApprovedOrRejected']
              "
            >
              <small fxFlex class="primary-text-color">
                By
                {{ rfpNotification["csrOrAdminApprovedOrRejected"].firstName }}
                {{ rfpNotification["csrOrAdminApprovedOrRejected"].lastName }}
              </small>
              <small fxFlex class="primary-text-color">
                {{ rfpNotification.modifiedAt | date : "medium" }}
              </small>
            </div>
            <mat-icon
              class="approve-icon approve-document"
              matTooltip="Aproved."
              style="cursor: pointer"
              *ngIf="
                rfpNotification[
                  'isCsrAdminApprovedSendReadyForPickupNotification'
                ]
              "
              >done_all
            </mat-icon>
            <mat-icon
              class="approve-icon reject-document"
              matTooltip="Rejected."
              style="cursor: pointer"
              *ngIf="
                rfpNotification[
                  'isCsrAdminRejectedSendReadyForPickupNotification'
                ]
              "
              >remove_done
            </mat-icon>
          </div>
        </ng-container>
      </ng-container>

      <ng-template
        #elseNotesTemplate
      >
      <div fxLayout="column" fxFlex="100">
        <ng-container *ngFor="let note of jobNotes.notes; let i = index">
          <div
          fxLayout="row"
          fxFlex="100"
          fxLayoutAlign="start start"
          fxLayoutGap="8px"
          class="example-box example-box-note"
          [class.separate]="i != jobNotes.notes.length - 1"
        >
        <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
          <div fxFlex="1"
            [class.high]="note.priority === 'high'"
            [class.medium]="note.priority === 'medium'"
            [class.low]="note.priority === 'low'"
          ></div>
          <div fxFlex="80">
            <div  class="step-note">
              <span class="preserve-spaces primary-text-color"
                >{{ note.text }}
              </span>
            </div>
          </div>
          <div fxFlex="15"
            fxLayout="column"
            fxLayoutGap="2px"
            *ngIf="note.type != 'SYSTEM_GENERATED'"
          >
            <small fxFlex class="primary-text-color">
              By {{ note.createdBy }}
            </small>
            <small fxFlex class="primary-text-color">
              {{ note.createdAt | date : "mediumDate" }}
            </small>
          </div>
          <mat-icon fxFlex="4"
            *ngIf="canAccess(jobNotes?.stepDepartment) && note.canDelete"
            fxFlexOffset="3px"
            style="cursor: pointer"
            (click)="onRemoveNote(note.noteId, jobNotes.notes)"
            >delete
          </mat-icon>
        </div>
        </ng-container>
      </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<!-- files template -->
<ng-template #filesTemplate let-item="item">
  <div class="job-notes" fxLayout="column">
    <div fxFlex fxLayoutAlign="center center" *ngIf="myFiles()?.length == 0">
      <small class="primary-text-color"> No files available. </small>
    </div>
    <div style="overflow: auto" fxLayout="column">
      <ng-container *ngIf="myFiles()?.length > 0">
        <ng-container *ngFor="let file of myFiles(); let index = index">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            class="example-box secondary-bg-color border"
            *ngIf="IsAccessUser(file)"
          >
            <img [src]="getFileSrc(file)" (click)="onShowFile(file)" />
            <small
              fxFlex
              (click)="onShowFile(file)"
              style="cursor: pointer"
              class="primary-text-color"
            >
              {{ file.fileName }}</small
            >

            <div fxLyout="row" fxLayoutAlign="center center">
              <button
                mat-icon-button
                [disabled]="isProofFileSharing || isCheckingForJobUpdate || isUpdatingWithPP"
                *ngIf="
                  file['isProofFile'] &&
                  this.job.items[this.selectedJobItemIndex]['stepActions'][
                    this.job.items[this.selectedJobItemIndex][
                      'currentStepIndex'
                    ]
                  ]['stepDepartment'].toLowerCase() == 'sales' &&
                  !file['isSharedToCustomer']
                "
                (click)="shareToCustomer(item, file)"
                matTooltip="Share with customer."
              >
                <mat-icon class="mat-icon-rtl-mirror pointer mat-icon" fxFlex
                  >reply</mat-icon
                >
              </button>
              <button
                mat-icon-button
                [disabled]="isProofFileSharing || isCheckingForJobUpdate || isUpdatingWithPP"
                *ngIf="
                  item.selectedStepIndex < 2 &&
                  file['isShowApprovalStatus'] &&
                  !file['isApproveArtwork'] &&
                  !file['isRejectArtwork']
                "
                [matTooltip]="
                  item.selectedStepIndex === 0
                    ? 'CSR approval required.'
                    : 'Pending Approval.'
                "
              >
                <mat-icon fxFlex class="reject-document"
                  >assignment_late
                </mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="file['isApproveArtwork']"
                [disabled]="isProofFileSharing || isCheckingForJobUpdate || isUpdatingWithPP"
                matTooltip="Artwork is approved by customer."
              >
                <mat-icon class="approve-document" fxFlex>done_all </mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="file['isRejectArtwork']"
                [disabled]="isProofFileSharing || isCheckingForJobUpdate || isUpdatingWithPP"
                matTooltip="Artwork is rejected by customer."
              >
                <mat-icon class="reject-document" fxFlex>
                  remove_done
                </mat-icon>
              </button>
            </div>

            <div fxLayout="column" fxLayoutGap="2px">
              <small fxFlex class="primary-text-color">
                By {{ file.createdBy }}
              </small>
              <small fxFlex class="primary-text-color">
                {{ file.createdAt | date : "mediumDate" }}
              </small>
            </div>

            <ng-container matColumnDef="action">
              <button [disabled]="isCheckingForJobUpdate || isUpdatingWithPP"
              mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button
                  *ngIf="
                    isPreview(file.fileName) && this.canAccessAddNoteAndFile()
                  "
                  mat-menu-item
                  (click)="onShowFile(file)"
                  class="primary-text-color grid-header-bg"
                >
                  <mat-icon class="icon">open_in_new</mat-icon>
                  <span>Preview</span>
                </button>
                <button
                  mat-menu-item
                  (click)="onFileDownload(file)"
                  *ngIf="this.canAccessAddNoteAndFile()"
                  class="primary-text-color grid-header-bg"
                >
                  <mat-icon class="icon">get_app</mat-icon>
                  <span>Download</span>
                </button>
                <button 
                  mat-menu-item
                  *ngIf="
                    file['canDelete'] &&
                    (this.permission.isAdminOrCSR() ||
                      (this.auth
                        .getUser()
                        .currentDepartment.departmentName.toLowerCase() ===
                        file.createdByDepartment?.name?.toLowerCase() &&
                        file.createdByDepartment?.name?.toLowerCase() ===
                          item['stepActions'][item['currentStepIndex']][
                            'stepDepartment'
                          ].toLowerCase()))
                  "
                  (click)="onRemoveFile(file.fileId, item.files)"
                  class="primary-text-color grid-header-bg"
                >
                  <mat-icon class="icon">delete</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng2-pdfjs-viewer [externalWindow]="true" #externalPdfViewer [zoom]="100">
</ng2-pdfjs-viewer>
