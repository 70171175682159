import { Injectable } from "@angular/core";
@Injectable()
export class ShipmentUpsService {

    package() {
        return {
            "Description": "",
            "Packaging": {
                "Code": ""
            },
            "PackageWeight": {
                "UnitOfMeasurement": {
                    "Code": "LBS"
                },
                "Weight": ""
            },
            "PackageServiceOptions": ""
        }
    }

    request() {
        return {
            "ShipmentRequest": {
                "Shipment": {
                    "Description": "",
                    "Shipper": {
                        "Name": "",
                        "AttentionName": "",

                        "Phone": {
                            "Number": ""
                        },

                        "ShipperNumber": "",
                        "Address": {
                            "AddressLine": "",
                            "City": "",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": ""
                        }
                    },
                    "ShipTo": {
                        "Name": "",
                        "AttentionName": "",
                        "Phone": {
                            "Number": ""
                        },
                        //"FaxNumber": "1234567999",
                        //"TaxIdentificationNumber": "456999",
                        "Address": {
                            "AddressLine": "",
                            "City": "",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": ""
                        }
                    },
                    "ShipFrom": {
                        "Name": "ShipperName",
                        "AttentionName": "AttentionName",
                        "Phone": {
                            "Number": ""
                        },
                        //"FaxNumber": "1234567999",
                        //"TaxIdentificationNumber": "456999",
                        "Address": {
                            "AddressLine": "",
                            "City": "",
                            "StateProvinceCode": "",
                            "PostalCode": "",
                            "CountryCode": ""
                        }
                    },
                    "PaymentInformation": {
                        "ShipmentCharge": {
                            "Type": "01",
                            "BillShipper": {
                                "AccountNumber": ""
                            }
                        }
                    },
                    "Service": {
                        "Code": "",
                        "Description": ""
                    },
                    "Package": [],
                    "ItemizedChargesRequestedIndicator": "",
                    "RatingMethodRequestedIndicator": "",
                    "TaxInformationIndicator": "",
                    "ShipmentRatingOptions": {
                        "NegotiatedRatesIndicator": ""
                    }
                },
                "LabelSpecification": {
                    "LabelImageFormat": {
                        "Code": "GIF"
                    }
                }
            }
        }
    }
}
