<div class="padding-15">
  <div fxLayout="row">
    <!-- <mat-icon class="pointer" (click)="close()">arrow_back</mat-icon> -->
    <span fxFlex="5px"></span>
    <span fxFlex style="font-size: 18px" class="primary-text-color">
      Tracking -#{{ data.identificationNo }}
    </span>
    <span fxFlex="10px"></span>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div fxLayout="column" class="panel">
    <div fxLayout="row">
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">Job No:</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          shipment["jobNumber"] == 0 ? "" : shipment["jobNumber"]
        }}</span>
      </div>
      <span fxFlex></span>
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">Customer:</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          shipment["toAddress"]["customer"]
        }}</span>
      </div>
    </div>
    <div fxLayout="row">
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">Packages</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          shipment["packages"]?.length
        }}</span>
      </div>
      <span fxFlex></span>
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">ShipTo:</strong>
        <span *ngIf="isLoad" class="primary-text-color"
          >{{ shipment["toAddress"]["address"] }},{{
            shipment["toAddress"]["city"]
          }},{{ shipment["toAddress"]["postalCode"] }}</span
        >
      </div>
    </div>
    <div fxLayout="row">
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">Shipped/Billing:</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          shipment["createdDate"] | date : "M/d/yy, h:mm a"
        }}</span>
      </div>
      <span fxFlex></span>
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">UPS Service:</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          ups["description"]
        }}</span>
      </div>
    </div>
    <div fxLayout="row">
      <div fxLayoutGap="10px">
        <strong class="primary-text-color">Billing Weight:</strong>
        <span *ngIf="isLoad" class="primary-text-color">{{
          shipment["billingWeight"]
        }}</span>
      </div>
    </div>
  </div>

  <div class="panel">
    <div style="margin-left: 12px; margin-bottom: 5px; font-size: 19px">
      <strong class="primary-text-color">
        {{ shipmentStatus.description }}
      </strong>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <span class="step active"></span>
      <div fxLayout="row">
        <span
          [class.active]="
            shipmentStatus.status === 'SDD' || shipmentStatus.status === 'DEL'
          "
          class="step-line"
        ></span>
        <span
          [class.active]="
            shipmentStatus.status === 'SDD' || shipmentStatus.status === 'DEL'
          "
          class="step active"
        ></span>
      </div>
      <div fxLayout="row">
        <span
          [class.active]="shipmentStatus.status === 'DEL'"
          class="step-line"
        ></span>
        <span
          [class.active]="shipmentStatus.status === 'DEL'"
          class="step"
        ></span>
      </div>
    </div>
    <div
      style="margin-left: 12px; margin-top: 10px"
      fxLayout="row"
      fxLayoutGap="230px"
    >
      <div fxLayout="column">
        <strong class="primary-text-color">{{ shipmentStatus.label1 }}</strong>
        <strong style="font-size: 16px" fxLayout="row" fxLayoutGap="10px">
          <span class="primary-text-color">{{ shipmentStatus.day }}</span>
          <span class="primary-text-color">{{ shipmentStatus.date }}</span>
        </strong>
      </div>
      <div fxLayout="column">
        <strong class="primary-text-color">{{ shipmentStatus.label2 }}</strong>
        <strong style="font-size: 16px" class="primary-text-color">{{
          shipmentStatus.time
        }}</strong>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="grid">
      <!-- Header -->
      <div
        class="grid-row"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="center center"
      >
        <span fxFlex="20"
          ><strong class="primary-text-color">Date</strong></span
        >
        <span fxFlex="55"
          ><strong class="primary-text-color">Status</strong></span
        >
        <span fxFlex="25"
          ><strong class="primary-text-color">Location</strong></span
        >
      </div>
      <!-- Record -->
      <div style="max-height: 200px; overflow: auto;">
        <div
          class="grid-row"
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="center center"
          *ngFor="let activity of shipmentActivity"
          class="primary-text-color padding-5"
        >
          <span fxFlex="20"
            >{{ activity.date }}<span fxFlex="5px"></span
            >{{ activity.time }}</span
          >
          <span fxFlex="55" class="text-ellipsis">{{
            activity.description
          }}</span>
          <span fxFlex="25" class="text-ellipsis">
            <span *ngIf="activity.city !== ''">{{ activity.city }}, </span>
            <span *ngIf="activity.stateProvince !== ''">
              {{ activity.stateProvince }},
            </span>
            <span>{{ activity.country }}</span>
            <span *ngIf="activity.postalCode !== ''">
              - {{ activity.postalCode }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
