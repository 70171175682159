import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/shared/interfaces/user';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthUserService } from 'src/app/shared/services/auth-user.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-job-chat',
  templateUrl: './job-chat.component.html',
  styleUrls: ['./job-chat.component.scss']
})
export class JobChatComponent implements OnInit {

  @Input() JobItem : any ;
  @Output() ShowPreview = new EventEmitter<any>();

  note : string = "";
  addNoteLoading : boolean = false;
  NoteTypes = [{id:0,type:'Select Ink'},{id:1,type:'Front Ink'},{id:2,type:'Back Ink'},];
  NoteTypeSelected = {id:0,type:'Select Ink'};
  itemDocuments = [];
  addDocumentLoading = false;
  user:User;
  isShowPreview = false;
  previewDocument;
  previewUrl;
  isAdmin = false;
  isCsr = false;
  currentToast = null;
  currentSelectedNote = null;
  addNoteValidate: boolean = true;
  addFileValidate: boolean = true;
  isLeaveNote: boolean = true;
  leaveNote: string = 'Leave Note';

  constructor(public documentService:DocumentService,
    private toast:ToastService,
    private auth:AuthUserService,
    private api:ApiService,public dialog: MatDialog,
    private permission : PermissionService,
    private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.isAdmin = this.permission.isAdmin();
    this.isCsr = this.permission.isCSR();
    this.user = this.auth.getUser();
    this.attachImageUrl();
    this.managePermissions();
  }
  
  attachImageUrl() {
    this.JobItem.orderItemNotesAndDocs.forEach(_noteOrDoc => {
      if (_noteOrDoc['isDoc']) {
        _noteOrDoc['url'] = !_noteOrDoc['url'] || _noteOrDoc['url'] === null ? this.documentService.getDocumentUrl(_noteOrDoc, 'customer') : _noteOrDoc['url'];
      }
    });
  }

  openToast(message) {
    if (this.currentToast != null) {
      this.currentToast.dismiss();
    }
    this.currentToast = this._snackBar.open(message, null, {
      duration: 2500
    });
  }

  managePermissions(){
    this.JobItem.orderItemNotesAndDocs.forEach(_noteOrDoc => {
      if (_noteOrDoc['isDoc']) {

        //Manipulate can Preview doc
        if(_noteOrDoc['type'].toLowerCase() === 'image' || _noteOrDoc['type'].toLowerCase() === 'pdf' || _noteOrDoc['type'].toLowerCase() === 'tiff' || _noteOrDoc['type'].toLowerCase() === 'svg' || _noteOrDoc['type'].toLowerCase() === 'tif' || _noteOrDoc['type'].toLowerCase() === 'excel'){
          _noteOrDoc.canPreview = true;
        }else{
          _noteOrDoc.canPreview = false;
        }

        //Manipulate can download doc
        _noteOrDoc.canDownload = true;

        //Manipulate can delete doc
        if(_noteOrDoc.isCsrDocument && !_noteOrDoc.isRequiredApproveArtwork){
          if(this.isAdmin){
            if(_noteOrDoc.isCreatedByAdmin){
              if(_noteOrDoc.workflowUserId == this.user.userId){
                _noteOrDoc.canDelete = true;
              }
              else{
                _noteOrDoc.canDelete = false;
              }
            }else{
              _noteOrDoc.canDelete = true;
            }
          }else if(this.isCsr){
            if(_noteOrDoc.workflowUserId == this.user.userId){
              _noteOrDoc.canDelete = true;
            }
            else{
              _noteOrDoc.canDelete = false;
            }
          }
          
        }else{
          _noteOrDoc.canDelete = false;
        }

      }else{

        //Manipulate can delete note
        if(_noteOrDoc.isCsrNote){
          if(this.isAdmin){
            if(_noteOrDoc.isCreatedByAdmin){
              if(_noteOrDoc.workflowUserId == this.user.userId){
                _noteOrDoc.canDelete = true;
                _noteOrDoc.canEdit = true;
              }
              else{
                _noteOrDoc.canDelete = false;
                _noteOrDoc.canEdit = false;
              }
            }else{
              _noteOrDoc.canDelete = true;
              _noteOrDoc.canEdit = false;
            }
          }else if(this.isCsr){
              if(_noteOrDoc.workflowUserId == this.user.userId){
                _noteOrDoc.canDelete = true;
                _noteOrDoc.canEdit = true;
              }
              else{
                _noteOrDoc.canDelete = false;
                _noteOrDoc.canEdit = false;
              }
          }
        }
        else{
          _noteOrDoc.canDelete = false;
          _noteOrDoc.canEdit = false;
        }

      }
    });
  }

  selectNoteType(selectedNoteType : any){
    this.NoteTypeSelected = selectedNoteType;
  }

  editNote(id,index){
    this.currentSelectedNote = this.JobItem.orderItemNotesAndDocs[index];
    this.note = this.currentSelectedNote.text;
    if(this.currentSelectedNote.isFrontInkNote){this.NoteTypeSelected = this.NoteTypes.find(o => o.id == 1);}
    if(this.currentSelectedNote.isBackInkNote){this.NoteTypeSelected = this.NoteTypes.find(o => o.id == 2);}
  }

  saveNote(){
    this.addNoteValidate = true;
    if(this.note == null || this.note == ""){return this.addNoteValidate = false;}

    if (this.currentSelectedNote) {
      this.addNoteLoading = true;
      let reqObj = {
        params: {
          ID: this.currentSelectedNote.id,
        },
        data: {
          id: this.currentSelectedNote.id,
          text: this.note,
          isFrontInkNote: this.NoteTypeSelected.id == 1 ? true : false,
          isBackInkNote: this.NoteTypeSelected.id == 2 ? true : false,
        },
      };

      this.api.request("UPDATE_ORDER_ITEM_NOTE", reqObj).subscribe((res) => {
        if (res["success"]) {
          var updatedNoteIndex = this.JobItem.orderItemNotesAndDocs.findIndex(
            (n) => n.id == this.currentSelectedNote.id && n.isNote
          );
          if (updatedNoteIndex > -1) {
            this.JobItem.orderItemNotesAndDocs[updatedNoteIndex].text = res.result.text;
            this.JobItem.orderItemNotesAndDocs[updatedNoteIndex].isFrontInkNote =
              res.result.isFrontInkNote;
              this.JobItem.orderItemNotesAndDocs[updatedNoteIndex].isBackInkNote =
              res.result.isBackInkNote;
            this.addNoteLoading = false;
            this.currentSelectedNote = null;
            this.note = "";
            this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 0);
          }
        }
      });
    } else {
      this.addNoteLoading = true;
      let reqObj = {
        csrName: this.user.firstName + " " + this.user.lastName,
        orderItemId: this.JobItem.itemNo,
        text: this.note,
        isFrontInkNote: this.NoteTypeSelected.id == 1 ? true : false,
        isBackInkNote: this.NoteTypeSelected.id == 2 ? true : false,
      };

      this.api.request("ADD_ORDER_ITEM_NOTE", { data: reqObj }).subscribe(
        (res) => {
          if (res["success"]) {
            var createdNote = res["result"];
            this.openToast(res["message"]);
            createdNote.canDelete = true;
            createdNote.canEdit = true;
            this.JobItem.orderItemNotesAndDocs.push(createdNote);
            this.note = "";
            this.NoteTypeSelected = this.NoteTypes.find((o) => o.id == 0);
          }
          this.addNoteLoading = false;
        },
        (error) => {
          this.addNoteLoading = false;
        }
      );
    }
   }

  selectDocuments(event) {
    for (let index = 0; index < event.length; index++) {
      const reader = new FileReader();
      let selectedDoc = {
        file: event[index]
      }

      const fileName = event[index].name;
      const fileExtension = fileName.split('.')[fileName.split('.').length - 1].replace('"', '');
      let docType = this.documentService.getDocumentType(fileExtension);
      
      if(docType == "Document"){this.toast.show('Please Select PDF FILES , EXCEL FILES and IMAGE FILES Only.');continue;}

      if (docType != 'Image') {
        selectedDoc['url'] = this.documentService.getDocumentSrc(docType);
      } else {
        reader.readAsDataURL(event[index]);
        reader.onload = (event: any) => {
          selectedDoc['url'] = event.target['result'];
        }
      }

      selectedDoc['id'] = null;
      selectedDoc['name'] =fileName;
      this.itemDocuments.push(selectedDoc);
    }
  }

  saveDocuments(){
    this.addFileValidate = true;
    if(this.itemDocuments.length == 0){
      this.addFileValidate = false;
    }
    if (this.itemDocuments && this.itemDocuments.length > 0) {
      this.addDocumentLoading = true;
      const userName = this.user.firstName + ' ' + this.user.lastName;
      let formdata = new FormData();
      this.itemDocuments.forEach(document => {
        formdata.append('files', document.file);
      });
      formdata.append('orderItemId', this.JobItem.itemNo.toString());
      formdata.append('csrName', userName);
      this.api.request('ADD_ORDER_ITEM_DOCUMENTS', { data: formdata }).subscribe(res => {
        if (res['success']) {
          this.itemDocuments = [];
          res['result'].forEach(document => {
            document['url'] = !document['url'] || document['url'] === null ? this.documentService.getDocumentUrl(document, 'customer') : document['url'];
            document.canDelete = true;
            document.canPreview = true;
            document.canDownload = true;
          })
          this.JobItem.orderItemNotesAndDocs = [...this.JobItem.orderItemNotesAndDocs,...res['result']];
          this.openToast(res['message']);
        }
        this.addDocumentLoading = false;
      }, error => { this.addDocumentLoading = false;});
      }
  }

  deleteNote(id: number, index: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '200px',
      width: '450px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to delete this note?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.request('DELETE_ORDER_ITEM_NOTE', { params: { ID: id } }).subscribe(res => {
          if (res['success']) {
            this.JobItem.orderItemNotesAndDocs.splice(index,1);
          }
        });
      }
    });
    
  }

  download(id:number){ 
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    });
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', this.documentService.getCustomerPortalDownoadUrl(id));
    linkElement.dispatchEvent(clickEvent);
  }

  preview(document){
    if(document['type'].toLowerCase() === 'image' || document['type'].toLowerCase() === 'pdf' ||
     document['type'].toLowerCase() === 'tiff' || document['type'].toLowerCase() === 'svg' ||
     document['type'].toLowerCase() === 'tif' || document['type'].toLowerCase() === 'excel'){
      this.ShowPreview.emit({previewDocument : document , previewUrl : this.documentService.getCustomerPortalDownoadUrl(document.id)});
    }
    else{
      this.toast.show("Preview is unavailable");
    }
  }

  deleteDocument(id: number, index: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: '200px',
      width: '450px',
      data: {
        type: 'Confirm',
        message: 'Are you sure you want to delete this document?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.request('DELETE_ORDER_ITEM_DOCUMENT', { params: { ID: id } }).subscribe(res => {
          if (res['success']) {
            this.JobItem.orderItemNotesAndDocs.splice(index,1);
          }
        })
      }
    });
  }

  removeSelectedDocument(index){
    this.itemDocuments.splice(index, 1);
  }

  selectChatSection(value: string){
   if(value == 'Leave Note'){
    this.isLeaveNote = true;
   }
   if(value == 'Document'){
    this.isLeaveNote = false;
   }
  }
}
