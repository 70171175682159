import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-job-send-back',
  templateUrl: './job-send-back.component.html',
  styleUrls: ['./job-send-back.component.scss']
})
export class JobSendBackComponent implements OnInit {

  stepCount: number;
  steps = [];
  selectedStep='';
  stepJobItems=[];
  reason = '';
  jobItemSelected:boolean = true;
  isValidate:boolean = true;
  warningMessage = "";
  showWarningMessage = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,
  public dialogRef: MatDialogRef<JobSendBackComponent>) { }


  ngOnInit() {
    this.steps = this.data.steps;
    this.warningMessage = this.data.warningMessage;
    if(this.warningMessage != null && this.warningMessage != undefined && this.warningMessage.trim().length > 0){
      this.showWarningMessage = true;
    }
  }

  selectStep() {
    this.stepJobItems = [];
    this.data.jobItems.forEach((item, index) => {
      if(item.status == 2){return;}
      const stepIndex = item.steps.split('|').findIndex(s => s == this.selectedStep );
      if (stepIndex >= 0 && stepIndex !== item.currentStepIndex) {
        const step = {
          jobItemIndex:index,
          changeStepIndex: stepIndex,
          currentStepAction: item.stepActions[item.currentStepIndex],
          jobItem: item,
          select: item['jobItemId'] === this.data.currentJobItemId ? true: false
        }
        this.stepJobItems.push(step);
      }
    });
  }

  onSubmit() {

    this.isValidate = true;
    this.jobItemSelected = true;

    if (this.selectedStep === '' || this.reason === ''  ) {
      this.isValidate = false;
      return;
    }

    if(this.stepJobItems.filter(i => i.select).length === 0){
      this.jobItemSelected = false;
    }

     const obj = {
       selectedItems: this.stepJobItems.filter(i => i.select),
       reason: this.reason
     }

     this.dialogRef.close(obj);
  }

}
