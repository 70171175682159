import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobsPermissionService {

  constructor() {}

  public canMoveToProduction(jobItem,firstStepDeptName,isPaid,isCreatedUserIsBypass) : Boolean{
    var isRequiredApproval = jobItem['isRequiredApproveArtwork'];
    
    if(isRequiredApproval){
      var currentStepIndex = jobItem['currentStepIndex'];
      if(firstStepDeptName.toLowerCase() === "design" || firstStepDeptName.toLowerCase() === "sales"){
        if(currentStepIndex === 1){
          if(isPaid || isCreatedUserIsBypass === true){
            return true;
          }
        }
        else{
          return true;
        }
      }
      else{
        return false;
      }

    }
    else{
      if(isPaid === true || isCreatedUserIsBypass){
        return true;
      }
      else{
        return false;
      }
    }
  }
}
