import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ConfigurationState } from '../../../state/configuration.state';
import { Observable } from 'rxjs';
import { Department } from './../../../../../shared/interfaces/department';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoadDepartments } from '../../../state/configuration.actions';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
  selector: 'app-action-steps-create',
  templateUrl: './action-steps-create.component.html',
  styleUrls: ['./action-steps-create.component.scss']
})
export class ActionStepsCreateComponent implements OnInit {


  @Select(ConfigurationState.AllDepartments) departments$: Observable<Department[]>;
  form: UntypedFormGroup;
  actionCodeValidate = true;
  actionNameValidate = true;
  stepActionValidate = true;
  additionalInfo = false;
  actions = [];
  title = 'Add Step';
  loading = false;
  IsStepActionAdd = false;



  constructor(private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<ActionStepsCreateComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public step,
    private toast: ToastService) { }

  ngOnInit() {
    this.form = this.initForm();

    //load all department if department not exist
    this.store.dispatch(new LoadDepartments());

    if (this.step) {
      this.title = 'Edit Step';
      if (this.step.additionalInfo !== '' && this.step.additionalInfo !== null) {
        this.form.controls.additionalInfo.patchValue(this.step.additionalInfo);
        this.additionalInfo = true;
      }
      this.form.controls.name.patchValue(this.step.stepTitle);
      this.form.controls.department.patchValue(this.step.department.departmentId);
      this.step.stepActions.forEach(action => {
        this.actions.push(action);
      });
    }

  }

  initForm = (): UntypedFormGroup =>
    this.formBuilder.group({
      name: [''],
      department: [''],
      additionalInfo: [''],
      action: ['']
    })

  hasError = (field: string, errorName: string): boolean =>
    this.form.get(field).errors ? this.form.get(field).errors[errorName] : false


  onCloseClick() {
    this.dialogRef.close();
  }

  addAction() {
    // validate step actions
    this.stepActionValidate = true;
    if (this.form.value.action == "" || this.form.value.action == undefined) {
      this.stepActionValidate = false;
      return;
    }

    // Check already action is exist or not
    if (this.actions.length > 0) {
      let action = this.actions.find(a => a.actionName == this.form.value.action);
      if (!action)
        this.actions.push({ 'actionId': undefined, 'actionName': this.form.value.action });
      this.IsStepActionAdd = true;
    } else
      this.actions.push({ 'actionId': undefined, 'actionName': this.form.value.action });

    this.form.controls.action.reset();
  }

  onRemoveClick(action) {
    let index = this.actions.indexOf(action);
    this.actions.splice(index, 1);
  }

  onSubmitClick() {

    if (this.loading) { return; }

    // validate create step form
    this.stepActionValidate = true;

    if (this.form.invalid) {
      if (this.actions.length == 1)
        this.stepActionValidate = false;
      return;
    }

    if (this.actions.length == 0 && (this.form.value.action == undefined || this.form.value.action == '')) {
      this.stepActionValidate = false;
      return;
    }

    if (this.form.value.action != undefined && this.form.value.action != '')
      this.actions.push({ 'actionId': undefined, 'actionName': this.form.value.action });
    //create request object
    let requestObject = {
      stepTitle: this.form.value.name,
      departmentId: this.form.value.department,
      actions: this.actions,
      additionalInfo: this.additionalInfo ? this.form.value.additionalInfo : '',
      IsStepActionAdd: this.IsStepActionAdd
    }

    if (this.step) {
      this.updateStep(requestObject);
      return;
    }
    this.saveSteps(requestObject);
  }

  saveSteps(requestObject) {
    this.loading = true;
    this.apiService.request('CREATE_STEP', { data: requestObject }).subscribe(res => {
      if (res['success']) {
        this.dialogRef.close(true);
        return;
      }
      this.loading = false;
      this.toast.show(res['message']);
    }, error => { this.loading = false; });
  }

  updateStep(requestObject) {
    requestObject.stepId = this.step.stepId
    let apiRequest = {
      params: { STEP_ID: this.step.stepId },
      data: requestObject
    }
    this.loading = true;
    this.apiService.request('EDIT_STEP', apiRequest).subscribe(res => {
      if (res['success']) {
        this.dialogRef.close(true);
        return;
      }
      this.toast.show(res['message']);
      this.loading = false;
    }, error => { this.loading = false; });
  }
}

